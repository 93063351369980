import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const MessagesPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'messagesPage' */ "./Messages")
      .catch( (e) => console.log("Page loading err", e) || Error404 );
  },
  loading: Loading,
});

export default MessagesPage;

