import * as actions from "./consts";
import { entitySetter } from "../utils";


const ACTION_HANDLERS = {
  [actions.SEED_TEMPLATES]: (state, { templates }) => entitySetter(state, templates),
  // [actions.CUSTOM_FIELDS_CREATE]: (state, { field }) =>  {
  //   return {
  //     ...state,
  //     [field.uuid]: field
  //   }
  // },
  // [actions.CUSTOM_FIELDS_UPDATE]: (state, {field}) => {
  //   return {
  //     ...state,
  //     [field.uuid]: field
  //   }
  // }
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
