import * as actions from "./consts";
import { key as FlowsPageState } from "./reducer";
import debounce from "lodash/debounce";
import { createSource } from "services/axios";
import { encodeToBase64, showError, showSuccess } from "services/utils";
import {
  loadFlows,
  loadFlow,
  updateFlow as theUpdateFlow,
  createFlow as theCreateFlow,
  copyFlow as theCopyFlow,
  archiveFlow,
  unarchiveFlow,
  cloneFlow } from "store/entities/flows/actions";

let loadMoreSource;

const cancelReasons = {
  DROP_SEARCH: "DROP_SEARCH",
  RESET_PAGE: "RESET_PAGE",
};


export const loadMore = () => (dispatch, getState) => {
  const flowsState = getState()[FlowsPageState];
  if (!flowsState) return Promise.resolve();
  if (flowsState.isLoading || flowsState.isError) {
    return Promise.resolve();
  }

  dispatch({
    type: actions.FLP_LOAD_REQUEST,
  });

  loadMoreSource = createSource();

  const params = {
    filter: encodeToBase64(flowsState.filters),
    offset: flowsState.ids.length,
    limit: 10,
  };

  return dispatch(loadFlows(params, loadMoreSource.token))
    .then((res) => {
      const { data, total, has_more } = res;
      const ids = data.map((el) => el.uuid);

      dispatch({
        type: actions.FLP_LOAD_SUCCESS,
        hasMore: has_more,
        ids,
        total,
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.FLP_LOAD_FAILED,
      });
      console.log("err", err);

      throw err;
    });
};



export const reset = () => {
  if (loadMoreSource) {
    loadMoreSource.cancel("Reset page");
  }

  return {
    type: actions.FLP_LEAVE,
  };
};

export const createFlow = (...params) => (dispatch, getState) =>
  dispatch(theCreateFlow(...params))
    .then((flow) => {
      // if (!getState().flowList.hasMore) {
      dispatch({
        type: actions.FLP_ADD_FLOW,
        id: flow.uuid,
      });
      // }

      return flow;
    });

export const archive = ({ uuid }) => (dispatch) => {
  return dispatch(archiveFlow({ uuid })).then(() => {
    dispatch({
      type: actions.FLP_DELETE_FROM_LIST,
      uuid,
    });
  });
};

export const unarchive = ({ uuid }) => (dispatch) => {
  return dispatch(unarchiveFlow({ uuid })).then(() => {
    dispatch({
      type: actions.FLP_DELETE_FROM_LIST,
      uuid,
    });
  });
};

export const copyFlow = (id, name) => async (dispatch, getState) => {
  let nodes = getState().entities.flows[id]?.nodes;
  if (!nodes) {
    const flow = await dispatch(loadFlow(id));
    nodes = flow.nodes;
  }

  return dispatch(theCopyFlow({ name, nodes }))
    .then((flow) => {
      dispatch({
        type: actions.FLP_ADD_FLOW,
        id: flow.uuid,
      });

      return flow;
    });
};

export const cloneAutomation = ({ flowVersionUuid, flowData, isTemplate }) => (dispatch) => {
  return dispatch(cloneFlow({ flowVersionUuid, flowData, isTemplate })).then(() => {
    dispatch({
      type: actions.FLP_P_CLEAR_IDS,
    });
    showSuccess("Automation successfully cloned");
    dispatch(loadMore());
  }).catch((err) => {
    showError(err.message);
  });
};

export const clearIds = () => (dispatch) => {
  dispatch({
    type: actions.FLP_P_CLEAR_IDS,
  });
};

export const updateFlow = (uuid, params) => (dispatch) => {
  return dispatch(theUpdateFlow(uuid, params)).then(() => {
    dispatch(clearIds());
    dispatch(loadMore());
  });
};

export const setFilters = (filters, needDebounce) => (dispatch, getState) => {
  dispatch({
    type: actions.FLP_P_SET_FILTERS,
    filters,
  });

  if (needDebounce) {
    debouncedDropSearch(dispatch);
  } else {
    dispatch(dropSearch());
  }
};

export const dropSearch = () => (dispatch) => {
  if (loadMoreSource) {
    loadMoreSource.cancel(cancelReasons.DROP_SEARCH);
  }

  dispatch({ type: actions.FLP_P_DROP_SEARCH });
};

const debouncedDropSearch = debounce((dispatch) => dispatch(dropSearch()), 1000);
