export const templatesPath = "/templates";

export const paths = {
  templatesStatic: `${templatesPath}/static`,
  templateDetails: `${templatesPath}/static/:templateId`,
};

export enum templateTypes {
  text = "text",
  email = "email",
}
