import { createBoundedUseStore, createStore } from "store/zustand/createStore";

type MainLayoutState = {
  isSideMenuOpen: boolean
}

export class MainLayoutService {
  public readonly store;
  public readonly useStore;

  constructor() {
    this.store = createStore<MainLayoutState>(
      "@feature/mainLayout",
      (set, get) => this.getInitialState(),
    );

    this.useStore = createBoundedUseStore(this.store);
  }

  public openSideMenu = () => {
    this.store.setState({
      isSideMenuOpen: true,
    });
  };

  public closeSideMenu = () => {
    this.store.setState({
      isSideMenuOpen: false,
    });
  };

  public toggleSideMenu = () => {
    this.store.setState((prevState) => ({
      isSideMenuOpen: !prevState.isSideMenuOpen,
    }));
  };

  private getInitialState(): MainLayoutState {
    return {
      isSideMenuOpen: false,
    };
  }
}


export const mainLayoutService = new MainLayoutService();
