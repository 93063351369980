import type { ServerStatus } from "./types";
import { serverStatuses as theServerStatuses } from "@gs/core/domain/LinkedinServer/LinkedinServer";

export const serverStatuses = theServerStatuses;

export enum SERVER_ACTIONS {
  SERVERS_SET = "SERVERS_SET",
}

export const serverUIStatuses: Partial<Record<ServerStatus, { statusName: string; color: string }>> = {
  [serverStatuses.LOGINING]: {
    statusName: "Connecting...",
    color: "#7C98B6",
  },
  [serverStatuses.PASS_CODE_RESENT]: {
    statusName: "Connecting...",
    color: "#7C98B6",
  },
  [serverStatuses.PROCESSING]: {
    statusName: "Connecting...",
    color: "#7C98B6",
  },
  [serverStatuses.NEED_CODE]: {
    statusName: "Confirmation Code Required",
    color: "#F2545B",
  },
  [serverStatuses.LOGIN_ERROR]: {
    statusName: "Login",
    color: "#F2545B",
  },
  [serverStatuses.PASS_CODE_ERROR]: {
    statusName: "Confirmation Code Required",
    color: "#F2545B",
  },
  [serverStatuses.BUSY]: {
    statusName: "Active",
    color: "#00BDA5",
  },
  [serverStatuses.RESTRICTED]: {
    statusName: "Restricted",
    color: "#F2545B",
  },
  [serverStatuses.CANCELED]: {
    statusName: "Canceled",
    color: "#F2545B",
  },
};
