/* IN PROGRESS */
import nodeTypes from "../nodeTypes";
import getNodeById from "./getNodeById";
import createNode from "./createNode";

export function getScopeRootId(nodeId, model) {
  const node = getNodeById(nodeId, model);
  const firstCommonNode = model.nodes.find((node) => node.serverNodeId === model.flow.version.first_common_node_id);
  const stopperNodeId = firstCommonNode.before[0];
  let nextParentNodeId = node.before[0];
  let curNodeId = nodeId;

  while (nextParentNodeId) {
    if (nextParentNodeId === stopperNodeId) throw "no incomplete root node";
    const parentNode = getNodeById(nextParentNodeId, model);
    if (parentNode.root) {
      const rootNode = getNodeById(parentNode.root, model);
      curNodeId = rootNode.id;
      nextParentNodeId = rootNode.before[0];
      continue;
    }
    if (parentNode.after.length > 1) {
      return {
        rootNodeId: parentNode.id,
        branchIndex: parentNode.after.indexOf(curNodeId),
      };
    }
    curNodeId = nextParentNodeId;
    nextParentNodeId = parentNode.before[0];
  }

  throw new Error("can't find parent with multi child");
}

function getLeftSubBrunchesNodeIdsInScope(startNodeId, rootNodeId, model) {
  const startNode = getNodeById(startNodeId, model);
  // check sub brunches of own brunch
  const leftSubBranchesNodeIds = [];
  let parentNodeId = startNode.before[0];
  let currentNodeId = startNode.id;

  while (currentNodeId !== rootNodeId) {
    const parentNode = getNodeById(parentNodeId, model);
    if (parentNode.root) {
      currentNodeId = parentNode.root;
      parentNodeId = getNodeById(currentNodeId, model).before[0];
      continue;
    }
    const currentNodeBranchIndex = parentNode.after.indexOf(currentNodeId);
    const idsToAdd = parentNode.after.slice(0, currentNodeBranchIndex);
    for (let i = idsToAdd.length - 1; i >= 0; i--) {
      leftSubBranchesNodeIds.unshift(idsToAdd[i]);
    }
    currentNodeId = parentNode.id;
    parentNodeId = parentNode.before[0];
  }

  return leftSubBranchesNodeIds;
}

function findBeforeIndexInUnionPlus(startNodeId, rootNodeId, model) {
  const rootNode = getNodeById(rootNodeId, model);
  const unionNode = getNodeById(rootNode.union, model);
  const nodeIdsToCheck = getLeftSubBrunchesNodeIdsInScope(startNodeId, rootNodeId, model);

  while (nodeIdsToCheck.length) {
    const nodeId = nodeIdsToCheck.pop();
    const node = getNodeById(nodeId, model);
    let diveNodeIds = [node.id];
    while (diveNodeIds.length) {
      const diveNodeId = diveNodeIds.pop();
      const diveNode = getNodeById(diveNodeId, model);
      const resIndex = unionNode.before.indexOf(diveNode.id);
      if (resIndex !== -1) {
        return resIndex + 1;
      } else {
        diveNodeIds = diveNodeIds.concat(diveNode.after);
      }
    }
  }

  return 0;
}

export function deleteEnd(endNodeId, model) {
  let rootNodeId;
  let branchIndex;
  try {
    const res = getScopeRootId(endNodeId, model);
    rootNodeId = res.rootNodeId;
    branchIndex = res.branchIndex;
  } catch (e) {
    console.log("last end, cant be deleted");
    return;
  }
  const rootNode = getNodeById(rootNodeId, model);
  const endNode = getNodeById(endNodeId, model);

  if (rootNode.union) {
    const indexToInsert = findBeforeIndexInUnionPlus(endNodeId, rootNodeId, model);
    const unionNode = getNodeById(rootNode.union, model);
    const prevPlus = getNodeById(endNode.before[0], model);
    prevPlus.after = [unionNode.id];
    unionNode.before.splice(indexToInsert, 0, prevPlus.id);
  } else if (rootNode.type === nodeTypes.START) {
    const firstCommonNode = model.nodes.find((node) => node.serverNodeId === model.flow.version.first_common_node_id);
    const newUnionNode = createNode(model, {
      type: nodeTypes.PLUS,
      root: rootNode.id,
      before: [endNode.before[0], firstCommonNode.before[0]],
      after: [firstCommonNode.id],
      union: null,
    });
    rootNode.union = newUnionNode.id;
    newUnionNode.before.forEach((id) => {
      const node = getNodeById(id, model);
      node.after = [newUnionNode.id];
    });
  } else {
    const lastBranchIndex = rootNode.after.length - 1;
    const pairBranchIndex = lastBranchIndex === branchIndex ? lastBranchIndex - 1 : lastBranchIndex;
    const pairBeforePlusNodeId = rootNode.after[pairBranchIndex];
    const pairBeforePlusNode = getNodeById(pairBeforePlusNodeId, model);
    const newUnionNode = createNode(model, {
      type: nodeTypes.PLUS,
      root: rootNode.id,
      before: [endNode.before[0], pairBeforePlusNode.id],
      after: pairBeforePlusNode.after,
      union: null,
    });
    rootNode.union = newUnionNode.id;
    newUnionNode.before.forEach((id) => {
      const node = getNodeById(id, model);
      node.after = [newUnionNode.id];
    });

    const nodeAfterUnion = getNodeById(newUnionNode.after[0], model);
    nodeAfterUnion.before = [newUnionNode.id];
  }

  model.nodes = model.nodes.filter((node) => node.id !== endNodeId);
}

export default deleteEnd;
