import * as actions from "./consts";
import find from "lodash/find";
import pull from "lodash/pull";
import cloneDeep from "lodash/cloneDeep";
import differenceBy from "lodash/differenceBy";
import { getFirstServerNodeId, getNodeTypeByAction } from "./utils/description";
import { getDefaultBranchesCount, stepTypes } from "./utils/consts";
import { createStep, deleteStep, editStep, loadFlow, moveStep, startFlow as theStartFlow, saveFlowVersion } from "store/entities/flows/actions";
import modelAddNode from "store/entities/flows/model/modifications/addNode";
import modelDeleteNode from "store/entities/flows/model/modifications/deleteNode";
import modelAddBranch from "store/entities/flows/model/modifications/addBranch";
import modelRemoveBranch from "store/entities/flows/model/modifications/removeBranch";
import moveNode from "store/entities/flows/model/modifications/moveNode";
import { getBeforeOfPlus, getNextServerNodeId } from "store/entities/flows/model/serverRelationships";
import restoreServerFlow from "store/entities/flows/model/restoreServerFlow";
import nodeTypes from "store/entities/flows/model/nodeTypes";
import createSourceNode from "store/entities/flows/model/handlers/createSourceNode";
import deleteEnd from "store/entities/flows/model/handlers/deleteEnd";
import recalculate from "store/entities/flows/model/recalculate";
import deleteSourceNode from "store/entities/flows/model/handlers/deleteSourceNode";
import { wait } from "services/utils";
import { setInvalidNodes } from "store/entities/flowErrors/actions";
import getAxios from "services/axios";
import isNull from "lodash/isNull";
import { showError } from "services/utils";

const axios = getAxios("flowV2");

export const parseFlow = (flow) => ({
  type: actions.FDP_PARSE,
  flow,
});

const createEndForRule = (flowId, uiEnd, before) => (dispatch, getState) => {
  const data = {
    type: stepTypes.END,
    payload: {},
    before,
    after: [],
  };

  return dispatch(createStep(flowId, data))
    .then((step) => {
      const model = getState().flowDetails.workTable;
      model.flow.nodes.push(step);
      uiEnd.serverNodeId = step.id;
    });
};

const generateNewId = (id, ids) => {
  if (ids.has(id)) {
    return generateNewId(id + 1, ids);
  } else {
    return id;
  }
};

export const saveVersion = () => async (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  const phantomSourceUiNode = model.nodes.find((node) => node.type === nodeTypes.SOURCE && !node.sourceId);
  const firstCommonServerNodeId = getNextServerNodeId(model, phantomSourceUiNode.after[0]);

  const contact_sources = model.flow.version.contact_sources.map((source) => {
    const sourceUiNode = model.nodes.find((node) => node.sourceId === source.id);
    const after_id = getNextServerNodeId(model, sourceUiNode.after[0]);

    return {
      ...source,
      after_id,
    };
  });

  const ids = new Set();
  newModel.flow.nodes.forEach(({ id }) => ids.add(id));
  let isNotUniqueIds = false;
  const nodesForServer = newModel.flow.nodes.map((node) => {
    const newPayload = cloneDeep(node.payload);
    if (newPayload.subtasks) {
      newPayload.subtasks.forEach((item, i) => {
        const subtaskId = generateNewId(item.id, ids);
        if (subtaskId !== item.id) {
          isNotUniqueIds = true;
          newPayload.subtasks.forEach((item2, j) => {
            if (item2.after[0]?.node_id === item.id) {
              newPayload.subtasks[j].after[0].node_id = subtaskId;
            }
            if (item2.before[0]?.node_id === item.id) {
              newPayload.subtasks[j].before[0].node_id = subtaskId;
            }
          });
          newPayload.subtasks[i].id = subtaskId;

        }
        if (ids.has(subtaskId)) {
          console.error("Id is not unique");
        }
        ids.add(subtaskId);
      });
    }
    const newNode = {
      ...node,
      payload: newPayload,
      type: node.action || "end",
      action: undefined,
    };
    delete newNode.payload.automation;
    return newNode;
  });

  const newVersion = await dispatch(saveFlowVersion(model.flow.uuid, {
    nodes: nodesForServer,
    contact_sources,
    first_common_node_id: firstCommonServerNodeId,
  }));

  dispatch({
    type: actions.FDP_VERSION_SAVE_SUCCESS,
    version: newVersion,
  });
  isNotUniqueIds && window.location.reload();
  return newVersion;
};

// add `id` `before` `after`
const handleSubtasks = (subtasks, model) => {
  for (const subtask of subtasks) {
    if (!subtask.id) {
      subtask.id = model.nextServerNodeId++;
    }
  }
  for (let i = 0; i < subtasks.length; i++) {
    const prevSubtask = subtasks[i - 1];
    const nextSubtask = subtasks[i + 1];
    const subtask = subtasks[i];
    if (prevSubtask) {
      subtask.before = [{ node_id: prevSubtask.id, branch_id: 1 }];
    } else {
      subtask.before = [];
    }
    if (nextSubtask) {
      subtask.after = [{ node_id: nextSubtask.id, branch_id: 1 }];
    } else {
      subtask.after = [];
    }
  }
};

export const validateNode = (serverNode) => (dispatch) => {
  const newNode = { ...serverNode };
  if (newNode.action) {
    newNode.type = newNode.action;
    delete newNode.action;
  }
  return axios.post("/api/flow-versions/validate-node", newNode);
};

export const addNode = (uiNode, { type, action, payload }) => (dispatch, getState) => {
  const newPayload = cloneDeep(payload);
  const newNode = cloneDeep(uiNode);
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);

  const nextUiNode = newModel.nodes.find((node) => node.id === newNode.after[0]);
  const afterCount = getDefaultBranchesCount(type, newPayload);
  const targetServerNodeId = getNextServerNodeId(newModel, newNode.id);
  const after = [];
  for (let i = 1; i <= afterCount; i++) {
    if (newPayload && newPayload.branches) {
      newPayload.branches[i - 1].branch_id = i;
    }
    after.push({
      branch_id: i,
      node_id: targetServerNodeId,
    });
  }

  if (newPayload.subtasks) {
    handleSubtasks(newPayload.subtasks, newModel);
  }
  const step = {
    id: newModel.nextServerNodeId,
    type,
    action,
    payload: newPayload,
    before: getBeforeOfPlus(newModel, newNode.id),
    after,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    automation: newPayload?.automation || "auto",
  };
  if (nextUiNode.serverNodeId === newModel.flow.version.first_common_node_id) {
    newModel.flow.version.first_common_node_id = step.id;
  }
  newModel.flow.nodes.push(step);
  newModel.nextServerNodeId++;
  const newUiNode = modelAddNode(newModel, newNode.id, afterCount);
  newUiNode.serverNodeId = step.id;

  restoreServerFlow(newModel);

  const stepWithoutAction = { ...cloneDeep(step), type: type === stepTypes.END ? type : action };
  delete stepWithoutAction.payload?.automation;
  delete stepWithoutAction.action;

  return dispatch(validateNode(stepWithoutAction)).then(() => {
    dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
  }).catch((error) => {
    throw error;
  });
};


export const dndNode = (nodeId, plusId) => (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  moveNode(newModel, nodeId, plusId);
  restoreServerFlow(newModel);
  dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
};

export const deleteNode = (nodeId) => (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const node = model.nodes.find((node) => node.id === nodeId);
  // if End
  if (node.after.length === 0) {
    return dispatch(removeEnd({ endId: nodeId }));
  }

  const newModel = cloneDeep(model);
  modelDeleteNode(newModel, nodeId);
  restoreServerFlow(newModel);
  dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
};


export const selectVersion = (version) => (dispatch, getState) => {
  const newFlow = cloneDeep(getState().flowDetails.workTable.flow);
  newFlow.nodes = version.nodes.map((node) => ({
    ...node,
    action: node.type,
    type: getNodeTypeByAction(node.type),
  }));
  newFlow.version = version;
  dispatch(parseFlow(newFlow));
};

export const updateNode = (uiNode, { type, action, payload }) => (dispatch, getState) => {
  let newUiNode = cloneDeep(uiNode);
  const newPayload = cloneDeep(payload);
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  const serverNode = find(newModel.flow.nodes, { id: newUiNode.serverNodeId });
  if (newPayload && newPayload.branches) {
    const toDelete = differenceBy(serverNode.after, newPayload.branches, "branch_id");
    const toDeleteIndexes = toDelete.map((del) => serverNode.after.indexOf(del));
    const toDeleteUiNodes = toDeleteIndexes.map((index) => newUiNode.after[index]);
    const toAdd = differenceBy(newPayload.branches, serverNode.after, "branch_id");
    const toAddIndexes = toAdd.map((add) => newPayload.branches.indexOf(add));
    for (const index of toAddIndexes) {
      modelAddBranch(newModel, newUiNode.id/*, index*/); // index нужно подставлять, если создавать ветки после удаления веток
    }
    for (const uiId of toDeleteUiNodes) {
      modelRemoveBranch(newModel, uiId);
    }
    newUiNode = newModel.nodes.find((n) => n.id === newUiNode.id);

    // взять все ui ноды с пустым after (все end ноды) и создать для всех у кого нет serverNodeId создать serverNode
    let i = 0;
    newModel.nodes
      .filter(({ after, serverNodeId }) => !after.length && !serverNodeId)
      .forEach((newUiEnd) => {
        createEndForRule(

          newModel,
          newUiEnd,
          {
            branch_id: toAdd[i++].branch_id,
            node_id: serverNode.id,
          },
        );
      });

    const afterServerNodeIds = newUiNode.after.map((uiId) => getFirstServerNodeId(newModel, uiId));

    serverNode.after = afterServerNodeIds.map((serverNodeId, i) => ({
      branch_id: newPayload.branches[i].branch_id,
      node_id: serverNodeId,
    }));

    // в пейлоаде сбрасываем ид веток, потому что restoreServerFlow(newModel); работает неправильно и тоже сбрасывает
    // ид веток. но в restoreServerFlow трудно разобраться и починить. а логики привязаной к branch_id пока всё равно нет
    newPayload.branches = newPayload.branches.map((branch, index) => ({
      ...branch,
      branch_id: index + 1,
    }));
  }

  if (newPayload.subtasks) {
    handleSubtasks(newPayload.subtasks, newModel);
  }
  serverNode.type = type;
  serverNode.action = action;
  serverNode.payload = newPayload;
  serverNode.updated_at = new Date().toISOString();
  serverNode.automation = payload?.automation || serverNode.automation || "auto";
  const nodeForServer = cloneDeep(serverNode);
  delete nodeForServer.payload?.automation;
  restoreServerFlow(newModel);
  recalculate(newModel);
  return dispatch(validateNode(nodeForServer)).then(() => {
    dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
  }).catch((error) => {
    throw error;
  });
};


export const focusNode = (nodeId) => ({
  type: actions.FDP_FOCUS_NODE,
  nodeId,
});

export const setTempTypeNode = (tempType) => ({
  type: actions.FDP_SET_TEMP_TYPE,
  tempType,
});

export const reset = () => ({
  type: actions.FDP_LEAVE,
});

export const changeMode = (mode) => ({
  type: actions.FDP_CHANGE_MODE,
  mode,
});

export const startFlow = (flowId, flowVersionId) => async (dispatch, getState) => {
  try {
    return await dispatch(theStartFlow(flowId));
  } catch (errors) {
    if (!errors.error) {
      showError(errors.message || "Unknown Error");
      return;
    };
    const invalidNodes = errors.error.data;
    dispatch(setInvalidNodes(invalidNodes, flowVersionId, flowId));

    throw errors;
  }
};

export const addSource = ({ filters, filter_tree_format, senderProfiles, name, active_limit, coworkers_limit }) => async (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  createSourceNode(newModel, filters, filter_tree_format, senderProfiles, name, active_limit, coworkers_limit);
  recalculate(newModel);
  dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
};

export const validateSource = (source) => async (dispatch) => {
  return axios.post("/api/flow-versions/validate-contact-source", source);
};

export const updateSource = (updatedSource) => async (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  const nodes = newModel.nodes.map((item) => item.id === updatedSource.id ? updatedSource : item);
  const currentSource = newModel.flow.version.contact_sources;
  const contact_sources = currentSource.map((item) => item.id === updatedSource.sourceId ? { ...item, ...updatedSource.payload } : item);
  const flow = { ...newModel.flow, version: { ...newModel.flow.version, contact_sources } };
  recalculate({ ...newModel, nodes, flow });
  dispatch({ type: actions.FDP_SET_MODEL, model: { ...newModel, nodes, flow } });
};

export const removeSource = ({ sourceId }) => async (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  deleteSourceNode(newModel, sourceId);
  recalculate(newModel);
  dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
};

export const removeEnd = ({ endId }) => async (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  deleteEnd(endId, newModel);
  restoreServerFlow(newModel);
  recalculate(newModel);
  dispatch({ type: actions.FDP_SET_MODEL, model: newModel });
  await wait();
  dispatch({ type: actions.FDP_SET_MODEL, model: cloneDeep(newModel) });
};

export const updateFocusedNodeId = (id) => async (dispatch, getState) => {
  const model = getState().flowDetails.workTable;
  const newModel = cloneDeep(model);
  if (isNull(id)) {
    delete newModel.focusedNodeId;
  } else {
    newModel.focusedNodeId = id;
  }
  dispatch({ type: actions.FDP_UPDATE_FOCUSED_ID, model: cloneDeep(newModel) });
};

export const setIsModified = (isModified) => async (dispatch) => {
  dispatch({ type: actions.FDP_SET_IS_MODIFIED, isModified });
};
export const setActiveModalType = (activeModalType) => async (dispatch) => {
  dispatch({ type: actions.FDP_SET_ACTIVE_MODAL, activeModalType });
};
export const setTempNodeData = (uiNodeId, data) => async (dispatch) => {
  dispatch({ type: actions.FDP_SET_TEMP_NODES_DATA, uiNodeId, data });
};
