import * as actions from "./consts";
import getAxios from "services/axios";
import moment from "moment";
import store from "store/store";
import { changeMessagesCount } from "../../global";
import { downloadFileFromResponse } from "services/utils";
import { messageTypes } from "../messages/model/enums";
import * as actionsCreate from "../tags/consts";
import { refreshProfilesCounters } from "../senderProfiles/actions";
import { createTag } from "../tags/actions";
import sse, { SSE } from "services/serverSideEvents";
import { createCachedFunction } from "services/utils";
import { dataBus } from "services/dataBus";


const axios = getAxios("contactV2");

export const handleLeadNewFormat = (newFormatLead) => {
  const oldFormatLead = {
    ...newFormatLead,
    ...newFormatLead.lead,
  };
  delete oldFormatLead.lead;

  return oldFormatLead;
};

export const getListOfLeads = (params, cancelToken) => (dispatch) => {
  return axios.get("/api/leads", {
    params,
    cancelToken,
  })
    .then((response) => {
      response.data = response.data.map(handleLeadNewFormat);

      dispatch({
        type: actions.SEED_LEADS,
        leads: response.data,
        dontOverride: true,
      });

      return response;
    });
};

export const getListOfLeadsByUuids = (ids) => (dispatch) => {
  return axios.post("/api/leads/list-by-uuids", { uuids: ids })
    .then((response) => {
      response = response.map(handleLeadNewFormat);
      dispatch({
        type: actions.SEED_LEADS,
        leads: response,
        dontOverride: true,
      });
      return response;
    });
};

export const getListOfLeadsByActivity = (params) => (dispatch) => {
  return axios.post("/api/leads/list-by-activity-types", params)
    .then((response) => {
      response.data = response.data.map(handleLeadNewFormat);

      dispatch({
        type: actions.SEED_LEADS,
        leads: response.data,
        dontOverride: true,
      });

      return response;
    });
};

export const getLeadsOptions = (query = "", params = {}) => {
  return Promise.resolve(store.dispatch(getListOfLeads({ ...params, limit: 50, disable_aggregation: 1, filter: { leadFilter: { q: query }, ...params.filter } })))
    .then(({ data }) => data.map((lead) => {
      return { value: lead.uuid, label: `${lead.first_name} ${lead.last_name}`, avatarUrl: lead.avatar_url || "empty" };
    }));
};


export const getSearchCount = (params) => {
  return axios.post("/api/leads/search-counts", params);
};

export const getSearchSuggestions = (params) => {
  return axios.post("/api/leads/search-suggestions", {
    ...params,
  });
};

const addNewTag = (params) => (dispatch) => {
  axios.put("/api/leads/add-tags", params)
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_TAGS_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const setTags = ({ filter, tags, include_coworkers, isNeedCreation = false, createPayload = "" }) => async (dispatch) => {
  if (isNeedCreation) {
    const newTag = await dispatch(createTag({ name: createPayload }));
    dispatch({
      type: actionsCreate.TAG_CREATE_SUCCESS,
      tag: newTag,
    });
    tags = [newTag.uuid];
  } dispatch(addNewTag({ filter, tags, include_coworkers }));
};

export const changeList = ({ filter, list_uuid, include_coworkers = false }) => (dispatch) => {
  return axios.put("/api/leads/change-list", { filter, list_uuid, include_coworkers })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_LIST_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const addToFlow = ({ filter, flow_uuid, include_coworkers = false, contact_source_id }) => (dispatch) => {
  return axios.put("/api/leads/add-to-flow", { filter, flow_uuid, include_coworkers, contact_source_id })
    .then((res) => {
      dispatch({
        type: actions.LEADS_ADD_TO_FLOW_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const removeFromFlow = ({ filter, flow_uuid, include_coworkers = false }) => (dispatch) => {
  let flowValue;
  if (Array.isArray(flow_uuid)) {
    flowValue = flow_uuid;
  } else {
    flowValue = [flow_uuid];
  }
  return axios.put("/api/leads/cancel-from-flow", { filter, flow_uuids: flowValue, include_coworkers })
    .then((res) => {
      dispatch({
        type: actions.LEADS_REMOVE_FROM_FLOW_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const assignToUser = ({ filter, sdr_profile_uuid, include_coworkers }) => (dispatch) => {
  return axios.put("/api/leads/assign-to", { filter, sdr_profile_uuid, include_coworkers });
  // .then(({data}) => {
  //   dispatch({
  //     type: actions.LEADS_SET_LIST_SUCCESS,
  //     leads: data
  //   })
  // })
};

export const changePipeline = ({ filter, pipeline_stage_uuid, include_coworkers }) => (dispatch) => {
  return axios.put("/api/leads/change-pipeline-stage", { filter, pipeline_stage_uuid, include_coworkers })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_PIPELINE_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const changeSenderProfile = ({ filter, sender_profile_uuid, include_coworkers }) => (dispatch) => {
  return axios.put("/api/leads/change-sender-profile", { filter, sender_profile_uuid, include_coworkers })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_SENDER_PROFILE,
        leads: res.data,
      });
      return res;
    });
};

export const markAsRead = ({ filter }) => (dispatch) => {
  return axios.put("/api/leads/mark-read", { filter })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_UNREAD_COUNTS_SUCCESS,
        leads: res.data,
      });
      dispatch(refreshProfilesCounters(res.queued));
      dataBus.emit("messages_read", { leadIds: res.data.map((lead) => lead.uuid) });
      return res;
    });
};
export const markAsUnread = ({ filter }) => (dispatch) => {
  return axios.put("/api/leads/mark-unread", { filter })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_UNREAD_COUNTS_SUCCESS,
        leads: res.data,
      });
      dispatch(refreshProfilesCounters());
      dataBus.emit("messages_unread", { leadIds: res.data.map((lead) => lead.uuid) });
      return res;
    });
};

export const removeTags = ({ filter, tags, include_coworkers }) => (dispatch) => {

  //return
  axios.put("/api/leads/remove-tags", { filter, tags, include_coworkers })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_TAGS_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const replaceTags = ({ filter, tags, include_coworkers }) => (dispatch) => {
  return axios.put("/api/leads/replace-tags", { filter, tags, include_coworkers })
    .then((res) => {
      dispatch({
        type: actions.LEADS_SET_TAGS_SUCCESS,
        leads: res.data,
      });
      return res;
    });
};

export const exportLeads = (params) => {
  return axios.post("/api/file-exports/leads", params)
    .then((response) => {
      downloadFileFromResponse({
        file: response,
        filename: `leads_export_${moment().format("YYYY_MM_DD")}.csv`,
      });
      return response;
    });
};

export const updateLead = ({ uuid, params }) => (dispatch) => {
  return axios.put(`/api/leads/${uuid}`, { ...params })
    .then((lead) => {
      const oldFormatLead = handleLeadNewFormat(lead);

      dispatch({
        type: actions.SEED_LEAD,
        lead: oldFormatLead,
      });

      return oldFormatLead;
    });
};

export const createLeads = () => {

};

export const deleteLeads = ({ filter }) => (dispatch) => {
  return axios.delete("/api/leads", { data: { filter } })
    .then((res) => {
      dispatch({
        type: actions.LEADS_DELETE_SUCCESS,
        loadedSelection: filter.ids,
      });
      return res;
    })
    .catch((e) => console.log(e));
};

// export const loadLeadByUuid = (uuid) => (dispatch) => {
//   return axios.get(`/api/leads/${uuid}`)
//     .then((lead) => {
//       const oldFormatLead = handleLeadNewFormat(lead);

//       dispatch({
//         type: actions.SEED_LEAD,
//         lead: oldFormatLead,
//       });

//       return oldFormatLead;
//     });
// };

export const cacher = createCachedFunction((uuid) => (dispatch) => {
  return axios.get(`/api/leads/${uuid}`)
    .then((lead) => {
      const oldFormatLead = handleLeadNewFormat(lead);

      dispatch({
        type: actions.SEED_LEAD,
        lead: oldFormatLead,
      });

      return oldFormatLead;
    });
}, { recursively: true });

export const loadLeadByUuid = cacher.cachedFunction;

export const addActivity = ({ leadUuid, activity }) => (dispatch) => {
  dispatch({
    type: actions.LEADS_ADD_ACTIVITY,
    leadUuid,
    activity,
  });
};

export const onMessagesOpened = (leadUuid) => (dispatch, getState) => {
  const lead = getState().entities.leads[leadUuid];
  if (!lead.markers || !lead.markers.dm_user_unread_count) return;

  dispatch(changeMessagesCount((s) => s - 1));
  setTimeout(() => dispatch({
    type: actions.LEADS_MESSAGES_READ,
    leadUuid,
  }), 1111);
};

export const generateActivitiesForEmails = (leadUuid) => (dispatch, getState) => {
  const allEmails = getState().entities.emails;

  const filteredEmailsOfLead = Object.values(allEmails)
    .filter((message) => message.lead_id === leadUuid);

  const typesMap = {
    [messageTypes.INBOX]: actions.ALL_ACTIVITIES.EMAIL_REPLIED,
    [messageTypes.OUTBOX]: actions.ALL_ACTIVITIES.EMAIL_SENT,
  };
  for (const msg of filteredEmailsOfLead) {
    dispatch({
      type: actions.LEADS_ADD_ACTIVITY,
      leadUuid,
      activity: {
        _generated: true,
        created_at: msg.order_ts || msg.created_at,
        data: {
          conversation_id: msg.social_conversation_id,
          message_id: msg.id,
        },
        type: typesMap[msg.type],
      },
    });
  }
};

export const generateActivitiesForMessages = (leadUuid, theMessageTypes = allMessageTypes) => (dispatch, getState) => {
  const allMessages = getState().entities.messages;

  const filteredMessagesOfLead = Object.values(allMessages)
    .filter((message) => message.lead_id === leadUuid)
    .filter((message) => theMessageTypes.includes(message.status));

  const typesMap = {
    [messageTypes.INBOX]: actions.ALL_ACTIVITIES.SOCIAL_DM_REPLIED,
    [messageTypes.OUTBOX]: actions.ALL_ACTIVITIES.SOCIAL_DM_SENT,
  };

  for (const msg of filteredMessagesOfLead) {
    const isConnectMessage = msg.automation === "connect";

    dispatch({
      type: actions.LEADS_ADD_ACTIVITY,
      leadUuid,
      activity: {
        _generated: true,
        created_at: msg.order_ts || msg.created_at,
        data: {
          conversation_id: isConnectMessage ? undefined : msg.social_conversation_id,
          message_id: msg.id,
        },
        type: isConnectMessage ? actions.ALL_ACTIVITIES.SOCIAL_CONNECT_SENT : typesMap[msg.type],
      },
    });
  }
};


export const markMessagesUnread = (leadUuid) => (dispatch) => {
  return axios.put(`/api/linkedin-conversations/${leadUuid}/lead-read`)
    .then(() => {
      dispatch(changeMessagesCount((s) => s + 1));
      dispatch({
        type: actions.LEADS_MARK_UNREAD,
        leadUuid,
      });
    });
};

export const dropSearch = (loadMoreSource) => (dispatch) => {
  dispatch({ type: actions.PEOPLE_P_DROP_SEARCH });
};

sse.listen(((events) => {
  for (const event of events) {
    if (event.type !== SSE.eventTypes.MESSAGE) return;
    store.dispatch({
      type: actions.SEED_LEAD_COUNTERS,
      lead: event.lead_aggregation,
    });
    store.dispatch(refreshProfilesCounters(true, 5000, false));
  }
}));
