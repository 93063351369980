import { memo } from "react";
import { NavLink } from "react-router-dom";
import { path as teamsPath } from "../TeamsManagement";
import styles from "./Navigation.module.scss";
import { path as userPath } from "../User";
import { path as subscriptionsPath } from "../Subscriptions";

const Navigation = () => (
  <div className={styles.container}>
    <div className="gs-p gs-fw-600">
      Personal settings
    </div>
    <div>
      <NavLink
        to={userPath}
        activeClassName="--active"
        className={styles.link}
      >
        Profile Details
      </NavLink>
      <NavLink
        to={teamsPath}
        activeClassName="--active"
        className={styles.link}
      >
        Teams Management
      </NavLink>
      <NavLink
        to={subscriptionsPath}
        activeClassName="--active"
        className={styles.link}
      >
        Billing & Subscriptions
      </NavLink>
    </div>
  </div>
);

export default memo(Navigation);
