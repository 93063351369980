export const FLP_LOAD_REQUEST = "FLP_LOAD_REQUEST";
export const FLP_LOAD_SUCCESS = "FLP_LOAD_SUCCESS";
export const FLP_LOAD_FAILED = "FLP_LOAD_FAILED";
export const FLP_P_SET_FILTERS = "FLP_P_SET_FILTERS";
export const FLP_P_DROP_SEARCH = "FLP_P_DROP_SEARCH";
export const FLP_P_CLEAR_IDS = "FLP_P_CLEAR_IDS";
export const FLP_LEAVE = "FLP_LEAVE";
export const FLP_ADD_FLOW = "FLP_ADD_FLOW";
export const FLP_DELETE_FROM_LIST = "FLP_DELETE_FROM_LIST";



