import { List } from "@gs/uiKit/dataDisplay/List";
import { Button } from "@gs/uiKit/general/Button";
import { MassActionItem } from "./MassActionItem/MassActionItem";
import type { MassAction } from "@gs/core/domain/MassAction/MassAction";

type MassActionsListProps = {
  massActionsData: MassAction[]
  loadMore: () => void
  isLoading: boolean
  hasMore: boolean
}

export const MassActionsList = ({ massActionsData, loadMore, isLoading, hasMore }: MassActionsListProps) => {
  return (
    <List
      loading={isLoading}
      itemLayout="horizontal"
      dataSource={massActionsData}
      loadMore={<LoadMoreComponent
        loadMore={loadMore}
        isLoading={isLoading}
        hasMore={hasMore}
      />}
      renderItem={(action) => <MassActionItem massAction={action} />}
    />
  );
};

const LoadMoreComponent = ({ isLoading, loadMore, hasMore }: Omit<MassActionsListProps, "massActionsData">) => {
  if (!hasMore) return null;
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button
        loading={isLoading}
        disabled={isLoading}
        onClick={loadMore}
      >
        Load more Actions
      </Button>
    </div>
  );
};
