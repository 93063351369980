import type { ComponentType, LazyExoticComponent } from "react";
import history from "services/browserHistory";
import { LogicError } from "@gs/core/errors/LogicError";
import type { Serializable } from "@gs/core/commonTypes";
import { modalsRegistry } from "feature/modal/modalsRegistry";

export interface GsModal {} // should be extended by declare
/*
Example how to extend:
declare module "feature/modal/ModalService" {
  interface GsModal {
    leadDetailsModal: {leadId: LeadId}
  }
}
 */
type WithQueryTrue<T> = T extends { query: true } ? T : never;
type QueryModalConfig = WithQueryTrue<typeof modalsRegistry[GsModalId]>;
type GsModalIdWithQuery = QueryModalConfig["modalId"]

export type GsModalId = keyof GsModal
const __hack: Serializable = {} as GsModal[GsModalIdWithQuery]; // hack to ensure all modals props are serializable
export type GsModalProps<P> = P & {onRequestClose: () => void}
export type GsModalConfig<M extends GsModalId> = {
  modalId: M
  Content: LazyExoticComponent<ComponentType<GsModalProps<GsModal[M]>>> | ComponentType<GsModalProps<GsModal[M]>>
  query?: boolean
  shouldCloseOnOverlayClick?: boolean
  onAfterOpen?: () => void
  className?: string
  overlayClassName?: string
}

type Location = typeof history.location
type History = typeof history
export const MODALS_QUERY_PARAM = "modals";

class ModalService {
  public registeredModals: Partial<{[M in GsModalId]: GsModalConfig<M>}> = {};
  public modals: Partial<GsModal> = {};
  private listeners: Set<(modals: typeof this.modals) => void> = new Set();
  private history: History;
  private lastSynchedQueryParamValue = "";

  constructor(history: History) {
    // @ts-ignore
    Object.values(modalsRegistry).forEach((modalConfig) => this.registerModal(modalConfig));
    this.history = history;
    this.syncQueryModals(this.history.location);
    this.history.listen((location) => this.syncQueryModals(location));
  }

  public registerModal<M extends GsModalId>(config: GsModalConfig<M>) {
    if (this.registeredModals[config.modalId]) {
      // throw new LogicError(`"${config.modalId}" modal is already registered`);
      console.warn(`"${config.modalId}" modal is already registered`);
    }

    (this.registeredModals[config.modalId] as GsModalConfig<M>) = config;
  }

  public openModal<M extends GsModalId>(modalId: M, modalParams: GsModal[M]) {
    const alreadyOpened = this.modals[modalId];
    const modalConfig = this.registeredModals[modalId];
    if (!modalConfig) {
      throw new LogicError(`Try to open unregistered modal "${modalId}"`);
    }
    if (alreadyOpened) {
      // throw new LogicError(`${modalId} modal is already open`);
      return console.warn(`${modalId} modal is already open`);
    }

    if (modalConfig.query) {
      this.addQueryModal(modalId, modalParams);
    } else {
      this.modals[modalId] = modalParams;
      this.triggerListeners();
    }
  }

  public closeModal(modalId: GsModalId) {
    const modal = this.modals[modalId];
    if (!modal) return;
    const modalConfig = this.registeredModals[modalId]!;

    if (modalConfig.query) {
      this.removeQueryModal(modalId);
    } else {
      delete this.modals[modalId];
      this.triggerListeners();
    }
  }

  public subscribe(listener: (modals: typeof this.modals) => void): () => void {
    this.listeners.add(listener);

    return () => this.listeners.delete(listener);
  }

  private triggerListeners() {
    this.listeners.forEach((cb) => cb(this.modals));
  }

  private addQueryModal<M extends GsModalId>(modalId: M, modalParams: GsModal[M]) {
    const searchParams = new URLSearchParams(this.history.location.search);
    const paramValueAsString = searchParams.get(MODALS_QUERY_PARAM);
    let paramValue: Partial<Record<M, GsModal[M]>>;
    try {
      paramValue = JSON.parse(paramValueAsString!) || {};
    } catch {
      paramValue = {};
    }
    if (!paramValue || typeof paramValue !== "object") {
      paramValue = {};
    }
    paramValue[modalId] = modalParams;

    searchParams.set(MODALS_QUERY_PARAM, JSON.stringify(paramValue));

    this.history.push({ search: searchParams.toString() });
  }

  private removeQueryModal(modalId: GsModalId) {
    try {
      const searchParams = new URLSearchParams(this.history.location.search);
      const paramValue = JSON.parse(searchParams.get(MODALS_QUERY_PARAM) as string) as Partial<Record<GsModalId, GsModal[GsModalId]>>;
      delete paramValue[modalId];
      if (Object.keys(paramValue).length === 0) {
        searchParams.delete(MODALS_QUERY_PARAM);
      } else {
        searchParams.set(MODALS_QUERY_PARAM, JSON.stringify(paramValue));
      }

      this.history.push({ search: searchParams.toString() });
    } catch {}
  }

  private syncQueryModals(location: Location) {
    const searchParams = new URLSearchParams(location.search);
    const paramValueAsString = searchParams.get(MODALS_QUERY_PARAM) || "";
    if (paramValueAsString === this.lastSynchedQueryParamValue) return;
    this.lastSynchedQueryParamValue = paramValueAsString;

    let paramValue: Partial<GsModal>;
    try {
      paramValue = JSON.parse(paramValueAsString!) || {};
    } catch {
      paramValue = {};
    }
    if (!paramValue || typeof paramValue !== "object") {
      paramValue = {};
    }

    for (const [modalId, modalConfig] of Object.entries(this.registeredModals)) {
      if (modalConfig.query) {
        delete this.modals[modalId as GsModalId];
      }
    }
    for (const [modalId, modalParams] of Object.entries(paramValue)) {
      this.modals[modalId as GsModalId] = modalParams as any;
    }
    this.triggerListeners();
  }
}

export const modalService = new ModalService(history);
