export const PROXY_SET_ENTITIES = "PROXY_SET_ENTITIES";
export const PROXY_SELECT = "PROXY_SELECT";
export const PROXY_CLEAR = "PROXY_CLEAR";
export const PROXY_SET_STATUS = "PROXY_SET_STATUS";


export const proxyStatuses = {
  NEUTRAL: "NEUTRAL",
  OK: "OK",
  ERROR: "ERROR",
};
