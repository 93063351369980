import { teamApi } from "../teamApi";
import { useEffect, useMemo, useState } from "react";
import { useTeamStore } from "../teamStore";

export const useAllTeams = () => {
  const [isLoading, setIsLoading] = useState(true);
  const teams = useTeamStore();
  const teamsArr = useMemo(() => Object.values(teams), [teams]);

  useEffect(() => {
    (async () => {
      const limit = 100;
      let offset = 0;
      let hasMore = true;
      while (hasMore) {
        const { data } = await teamApi.getTeamsMemoized({ offset, limit });
        hasMore = data.has_more;
        offset += limit;
      }

      setIsLoading(false);
    })();
  }, []);

  return [teamsArr, isLoading] as const;
};
