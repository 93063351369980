import React from "react";
import cn from "classnames";

import styles from "./ModalContainer.module.scss";

const ModalContainer = ({ Tag = "form", className = "", style = {}, children }) => (
  <Tag
    className={cn(className, styles.container)}
    style={style}
  >
    { children }
  </Tag>
);

export default ModalContainer;
