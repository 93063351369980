import * as actions from "./consts";

const ACTION_HANDLERS = {
  [actions.TEMPLATES_P_LOAD_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.TEMPLATES_P_LOAD_SUCCESS]: (state, { templateIds, total, hasMore }) => {
    const newIds = Array.from(new Set([...state.templateIds, ...templateIds]));

    const s = {
      ...state,
      isLoading: false,
      templateIds: newIds,
      total,
      hasMore,
    };

    return s;

  },
  [actions.TEMPLATES_P_LOAD_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [actions.TEMPLATES_P_SET_FILTERS]: (state, { filters }) => ({
    ...state,
    filters: {
      ...filters,
    },
  }),
  [actions.TEMPLATES_P_DROP_SEARCH]: (state) => ({
    ...state,
    templateIds: [],
    hasMore: true,
  }),
  [actions.TEMPLATES_P_DELETE_CATEGORY]: (state, { templateIds }) => ({
    ...state,
    templateIds,
  }),
  [actions.TEMPLATES_P_LEAVE]: () => initialState,
};

const initialState = {
  isLoading: false,
  hasMore: true,
  templateIds: [],
  filters: {},
};

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export const key = "templatesPage";
