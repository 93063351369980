import type { FC, ReactNode } from "react";
import React, { PureComponent, cloneElement } from "react";
import cn from "classnames";
import ReactModal from "react-modal";
import ModalContainer from "./Container";
import ModalHeader from "./Header";
import ModalFooter from "./Footer";

import "./Modal.module.scss";
import isUndefined from "lodash/isUndefined";

class Modal1 extends PureComponent {
  constructor() {
    // @ts-ignore
    super();
    this.state = {
      isOpened: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeModalWithSave = this.closeModalWithSave.bind(this);
  }

  public afterOpenModal() {
    this.setState({ isOpened: true });
  }

  public closeModal() {
    const { checkDataSaved } = this.props as any;
    if (checkDataSaved && !checkDataSaved() || isUndefined(checkDataSaved)) {
      this.closeModalWithSave();
    }
  }
  public closeModalWithSave() {
    this.setState({ isOpened: false });
    setTimeout(() => {
      // @ts-ignore
      this.props.closeModal();
    }, 410);
  }
  public render() {
    const {
      label,
      children,
      modalCustomStyles,
      isOpen,
      className = "",
      overlayClassName = "gs-modal__overlay",
    } = this.props as any;

    // @ts-ignore
    const { isOpened } = this.state;

    if (!children) {
      return null;
    }

    return (
      <ReactModal
        contentLabel={label}
        isOpen={isOpen}
        style={modalCustomStyles}
        onRequestClose={() => this.closeModal()}
        onAfterOpen={() => this.afterOpenModal()}
        shouldCloseOnOverlayClick={true}
        className={cn("gs-modal", { _open: isOpened }) + " " + className}
        overlayClassName={cn(overlayClassName, { _open: isOpened })}
      >
        {/* @ts-ignore */}
        {cloneElement(children,
          {
            onRequestClose: this.closeModal,
            closeModalWithSave: this.closeModalWithSave,
          })}
      </ReactModal>
    );
  }
}

ReactModal.setAppElement("#root");

const Modal = Modal1 as any as FC<{ children?: ReactNode; label?: string; isOpen?: boolean; closeModal?: () => void }> & {
  Container: typeof ModalContainer
  Header: typeof ModalHeader
  Footer: typeof ModalFooter
};

Modal.Container = ModalContainer;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
