import { LoadingOutlined } from "@ant-design/icons";

export const ModalLoadingContent = () => (
  <div
    style={{
      width: 300,
      height: 200,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "var(--color-bg-base)",
    }}
  >
    <LoadingOutlined
      style={{
        fontSize: 45,
        color: "var(--color-primary)",
      }}
    />
  </div>
);
