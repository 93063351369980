// ------------------------------------
// CONSTANTS
// ------------------------------------
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const HIDE_SEARCH = "HIDE_SEARCH";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const HIDE_MENU = "HIDE_MENU";
export const LOGIN_STEP_SET = "LOGIN_STEP_SET";
export const LOGIN_STEP_UP = "LOGIN_STEP_UP";
export const LOGIN_STEP_DOWN = "LOGIN_STEP_DOWN";
export const GLOBAL_SET_MIDDLE_MENU_VISIBILITY = "GLOBAL_SET_MIDDLE_MENU_VISIBILITY";
export const GLOBAL_CHANGE_MESSAGES_BADGE = "GLOBAL_CHANGE_MESSAGES_BADGE";
export const ADD_DRAWER_UUID = "ADD_DRAWER_UUID";
export const REMOVE_DRAWER_UUID = "REMOVE_DRAWER_UUID";

// ------------------------------------
// Selectors
// ------------------------------------
export const getMessagesBadge = (state) => state.global.messagesBadge;
export const getOpenedDrawers = (state) => state.global.openedDrawers;
// ------------------------------------
// Actions
// ------------------------------------
export function toggleSearch(isCollapsed) {
  return {
    type: TOGGLE_SEARCH,
    payload: isCollapsed,
  };
}

export function hideSearch(isCollapsed) {
  return {
    type: HIDE_SEARCH,
    payload: isCollapsed,
  };
}

export function loginStepSet(index) {
  return {
    type: LOGIN_STEP_SET,
    index,
  };
}

export function loginStepUp() {
  return {
    type: LOGIN_STEP_UP,
  };
}

export function loginStepDown() {
  return {
    type: LOGIN_STEP_DOWN,
  };
}

export const setMiddleMenuVisibility = (middleMenuVisibility) => ({
  type: GLOBAL_SET_MIDDLE_MENU_VISIBILITY,
  middleMenuVisibility,
});

export const changeMessagesCount = (diffFn) => ({
  type: GLOBAL_CHANGE_MESSAGES_BADGE,
  diffFn,
});

export function setDrawerUuid(uuid) {
  return {
    type: ADD_DRAWER_UUID,
    uuid,
  };
}

export function removeLastDrawerUuid() {
  return {
    type: REMOVE_DRAWER_UUID,
  };
}

// ------------------------------------
// ACTION HANDLERS
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_SEARCH]: (state, action) => {
    return {
      ...state,
      searchOpened: !state.searchOpened,
    };
  },
  [HIDE_SEARCH]: (state, action) => {
    return {
      ...state,
      searchOpened: false,
    };
  },
  [TOGGLE_MENU]: (state, { isOpen }) => {
    const navigationOpened = isOpen == null ? !state.navigationOpened : isOpen;
    return {
      ...state,
      navigationOpened,
    };
  },
  [HIDE_MENU]: (state, action) => {
    return {
      ...state,
      navigationOpened: false,
    };
  },
  [LOGIN_STEP_SET]: (state, action) => {
    return {
      ...state,
      loginStep: action.reducer,
    };
  },
  [LOGIN_STEP_UP]: (state, action) => {
    if (state.loginStep == 4) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      loginStep: state.loginStep + 1,
    };
  },
  [LOGIN_STEP_DOWN]: (state, action) => {
    if (state.loginStep == 1) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      loginStep: state.loginStep - 1,
    };
  },
  [GLOBAL_SET_MIDDLE_MENU_VISIBILITY]: (state, { middleMenuVisibility }) => ({
    ...state,
    middleMenuVisibility,
  }),
  [GLOBAL_CHANGE_MESSAGES_BADGE]: (state, { diffFn }) => ({
    ...state,
    messagesBadge: Math.max(0, diffFn(state.messagesBadge)),
  }),
  [ADD_DRAWER_UUID]: (state, { uuid }) => {
    return {
      ...state,
      openedDrawers: [...state.openedDrawers, uuid],
    };
  },
  [REMOVE_DRAWER_UUID]: (state) => {
    return {
      ...state,
      openedDrawers: state.openedDrawers.slice(0, -1),
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  navigationOpened: false,
  searchOpened: false,
  loginStep: 1,
  openedDrawers: [],
  rowHeight: 43,
  middleMenuVisibility: true,
  messagesBadge: null,
  tasksBadge: null,
};

export default function globalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
