import { useState, useCallback } from "react";
import { showError } from "services/utils";
import { createCategory, editCategory } from "store/entities/templateCategories/actions";
import TextRow from "components/common/inputs/FormRows/TextRow";
import Icon from "../../../common/elements/Icon";
import { useDispatch } from "react-redux";
// import AsyncActionButton from "components/common/buttons/AsyncActionButton";
import type { IModalContentProps } from "../interfaces";
import { Button } from "components/common/buttons/Button";

import styles from "./NewOrEditModal.module.scss";


export const ModalContent = ({ onRequestClose, category }: IModalContentProps) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState<{
    name: string
  }>({
    name: category?.name || "",
  });

  const handleChange = ({ key, value }: {key: string; value: string}) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onSubmit = useCallback((e: any) => {
    e.preventDefault();
    const action = category ? editCategory : createCategory;

    dispatch(action({ uuid: category?.uuid, ...form }))
      //@ts-ignore
      .then(onRequestClose)
      .catch((err: string) => showError(err, "Failed"));
  }, [form]);

  return (
    <form className={styles.Modal}>
      <div className={styles.ModalHeader}>
        <h2>
          {category ? "Edit" : "Create new"}
          {" "}
          Folder
        </h2>
        <div
          className={styles.ModalCloseBtn}
          onClick={() => onRequestClose()}
        >
          <Icon
            icon="Cross"
            color='#516F90'
            size={20}
          />
        </div>
      </div>

      <TextRow
        value={form?.name || ""}
        required
        onChange={(e: any) => handleChange({ key: "name", value: e.target.value })}
        placeholder="Type folder name"
        label="Folder Name"
        rowClassName={styles.CustomRow}
        autoFocus
      />

      <div className={styles.ModalFooter}>
        <Button
          green
          onClick={onSubmit}
          disabled={!form?.name}
        >
          {category ? "Update Folder" : "Create Folder"}
        </Button>
      </div>
    </form>
  );
};
