import find from "lodash/find";
import pull from "lodash/pull";
import createNode from "../handlers/createNode";
import deleteNodeRecursively from "./deleteNodeRecursively";
import recalculate from "../recalculate";
import nodeTypes from "../nodeTypes";


export default (model, targetPlusNodeId, branchesCount) => {
  const targetPlusNode = find(model.nodes, { id: targetPlusNodeId });

  const newUiNode = createNode(model, {
    type: nodeTypes.REAL,
    before: [targetPlusNodeId],
    parents: targetPlusNode.parents,
  });
  const theAfters = targetPlusNode.after; // у плюса after всегда Один
  targetPlusNode.after = [newUiNode.id];

  switch (branchesCount) {
    case 0: {
      newUiNode.after = [];

      const afterNode = find(model.nodes, { id: theAfters[0] });
      if (afterNode.before.length <= 2) {
        deleteNodeRecursively(model, afterNode.id, newUiNode.before[0]);
      } else {
        pull(afterNode.before, targetPlusNode.id);
      }
      break;
    }
    case 1: {
      const newUiPlusNode = createNode(model, {
        type: nodeTypes.PLUS,
        before: [newUiNode.id],
        after: theAfters,
        parents: targetPlusNode.parents,
      });

      newUiNode.after = [newUiPlusNode.id];

      const theAfterNode = find(model.nodes, { id: theAfters[0] });
      const index = theAfterNode.before.indexOf(targetPlusNodeId);
      theAfterNode.before[index] = newUiPlusNode.id;
      break;
    }
    default: {
      const newUnionPlusNode = createNode(model, {
        type: nodeTypes.PLUS,
        before: [],
        after: theAfters,
        parents: targetPlusNode.parents,
        root: newUiNode.id,
      });
      newUiNode.union = newUnionPlusNode.id;
      newUiNode.after = [];
      const theAfterNode = find(model.nodes, { id: theAfters[0] });
      const index = theAfterNode.before.indexOf(targetPlusNodeId);
      theAfterNode.before[index] = newUnionPlusNode.id;

      for (let i = 0; i < branchesCount; i++) {
        const newPlusNode = createNode(model, {
          type: nodeTypes.PLUS,
          before: [newUiNode.id],
          after: [newUnionPlusNode.id],
          parents: targetPlusNode.parents,
        });
        newUiNode.after.push(newPlusNode.id);
        newUnionPlusNode.before.push(newPlusNode.id);
      }
    }
  }

  recalculate(model);

  return newUiNode;
};
