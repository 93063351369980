import type { MassActionType } from "@gs/core/domain/MassAction/massActionType";
import { useFindOrFetchFlow } from "domain/flow/hook/useFindOrFetchFlow";
import type { FlowId } from "@gs/core/domain/Flow/Flow";

export const FlowMassActionName = ({
  payload,
  type,
}: {
  payload: { flow_uuid: FlowId }
  type: MassActionType
}) => {
  const flowId = payload.flow_uuid;
  const [flow] = useFindOrFetchFlow(flowId);
  return (
    <>
      {type === "contact_add_to_flow" ? "Add to" : "Removed from"}
      {" "}
      {flow?.name}
      {" "}
      automation
    </>
  );
};
