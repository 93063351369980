import * as actions from "./consts";
import getAxios from "services/axios";
import { dataBus } from "services/dataBus";

const axios = getAxios("contactV2");

export const loadFlowSources = (params) => (dispatch, getState) => {
  return axios.get("/api/data-sources", { params })
    .then((res) => {
      const dsUuids = res.data.map(({ uuid }) => uuid);
      if (dsUuids.length > 0) {
        return axios.put("/api/data-sources/metrics", { uuids: dsUuids }).then((metrics) => {
          const sources = res.data.map((item) => {
            return {
              ...item,
              metrics: metrics[item.uuid],
            };
          });
          dispatch({
            type: actions.FLOW_SOURCES_GET_SUCCESS,
            flowSources: sources,
          });
          return res;
        });
      } else {
        dispatch({
          type: actions.FLOW_SOURCES_GET_SUCCESS,
          flowSources: res.data,
        });
        return res;
      }


    });
};

export const loadFlowSource = (id) => (dispatch, getState) => {
  return axios.get(`/api/data-sources/${id}`)
    .then((flowSource) => {
      dispatch({
        type: actions.FLOW_SOURCES_GET_SUCCESS,
        flowSources: [flowSource],
      });

      return flowSource;
    });
};

export const loadDataSourcesByList = (listId) => (dispatch) => {
  return axios.get(`/api/lists/${listId}/data-sources`)
    .then((res) => {
      dispatch({
        type: actions.FLOW_SOURCES_GET_SUCCESS,
        flowSources: res,
      });

      return res;
    });
};

export const deleteFlowSource = (id) => (dispatch, getState) => {
  return axios.delete(`/api/data-sources/${id}`)
    .then( (res) => {
      const ds = getState().entities.flowSources[id];

      dispatch({
        type: actions.FLOW_SOURCES_DELETE_SUCCESS,
        id,
        listId: ds && ds.list_id,
      });

      return res;
    });
};

export const createFlowSource = (params) => (dispatch) => {
  return axios.post("/api/data-sources", params)
    .then( (flowSource) => {
      console.log("new flowSource", flowSource);
      dispatch({
        type: actions.FLOW_SOURCES_CREATE_SUCCESS,
        flowSource,
      });

      return flowSource;
    });
};

export const updateFlowSource = (id, params) => (dispatch) => {
  return axios.put(`/api/data-sources/${id}`, params)
    .then((flowSource) => {
      return axios.put("/api/data-sources/metrics", { uuids: [flowSource.uuid] }).then((metrics) => {
        const source = metrics[flowSource.uuid];
        flowSource.metrics = source;
        dispatch({
          type: actions.FLOW_SOURCES_CREATE_SUCCESS,
          flowSource,
        });
        return flowSource;
      });
    });
};

export const uploadCsv = ({ file, listId, tags, forceMove, type }) => (dispatch) => {
  const fd = new FormData();
  fd.set("type", type);
  fd.set("file", file);
  fd.set("list_uuid", listId);
  if (tags.length) {
    fd.set("tags", JSON.stringify(tags)); // JSON.stringify(tags)
  }
  if (forceMove) {
    fd.set("force_list_move", "1");
  }

  return axios.post("/api/file-imports/upload-csv", fd);
};

export const importCsvLeads = ({ uploadId, columns }) => async (dispatch) => {
  const res = await axios.post(`/api/file-imports/${uploadId}/import-leads`, { columns });
  dataBus.emit("dataSource:create", { ids: [] });
  return res;
};

export const importCsvCompanies = ({ uploadId, columns }) => async (dispatch) => {
  const res = await axios.post(`/api/file-imports/${uploadId}/import-companies`, { columns });
  dataBus.emit("dataSource:create", { ids: [] });
  return res;
};



export const clearSearchUrls = (data) => {
  delete data.page;
  delete data.searchSessionId;
  delete data.preserveScrollPosition;
  delete data.doFetchHeroCard;
  delete data.logHistory;
  delete data.rsLogId;
  // delete data.companyTimeScope;
};
