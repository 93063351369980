import { combineReducers } from "redux";

import contactsReducer from "./contacts";
import leadsReducer from "./leads";
import customFieldsReducer from "./customFields";
import companiesReducer from "./companies";
import pipelineStagesReducer from "./pipelineStages";
import flowsReducer from "./flows/reducer";
import { flowStatisticsReducer } from "./flowStatistics/reducer";
import { flowMetricsReducer } from "./flowMetrics";
import flowWorkspaces from "./flowWorkspaces";
import flowErrors from "./flowErrors";
import listsReducer from "./lists/reducer";
import mailboxesReducer from "./mailboxes/reducer";
import usersReducer from "./users/reducer";
import integrationsReducer from "./integrations/reducer";
import schedulesReducer from "./schedules/reducer";
import tagsReducer from "./tags/reducer";
import templateCategories from "./templateCategories";
import templates from "./templates";
import senderProfiles from "./senderProfiles";
import servers from "./servers";
import linkedinAccounts from "./linkedinAccounts";
import segmentsReducer from "./segments";
import cardsReducer from "./cards";
import plansReducer from "./plans";
import subscriptions from "./subscriptions";
import invites from "./invites";
import flowSources from "./flowSources";
import leadsStatuses from "./leadsStatuses";
import messages from "./messages";
import tasks from "./tasks";
import conversations from "./conversations";
import buyerPersonas from "./buyerPersonas";
import emails from "./emails";
import teams from "./teams/reducer";
import uncompletedMessages from "../uncompletedMessages/reducer";
import gptPromtsReducer from "./gptPrompts";
import gptPromptTemplatesReducer from "./gptPromptTemplates";
import invoicesReducer from "./invoices";

// ------------------------------------
// В алфовитном порядке плеазе
// ------------------------------------
export default combineReducers({
  buyerPersonas,
  cards: cardsReducer,
  companies: companiesReducer,
  contacts: contactsReducer,
  conversations,
  customFields: customFieldsReducer,
  emails,
  flows: flowsReducer,
  flowErrors,
  flowSources,
  flowStatistics: flowStatisticsReducer,
  flowMetrics: flowMetricsReducer,
  flowWorkspaces,
  gptPromptTemplates: gptPromptTemplatesReducer,
  gptPrompts: gptPromtsReducer,
  integrations: integrationsReducer,
  invites,
  invoices: invoicesReducer,
  leadsStatuses,
  linkedinAccounts,
  lists: listsReducer,
  leads: leadsReducer,
  mailboxes: mailboxesReducer,
  messages,
  plans: plansReducer,
  pipelineStages: pipelineStagesReducer,
  schedules: schedulesReducer,
  segments: segmentsReducer,
  senderProfiles,
  servers,
  subscriptions,
  tags: tagsReducer,
  tasks,
  templateCategories,
  templates,
  teams,
  users: usersReducer,
  uncompletedMessages,
});
