import { createBoundedUseStore, createStore } from "../../store/zustand/createStore";

export const createDomainStore = <Domain, DomainId extends PropertyKey>(key: string) => {
  const domainStore = createStore<Record<DomainId, Domain>>(
    `@domain/${key}`,
    (set, get) => ({} as Record<DomainId, Domain>),
  );

  const useDomainStore = createBoundedUseStore(domainStore);

  return [domainStore, useDomainStore] as const;
};
