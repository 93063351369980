import * as consts from "./consts";
import { entitySetter } from "../entities/utils";


const ACTION_HANDLERS = {
  [consts.UNCOMPLETED_MESSAGES_SET]: (state, { messages, leadUuid }) => {
    let s = { ...state };

    s[leadUuid] = messages;


    // s = entitySetter(s, messages);

    return s;
  },
  [consts.UNCOMPLETED_MESSAGE_STATUS_CHANGE]: (state, { messageId, leadUuid, status = "new" }) => {
    let s = { ...state };

    s[leadUuid] = s[leadUuid]?.map((msg) => {
      if (msg.uuid === messageId) {
        return { ...msg, status };
      } else return msg;
    });
    return s;
  },
  [consts.UPDATE_UNCOMPLETED_MESSAGE]: (state, { message, leadUuid }) => {
    let s = { ...state };


    // s[leadUuid].push(message)
    s[leadUuid] = s[leadUuid]?.map((msg) => {
      if (msg.uuid === message.uuid) {
        return message;
      } else return msg;
    });

    return s;
  },
  [consts.DELETE_UNCOMPLETED_MESSAGE]: (state, { messageId, leadUuid }) => {
    let s = { ...state };
    if (s[leadUuid]) {
      s[leadUuid] = s[leadUuid].filter((msg) => msg.uuid !== messageId);
    }


    return s;
  },
  [consts.ADD_UNCOMPLETED_MESSAGE]: (state, { message, leadUuid }) => {
    let s = { ...state };
    s[leadUuid] = s[leadUuid].filter((msg) => msg.uuid !== message.uuid);
    s[leadUuid] = [...s[leadUuid], message];
    return s;
  },
};

const initialState = {
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
