import styles from "./UserMassActions.module.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { createDrawerTrigger } from "../SimpleDrawerTrigger/createDrawerTrigger";
import { massActionApi } from "domain/massAction/massActionApi";
import { useState, useEffect, useRef } from "react";
import type { MassActionId, MassActionMetrics } from "@gs/core/domain/MassAction/MassAction";
import { showError } from "services/utils";
import { useMassActionStore } from "domain/massAction/massActionStore";
import { MassActionsList } from "./MassActionsList";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useShallow } from "zustand/react/shallow";
import type { IntervalId } from "@gs/core/commonTypes";
import { dataBus } from "services/dataBus";
import difference from "lodash/difference";

const MASS_ACTIONS_LIST_LIMIT = 20;
const MASS_ACTIONS_METRICS: (keyof MassActionMetrics)[] = ["total_count", "done_count", "failed_count", "cancelled_count"];

const DrawerContent = () => {
  const [massActionIds, setMassActionIds] = useState<MassActionId[]>([]);
  const massActionIdsRef = useRef(massActionIds);
  massActionIdsRef.current = massActionIds;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const massActions = useMassActionStore(useShallow((massActionStore) => (massActionIds.map((id) => massActionStore[id]!))));
  const intervalIdOfRefresh = useRef<IntervalId | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    return dataBus.subscribe("massAction:delete", ({ massActionIds: deletedIds }) => {
      const withoutDeleted = difference(massActionIds, deletedIds);
      if (withoutDeleted.length === massActionIds.length) return;
      setMassActionIds(withoutDeleted);
    });
  }, [massActionIds]);

  const getMassActions = async (params: { limit: number; offset: number; filter?: { id?: MassActionId[] } }) => {
    const response = await massActionApi.getMassActions(
      {
        ...params,
        orderField: "created_at",
        orderType: "desc",
      },
    );
    const ids = response.data.data.map((massAction) => massAction.uuid);
    if (ids.length > 0) {
      await massActionApi.getMassActionsMetrics({ ids, metrics: MASS_ACTIONS_METRICS });
    }
    return {
      ids,
      hasMore: response.data.has_more,
    };
  };

  const loadMoreMassActions = async () => {
    setIsLoading(true);
    try {
      const response = await getMassActions({
        limit: MASS_ACTIONS_LIST_LIMIT,
        offset: massActionIds.length,
      });
      setMassActionIds(Array.from(new Set([...massActionIds, ...response.ids])));
      setHasMore(response.hasMore);
    } catch (err: unknown) {
      showError(err, "Failed to load Queue. Reason: Unknown");
    } finally {
      setIsLoading(false);
    }
  };

  const refreshMassActions = async () => {
    if (massActionIdsRef.current.length === 0) {
      return;
    }

    try {
      await getMassActions({
        limit: massActionIdsRef.current.length,
        offset: 0,
        filter: {
          id: massActionIdsRef.current,
        },
      });
    } catch (err: unknown) {
      showError(err, "Failed refreshing Action Queue");
    }
  };
  useEffect(() => {
    loadMoreMassActions();
  }, []);

  useEffect(() => {
    intervalIdOfRefresh.current = setInterval(refreshMassActions, 10000);

    return () => {
      if (intervalIdOfRefresh.current) {
        clearInterval(intervalIdOfRefresh.current);
      }
    };
  }, []);

  useEffect(() => {
    const unsubscribe = dataBus.subscribe("massAction:create", async ({ massActionIds: newIds }) => {
      await massActionApi.getMassActionsMetrics({ ids: newIds, metrics: MASS_ACTIONS_METRICS });
      setMassActionIds((prev) => Array.from(new Set([...newIds, ...prev])));
    });
    return unsubscribe;
  }, []);


  return (
    <div className={styles.ContentWrapper}>
      <div className={styles.Note}>
        {/* @ts-ignore */}
        <ExclamationCircleOutlined />
        That’s your action log here, for the sake of the transparency. Download files, keep track of action statuses and errors.
      </div>

      {(massActions.length === 0 && !isLoading) ? (
        <div className={styles.NoData}>
          {/* @ts-ignore */}
          <ClockCircleOutlined
            className={styles.NoDataIcon}
          />
          <p className={styles.NoDataTitle}>Action Queue</p>
          <p>There’re no actions to queue yet</p>
        </div>
      ) : (
        <div className={styles.List}>
          <MassActionsList
            loadMore={loadMoreMassActions}
            massActionsData={massActions}
            hasMore={hasMore}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export const MassActionsDrawer = createDrawerTrigger(DrawerContent, { mask: false });
