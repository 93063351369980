import { useFindOrFetchSenderProfile } from "domain/senderProfile/hook/useFindOrFetchSenderProfile";
import type { SenderProfileId } from "@gs/core/domain/SenderProfile/SenderProfile";

export const SenderProfileMassActionName = ({
  payload,
}: {
  payload: { sender_profile_uuid: SenderProfileId | SenderProfileId[] }
}) => {
  const senderProfileId = Array.isArray(payload.sender_profile_uuid) ? payload.sender_profile_uuid[0]! : payload.sender_profile_uuid;
  const [senderProfile] = useFindOrFetchSenderProfile(senderProfileId);
  return (
    <>
      Change Sender Profile To
      {" "}
      {senderProfile?.first_name}
      {" "}
      {senderProfile?.last_name}
    </>
  );
};
