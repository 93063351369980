import React from "react";
import Bugsnag from "@bugsnag/js";
import type { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { LogicError } from "@gs/core/errors/LogicError";
import type { UserId } from "@gs/core/domain/User/User";

const BUGSNAG_API_KEY = "344dbdc2f143b828e40ab36498d4599e";
export const initBugsnag = () => {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],

  });
  BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY });
};

export const bugsnagSetUser = (data: {
  id: UserId
  email: string
  name: string
}) => Bugsnag.setUser(String(data.id), data.email, data.name);

export const bugsnagClearUser = Bugsnag.setUser("", "", "");

let BugsnagErrorBoundaryComponent: BugsnagErrorBoundary;
export const getBugsnagErrorBoundary = (): BugsnagErrorBoundary => {
  if (!BugsnagErrorBoundaryComponent) {
    const plugin = Bugsnag.getPlugin("react");

    if (!plugin) throw new LogicError("Call initBugsnag before using BugsnagErrorBoundary");

    BugsnagErrorBoundaryComponent = plugin.createErrorBoundary(React);
  }

  return BugsnagErrorBoundaryComponent;
};
