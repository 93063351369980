import find from "lodash/find";

export const clearY = (model) => {
  delete model.maxY;
  for (const uiNode of model.nodes) {
    delete uiNode.depth;
    delete uiNode.y;
  }
};

export default (model) => {
  model.nodes.filter((node) => node.union).forEach((uiNode) => setMultiAftersMaxDepth(model, uiNode));

  const firstUiNode = model.nodes.filter((node) => !node.before.length)[0];
  firstUiNode.y = 0;
  model.maxY = 0;

  setYForAllAfterRecursively(model, firstUiNode);

  return model;
};

function setYForAllAfterRecursively(model, uiNode) {
  for (const uiId of uiNode.after) {
    const nextUinNode = find(model.nodes, { id: uiId });
    let y = 0;
    if (nextUinNode.root) {
      const rootNode = find(model.nodes, { id: nextUinNode.root });
      y = rootNode.y + rootNode.depth + 1;
    }
    y = Math.max(uiNode.y + 1, nextUinNode.y || 0, y);
    nextUinNode.y = y;
    model.maxY = Math.max(y, model.maxY);
    setYForAllAfterRecursively(model, nextUinNode);
  }
}

function setMultiAftersMaxDepth(model, multiUiNode) {
  if (multiUiNode.depth) return;
  multiUiNode.depth = 0;
  const stopUiId = multiUiNode.union;

  for (const nextUiId of multiUiNode.after) {
    const theDepth = recursivelyCountDepth(model, nextUiId, stopUiId, 0);
    if (theDepth > multiUiNode.depth) multiUiNode.depth = theDepth;
  }
}

function recursivelyCountDepth(model, uiNodeId, stopUiId, currentDepth) {
  if (uiNodeId === stopUiId) return currentDepth;
  const uiNode = find(model.nodes, { id: uiNodeId });

  return Math.max(currentDepth + 1, ...uiNode.after.map((nextUiId) => {
    const nextUiNode = find(model.nodes, { id: nextUiId });
    if (nextUiNode.union) {
      if (!nextUiNode.depth) {
        setMultiAftersMaxDepth(model, nextUiNode);
      }
      return recursivelyCountDepth(model, nextUiNode.union, stopUiId, currentDepth + nextUiNode.depth + 2);
    } else {
      return recursivelyCountDepth(model, nextUiId, stopUiId, currentDepth + 1);
    }
  }));
}
