import { __DEV__, CUSTOM_CONFIG_NAME } from "services/env";
import config from "./config.json";

const overwriteEndpointsDomainFromLive = (envConfig) => {
  const newConfig = { ...envConfig };
  Object.entries(newConfig.endPoints).forEach(([key, value]) => {
    const url = new URL(value);
    url.hostname = location.hostname;
    url.protocol = location.protocol;
    newConfig.endPoints[key] = url.href;
  });

  return newConfig;
};

export const dev = config.dev;
export const prod = config.prod;
export const appConfig = config;
export const mobileWidth = 800;
const customConfig = CUSTOM_CONFIG_NAME && config[CUSTOM_CONFIG_NAME];
const envConfig = (__DEV__ ? (customConfig || dev) : overwriteEndpointsDomainFromLive(customConfig || prod));

export default envConfig;

export const firebaseConfig = {
  apiKey: envConfig.firebaseApiKey,
  authDomain: "getsales-io-web.firebaseapp.com",
  projectId: "getsales-io-web",
  storageBucket: "getsales-io-web.appspot.com",
  messagingSenderId: "1043924910578",
  appId: "1:1043924910578:web:ee40390e5abb604a426b6b",
  measurementId: "G-YPT38Q920D",
};

