export const icons = {
  SEND_EMAIL: "send-email",
  SEND_MANUAL_EMAIL: "mail-manual",
  PHONE_CALL: "call-phone",
  LINKEDIN: "linkedin-with-bg",
  SEND_CONTACT_IN_NEW_FLOW: "sent-to-another-flow",
  ADD_TO_LIST: "tag-new",
  ASSIGN_TO: "businessman-4",
  CHANGE_VALUE_FIELD: "pen",
  REMOVE_FROM_LIST: "minus-in-square",
  IS_ASSIGNED_TO: "person-cColor",
  HAS_FIELD: "contactfields",
  IS_IN_FLOW: "personFromFlow",
  EMAIL_LINK_CLICK: "link-clicked",
  EMAIL_OPEN: "mail-opened",
  EMAIL_FORWARDED: "forward",
  FILE_DOWNLOAD: "import",

  VISIT_PAGE: "FlowVisitPage",

  CHECK_CONNECTION: "FlowConnect",
};
