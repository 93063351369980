import InputRange from "react-input-range";
import cn from "classnames";

import "react-input-range/lib/css/index.css";
import styles from "./Slider.module.scss";

export default ({ ...props }) => {
  return (
    <InputRange
      classNames={{
        track: styles.timeRangeTrack,
        activeTrack: styles.timeRangeActiveTrack,
        disabledInputRange: styles.timeRangeInputRangeDisabled,
        inputRange: cn(styles.timeRangeInputRange, { [styles.timeRangeOneSlider]: Number.isInteger(props.value) }),
        labelContainer: styles.timeRangeLabelContainer,
        maxLabel: styles.timeRangeRightLabel,
        minLabel: styles.timeRangeLeftLabel,
        slider: styles.timeRangeSlider,
        sliderContainer: styles.timeRangeSliderContainer,
        valueLabel: styles.timeRangeValueLabel,
      }}
      {...props}
    />
  );
};
