import * as actions from "./consts";
import { createCachedFunction } from "../utils";
import getAxios from "services/axios";
import store from "store/store";

const axios = getAxios("flowV2");

export const cacher = createCachedFunction((params) => (dispatch) => {
  return axios.get("/api/flow-workspaces", {
    params: {
      limit: 1000,
      ...params,
    },
  })
    .then((response) => {
      const { data } = response;
      const uuids = data.map(({ uuid }) => uuid);

      if (!uuids.length) return response;

      return getWorkspacesMetrics({ uuids })
        .then((metrics) => {
          const workspaces = data.map((workspace) => {
            return {
              ...workspace,
              metrics: metrics[workspace.uuid],
            };
          });

          dispatch({
            type: actions.SEED_WORKSPACES,
            workspaces,
          });

          return response;
        });
    });
}, { recursively: true });


export const getFlowWorkspaces = cacher.cachedFunction;

export const updateWorkspaces = () => (dispatch) => {
  cacher.clearCache();
  dispatch(getFlowWorkspaces());
};

export const updateFlowWorkspacesMetrics = () => (dispatch, getState) => {
  const state = getState().entities["flowWorkspaces"];
  const uuids = Object.values(state).map((workspace) => workspace.uuid);
  if (!uuids.length) return Promise.resolve([]);
  return getWorkspacesMetrics({ uuids }).then((metrics) => {
    const workspaces = Object.values(state).map((workspace) => {
      return {
        ...workspace,
        metrics: metrics[workspace.uuid],
      };
    });
    dispatch({
      type: actions.SEED_WORKSPACES,
      workspaces,
    });
    return metrics;
  });
};

export const createFlowWorkspace = ({ name }) => (dispatch) => {
  return axios.post("api/flow-workspaces", { name })
    .then( (workspace) => {
      cacher.clearCache();
      dispatch({
        type: actions.CREATE_WORKSPACE,
        workspace,
      });
      return workspace;
    });
};

export const editFlowWorkspace = ({ uuid, name }) => (dispatch) => {
  return axios.put(`api/flow-workspaces/${uuid}`, { name })
    .then( (workspace) => {
      cacher.clearCache();
      dispatch({
        type: actions.UPDATE_WORKSPACE,
        workspace,
      });
    });
};

export const deleteFlowWorkspace = (uuid) => (dispatch) => {
  //flowsCacher.clearCache();

  return axios.delete(`api/flow-workspaces/${uuid}`)
    .then(() => {
      cacher.clearCache();
      dispatch({
        type: actions.DELETE_WORKSPACE,
        workspaceUuid: uuid,
      });
    });
};

export const getWorkspacesMetrics = ({ uuids }) => {
  return axios.post("/api/flow-workspaces/metrics", {
    uuids,
  });
};

export const getWorkspacesOptions = async (query) => {
  const res = await store.dispatch(getFlowWorkspaces()).then(({ data }) => data.reduce((res, workspace) => {
    if (RegExp(query, "i").test(workspace.name) || typeof query !== "string") {
      res.push({ value: workspace.uuid, label: workspace.name });
    }

    return res;
  }, []));
  res.push({ value: null, label: "Default Workspace" });
  return res;
};
