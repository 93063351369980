import type { MassActionType } from "@gs/core/domain/MassAction/massActionType";
import { FlowMassActionName } from "./FlowMassActionName";
import { PipelineMassActionName } from "./PipelineMassActionNames";
import { ListMassActionName } from "./ListMassActionName";
import { TagMassActionName } from "./TagMassActionName";
import { DefaultMassActionName } from "./DefaultMassActionName";
import type { MassActionTypeMap } from "@gs/core/domain/MassAction/MassAction";
import { SenderProfileMassActionName } from "./SenderProfileMassActionName";
import type { FlowId } from "@gs/core/domain/Flow/Flow";


export function getNameComponentByType<T extends MassActionType>(type: T, payload: MassActionTypeMap[T]["payload"]) {
  if (["account_add_list", "contact_change_list"].includes(type) && typeof payload === "object" && payload && "list_uuid" in payload) {
    return <ListMassActionName payload={payload} />;
  } else if (["account_change_pipeline_stage", "contact_change_pipeline_stage"].includes(type) && typeof payload === "object" && payload && "pipeline_stage_uuid" in payload) {
    return <PipelineMassActionName payload={payload} />;
  } else if (["account_remove_tags", "account_replace_tags", "account_add_tags", "contact_add_tags", "contact_remove_tags", "contact_replace_tags"].includes(type) && typeof payload === "object" && payload && ("tags" in payload || "removed_tags" in payload || "added_tags" in payload)) {
    return <TagMassActionName
      type={type}
      payload={payload}
    />;
  } else if (["contact_add_to_flow", "contact_cancel_from_flows"].includes(type) && typeof payload === "object" && payload && "flow_uuid" in payload) {
    return <FlowMassActionName
      type={type}
      payload={payload as { flow_uuid: FlowId }}
    />;
  } else if (["contact_change_sender_profile"].includes(type) && typeof payload === "object" && payload && "sender_profile_uuid" in payload) {
    return <SenderProfileMassActionName payload={payload} />;
  } else {
    return <DefaultMassActionName type={type} />;
  }
};
