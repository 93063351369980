import React from "react";
import PropTypes from "prop-types";
import { getError } from "services/utils";
import ModalTrigger from "components/hocs/ModalTrigger";
import Modal from "components/common/Modal/Modal";
import Button from "components/common/buttons/Button";

class ConfirmationModalContainer extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      isProcessing: false,
      error: "",
    };

    this.confirm = this.confirm.bind(this);
  }

  confirm() {
    if (this.state.isProcessing) {
      return;
    }

    const {
      onRequestClose,
      action,
    } = this.props;

    this.setState({ isProcessing: true });

    Promise.resolve(action())
      .then( onRequestClose )
      .catch( (error) => {
        console.log("error", error);
        this.setState({
          isProcessing: false,
          error: getError(error) || "Unknown error",
        });
      });
  }

  render() {
    const {
      onRequestClose,
      headerText,
      mainText = "Are you sure?",
      confirmText = "Delete",
      cancelText = "Cancel",
      color = "red", // green | blue | white // Саня это нуадо поменять. сам решай на что. можно на buttonClass
    } = this.props;

    const { error, isProcessing } = this.state;

    return (
      <Modal.Container>
        <Modal.Header
          header={headerText || mainText}
          onClose={onRequestClose}
        />
        <div style={{ maxWidth: "290px", whiteSpace: "pre-wrap" }}>
          { mainText}
        </div>
        { error && (
          <div style={{ color: "red" }}>
            { error }
          </div>
        )}
        <div
          className='gs-modal__footer gs-modal__actions'
          style={{ paddingBottom: 0 }}
        >
          <Button
            cancelType
            onClick={(e) => {
              e.preventDefault();
              onRequestClose();
            }}
          >
            { cancelText }
          </Button>
          <Button
            green
            type="button"
            onClick={this.confirm}
            active={isProcessing}
          >
            { confirmText }
          </Button>
        </div>
      </Modal.Container>
    );
  }
}

ConfirmationModalContainer.propTypes = {
  onRequestClose: PropTypes.func,
  action: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  mainText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  color: PropTypes.string,
};

export const ConfirmationContent = ConfirmationModalContainer;

const ConfirmationModal = ModalTrigger(ConfirmationModalContainer);

export {
  ConfirmationModal as default,
  ConfirmationModal,
};
