import * as actions from "./consts";
import { entitySetter, deleteByIds } from "../utils";
// import { immuteSet } from "services/immuteActions";
//
// const { stripeStatuses } = actions;

const ACTION_HANDLERS = {
  [actions.SUBSCRIPTIONS_GET_SUCCESS]: (state, { subscriptions }) => entitySetter(state, subscriptions),
  [actions.SUBSCRIPTIONS_CANCEL_SUCCESS]: (state, { subscriptionId, subscription }) => {
    const s = { ...state };
    if (subscription) {
      s[subscription.id] = subscription;
    }
    return s;
  },
  [actions.SUBSCRIPTIONS_RESUME_SUCCESS]: (state, { subscription }) => entitySetter(state, subscription, true),
  [actions.SUBSCRIPTIONS_UPDATE]: (state, { subscription }) => {
    const s = { ...state };
    s[subscription.id] = subscription;
    return s;
  },
};

const initialState = {};

export default function subscriptionsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
