const SEED_CATEGORIES = "SEED_CATEGORIES_TEMPLATE";
const DELETE_TEMPLATE_CATEGORY = "DELETE_TEMPLATE_CATEGORY";
const CREATE_TEMPLATE_CATEGORY = "CREATE_TEMPLATE_CATEGORY";
const UPDATE_TEMPLATE_CATEGORY = "UPDATE_TEMPLATE_CATEGORY";

export {
  SEED_CATEGORIES,
  DELETE_TEMPLATE_CATEGORY,
  CREATE_TEMPLATE_CATEGORY,
  UPDATE_TEMPLATE_CATEGORY,
};
