const SEED_COMPANIES = "SEED_COMPANIES";
const COMPANIES_SET_TAGS_SUCCESS = "COMPANIES_SET_TAGS_SUCCESS";
const COMPANIES_SET_PIPELINE_SUCCESS = "COMPANIES_SET_PIPELINE_SUCCESS";
const COMPANIES_SET_LIST_SUCCESS = "COMPANIES_SET_LIST_SUCCESS";
const COMPANIES_DELETE_SUCCESS = "COMPANIES_DELETE_SUCCESS";


const EMAIL_ACTIVITIES = {
  EMAIL_OPENED: "email_opened",
  EMAIL_REPLIED: "email_replied",
  EMAIL_SENT: "email_sent",
  EMAIL_BOUNCED: "email_bounced",
  EMAIL_LINK_CLICKED: "email_link_clicked",
  EMAIL_LEAD_UNSUBSCRIBED: "email_lead_unsubscribed",
};

const SOCIAL_ACTIVITIES = {
  LINKEDIN_CONNECTION_SENT: "linkedin_connection_request_sent",
  LINKEDIN_CONNECTION_ACCEPTED: "linkedin_connection_request_accepted",
  LINKEDIN_CONNECTION_WITHDRAWN: "linkedin_connection_request_withdrawn",
  LINKEDIN_MESSAGE_SENT: "linkedin_message_sent",
  LINKEDIN_MESSAGE_REPLIED: "linkedin_message_replied",
  LINKEDIN_OPENED: "linkedin_opened",
  LINKEDIN_LINK_CLICKED: "linkedin_link_clicked",
  LINKEDIN_POST_LIKED: "linkedin_post_liked",
  LINKEDIN_ENDORSE_SKILLS: "linkedin_skills_endorsed",
  LINKEDIN_PROFILE_VISITED: "linkedin_profile_visited",
  LINKEDIN_POST_COMMENTED: "linkedin_post_commented",
  LINKEDIN_BLOCKED: "linkedin_blocked",
};

const FLOW_ACTIVITIES = {
  FLOW_FINISHED: "flow_finished",
  FLOW_FAILED: "flow_failed",
  FLOW_CANCELLED: "flow_cancelled",
  ADDED_TO_FLOW: "added_to_flow",
};

const TAGS_ACTIVITIES = {
  TAGS_ADDED: "tags_added",
  TAGS_REMOVED: "tags_removed",
};

const OTHER_ACTIVITIES = {
  LEAD_CREATED: "created",
  SENDER_PROFILE_CHANGED: "sender_profile_changed",
  SYNCED_CRM: "synced_crm",
  PIPELINE_STAGE_CHANGED: "pipeline_stage_changed",
  LIST_CHANGED: "list_changed",
  LIST_ADDED: "list_added",
  LIST_REMOVED: "list_removed",
  LEAD_UPDATED: "updated",
  LEAD_ENRICHED: "enriched",
  LEAD_DELETED: "deleted",
};

const ALL_COMPANY_ACTIVITIES = {
  ...EMAIL_ACTIVITIES,
  ...SOCIAL_ACTIVITIES,
  ...FLOW_ACTIVITIES,
  ...TAGS_ACTIVITIES,
  ...OTHER_ACTIVITIES,
};


const ActivitesNames = {
  [ALL_COMPANY_ACTIVITIES.TAGS_ADDED]: "Tags Added to Lead",
  [ALL_COMPANY_ACTIVITIES.TAGS_REMOVED]: "Tags Removed from Lead Removed",

  [ALL_COMPANY_ACTIVITIES.FLOW_FINISHED]: "Flow Finished",
  [ALL_COMPANY_ACTIVITIES.FLOW_FAILED]: "Flow Failed",
  [ALL_COMPANY_ACTIVITIES.FLOW_CANCELLED]: "Flow cancelled",
  [ALL_COMPANY_ACTIVITIES.ADDED_TO_FLOW]: "Added to Flow",

  [ALL_COMPANY_ACTIVITIES.LINKEDIN_CONNECTION_SENT]: "Connect",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_CONNECTION_ACCEPTED]: "LinkedIn Connected",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_CONNECTION_WITHDRAWN]: "Withdraw Connect",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_MESSAGE_SENT]: "Direct Message sent",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_MESSAGE_REPLIED]: "Direct Message replied",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_OPENED]: "LinkedIn Opened",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_LINK_CLICKED]: "Direct Message click",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_POST_LIKED]: "Post Like",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_ENDORSE_SKILLS]: "Skills Endorsement",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_PROFILE_VISITED]: "Profile Visit",
  [ALL_COMPANY_ACTIVITIES.LINKEDIN_POST_COMMENTED]: "Post Comment",

  [ALL_COMPANY_ACTIVITIES.EMAIL_OPENED]: "Email opened",
  [ALL_COMPANY_ACTIVITIES.EMAIL_REPLIED]: "Email replied",
  [ALL_COMPANY_ACTIVITIES.EMAIL_SENT]: "Email sent",
  [ALL_COMPANY_ACTIVITIES.EMAIL_BOUNCED]: "Email bounced",
  [ALL_COMPANY_ACTIVITIES.EMAIL_LINK_CLICKED]: "Email link click",
  [ALL_COMPANY_ACTIVITIES.EMAIL_LEAD_UNSUBSCRIBED]: "Email lead unsubscribed",

  [ALL_COMPANY_ACTIVITIES.LEAD_CREATED]: "Lead Created",
  [ALL_COMPANY_ACTIVITIES.SENDER_PROFILE_CHANGED]: "Sender profile changed",
  [ALL_COMPANY_ACTIVITIES.SYNCED_CRM]: "Synced to CRM",
  [ALL_COMPANY_ACTIVITIES.PIPELINE_STAGE_CHANGED]: "Pipeline stage changed",
  [ALL_COMPANY_ACTIVITIES.LIST_CHANGED]: "List changed",
  [ALL_COMPANY_ACTIVITIES.LIST_ENRICHED]: "Lead Enriched",
};



export {
  SEED_COMPANIES,
  COMPANIES_SET_TAGS_SUCCESS,
  COMPANIES_SET_LIST_SUCCESS,
  COMPANIES_SET_PIPELINE_SUCCESS,
  COMPANIES_DELETE_SUCCESS,
  ALL_COMPANY_ACTIVITIES,
};


