import { getBaseAxios } from "services/axios";
import { listStore } from "./listStore";
import { createListApi } from "@gs/core/domain/List/createListApi";
import type { ListId, List } from "@gs/core/domain/List/List";

export const listApi = (() => {
  const leadsAxios = getBaseAxios("contactV2");

  const coreListApi = createListApi(leadsAxios);

  const getLists: typeof coreListApi.getLists = async (...args) => {
    const result = await coreListApi.getLists(...args);

    listStore.setState(
      result.data.data.reduce<Record<ListId, List>>((acc, list) => {
        acc[list.uuid] = list;
        return acc;
      }, {}),
    );

    return result;
  };

  const getList: typeof coreListApi.getList = async (...args) => {
    const result = await coreListApi.getList(...args);

    listStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const createList: typeof coreListApi.createList = async (...args) => {
    const result = await coreListApi.createList(...args);

    listStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  return {
    getLists,
    getList,
    createList,
  };
})();
