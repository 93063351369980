import styles from "./ModalContainer.module.scss";
import type { CSSProperties, ReactNode, ComponentType } from "react";
import React from "react";
import cn from "classnames";

export const ModalContainer = (props: {
  WrapperElement?: "div" | "form" | ComponentType
  className?: string
  style?: CSSProperties
  children: ReactNode
}) => {
  const { WrapperElement = "div", className, style, children } = props;
  return (
    <WrapperElement
      className={cn(className, styles.container)}
      style={style}
    >
      {children}
    </WrapperElement>
  );
};

