import React, { PureComponent } from "react";
import cn from "classnames";
import Modal from "components/common/Modal/Modal";

const defaultFunction = () => {};

export default (ModalContent) => {
  return class extends PureComponent {
    constructor(props) {
      super();
      this.state = {
        isOpen: !!props.isOpen || false,
      };

      this.handleClick = this.handleClick.bind(this);
    }

    handleModal(show) {
      if (show && this.props.disabled) {
        return;
      }

      this.setState({ isOpen: show });

      const { onOpen = defaultFunction, onClose = defaultFunction } = this.props;
      if (show) {
        onOpen();
      } else {
        onClose();
      }
    }
    handleClick(e) {
      this.handleModal(true);

      const { onClick } = this.props;

      onClick && onClick(e);
    }

    componentDidUpdate(prevProps) {
      const { isOpen } = this.props;

      if (prevProps.isOpen !== isOpen && isOpen) {
        this.handleModal(true);
      }
    }

    render() {
      const {
        children,
        className,
        style,
        modalCustomStyles,
        modalLabel = "",
        modalClassName = "",
        overlayClassName = "gs-modal__overlay",
      } = this.props;

      return (
        <div
          onClick={ this.handleClick }
          className={className}
          style={style}
          role="button"
        >
          { children }
          <Modal
            label={modalLabel}
            style={style}
            modalCustomStyles={modalCustomStyles}
            className={cn("gs-modal", { [modalClassName]: modalClassName })}
            overlayClassName={overlayClassName}
            closeModal={() => this.handleModal(false)}
            isOpen={this.state.isOpen}
          >
            <ModalContent {...this.props} />
          </Modal>
        </div>
      );
    }
  };
};
