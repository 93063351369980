import type { FC, HTMLAttributes } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as PeopleIcon } from "bootstrap-icons/icons/people.svg";
import { Badge } from "@gs/uiKit/dataDisplay/Badge/Badge";
import type { ServerStatus } from "@gs/core/domain/LinkedinServer/LinkedinServer";
import { serversSelector } from "store/entities/servers/selectors";
import { mailboxesArraySelector } from "store/entities/mailboxes/selectors";

// TODO: remove redux, add listeners
export const SenderProfilesMenuItemIcon: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const [isSenderProfilesErrors, setIsSenderProfilesErrors] = useState(false);
  const [isMailboxErrors, setIsMailboxErrors] = useState(false);
  const serversData = useSelector(serversSelector);
  const mailboxes = useSelector(mailboxesArraySelector);
  const allowedStatuses: ServerStatus[] = ["ready_for_login", "captcha", "busy"];

  useEffect(() => {
    setIsMailboxErrors(
      mailboxes.some(
        (mailbox) => mailbox.send_status === "error" || mailbox.sync_status === "error",
      ),
    );
  }, [mailboxes]);

  useEffect(() => {
    const serversArray = Object.values(serversData);
    let count = 0;
    serversArray.forEach((server: any) => {
      if (!allowedStatuses.includes(server.status)) {
        count++;
      }
    });
    if (count > 0) {
      setIsSenderProfilesErrors(true);
    } else {
      setIsSenderProfilesErrors(false);
    }
  }, [serversData]);

  return (
    <Badge
      dot={isSenderProfilesErrors || isMailboxErrors}
      {...props}
    >
      <PeopleIcon />
    </Badge>
  );
};
