import * as actions from "./consts";
import { createCachedFunction } from "../utils";
import getAxios from "services/axios";
import store from "store/store";

const axios = getAxios("contactV2");

const cacher = createCachedFunction((params) => (dispatch) => {
  return axios.get("/api/custom-fields", {
    params,
  })
    .then((response) => {
      const uuids = [];
      response.data.forEach((obj) => {
        if (obj.object !== "sender_profile") {
          uuids.push(obj.uuid);
        }
      });
      if (!uuids.length) {
        dispatch({
          type: actions.CUSTOM_FIELDS_SET,
          fields: response.data,
        });
        return response;
      } else {
        return getCustomFieldsMetrics({ uuids })
          .then((metrics) => {
            const fields = response.data.map((field) => {
              return {
                ...field,
                metrics: metrics[field.uuid],
              };
            });

            dispatch({
              type: actions.CUSTOM_FIELDS_SET,
              fields,
            });

            return response;
          });
      }
    });
}, { recursively: true });


export const getCustomFields = cacher.cachedFunction;

export const createCustomFiled = ({ params }) => (dispatch) => {
  return axios.post("api/custom-fields", params)
    .then( (field) => {
      cacher.clearCache();
      dispatch({
        type: actions.CUSTOM_FIELDS_CREATE,
        field,
      });
      return field;
    });
};

export const editCustomFiled = ({ fieldId, params }) => (dispatch) => {
  return axios.put(`api/custom-fields/${fieldId}`, params)
    .then( (field) => {
      cacher.clearCache();
      dispatch({
        type: actions.CUSTOM_FIELDS_UPDATE,
        field,
      });
    });
};

export const deleteCustomFiled = (fieldId) => (dispatch) => {
  return axios.delete(`api/custom-fields/${fieldId}`)
    .then(() => {
      cacher.clearCache();
      dispatch({
        type: actions.CUSTOM_FIELDS_DELETE,
        fieldId,
      });
    });
};

export const getCustomFieldsMetrics = ({ uuids }) => {
  return axios.put("/api/custom-fields/metrics", {
    uuids,
  });
};

export const getCustomFieldsOptions = () => store.dispatch(getCustomFields())
  .then(({ data }) => data.map((item) => ({ label: item.name, value: item.key })));

