import { Redirect, Route, Switch } from "react-router-dom";

import User, { path as userPath } from "./User";
import TeamsManagement, { path as teamsPath } from "./TeamsManagement";
import { SubscriptionsPage, path as subscriptionsPath } from "./Subscriptions";
import NotFoundRoute from "../NotFound";
import rootPath from "./path";
import styles from "./Styles.module.scss";
import Navigation from "./Navigation/Navigation";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import { ContentBody } from "feature/mainLayout/component/ContentBody/ContentBody";

export { default as path } from "./path";

export default () => (
  <>
    <ContentHeader />
    <ContentBody style={{ display: "flex", gap: "var(--padding)" }}>
      <Route
        path="/user-settings"
        component={Navigation}
      />
      <div className={styles.TabContent}>
        <Switch>
          <Route
            exact
            path={rootPath}
          >
            <Redirect to={userPath} />
          </Route>
          <Route
            exact
            path={userPath}
            component={User}
          />
          <Route
            path={subscriptionsPath}
            component={SubscriptionsPage}
          />
          <Route
            exact
            path={teamsPath}
            component={TeamsManagement}
          />
          <Route component={NotFoundRoute} />
          {" "}
          {/* 404 */}
        </Switch>
      </div>
    </ContentBody>
  </>
);
