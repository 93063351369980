import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse } from "../../commonTypes";
import type { Mixed, OrderType } from "../../commonTypes";
import type { MassAction, MassActionId, MassActionMetrics } from "./MassAction";
import type { TeamId } from "../Team/Team";
import type { UserId } from "../User/User";

export type MassActionResponse<Entity> = {
  count: number
  data: Entity[]
  mass_action: MassAction | null
}

export const createMassActionApi = (axios: AxiosInstance) => {
  const getMassActions = async (
    params: {
      filter?: {
        id?: Mixed<MassActionId>
        teamId?: Mixed<TeamId>
        type?: Mixed<MassAction["type"]>
        status?: Mixed<MassAction["status"]>
        userId?: Mixed<UserId>
        withDeleted?: boolean
      }
      orderField?: string
      orderType?: OrderType
      limit: number
      offset: number
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<MassAction>>(
      "/api/mass-actions",
      {
        ...config,
        params: {
          limit: params.limit,
          offset: params.offset,
          order_field: params.orderField,
          order_type: params.orderType,
          filter: {
            uuid: params.filter?.id,
            team_id: params.filter?.teamId,
            type: params.filter?.type,
            status: params.filter?.status,
            user_id: params.filter?.userId,
            deleted: params.filter?.withDeleted,
          },
        },
      },
    );
  };

  const getMassAction = (id: MassActionId) => {
    return axios.get<MassAction>(`/api/mass-actions/${id}`);
  };

  const deleteMassAction = (params: {id: MassActionId}, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/mass-actions/${params.id}`, config);
  };

  const getMassActionsMetrics = async <Metrics extends Array<keyof MassActionMetrics>> ( params: {
    ids?: MassActionId[]
    metrics?: Metrics
  }) => {
    return axios.post<Record<MassActionId, MassActionMetrics>>("/api/mass-actions/metrics", {
      uuids: params.ids,
      metrics: params.metrics,
    });
  };

  return {
    getMassActions,
    getMassAction,
    getMassActionsMetrics,
    deleteMassAction,
  };
};
