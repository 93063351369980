import { useState, useEffect, useLayoutEffect } from "react";
import { messagesFromClientPluginToFrontApp, messagesFromFrontAppToClientPlugin, eventNames } from "clientPluginCommunication";

export const sendMessageToPlugin = (type, payload) => {
  const event = new CustomEvent(eventNames.TO_PLUGIN_EVENT_NAME, { detail: { type, payload } });
  document.body.dispatchEvent(event);
};

const getDefaultRecords = () => {
  try {
    return JSON.parse(localStorage.getItem("_dynamicRecords")) || [];
  } catch (e) {
    return [];
  }
};

export const usePluginMeta = () => {
  const [meta, setMeta] = useState({});

  useEffect(() => {
    sendMessageToPlugin(messagesFromFrontAppToClientPlugin.GET_URL);

    function eventHandler(event) {
      switch (event.detail.type) {
        case messagesFromClientPluginToFrontApp.RECORDS: {
          setMeta(event.detail.meta);
          break;
        }
        case messagesFromClientPluginToFrontApp.CURRENT_TAB_URL: {
          setMeta({ url: event.detail.url });
          break;

        }
      }
    }
    document.body.addEventListener(eventNames.FROM_PLUGIN_EVENT_NAME, eventHandler);

    return () => document.body.removeEventListener(eventNames.FROM_PLUGIN_EVENT_NAME, eventHandler);
  }, []);

  return meta;
};
export const usePluginRecords = () => {
  const [records, setRecords] = useState(getDefaultRecords());
  const [context, setContext] = useState(localStorage.getItem("_dynamicContext") || "");

  useLayoutEffect(() => {
    function eventHandler(event) {
      switch (event.detail.type) {
        case messagesFromClientPluginToFrontApp.RECORDS: {
          setRecords(event.detail.records);
          break;
        }
        case messagesFromClientPluginToFrontApp.RESTART: {
          setRecords([]);
          setTimeout(() => {
            sendMessageToPlugin(
              messagesFromFrontAppToClientPlugin.START_PARSING,
            );
          }, 1000);
          break;
        }
        case messagesFromClientPluginToFrontApp.DYNAMIC_DATA: {
          const { data, context: theContext, route, uniqField } = event.detail;

          if (context != theContext) {
            setContext(theContext);
            setRecords(data);
          } else {

            const existed = {};
            for (const record of records) {
              existed[record[uniqField]] = true;
            }
            setRecords([
              ...records,
              ...data.filter((item) => !existed[item[uniqField]]),
            ]);
          }
          break;
        }
      }
    }
    document.body.addEventListener(eventNames.FROM_PLUGIN_EVENT_NAME, eventHandler);

    return () => {
      document.body.removeEventListener(eventNames.FROM_PLUGIN_EVENT_NAME, eventHandler);
    };
  }, [context, records]);
  useEffect(() => {
    setRecords(getDefaultRecords());
  }, []);

  return records;
};
