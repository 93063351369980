export const SUBSCRIPTIONS_GET_SUCCESS = "SUBSCRIPTIONS_GET_SUCCESS";
export const SUBSCRIPTIONS_CANCEL_SUCCESS = "SUBSCRIPTIONS_CANCEL_SUCCESS";
export const SUBSCRIPTIONS_RESUME_SUCCESS = "SUBSCRIPTIONS_RESUME_SUCCESS";
export const SUBSCRIPTIONS_UPDATE = "SUBSCRIPTIONS_UPDATE";


export const paddleStatuses = {
  ACTIVE: "active",
  TRIALING: "trialing",
  PAST_DUE: "past_due",
  PAUSED: "paused",
  CANCELED: "deleted",
};

export const stripeStatuses = {
  ACTIVE: "active",
  CANCELED: "cancelled",
  INCOMPLETE: "incomplete",
  EXPIRED: "incomplete_expired",
  PAST: "past_due",
  UNPAID: "unpaid",
  TRIALING: "trialing",
  TRIAL: "trial",
};
