import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const ImportsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'ImportsPage' */ "./Imports")
      .catch( (e) => {
        console.log("Imports Page loading err", e);
        return Error404;
      });
  },
  // @ts-ignore
  loading: Loading,
});

export default ImportsPage;

