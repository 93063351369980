import styles from "./ModalHeader.module.scss";
import type { ReactNode } from "react";
import React from "react";
import cn from "classnames";
import { Button } from "@gs/uiKit/general/Button";
import { CloseOutlined } from "@ant-design/icons";
import { Divider } from "@gs/uiKit/layout/Divider";

export const ModalHeader = (props: {
  header: ReactNode
  onClose: () => void
  className?: string
  closeClassName?: string
}) => {
  const { header, onClose, className, closeClassName = "" } = props;

  return (
    <>
      <div className={cn(styles.container, className)}>
        <h2 className={styles.title}>
          {header}
        </h2>

        <Button
          onClick={onClose}
          type="text"
          icon={<CloseOutlined />}
          className={closeClassName}
        />
      </div>
      <Divider style={{ margin: 0 }} />
    </>
  );
};

