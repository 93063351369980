import { useFindOrFetchList } from "domain/list/hook/useFindOrFetchList";
import type { ListId } from "@gs/core/domain/List/List";

export const ListMassActionName = ({
  payload,
}: {
  payload: { list_uuid: ListId | ListId[] }
}) => {
  const listId = Array.isArray(payload.list_uuid) ? payload.list_uuid[0]! : payload.list_uuid;
  const [list] = useFindOrFetchList(listId);
  return (
    <>
      Send To
      {" "}
      {list?.name}
      {" "}
      list
    </>
  );
};
