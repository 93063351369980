export enum messageStatuses {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  DONE = "done",
  FAILED = "failed",
  CANCELED = "canceled",
  DELEGATED = "delegated"
}

export enum messageTypes {
  INBOX = "inbox",
  OUTBOX = "outbox",
}

export enum messageAutomations {
  AUTO = "auto",
  NATIVE = "synced",
  MANUAL = "manual",
  CONNECT = "connect",
  AMO = "amo_synced",
}
