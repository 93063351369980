import { getBaseAxios } from "services/axios";
import { createFileExportApi } from "@gs/core/domain/FileExport/createFileExportApi";
import { massActionStore } from "domain/massAction/massActionStore";
import { dataBus } from "services/dataBus";

export const fileExportApi = (() => {
  const contactAxios = getBaseAxios("contactV2");
  const coreMassActionApi = createFileExportApi(contactAxios);

  const exportLeadToCsv: typeof coreMassActionApi.exportLeadToCsv = async (...args) => {
    const result = await coreMassActionApi.exportLeadToCsv(...args);
    massActionStore.setState({
      [result.data.uuid]: result.data,
    });
    dataBus.emit("massAction:create", { massActionIds: [result.data.uuid] });
    return result;
  };

  const exportCompanyToCsv: typeof coreMassActionApi.exportCompanyToCsv = async (...args) => {
    const result = await coreMassActionApi.exportCompanyToCsv(...args);
    massActionStore.setState({
      [result.data.uuid]: result.data,
    });
    dataBus.emit("massAction:create", { massActionIds: [result.data.uuid] });
    return result;
  };

  return {
    exportLeadToCsv,
    exportCompanyToCsv,
    downloadCsvExport: coreMassActionApi.downloadCsvExport,
  };
})();
