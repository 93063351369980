import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { User } from "./User";
import type { TeamId } from "../Team/Team";
import type { Timezone } from "../../commonTypes";

type UserChangeableFields = Pick<User, "first_name" | "last_name" | "email" | "phone" | "company" | "position" | "timezone" | "image_url">

export const createCurrentUserApi = (axios: AxiosInstance) => {
  const getCurrentUser = (config?: AxiosRequestConfig) => {
    return axios.get<User>("api/users/current", {
      ...config,
    });
  };

  const updateCurrentUser = (params: UserChangeableFields, config?: AxiosRequestConfig) => {
    return axios.put<User>("/api/users", params, config);
  };

  const signIn = (
    params: {
      email: string
      password: string
    },
    config?: AxiosRequestConfig) => {
    return axios.post<{token: string}>("/api/users/get-jwt-token", params, config);
  };

  const signUp = (
    params: {
      email: string
      gaTrackingId?: string
      name: string
      password: string
      timezone: Timezone
    },
    config?: AxiosRequestConfig) => {
    return axios.post<{token: string; user: User}>(
      "/api/users",
      {
        email: params.email,
        ga_tracking_id: params.gaTrackingId,
        name: params.name,
        password: params.password,
        password_confirmation: params.password,
        timezone: params.timezone,
      },
      config,
    );
  };

  const signOut = ( config?: AxiosRequestConfig) => {
    return axios.post<void>("api/users/revoke-jwt-token", {}, config);
  };

  const sendResetPasswordEmail = (params: {email: string}, config?: AxiosRequestConfig) => {
    return axios.post<void>("/api/users/send-reset-password-email", params, config);
  };

  const resetPassword = (
    params: {
      email: string
      password: string
      token: string
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<void>("/api/users/reset-password", params, config);
  };

  const resendConfirmationEmail = (config?: AxiosRequestConfig) => {
    return axios.get<void>("/api/users/resend-confirmation-email", config);
  };

  const changePassword = (
    params: {
      currentPassword: string
      newPassword: string
    },
    config?: AxiosRequestConfig) => {
    return axios.put<void>(
      "/api/users/change-password",
      {
        old_password: params.currentPassword,
        password: params.newPassword,
        password_confirmation: params.newPassword,
      },
      config,
    );
  };

  const changeEmail = (params: {email: string}, config?: AxiosRequestConfig) => {
    return axios.put<void>("/api/users/change-email", params, config);
  };
  const setLastTeamId = (params: {teamId: TeamId}, config?: AxiosRequestConfig) => {
    return axios.post<void>("/api/users/team-changed", { team_id: params.teamId }, config);
  };

  return {
    getCurrentUser,
    updateCurrentUser,
    signIn,
    signUp,
    signOut,
    sendResetPasswordEmail,
    resetPassword,
    resendConfirmationEmail,
    changePassword,
    changeEmail,
    setLastTeamId,
  };
};
