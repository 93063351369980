import { Route, Switch, Redirect } from "react-router-dom";
import Error404 from "components/routes/NotFound";
import { Templates } from "./Templates";
import { paths, templatesPath } from "./const";
import { Categories } from "./Categories";
import { useState } from "react";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import { InnerLink } from "components/common/routing/InnerLink";
import { ContentBody } from "feature/mainLayout/component/ContentBody/ContentBody";

export const TemplatesPage = () => {
  const [folderId, setFolderId] = useState<any>("");
  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>
          <InnerLink
            to={templatesPath}
            style={{ color: "inherit" }}
          >
            Templates
          </InnerLink>
        </ContentHeader.Title>
      </ContentHeader>
      <ContentBody style={{ display: "flex", gap: "var(--padding)" }}>
        <Categories
          onChange={(category) => setFolderId(category["template_category_uuid"])}
          activeCategory={folderId}
        />
        <div style={{ flex: "1 1 auto" }}>
          <Switch>
            <Route
              exact
              path={"/templates"}
            >
              <Redirect to={paths.templatesStatic} />
            </Route>

            <Route
              exact
              path={`${paths.templatesStatic}/:templateUuid?`}
            >
              <Templates folderId={folderId} />
            </Route>
            <Route>
              <Error404 />
            </Route>
          </Switch>
        </div>
      </ContentBody>
    </>
  );
};

