import find from "lodash/find";
import pull from "lodash/pull";

const deleteNodeRecursively = (model, nodeId, fromId) => {
  const uiNode = find(model.nodes, { id: nodeId });
  const fromNode = find(model.nodes, { id: fromId });

  switch (uiNode.before.length) {
    case 1: {
      const aftersCopy = [...uiNode.after];
      for (const id of aftersCopy) {
        deleteNodeRecursively(model, id, uiNode.id);
      }
      pull(model.nodes, uiNode);
      pull(fromNode.after, nodeId);
      break;
    }
    case 2: {
    // у соединяющего плюса все before тоже плюсы
      const anotherBeforeId = uiNode.before.filter((id) => id !== fromId)[0];
      const anotherBeforeNode = find(model.nodes, { id: anotherBeforeId });
      const nextNode = find(model.nodes, { id: uiNode.after[0] });

      let index = nextNode.before.indexOf(uiNode.id);
      nextNode.before[index] = anotherBeforeNode.id;

      index = anotherBeforeNode.after.indexOf(uiNode.id);
      anotherBeforeNode.after[index] = nextNode.id;

      const rootNode = find(model.nodes, { id: uiNode.root });
      rootNode.union = null;
      pull(model.nodes, uiNode);
      break;
    }
    // > 2
    default: {
      pull(uiNode.before, fromId);
    }
  }
};

export default deleteNodeRecursively;
