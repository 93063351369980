import { NavLink } from "react-router-dom";
import type { INavigationCategoryProps } from "../../../interfaces";
import { getLinkTo } from "../../../const";

export const NavigationCategory = ({ categoryName, templates, className }: INavigationCategoryProps) => {
  return (
    <div className={className}>
      <h4>{categoryName}</h4>
      {templates.map(({ label, uuid }) => {
        return (
          <NavLink
            key={label}
            className={(isActive) =>
              "nav-link" + (!isActive ? " unselected" : "")
            }
            style={(isActive) => ({
              backgroundColor: isActive ? "#CCEDF2" : "transparent",
              // color: isActive ? "white" : "#253342",
            })}
            to={getLinkTo(uuid)}
          >
            {label}
          </NavLink>
        );
      })}
    </div>
  );
};
