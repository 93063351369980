import { getBaseAxios } from "services/axios";
import { teamStore } from "./teamStore";
import { createTeamApi } from "@gs/core/domain/Team/createTeamApi";
import type { Team, TeamId } from "@gs/core/domain/Team/Team";
import withMemo from "@eabgaryan/with-memo";
import stableStringify from "fast-json-stable-stringify";
import { dataBus } from "services/dataBus";

export const teamApi = (() => {
  const idAxios = getBaseAxios("idV2");

  const coreTeamApi = createTeamApi(idAxios);

  const getTeams: typeof coreTeamApi.getTeams = async (...args) => {
    const result = await coreTeamApi.getTeams(...args);

    teamStore.setState(
      result.data.data.reduce<Record<TeamId, Team>>((acc, team) => {
        acc[team.id] = team;
        return acc;
      }, {}),
    );

    return result;
  };

  const getTeamsMemoized = withMemo(
    getTeams,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  dataBus.subscribe("subscription:created", () => getTeamsMemoized.invalidateCache());
  dataBus.subscribe("subscription:updated", () => getTeamsMemoized.invalidateCache());

  const getTeam: typeof coreTeamApi.getTeam = async (...args) => {
    const response = await coreTeamApi.getTeam(...args);
    const team = response.data;

    teamStore.setState({ [team.id]: team });

    return response;
  };

  const getTeamMemoized = withMemo(
    getTeam,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );


  const createTeam: typeof coreTeamApi.createTeam = async (...args) => {
    const response = await coreTeamApi.createTeam(...args);
    const team = response.data;

    getTeamsMemoized.invalidateCache();

    teamStore.setState({ [team.id]: team });

    return response;
  };


  const updateTeam: typeof coreTeamApi.updateTeam = async (...args) => {
    const response = await coreTeamApi.updateTeam(...args);
    const team = response.data;

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].id });

    teamStore.setState({ [team.id]: team });

    return response;
  };


  const deleteTeam: typeof coreTeamApi.deleteTeam = async (...args) => {
    const response = await coreTeamApi.deleteTeam(...args);

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].id });

    teamStore.setState((prev) => {
      const newState = { ...prev };

      delete newState[args[0].id];

      return newState;
    }, true);

    return response;
  };

  return {
    createTeam,
    getTeams,
    getTeamsMemoized,
    getTeam,
    getTeamMemoized,
    updateTeam,
    deleteTeam,
  };
})();
