export const FLOWS_LOAD_SUCCESS = "FLOWS_LOAD_SUCCESS";
export const FLOW_LOAD_SUCCESS = "FLOW_LOAD_SUCCESS";
export const FLOW_CREATE_SUCCESS = "FLOW_CREATE_SUCCESS";
export const FLOW_UPDATE_SUCCESS = "FLOW_UPDATE_SUCCESS";

export const FLOW_DELETE_SUCCESS = "FLOW_DELETE_SUCCESS";
export const FLOW_STATUS_CHANGE_SUCCESS = "FLOW_STATUS_CHANGE_SUCCESS";

export const FLOW_SET_SOURCE_STATISTICS = "FLOW_SET_SOURCE_STATISTICS";

// flow steps
export const FLOW_STEP_EDIT_REQUEST = "FLOW_STEP_EDIT_REQUEST";
export const FLOW_STEP_EDIT_SUCCESS = "FLOW_STEP_EDIT_SUCCESS";
export const FLOW_STEP_EDIT_FAILED = "FLOW_STEP_EDIT_FAILED";

export const FLOW_STEP_NEW_SUCCESS = "FLOW_STEP_NEW_SUCCESS";
export const FLOW_STEP_DELETE_SUCCESS = "FLOW_STEP_DELETE_SUCCESS";

export const CONTACTS_SET_FLOW_SUCCESS = "CONTACTS_SET_FLOW_SUCCESS";
export const CONTACTS_DELETE_FLOW_SUCCESS = "CONTACTS_DELETE_FLOW_SUCCESS";
