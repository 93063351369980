import { useState } from "react";
import moment from "moment";
import InputRange from "components/common/inputs/Slider";
import CheckBox from "components/common/inputs/CheckBox";
import styles from "./Styles.module.scss";

const MINUTES_PER_DAY = 24 * 60;

const daysOrder = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  0: "Sun",
};

export const DayRow = ({ day, timeblock, addTimeBlock, removeTimeBlock, updateTimeBlock }) => {
  const minValue = 0;
  const maxValue = MINUTES_PER_DAY;
  const [timeValue, setTimeValue] = useState({
    min: +timeblock?.from || timeblock?.min || 0,
    max: +timeblock?.to || timeblock?.max || MINUTES_PER_DAY,
  });

  const setFormatLabel = (value) => {
    const format = value === MINUTES_PER_DAY ? "kk:mm" : "HH:mm";
    return `${moment().hour(0).minute(value).format(format)}`;
  };

  const handleChangeRange = (value) => {
    if (value.min < minValue) {
      value.min = minValue;
    }
    if (value.max > maxValue) {
      value.max = maxValue;
    }
    updateTimeBlock(day, value);
  };

  const handleCheckbox = (e) => {
    const checked = e.target.checked;

    if (checked) {
      addTimeBlock({
        dow: day,
        min: timeValue.min,
        max: timeValue.max,
      });
    } else {
      removeTimeBlock(day);
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.checkbox}>
        <CheckBox
          checked={!!timeblock}
          onChange={(e) => handleCheckbox(e)}
        />
      </div>
      <div className={styles.day}> 
        {" "}
        { daysOrder[day] }
        {" "}
      </div>
      <div className={styles.timeRange}>
        <InputRange
          disabled={!timeblock}
          maxValue={maxValue}
          minValue={minValue}
          step={10}
          formatLabel={(value) => setFormatLabel(value)}
          value={timeValue}
          onChangeComplete={(value) => handleChangeRange(value)}
          onChange={(value) => setTimeValue(value)}
        />
      </div>
    </div>
  );
};
