import React from "react";
import cn from "classnames";
import Icon from "components/common/elements/Icon";

import styles from "./ModalHeader.module.scss";

const ModalHeader = ({ header, onClose, className = "", closeOuterClassName = "" }) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.title}>
      {header}
    </div>

    <div
      onClick={onClose}
      className={cn(styles.closeOuter, closeOuterClassName)}
    >
      <Icon
        icon="Cross"
        size={20}
        color="#516F90"
        className={styles.closeInner}
      />
    </div>
  </div>
);

export default ModalHeader;
