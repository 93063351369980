const TASKS_SET = "TASKS_SET";
const TASKS_SET_EXECUTE = "TASKS_SET_EXECUTE";
const TASKS_SET_UPDATE = "TASKS_SET_UPDATE";

const WITH_SNIPPET = {
  SOCIAL_CONNECT: "social_connect",
  SOCIAL_DM: "social_dm",
  SOCIAL_COMMENT: "social_comment",
};

const CUSTOM = {
  ROUTER: "router",
};

const NO_SNIPPET = {
  CUSTOM: "custom",
  CUSTOM_TASK: "custom_task",
  PHONE: "phone",
  PHONE_CALL: "phone_call",
  SOCIAL_ENDORSE_SKILLS: "social_endorse_skills",
  SOCIAL_ACTIVITY: "social_activity",
};

const EMAIL = {
  EMAIL: "email",
};

const ALL_TASKS = {
  ...WITH_SNIPPET,
  ...CUSTOM,
  ...NO_SNIPPET,
  ...EMAIL,
};

const tasksNames = {
  [ALL_TASKS.SOCIAL_CONNECT]: "Send Connect",
  [ALL_TASKS.SOCIAL_DM]: "Send Message",
  [ALL_TASKS.SOCIAL_COMMENT]: "Comment Post",
  [ALL_TASKS.ROUTER]: "Manual Router",
  [ALL_TASKS.CUSTOM_TASK]: "Custom Task",
  [ALL_TASKS.PHONE_CALL]: "Call Phone",
  [ALL_TASKS.SOCIAL_ENDORSE_SKILLS]: "Endorse Skills",
  [ALL_TASKS.SOCIAL_ACTIVITY]: "Like Post",
  [ALL_TASKS.EMAIL]: "Send Email",
};

const tasksIcons = {
  [ALL_TASKS.SOCIAL_CONNECT]: "linkedin-with-bg",
  [ALL_TASKS.SOCIAL_DM]: "linkedin-with-bg",
  [ALL_TASKS.SOCIAL_COMMENT]: "linkedin-with-bg",
  [ALL_TASKS.ROUTER]: "pointer",
  [ALL_TASKS.CUSTOM_TASK]: "linkedin-with-bg",
  [ALL_TASKS.PHONE_CALL]: "call",
  [ALL_TASKS.SOCIAL_ENDORSE_SKILLS]: "endorse",
  [ALL_TASKS.SOCIAL_ACTIVITY]: "linkedin-with-bg",
  [ALL_TASKS.EMAIL]: "email",
};

export {
  TASKS_SET,
  TASKS_SET_EXECUTE,
  TASKS_SET_UPDATE,
  WITH_SNIPPET,
  CUSTOM,
  NO_SNIPPET,
  EMAIL,
  ALL_TASKS,
  tasksNames,
  tasksIcons,
};
