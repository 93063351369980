const SEED_LEADS = "SEED_LEADS";
const SEED_LEAD = "SEED_LEAD";
const SEED_LEAD_COUNTERS = "SEED_LEAD_COUNTERS";
const LEADS_DELETE_SUCCESS = "LEADS_DELETE_SUCCESS";
const LEADS_SET_TAGS_SUCCESS = "LEADS_SET_TAGS_SUCCESS";
const LEADS_SET_LIST_SUCCESS = "LEADS_SET_LIST_SUCCESS";
const LEADS_SET_PIPELINE_SUCCESS = "LEADS_SET_PIPELINE_SUCCESS";
const LEADS_SET_SENDER_PROFILE = "LEADS_SET_SENDER_PROFILE";
const LEADS_ADD_TO_FLOW_SUCCESS = "LEADS_ADD_TO_FLOW_SUCCESS";
const LEADS_REMOVE_FROM_FLOW_SUCCESS = "LEADS_REMOVE_FROM_FLOW_SUCCESS";
const LEADS_ADD_ACTIVITY = "LEADS_ADD_ACTIVITY";
const LEADS_SET_UNREAD_COUNTS_SUCCESS = "LEADS_SET_UNREAD_COUNTS_SUCCESS";
const LEADS_MESSAGES_READ = "LEADS_MESSAGES_READ";
const LEADS_MARK_UNREAD = "LEADS_MARK_UNREAD";
const PEOPLE_P_DROP_SEARCH = "PEOPLE_P_DROP_SEARCH";


const EMAIL_ACTIVITIES = {
  EMAIL_OPENED: "email_opened",
  EMAIL_REPLIED: "email_replied",
  EMAIL_SENT: "email_sent",
  EMAIL_BOUNCED: "email_bounced",
  EMAIL_LINK_CLICKED: "email_link_clicked",
  EMAIL_LEAD_UNSUBSCRIBED: "email_lead_unsubscribed",
};

const SOCIAL_ACTIVITIES = {
  LINKEDIN_CONNECTION_SENT: "linkedin_connection_request_sent",
  LINKEDIN_CONNECTION_ACCEPTED: "linkedin_connection_request_accepted",
  LINKEDIN_CONNECTION_WITHDRAWN: "linkedin_connection_request_withdrawn",
  LINKEDIN_MESSAGE_SENT: "linkedin_message_sent",
  LINKEDIN_MESSAGE_REPLIED: "linkedin_message_replied",
  LINKEDIN_OPENED: "linkedin_opened",
  LINKEDIN_LINK_CLICKED: "linkedin_link_clicked",
  LINKEDIN_POST_LIKED: "linkedin_post_liked",
  LINKEDIN_ENDORSE_SKILLS: "linkedin_skills_endorsed",
  LINKEDIN_PROFILE_VISITED: "linkedin_profile_visited",
  LINKEDIN_POST_COMMENTED: "linkedin_post_commented",
  LINKEDIN_BLOCKED: "linkedin_blocked",
};

const FLOW_ACTIVITIES = {
  FLOW_FINISHED: "flow_finished",
  FLOW_FAILED: "flow_failed",
  FLOW_CANCELLED: "flow_cancelled",
  ADDED_TO_FLOW: "added_to_flow",
};

const TAGS_ACTIVITIES = {
  TAGS_ADDED: "tags_added",
  TAGS_REMOVED: "tags_removed",
};

export const OTHER_ACTIVITIES = {
  LEAD_CREATED: "created",
  SENDER_PROFILE_CHANGED: "sender_profile_changed",
  SYNCED_CRM: "synced_crm",
  PIPELINE_STAGE_CHANGED: "pipeline_stage_changed",
  LIST_CHANGED: "list_changed",
  LIST_ADDED: "list_added",
  LIST_REMOVED: "list_removed",
  LEAD_UPDATED: "updated",
  LEAD_ENRICHED: "enriched",
  LEAD_DELETED: "deleted",
};

const ALL_ACTIVITIES = {
  ...EMAIL_ACTIVITIES,
  ...SOCIAL_ACTIVITIES,
  ...FLOW_ACTIVITIES,
  ...TAGS_ACTIVITIES,
  ...OTHER_ACTIVITIES,
};

const ActivitesNames = {
  [ALL_ACTIVITIES.TAGS_ADDED]: "Tags Added to Lead",
  [ALL_ACTIVITIES.TAGS_REMOVED]: "Tags Removed from Lead Removed",

  [ALL_ACTIVITIES.FLOW_FINISHED]: "Automation Finished",
  [ALL_ACTIVITIES.FLOW_FAILED]: "Automation Failed",
  [ALL_ACTIVITIES.FLOW_CANCELLED]: "Automation Cancelled",
  [ALL_ACTIVITIES.ADDED_TO_FLOW]: "Added to Automation",

  [ALL_ACTIVITIES.LINKEDIN_CONNECTION_SENT]: "Connect",
  [ALL_ACTIVITIES.LINKEDIN_CONNECTION_ACCEPTED]: "LinkedIn Connected",
  [ALL_ACTIVITIES.LINKEDIN_CONNECTION_WITHDRAWN]: "Withdraw Connect",
  [ALL_ACTIVITIES.LINKEDIN_MESSAGE_SENT]: "Direct Message sent",
  [ALL_ACTIVITIES.LINKEDIN_MESSAGE_REPLIED]: "Direct Message replied",
  [ALL_ACTIVITIES.LINKEDIN_OPENED]: "LinkedIn Opened",
  [ALL_ACTIVITIES.LINKEDIN_LINK_CLICKED]: "Direct Message click",
  [ALL_ACTIVITIES.LINKEDIN_POST_LIKED]: "Post Like",
  [ALL_ACTIVITIES.LINKEDIN_ENDORSE_SKILLS]: "Skills Endorsement",
  [ALL_ACTIVITIES.LINKEDIN_PROFILE_VISITED]: "Profile Visit",
  [ALL_ACTIVITIES.LINKEDIN_POST_COMMENTED]: "Post Comment",

  [ALL_ACTIVITIES.EMAIL_OPENED]: "Email opened",
  [ALL_ACTIVITIES.EMAIL_REPLIED]: "Email replied",
  [ALL_ACTIVITIES.EMAIL_SENT]: "Email sent",
  [ALL_ACTIVITIES.EMAIL_BOUNCED]: "Email bounced",
  [ALL_ACTIVITIES.EMAIL_LINK_CLICKED]: "Email link click",
  [ALL_ACTIVITIES.EMAIL_LEAD_UNSUBSCRIBED]: "Email lead unsubscribed",

  [ALL_ACTIVITIES.LEAD_CREATED]: "Lead Created",
  [ALL_ACTIVITIES.LEAD_DELETED]: "deleted",
  [ALL_ACTIVITIES.SENDER_PROFILE_CHANGED]: "Sender profile changed",
  [ALL_ACTIVITIES.SYNCED_CRM]: "Synced to CRM",
  [ALL_ACTIVITIES.PIPELINE_STAGE_CHANGED]: "Pipeline stage changed",
  [ALL_ACTIVITIES.LIST_CHANGED]: "List changed",
  [ALL_ACTIVITIES.LEAD_ENRICHED]: "Lead Enriched",
};

export {
  PEOPLE_P_DROP_SEARCH,
  SEED_LEADS,
  SEED_LEAD,
  SEED_LEAD_COUNTERS,
  LEADS_ADD_ACTIVITY,
  LEADS_MESSAGES_READ,
  LEADS_DELETE_SUCCESS,
  LEADS_SET_TAGS_SUCCESS,
  LEADS_SET_LIST_SUCCESS,
  LEADS_SET_PIPELINE_SUCCESS,
  LEADS_SET_SENDER_PROFILE,
  LEADS_ADD_TO_FLOW_SUCCESS,
  LEADS_REMOVE_FROM_FLOW_SUCCESS,
  LEADS_SET_UNREAD_COUNTS_SUCCESS,
  LEADS_MARK_UNREAD,
  ALL_ACTIVITIES,
  ActivitesNames,
  EMAIL_ACTIVITIES,
  SOCIAL_ACTIVITIES,
};

