import type { IsoDate } from "../../commonTypes";
import type { LinkedinAccountId } from "../LinkedinAccount/LinkedinAccount";
import type { TeamId } from "../Team/Team";

export const serverStatuses = {
  BUSY: "busy", // сервер залогинился -> всё хорошо
  FREE: "free",
  LOGINING: "ready_for_login", // плагин на сервере вводит отданные ему логин/пароль -> показать спиннерв
  NEED_CODE: "pass_code_needed",
  LOGIN_ERROR: "login_error",
  PASS_CODE_ERROR: "pass_code_error",
  PASS_CODE_RESENT: "pass_code_resent", // сервер ведёт себя также как при LOGINING. нужен только в ui для отрисовки правильной формы
  CAPTCHA: "captcha",
  ACCOUNT_BUSY: "account_busy",
  CANCELED: "canceled",
  RESTRICTED: "restricted", // значит заблочили юзера на сервере. этот статус ставит бэк
  PROCESSING: "processing", // это вроде можно удалить. но на беке в словаре этот статус есть
} as const;

export type ServerStatus = (typeof serverStatuses)[keyof typeof serverStatuses]


export type LinkedinServerId = number & {__brand: LinkedinServerId}

export type LinkedinServer = {
  id: LinkedinServerId
  auth_token: null | string
  ip: string
  ip_location: string
  proxy_settings: NonNullable<{
    username?: string
    password?: string
    server: string
    port: number
    scheme: string
  }>
  status: ServerStatus
  linkedin_account_uuid: LinkedinAccountId
  team_id: TeamId
  updated_at: IsoDate
  created_at: IsoDate
}
