import React, { PureComponent } from "react";
import cn from "classnames";
import Input from "components/common/inputs/Input";
import { Icon } from "components/common/elements/Icon";

import styles from "../Styles.module.scss";
import { showError } from "../../../../../services/utils";

class TextRow extends PureComponent {
  constructor() {
    super();

    this.state = {
      dirty: false,
      touched: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  onChange(e) {
    const { maxLengthValue } = this.props;
    const value = e.target.value;
    if (maxLengthValue && value.length >= maxLengthValue) return showError(`The maximum field length should not be greater than ${maxLengthValue}`);
    if (value && !this.state.dirty) {
      this.setState({ dirty: true });
    }
    this.props.onChange(e);
  }
  onBlur(e) {
    const { onBlur } = this.props;
    const { dirty, touched } = this.state;

    if (dirty && !touched) {
      this.setState({ touched: true });
    }

    if (onBlur) {
      onBlur(e);
    }
  }

  render() {
    let {
      rowClassName,
      style,
      label,
      labelDescription,
      type = "text",
      error,
      isSubmitted,
      required,
      boldLabel,
      ...other
    } = this.props;

    const { dirty, touched } = this.state;

    const isError = (isSubmitted || dirty && touched) && error;
    // надо сделать, чтобы можно было много ошибок проказывать
    // а еще надо, чтоб error мог быть функцией валидации, возвращающий массив ошибок
    if (Array.isArray(error)) {
      error = error[0];
    }

    return (
      <label
        className={cn(styles.gsFormRow, rowClassName)}
        style={style}
      >
        { label && (
          <div className={styles.label}>
            <span
              style={{ fontWeight: boldLabel ? "700" : "400" }}
              className={cn({ [styles.required]: required })}
            >
              { label }
            </span>
            {labelDescription && <div className={styles.labelDescription}>
              <Icon
                size={10}
                icon={"note-icon"}
              />
              {labelDescription}
            </div>}
          </div>
        )}
        <Input
          type={type}
          {...other}
          onBlur={this.onBlur}
          onChange={this.onChange}
          isError={isError}
        />
        { isError &&
          // Здесь надо сделать массив ошибок, а не одну
          <span className="gs-text _error _c-danger gs-input--label__error">
            { error }
          </span>
        }
      </label>
    );
  }
}

TextRow.propTypes = {
};

export default TextRow;
