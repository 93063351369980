import styles from "./NavigationMenu.module.scss";
import { ReactComponent as BriefcaseIcon } from "bootstrap-icons/icons/briefcase.svg";
import { ReactComponent as BarChartIcon } from "bootstrap-icons/icons/bar-chart.svg";
import { ReactComponent as LightningIcon } from "bootstrap-icons/icons/lightning.svg";
import { ReactComponent as ClipboardCheckIcon } from "bootstrap-icons/icons/clipboard-check.svg";
import { ReactComponent as GearIcon } from "bootstrap-icons/icons/gear.svg";
import { MagicWand } from "@phosphor-icons/react";
import { Menu } from "@gs/uiKit/navigation/Menu";
import type { MenuItem } from "@gs/uiKit/navigation/Menu";
import dashboardPath from "components/routes/dashboard/const";
import { paths as leadsPaths } from "components/routes/crmGroup/const";
import { paths as flowsPath } from "components/routes/flowsGroup/const";
import { templatesPath } from "components/routes/Templates/const";
import messagesPath from "components/routes/Messages/path";
import tasksPath from "components/routes/Tasks/path";
import senderProfilesPath from "components/routes/SenderProfileGroup/consts";
import settingsPath, { settingsPaths as settings } from "components/routes/SettingsGroup/path";
import type { ReactNode } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { MessagesMenuItemIcon } from "./MessagesMenuItemIcon";
import { SenderProfilesMenuItemIcon } from "./SenderProfilesMenuItemIcon";
import { themeService } from "feature/theme/ThemeService";
import { InnerLink } from "components/common/routing/InnerLink";
import cn from "classnames";
import linkedinFiltersPath from "components/routes/SettingsGroup/LinkedinFilters/path";
import { featureFlag } from "services/featureFlag";

type RouteMenuItem = {
  label: string
  icon?: ReactNode
  path: string
  subItems?: Array<RouteMenuItem>
}
const menuItems: Array<RouteMenuItem> = [
  {
    label: "Dashboard",
    path: dashboardPath,
    icon: <BarChartIcon />,
  },
  {
    label: "CRM",
    path: leadsPaths.contacts,
    icon: <BriefcaseIcon />,
    subItems: [
      {
        label: "Contacts",
        path: leadsPaths.contacts,
      },
      {
        label: "Accounts",
        path: leadsPaths.companies,
      },
      {
        label: "Lists",
        path: leadsPaths.lists,
      },
      {
        label: "Imports",
        path: leadsPaths.imports,
      },
    ],
  },
  {
    label: "Automation",
    path: flowsPath.automation,
    icon: <LightningIcon />,
    subItems: [
      {
        label: "Workspaces",
        path: flowsPath.automation,
      },
      {
        label: "Archive",
        path: flowsPath.archive,
      },
    ],
  },
  {
    label: "Templates",
    path: templatesPath,
    icon: <MagicWand />,
  },
  {
    label: "Messages",
    path: messagesPath,
    icon: <MessagesMenuItemIcon />,
  },
  {
    label: "Tasks",
    path: `${tasksPath}/manual`,
    icon: <ClipboardCheckIcon />,
  },
  {
    label: "Sender Profiles",
    path: senderProfilesPath,
    icon: <SenderProfilesMenuItemIcon />,
  },
  {
    label: "Settings",
    path: settingsPath,
    icon: <GearIcon />,
    subItems: [
      {
        label: "Contact Pipeline Stages",
        path: settings.leadPipelineStages,
      },
      {
        label: "Account Pipeline Stages",
        path: settings.companyPipelineStages,
      },
      {
        label: "Contact Custom Fields",
        path: settings.leadCustomFields,
      },
      {
        label: "Account Custom Fields",
        path: settings.companyCustomFields,
      },
      {
        label: "Sender Profile Custom Fields",
        path: settings.senderProfilesCustomFields,
      },
      {
        label: "Team Members",
        path: settings.teamMembers,
      },
      {
        label: "Webhooks",
        path: settings.webhooks,
      },
      ...(featureFlag.abm
        ? [{
          label: "Linkedin Filters",
          path: linkedinFiltersPath,
        }]
        : []
      ),
    ],
  },
];

const useRouteMenuItems = (routeMenuItems: readonly Readonly<RouteMenuItem>[]): {items: MenuItem[]; selectedKeys: string[]} => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const { items, keyPathMap } = useMemo<{items: MenuItem[]; keyPathMap: Map<string, string>}>(() => {
    let nextKey = 1;
    const theItems: MenuItem[] = [];
    const keyPathMap = new Map<string, string>();
    const createMenuItem = (routeMenuItem: RouteMenuItem): MenuItem => {
      const key = String(nextKey++);
      const newMenuItem: MenuItem = routeMenuItem.subItems
        ? {
          key,
          icon: routeMenuItem.icon,
          label: (
            <>
              <InnerLink
                className={styles.linkItem}
                to={routeMenuItem.path}
                style={{
                  position: "absolute",
                  inset: 0,
                }}
              >
              </InnerLink>
              { routeMenuItem.label }
            </>
          ),
          children: routeMenuItem.subItems.map(createMenuItem),
        }
        : {
          key,
          icon: routeMenuItem.icon,
          label: (
            <>
              <InnerLink
                className={styles.linkItem}
                to={routeMenuItem.path}
                style={{
                  position: "absolute",
                  inset: 0,
                }}
              >
              </InnerLink>
              { routeMenuItem.label }
            </>
          ),
        };
      keyPathMap.set(key, routeMenuItem.path);

      return newMenuItem;
    };

    for (const routeMenuItem of routeMenuItems) {
      const newMenuItem = createMenuItem(routeMenuItem);
      theItems.push(newMenuItem);
    }

    return { items: theItems, keyPathMap };
  }, [routeMenuItems]);

  useEffect(() => {
    const newSelectedKeys: string[] = [];
    keyPathMap.forEach((path, key) => {
      if (matchPath(location.pathname, path)) {
        newSelectedKeys.push(key);
      }
    });
    setSelectedKeys(newSelectedKeys);
  }, [location.pathname, routeMenuItems]);

  return {
    items,
    selectedKeys,
  };
};

export const NavigationMenu = (props: {className?: string}) => {
  const { items, selectedKeys } = useRouteMenuItems(menuItems);
  const theme = themeService.useStore((s) => s.theme);
  const { className, ...restProps } = props;

  return (
    <Menu
      className={cn(className, styles.container)}
      mode="vertical"
      theme={theme}
      selectedKeys={selectedKeys}
      items={items}
      {...restProps}
    />
  );
};
