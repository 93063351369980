export const dashboardPath = "/dashboard";


export const timePeriodPaths = {
  today: `${dashboardPath}/today`,
  yesterday: `${dashboardPath}/yesterday`,
  week: `${dashboardPath}/week`,
  month: `${dashboardPath}/month`,
  custom: `${dashboardPath}/custom`,
  insights: `${dashboardPath}/insights`,
};

export const paths = {
  dashboard: dashboardPath,
  insights: timePeriodPaths.insights,
};

export default dashboardPath;
