import styles from "./UserMassActions.module.scss";
import { MassActionsDrawer } from "./MassActionsDrawer";
import { useEffect, useState, useRef } from "react";
import { dataBus } from "services/dataBus";
import type { TimeoutId } from "@gs/core/commonTypes";
import cn from "classnames";
import { Button } from "@gs/uiKit/general/Button";
import { Hourglass } from "@phosphor-icons/react";
import { Tooltip } from "@gs/uiKit/dataDisplay/Tooltip";

export const UserMassActions = () => {
  const [isNewMassActionStarted, setIsNewMassActionStarted] = useState<boolean>(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);
  const animationTimeout = useRef<TimeoutId>();
  useEffect(() => {
    const unsubscribe = dataBus.subscribe("massAction:create", ({ massActionIds }) => {
      // for (const id of massActionIds) {
      //   const massAction = massActionStore.getState()[id];
      //   if (massAction?.type === "contact_export_csv") {
      //     setIsDrawerOpened(true);
      //     break;
      //   }
      // }
      setIsDrawerOpened(true);
      setIsNewMassActionStarted(true);
      animationTimeout.current = setTimeout(() => {
        setIsNewMassActionStarted(false);
      }, 2000);
    });
    return () => {
      clearTimeout(animationTimeout.current);
      unsubscribe();
    };
  }, []);

  return (
    <MassActionsDrawer
      isOpened={isDrawerOpened}
      onClose={() => {
        setIsDrawerOpened(false);
      }}
      className={styles.Drawer}
      drawerProps={{
        width: 400,
        classNames: {
          body: styles.DrawerBody,
          header: styles.DrawerHeader,
        },
        title: "Action Queue",
        destroyOnClose: true,
      }}
    >
      <Tooltip
        title="Action Queue"
        arrow={false}
      >
        <Button
          onClick={() => setIsDrawerOpened(true)}
          type="text"
          icon={<Hourglass style={{ position: "relative", height: "20px", width: "20px", top: 1 }} />}
          className={cn({ [styles.IconAnimated!]: isNewMassActionStarted })}
        />
      </Tooltip>
    </MassActionsDrawer>
  );
};
