import * as actions from "./consts";
import getAxios from "services/axios";
import { createCachedFunction } from "../utils";

const axios = getAxios("idV2");
export const createTeam = (params) => (dispatch) => {
  return axios.post("api/teams", params)
    .then((teams) => {
      cacher.clearCache();
      dispatch(addTeam(teams));
      return teams;
    });
};
export const addTeam = (teams = []) => ({
  type: actions.SET_TEAMS,
  teams,
});

export const editTeam = (team) => (dispatch) => {
  return axios.put(`api/teams/${team.id}`, team).then((updatedTeam) => {
    dispatch({
      type: actions.EDIT_TEAM,
      updatedTeam,
    });

    return updatedTeam;
  });
};

export const deleteTeam = (id) => (dispatch) => {
  return axios.delete(`api/teams/${id}`).then(() => {
    dispatch({
      type: actions.DELETE_TEAM,
      id,
    });
  });
};

export const loadTeamById = (id) => (dispatch) => {
  return axios.get(`api/teams/${id}`).then((team) => {
    dispatch(addTeam([team]));
  });
};

export const cacher = createCachedFunction(() => (dispatch) => {
  return axios.get("api/teams", { params: { limit: 100 } })
    .then( (teams) => {
      dispatch(addTeam(Object.values(teams.data)));

      return teams.data;
    });
}, { recursively: true });

export const getTeams = cacher.cachedFunction;

export const getPendingTeam = (teamId) => {
  return axios.get(`api/teams/${ teamId }/show-pending`).then((team) => {
    return team;
  });
};
