import * as actions from "./consts";
import { createCachedFunction } from "../utils";
import getAxios from "services/axios";
import store from "store/store";

const axios = getAxios("contactV2");

const pipelinesToOptions = (pipelines, query) => {
  return pipelines.reduce((res, pipeline) => {
    if (RegExp(query, "i").test(pipeline.name) || typeof query !== "string") {
      res.push({ value: pipeline.uuid, label: pipeline.name });
    }
    return res;
  }, []);
};

export const cacher = createCachedFunction((params = {}, cancelToken) => (dispatch) => {
  const { object, q, filter } = params;
  return axios.get("/api/pipeline-stages", {
    params: {
      ...params,
      limit: 100,
      filter: {
        object, q,
      },
    },
    cancelToken,
  })
    .then(({ data }) => {
      return getMetrics({ object, filter })
        .then((metrics) => {
          const stages = data.map((stage) => {
            return {
              ...stage,
              metrics: metrics[stage.uuid],
            };
          });

          dispatch({
            type: actions.SEED_PIPELINE_STAGES,
            category: object,
            stages,
          });
          return stages;
        });
    });
}, { recursively: true });

export const getPipelineStages = cacher.cachedFunction;

const byIdCacher = createCachedFunction((listId) => (dispatch) => {
  return axios.get(`/api/pipeline-stages/${listId}`)
    .then((response) => {
      dispatch({
        type: actions.SEED_PIPELINE_STAGES,
        stages: [response],
        category: "lead",
      });

      return response;
    });
}, { recursively: true });


export const loadStageById = byIdCacher.cachedFunction;
export const createPipeline = (params) => (dispatch) => {
  return axios.post("/api/pipeline-stages", params).then((pipeline) => {
    return getMetrics({ object: pipeline.object, filter: { object: pipeline.object } })
      .then((metrics) => {
        const stageWithMetrics = {
          ...pipeline,
          metrics: metrics[pipeline.uuid],
        };
        dispatch({
          type: actions.ADD_PIPELINE_STAGE,
          stage: stageWithMetrics,
          category: params.object,
        });
        return stageWithMetrics;
      });
  });
};

export const updatePipeline = ({ object, newPipeline }) => (dispatch, getState) => {
  const pipelinesState = getState().entities.pipelineStages[object];
  return axios.put(`/api/pipeline-stages/${newPipeline.uuid}`, newPipeline).then((pipeline) => {
    dispatch({
      type: actions.EDIT_PIPELINE_STAGE,
      stages: { ...pipelinesState, [pipeline.uuid]: { ...pipeline, metrics: newPipeline.metrics } },
      category: object,
    });
    return pipeline;
  });
};

export const dndPipeline = ({ object, newPipeline }) => (dispatch, getState) => {
  const pipelinesState = getState().entities.pipelineStages[object];
  // const {uuid, category, order} = newPipeline;
  // const newPipelineData = {uuid, category, order}
  // const newPipelines = {}
  // const currentPipeline = pipelinesState[newPipeline.uuid]
  // // if (currentPipeline.order === order) return
  //  if(currentPipeline.order > order){
  //   for (const key in pipelinesState) {
  //     const p = {...pipelinesState[key]}
  //     if (key === uuid) {
  //       p.order = order
  //     } else if (p.order >= order) {
  //       p.order += 1
  //     }
  //     newPipelines[key] = p
  //   }
  // }
  // else{
  //   for (const key in pipelinesState) {
  //     const p = {...pipelinesState[key]}
  //     if (key === uuid) {
  //       p.order = order
  //     } else if (p.order <= order && p.order > currentPipeline.order) {
  //       p.order -= 1
  //     }
  //     newPipelines[key] = p
  //   }
  // }
  // dispatch({
  //   type: actions.EDIT_PIPELINE_STAGE,
  //   stages: {...pipelinesState, ...newPipelines},
  //   category: object
  // });
  return axios.put(`/api/pipeline-stages/${newPipeline.uuid}`, newPipeline).then((pipeline) => {
    return pipeline;
  });
};

export const deletePipeline = ({ uuid }, { object }) => (dispatch) => {
  return axios.delete(`/api/pipeline-stages/${uuid}?replace_pipeline_stage_uuid=${uuid}`).then(() => {
    dispatch({
      type: actions.DELETE_PIPELINE_STAGE,
      stageUuid: uuid,
      category: object,
    });
  });
};

export const getMetrics = ({ object, filter }) => {
  return axios.put("/api/pipeline-stages/metrics", {
    object,
    filter,
  });
};

export const getLeadPipelineOptions = (query, params = {}) => {
  const stagesLead = store.getState().entities.pipelineStages.lead;
  if (stagesLead) {
    return pipelinesToOptions(Object.values(stagesLead), query);
  }
  return store.dispatch(getPipelineStages({ object: "lead", ...params }))
    .then((pipelines) => pipelinesToOptions(pipelines, query));
};

export const getLeadPipelineOptionsWithPagination = (params = {}) => {
  const { query, ...otherParams } = params;
  const q = query || "";
  return Promise.resolve(store.dispatch(getPipelineStages({ object: "lead", q, filter: { q, ...params.filter }, ...otherParams })))
    .then((res) => {
      const options = res.map((stage) => {
        return { value: stage.uuid, label: stage.name };
      });
      return { options, hasMore: false };
    });
};

export const getCompanyPipelineOptions = (query, params = {}) => {
  const stagesCompany = store.getState().entities.pipelineStages.company;
  if (stagesCompany) {
    return pipelinesToOptions(Object.values(stagesCompany), query);
  }
  return store.dispatch(getPipelineStages({ object: "company", ...params }))
    .then((pipelines) => pipelinesToOptions(pipelines, query));
};

export const getCompanyPipelineOptionsWithPagination = (params = {}) => {
  const { query, ...otherParams } = params;
  cacher.clearCache();
  const q = query || "";
  return Promise.resolve(store.dispatch(getPipelineStages({ ...otherParams, q, filter: { q, ...params.filter }, object: "company" })))
    .then((res) => {
      const options = res.map((stage) => {
        return { value: stage.uuid, label: stage.name };
      });
      return { options, hasMore: false };
    });
};
