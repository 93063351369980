import { createSelector } from "reselect";

const sliceSelector = (state) => state.entities.mailboxes || {};

const getMailboxById = (state, id) => sliceSelector(state)[id];

const mailboxesIdsSelector = createSelector(
  [sliceSelector], (mailboxes) => Object.keys(mailboxes),
);

const mailboxesArraySelector = createSelector(
  [sliceSelector], (mailboxes) => Object.values(mailboxes),
);

// return Boolean
const existMailboxSelector = (state) => !!mailboxesArraySelector(state).length;

export {
  sliceSelector,
  getMailboxById,
  mailboxesArraySelector,
  mailboxesIdsSelector,
  existMailboxSelector,
};

