import * as actions from "./consts";
import { entitySetter } from "../utils";
import { LN_ACCOUNT_ACTIONS } from "../linkedinAccounts/consts";


const ACTION_HANDLERS = {
  [actions.SEED_PROFILES]: (state, { profiles }) => entitySetter(state, profiles),
  [actions.DELETE_SENDER_PROFILE]: (state, { profileId }) => {
    if (!state[profileId]) return state;

    const s = { ...state };
    delete s[profileId];
    return s;
  },
  [LN_ACCOUNT_ACTIONS.LN_ACCOUNT_DELETE]: (senderProfiles, { id }) => {
    let isEffected = false;
    const newSenderProfiles = { ...senderProfiles };
    for (const senderProfile of Object.values(senderProfiles)) {
      if (senderProfile.linkedin_account_uuid == id) {
        isEffected = true;
        newSenderProfiles[senderProfile.uuid] = {
          ...newSenderProfiles[senderProfile.uuid],
          linkedin_account_uuid: null,
        };
      }
    }
    return isEffected ? newSenderProfiles : senderProfiles;
  },
  [actions.SET_PROFILE_COUNTERS_LOADING]: (state, { status }) => {
    const s = { ...state };
    const keys = Object.keys(s);
    const newState = keys.map((key) => s[key] = { ...s[key], counters: { ...s[key].counters, isLoading: true } });
    return s;
  },
  [actions.SEED_PROFILE_COUNTERS]: (state, { counters }) => {
    const s = { ...state };
    const keys = Object.keys(counters).filter((key) => key !== "total");
    const newState = keys.map((key) => s[key] = { ...s[key], counters: { ...counters[key], isLoading: false } });
    return s;
  },
  [actions.SEED_PROFILES_SERVERS]: (state, { servers }) => {
    const s = { ...state };
    const keys = Object.keys(s);
    servers.forEach((server) => {
      keys.forEach((key) => {
        if (s[key].linkedin_account_uuid && s[key].linkedin_account_uuid === server.linkedin_account_uuid) {
          s[key] = { ...s[key], server };
        }
      });
    });
    return s;
  },

};



const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
