import { Link } from "react-router-dom";
import type { IPopupContentProps } from "./interfaces";
import styles from "./ActionPopup.module.scss";
import Icon from "../elements/Icon";
import { Tooltip } from "antd";

export const PopupContent = ({ items, onRequestClose }: IPopupContentProps) => {

  const handleClick = (action?: () => void, closeAfterAction?: boolean, disabled?: boolean) => {
    if (disabled) return;
    action?.();
    if (closeAfterAction) {
      onRequestClose();
    }
  };

  return (
    <ul className={styles.ActionPopup}>
      {items.map(({ label, action, icon, to, closeAfterAction, disabled, tooltipText }) => {
        const component = to ? (
          <Link to={to}>{label}</Link>
        ) : (!tooltipText || !disabled) ? (
          <button
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(action, closeAfterAction, disabled);
            }}
          >
            {icon && <Icon
              size={13}
              icon={icon}
                     />}
            {label}
          </button>
        ) : (
          <Tooltip
            overlayStyle={{ minWidth: "max-content" }}
            title={<span style={{ fontSize: "12px", width: "max-content" }}>{tooltipText}</span>}
            placement={"left"}
            color="#222B45"
          >
            <button
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClick(action, closeAfterAction, disabled);
              }}
            >
              {icon && <Icon
                size={13}
                icon={icon}
                       />}
              {label}
            </button>
          </Tooltip>
        );

        return (
          <li key={label}>

            {component}
          </li>
        );
      })}
    </ul>
  );
};
