import Modal from "components/common/Modal/Modal";
import React from "react";

export const ModalLoadingError = (props: {closeModal: () => void}) => (
  <Modal.Container Tag="div">
    <Modal.Header
      header="Something went wrong"
      onClose={props.closeModal}
    />

    <div>
      Sorry we can't load this window
    </div>
  </Modal.Container>
);
