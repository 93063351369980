import type { AxiosInstance } from "axios";
import type { MassAction } from "../MassAction/MassAction";
import type { LeadMassActionFilter } from "../Lead/createLeadApi";
import { leadMassFilterToServerFormat } from "../Lead/createLeadApi";
import type { CompanyMassActionFilter } from "../Company/createCompanyApi";
import { companyMassFilterToServerFormat } from "../Company/createCompanyApi";

export const createFileExportApi = (axios: AxiosInstance) => {
  const exportLeadToCsv = async (
    params: {
      filter: LeadMassActionFilter
    },
  ) => {
    return axios.post<MassAction>("/api/file-exports/leads", {
      filter: leadMassFilterToServerFormat(params.filter),
    });
  };

  const exportCompanyToCsv = async (
    params: {
      filter: CompanyMassActionFilter
    },
  ) => {
    return axios.post<MassAction>("/api/file-exports/companies", {
      filter: companyMassFilterToServerFormat(params.filter),
    });
  };

  const downloadCsvExport = async (params: {
    fileName: string
  }) => {
    return axios.post<string>("/api/file-exports/download", {
      file_name: params.fileName,
    });
  };

  return {
    exportLeadToCsv,
    exportCompanyToCsv,
    downloadCsvExport,
  };
};

