import type { IsoDate, Mixed, MixedNumberOrDate } from "../../commonTypes";
import type { SubscriptionId } from "../Subscription/Subscription";
import type { UserId } from "../User/User";
import type { TeamId } from "./Team";
import type { TeamStatus } from "./TeamStatus";

export type TeamFilter = {
  query?: string
  id?: Mixed<TeamId>
  subscriptionId?: Mixed<SubscriptionId>
  name?: Mixed<string>
  status?: Mixed<TeamStatus>
  lastPaymentIntentAt?: MixedNumberOrDate<IsoDate>
  userId?: Mixed<UserId>
  withDeleted?: boolean
}

export const teamFilterToServerFormat = (filter?: TeamFilter) => filter && ({
  q: filter.query,
  id: filter.id,
  name: filter.name,
  user_id: filter.userId,
  deleted: filter.withDeleted,
  subscription_id: filter.subscriptionId,
  status: filter.status,
  last_payment_intent_at: filter.lastPaymentIntentAt,
});
