import { webhooksPaths } from "../path";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";

export const WebhooksNavigation = () => {
  return (
    <ContentHeader.Tabs.Navigation
      tabs={[
        {
          exact: true,
          label: "Webhooks",
          path: webhooksPaths.webhooks,
        },
        {
          label: "History",
          path: webhooksPaths.webhooksHistory,
        },
      ]}
    />
  );
};
