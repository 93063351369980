import * as actions from "./consts";
import getAxios from "services/axios";
import { createCachedFunction } from "../utils";
import store from "store/store";
import { encodeToBase64 } from "../../../services/utils";

const axios = getAxios("contactV2");

const loadTagsBase = (params) => (dispatch) => {
  return axios.get("/api/tags", { params: { limit: 200, ...params } })
    .then((res) => {
      const { data } = res;

      dispatch({
        type: actions.TAGS_GET_SUCCESS,
        tags: data,
      });

      return res;
    });
};

const cacher = createCachedFunction((params) => async (dispatch) => {
  const res = await dispatch(loadTagsBase({ limit: 1000, ...params }));
  return res.data;
}, { recursively: true });


export const loadTags = cacher.cachedFunction;

const byIdCacher = createCachedFunction((tagId) => (dispatch) => {
  return axios.get(`/api/tags/${tagId}`)
    .then((tag) => {
      dispatch({
        type: actions.TAG_CREATE_SUCCESS,
        tag,
      });

      return tag;
    });
}, { recursively: true });


export const loadTagById = byIdCacher.cachedFunction;
export const clearTagsCache = () => cacher.clearCache();

export const getTagsOptions = (query = "", params = {}) => {
  // return Promise.resolve(store.dispatch(loadTags({ ...params, filter:encodeToBase64({ q: query, ...params.filter }) })))
  return Promise.resolve(axios.get("/api/tags", { params: { limit: 200, ...params, filter: encodeToBase64({ q: query, ...params.filter }) } }))
    .then(({ data }) => data.map((tag) => {
      return { value: tag.uuid, label: tag.name };
    }));
};

export const getTagsOptionsWithPagination = (params = {}) => {
  const { query, ...otherParams } = params;

  return store.dispatch(loadTagsBase({ ...otherParams, filter: { q: query || "", ...params.filter } }))
    .then((res) => {
      const opts = res.data.map((tag) => {
        return { value: tag.uuid, label: tag.name };
      });
      return { options: opts, hasMore: res.has_more };
    },
    );
};

export const deleteTag = (uuid) => (dispatch, getState) => {
  return axios.delete(`/api/tags/${uuid}`)
    .then((res) => {
      cacher.clearCache(); // надо ли??
      dispatch({
        type: actions.TAG_DELETE_SUCCESS,
        uuid,
      });

      return res;
    });
};

export const createTag = (params) => (dispatch) => {
  return axios.post("/api/tags", params)
    .then((tag) => {
      cacher.clearCache();
      dispatch({
        type: actions.TAG_CREATE_SUCCESS,
        tag,
      });

      return tag;
    });
};

export const quickTagCreate = (name) => store.dispatch(createTag({ name }));
