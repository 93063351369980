import React, { PureComponent } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./Avatar.module.scss";

class Avatar extends PureComponent {
  constructor({ imageUrl = "" }) {
    super();

    this.state = {
      hasIcon: false,
      imageUrl,
    };
  }

  handleError() {
    if (this.props.fallbackIcon) {
      this.setState({
        hasIcon: true,
      });

      return;
    }

    this.setState({
      imageUrl: this.props.fallbackUrl,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        hasIcon: false,
        imageUrl: this.props.imageUrl,
      });
    }
  }

  render() {
    const {
      name = "",
      size = 40,
      className = "",
      radius = "55%",
      style,
      fallbackIcon,
    } = this.props;
    const {
      imageUrl,
      hasIcon,
    } = this.state;

    if (hasIcon || !imageUrl) {
      return fallbackIcon || <></>;
    }

    return (
      <img
        onError={() => this.handleError()}
        src={imageUrl}
        alt={name}
        className={cn(styles.Avatar, className)}
        style={{
          width: size,
          height: size,
          flexBasis: `${size}px`,
          flexGrow: 0,
          borderRadius: radius,
          ...style,
        }}
      />
    );
  }
}

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  fallbackUrl: PropTypes.string,
  radius: PropTypes.number,
  size: PropTypes.number,
};

export {
  Avatar as default,
  Avatar,
};
