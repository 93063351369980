import * as actions from "./consts";
import { getTeam } from "store/entities/users/actions";
import { createInvite, getInvites, revokeInvite } from "store/entities/invites/actions";
import { getError } from "services/utils";
import getAxios from "services/axios";
import { createSource } from "services/axios";
import { loadUsers } from "store/entities/users/actions";
import { getUsersAcceptedInvite } from "store/entities/users/actions";

const axios = getAxios("idV2");

let loadMoreSource;

export const loadMoreUsers = (options) => (dispatch, getState) => {
  const teamState = getState()["teamPage"];
  if (teamState.isLoading) {
    return Promise.resolve();
  }
  dispatch({
    type: actions.TEAM_P_LOAD_USERS_REQUEST,
  });

  loadMoreSource = createSource();

  const params = {
    filter: {
      q: teamState.q,
    },
    order_field: teamState.staticFilters.order_field,
    order_type: teamState.staticFilters.order_type,
    offset: options?.refresh ? 0 : teamState.ids.users.length,
    limit: 10,
  };

  return dispatch(loadUsers(params, loadMoreSource.token))
    .then((res) => {
      const { data, total, has_more } = res;
      const ids = data.map((el) => el.id);

      if (options?.refresh) {
        dispatch({
          type: actions.TEAM_P_CLEAR_IDS,
        });
      }

      dispatch({
        type: actions.TEAM_P_LOAD_USERS_SUCCESS,
        hasMore: has_more,
        userIds: ids,
        total,
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.TEAM_P_LOAD_USERS_FAILURE,
      });
      console.log("err", err);

      throw err;
    });
};

export const loadMoreUsersInvites = (options) => (dispatch, getState) => {
  const teamState = getState()["teamPage"];
  if (teamState.isInvitesLoading) {
    return Promise.resolve();
  }


  dispatch({ type: actions.TEAM_P_LOAD_INVITES_REQUEST });

  loadMoreSource = createSource();

  const params = {
    filter_by_user_id: 0,
    limit: teamState.invitesPagination.limit,
    offset: options?.refresh ? 0 : teamState.invitesPagination.offset,
  };

  return dispatch(getInvites(params, loadMoreSource.token))
    .then((res) => {
      const { data, total, has_more } = res;

      dispatch({
        type: actions.TEAM_P_LOAD_INVITES_SUCCESS,
        invites: data,
        hasMore: has_more,
        total,
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.TEAM_P_LOAD_INVITES_FAILURE,
        error: getError(err, "Failed to load sent invites."),
      });

      throw err;
    });
};

export const loadTeam = () => (dispatch) => {
  dispatch({ type: actions.TEAM_P_LOAD_USERS_REQUEST });

  // dispatch(getTeam())
  //   .then(users => dispatch({
  //     type: actions.TEAM_P_LOAD_USERS_SUCCESS,
  //     userIds: users.map(user => user.id),
  //   }))
  //   .catch(err => {
  //     dispatch({
  //       type: actions.TEAM_P_LOAD_USERS_FAILURE,
  //       error: getError(err, 'Failed to load your team.'),
  //     });

  //     throw err;
  //   });
};

export const getTeamUsers = (id, params) => (dispatch) => {
  return axios.get("api/users", params)
    .then( (users) => {
      dispatch({ type: actions.TEAM_P_LOAD_GET_USERS, users: users.data });
      return users.data;
    });
};

// export const loadInvites = () => (dispatch) => {
//   dispatch({ type: actions.TEAM_P_LOAD_INVITES_REQUEST });

//   return dispatch(getInvites({ limit: 200 }))
//     .then((res) => dispatch({
//       type: actions.TEAM_P_LOAD_INVITES_SUCCESS,
//       inviteIds: res.data.map((inv) => inv.id),
//     }))
//     .catch((err) => {
//       dispatch({
//         type: actions.TEAM_P_LOAD_INVITES_FAILURE,
//         error: getError(err, "Failed to load sent invites."),
//       });

//       throw err;
//     });
// };

export const setQ = (q) => (dispatch) => {
  dispatch({ type: actions.TEAM_P_SET_Q, q });
  dispatch({
    type: actions.TEAM_P_CLEAR_IDS,
  });
  dispatch(loadMoreUsers());
};
export const setStaticFilters = (filters) => (dispatch) => {
  dispatch({ type: actions.TEAM_P_SET_FILTERS, filters });
  dispatch({
    type: actions.TEAM_P_CLEAR_IDS,
  });
  dispatch(loadMoreUsers());
};

export const sendInvitations = (emails) => (dispatch) => {
  return dispatch(createInvite(emails)).then(() => {
    dispatch(loadMoreUsersInvites({ refresh: true }));
  });
};

export const revokeInvitation = (id) => (dispatch) => {
  return dispatch(revokeInvite(id)).then(() => {
    dispatch(loadMoreUsersInvites({ refresh: true }));
  });
};

export const setInvitesPagination = (params) => (dispatch) => {
  dispatch({
    type: actions.TEAM_P_SET_PAGINATION,
    params,
  });
};

export const removeFromTeam = (userId) => (dispatch) => {
  return getUsersAcceptedInvite(userId).then((invite) => {
    dispatch(revokeInvite(invite.id)).then(() => {
      dispatch(loadMoreUsers({ refresh: true }));
    });
  });
};
