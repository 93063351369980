import cn from "classnames";
import Spinner from "components/common/elements/Spinner";

import styles from "./Styles.module.scss";

const Preloader = ({ isLoginForm = false }) => (
  <div
    className={cn(
      styles.Preload, {
        [styles.PreloadLoginForm]: isLoginForm,
      })
    }
  >
    <Spinner
      color='#FC5D47'
      size={50}
    />
  </div>
);

export {
  Preloader as default,
  Preloader,
};
