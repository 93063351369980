import { useEffect } from "react";
import { teamApi } from "domain/team/teamApi";
import { useTeamStore } from "domain/team/teamStore";
import { sessionService } from "./SessionService";

export const useCurrentTeam = () => {
  const currentTeamId = sessionService.useStore((s) => s.teamId);
  const currentTeam = useTeamStore((teams) => currentTeamId && teams[currentTeamId]);

  useEffect(() => {
    if (currentTeam) return;
    if (!currentTeamId) return;

    teamApi.getTeam({ id: currentTeamId });
  }, [currentTeamId]);

  return currentTeam;
};
