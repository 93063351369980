import { createSelector } from "reselect";

const sliceSelector = (state) => state.entities.templates || {};
const templatesArraySelector = createSelector(
  [sliceSelector], (templates) => Object.values(templates),
);


export {
  sliceSelector,
  templatesArraySelector,
};
