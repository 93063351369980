import nodeTypes from "../nodeTypes";

const deleteSourceNode = (model, sourceId) => {
  model.flow.version.contact_sources = model.flow.version.contact_sources.filter((source) => source.id !== sourceId);
  const sourceUiNode = model.nodes.find((node) => node.sourceId === sourceId);
  const prevPlus = model.nodes.find((node) => node.id === sourceUiNode.before[0]);
  const startUiNode = model.nodes.find((node) => node.type === nodeTypes.START);
  startUiNode.after = startUiNode.after.filter((id) => id !== prevPlus.id);
  const unionPlus = model.nodes.find((node) => node.id === startUiNode.union);
  const allDeletedUiNodes = new Set();
  const uiNodeIdsToDelete = [prevPlus.id];
  while (uiNodeIdsToDelete.length) {
    const curUiNodeId = uiNodeIdsToDelete.pop();
    const curUiNode = model.nodes.find((node) => node.id === curUiNodeId);
    if (!curUiNode) continue;
    if (curUiNode.serverNodeId) {
      const serverNode = model.flow.nodes.find((serverNode) => serverNode.id === curUiNode.serverNodeId);
      allDeletedUiNodes.add(serverNode.id);
      model.flow.nodes = model.flow.nodes.filter((serverNode) => serverNode.id !== curUiNode.serverNodeId);
    }
    model.nodes = model.nodes.filter((node) => node.id !== curUiNode.id);

    for (const afterId of curUiNode.after) {
      if (afterId === unionPlus.id) {
        unionPlus.before = unionPlus.before.filter((id) => id !== curUiNode.id);
      } else {
        uiNodeIdsToDelete.push(afterId);
      }
    }
  }
  for (const item of allDeletedUiNodes) {
    const dependenceNode = model.flow.nodes.find((node) => node.before.find((q) => q.node_id === item));
    if (dependenceNode) {
      const newBefore = dependenceNode.before.filter((bItem) => bItem.node_id !== item);
      dependenceNode.before = newBefore || [];
    }
  }

  if (unionPlus && unionPlus.before.length === 1) {
    const nodeBefore = model.nodes.find((node) => node.id === unionPlus.before[0]);
    const nodeAfter = model.nodes.find((node) => node.id === unionPlus.after[0]);
    nodeBefore.after = [nodeAfter.id];
    nodeAfter.before = [nodeBefore.id];
    model.nodes = model.nodes.filter((node) => node.id !== unionPlus.id);
  }
};

export default deleteSourceNode;
