import React, { useState, useEffect, useCallback } from "react";
import { Drawer } from "@gs/uiKit/feedback/Drawer";
import type { DrawerProps } from "antd";
import noop from "lodash/noop";

type SimpleDrawerProps<ContentProps = {}> = DrawerProps & {
  isOpened?: boolean
  onOpen?: () => void
  onClose?: () => void
  className?: string
  drawerProps: Omit<DrawerProps, "onClose" | "open">
  contentProps?: Omit<ContentProps, "onRequestClose" | "isDrawerOpened">
}

export function createDrawerTrigger<Props extends { onRequestClose: () => void; isDrawerOpened: boolean }>(DrawerContent: React.ComponentType<Props>, config: {mask?: boolean} = {}) {
  const { mask = true } = config;
  return (props: SimpleDrawerProps<Props>) => {
    const {
      children,
      style,
      className,
      onOpen = noop,
      onClose = noop,
      contentProps,
    } = props;
    const [isOpened, setIsOpened] = useState(props.isOpened ?? false);

    useEffect(() => {
      if (props.isOpened == null) return;
      setIsOpened(props.isOpened);
    }, [props.isOpened]);

    useEffect(() => {
      if (isOpened) {
        onOpen();
      } else {
        onClose();
      }
    }, [isOpened]);

    const onRequestClose = useCallback(() => setIsOpened(false), []);

    return (
      <>
        <div
          role="button"
          style={style}
          className={className}
          onClick={() => setIsOpened(true)}
        >
          {children}
        </div>
        <Drawer
          style={{ zIndex: 900 }}
          afterOpenChange={(visible) => setIsOpened(visible)}
          onClose={() => {
            setIsOpened(false);
          }}
          mask={mask}
          open={isOpened}
          {...props.drawerProps}
        >
          {/* @ts-ignore */}
          <DrawerContent
            isDrawerOpened={isOpened}
            onRequestClose={onRequestClose}
            {...contentProps}
          />
        </Drawer>
      </>
    );
  };
};
