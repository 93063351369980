import styles from "./Verification.module.scss";
import { useSelector } from "react-redux";
import browserHistory from "services/browserHistory";
import authPath from "../Signup/path";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";
import { useCurrentUser } from "feature/session/useCurrentUser";
import { sessionService } from "../../../../feature/session/SessionService";
import { currentUserApi } from "../../../../feature/session/currentUserApi";

const Verification = () => {
  const { currentUser: user } = useCurrentUser();
  const isWhiteLabel = useSelector(getIsWhiteLabel);
  const emailConfirm = () => {
    currentUserApi.resendConfirmationEmail();
  };
  const logOut = () => {
    sessionService.signOut();
  };
  if (user?.email_status !== "unverified") {
    browserHistory.push(authPath.login);
  }
  return (
    <div className={styles.Container}>
      <span className={styles.Header}>
        Welcome
        {!isWhiteLabel && " to GetSales.io"}
        ! Please Confirm your email.
      </span>
      <div className={styles.Wrapper}>
        <span className={styles.Text}>We’ve sent you an email, follow the instructions there to activate your account.</span>
        <span className={styles.Email}>{user?.email || ""}</span>
        <button
          onClick={emailConfirm}
          className={styles.Btn}
        >
          Send Confirmation Email Again
        </button>
        <button
          onClick={logOut}
          className={styles.Btn}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};
export default Verification;
