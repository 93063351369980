import * as actions from "./consts";
import { entitySetter } from "../utils";


const ACTION_HANDLERS = {
  [actions.CUSTOM_FIELDS_SET]: (state, { fields }) => entitySetter(state, fields),
  [actions.CUSTOM_FIELDS_CREATE]: (state, { field }) => {
    return {
      ...state,
      [field.uuid]: field,
    };
  },
  [actions.CUSTOM_FIELDS_UPDATE]: (state, { field }) => {
    return {
      ...state,
      [field.uuid]: field,
    };
  },
  [actions.CUSTOM_FIELDS_DELETE]: (state, { fieldId }) => {
    const s = { ...state };
    delete s[fieldId];
    return s;
  },
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
