import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getWhiteLabelName } from "../../../store/whiteLabel/selectors";

function useDocumentTitle(teamId, teams) {
  const whiteLabelName = useSelector(getWhiteLabelName);

  useEffect(() => {
    if (teamId === 0 || Object.keys(teams).length < 1) {
      document.title = whiteLabelName || "GetSales.io";
    } else {
      document.title = `#${teamId} ${teams[teamId]?.name}`;

    }

  }, [teamId, teams]);

}

export default useDocumentTitle;
