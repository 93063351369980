export const tasksPath = "/tasks";

export const paths = {
  manual: `${tasksPath}/manual`,
  auto: `${tasksPath}/auto`,
};

export default tasksPath;

export const NAVIGATION_DATA = [
  {
    label: "Manual Tasks",
    exact: true,
    to: paths.manual,
    isActive: (_, location) => [
      paths.manual,
      `${paths.manual}/`,
    ].includes(location.pathname),
  },
  // {
  //   label : "Auto Tasks",
  //   exact: true,
  //   to: paths.auto,
  //   isActive : (_, location) => [
  //     paths.auto,
  //     `${paths.auto}/`,
  //   ].includes(location.pathname),
  // },
];
