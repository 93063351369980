import styles from "./ModalFooter.module.scss";
import type { ReactNode } from "react";
import React from "react";
import cn from "classnames";
import { Flex } from "@gs/uiKit/layout/Flex";
import { ModalFooterButton } from "./ModalFooterButton/ModalFooterButton";

export type ModalFooterProps = {
  children: ReactNode
  className?: string
  shiftRight?: boolean
}

export const ModalFooter = (props: ModalFooterProps) => {
  const shiftRight = props.shiftRight ?? true;

  return (
    <Flex
      gap="middle"
      className={cn(styles.container, props.className)}
    >
      { shiftRight && <div style={{ flexGrow: 1 }} /> }
      { props.children }
    </Flex>
  );
};

ModalFooter.Button = ModalFooterButton;
