export const flowsPath = "/flow";


export const paths = {
  flowsList: flowsPath,
  templates: `${flowsPath}/automation/templates/:templateId?/public`,
  flowDetails: `${flowsPath}/:id/:subRoute?`,
  publicFlow: `${flowsPath}/:publicId/public`,
  automation: `${flowsPath}/automation`,
  archive: `${flowsPath}/archive`,
};

export const statusFilters = {
  automation: {
    status: ["on", "off"],
  },
  archive: {
    status: "archived",
  },
};
