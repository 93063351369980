import React from "react";
import styles from "./Styles.module.scss";

const LongTime = ({ retry }) => (
  <div className={styles.LoadingPage}>
    <h1 className={styles.Label}>
      Taking a long time...
    </h1>
    <a
      className={styles.LoadingPageAction}
      role="button"
      onClick={retry}
    >
      Retry
    </a>
    <a
      className={styles.LoadingPageAction}
      role="button"
      href="/"
    >
      Go to Home Page
    </a>
  </div>
);

export {
  LongTime,
};
