import * as actions from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  [actions.EMAILS_SET]: (state, { emails }) => entitySetter(state, emails),
  [actions.EMAILS_UPDATE]: (state, { email }) => {
    const s = { ...state };
    s[email.uuid] = email;
    return s;
  },
  [actions.EMAILS_DELETE]: (state, { emailUuid }) => {
    const s = { ...state };
    delete s[emailUuid];
    return s;
  },
};

const initialState = {
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
