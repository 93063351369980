import type { MassAction, MassActionId } from "@gs/core/domain/MassAction/MassAction";
import styles from "./MassActionItem.module.scss";
import { Progress } from "@gs/uiKit/feedback/Progress";
import { useMassActionMetricsStore } from "domain/massAction/massActionMetricsStore";
import { massActionStatusLabels } from "@gs/core/domain/MassAction/massActionStatusLabels";
import { percentsCounter } from "services/helpers/percentsCounter";
import { Skeleton } from "@gs/uiKit/feedback/Skeleton";


type MassActionMetrics = {
  id: MassActionId
  status: MassAction["status"]
}

export const MassActionMetrics = ({ status, id }: MassActionMetrics) => {
  const metrics = useMassActionMetricsStore((s) => s[id]!);
  return (
    <div className={styles.Progress}>
      <div className={styles.ProgressInfo}>
        <div
          className={styles.ProgressInfoStatus}
          style={{ color: status === "in_progress" ? "#CBD6E2" : "#99ACC2" }}
        >
          {`${massActionStatusLabels[status]} ${status !== "queued" ? `${percentsCounter(metrics.done_count, metrics.total_count)}%` : ""}`}
        </div>
        <div className={styles.ProgressInfoCount}>
          <span>{metrics.done_count === 0 ? "" : `(${metrics.done_count} of ${metrics.total_count})`}</span>
          {metrics.failed_count > 0 && (
            <span style={{ color: "#F2545B" }}>{` ${metrics.failed_count} error${metrics.failed_count > 1 ? "s" : ""}`}</span>
          )}
        </div>
      </div>
      {status === "queued" ? (
        <Skeleton.Input block active />
      ) : (
        <Progress
          strokeColor={status === "in_progress" ? "#CBD6E2" : "#99ACC2"}
          showInfo={false}
          percent={percentsCounter(metrics.done_count, metrics.done_count)}
          className={styles.ProgressBar}
        />
      )}
    </div >
  );
};
