import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse } from "../../commonTypes";
import type { TeamId, Team } from "./Team";
import type { TeamFilter } from "./TeamFilter";
import { teamFilterToServerFormat } from "./TeamFilter";

type TeamChangeableFields = Pick<Team, "name" | "color">

export const createTeamApi = (axios: AxiosInstance) => {
  const getTeams = (
    params: {
      filter?: TeamFilter
      limit: number
      offset: number
      orderField?: string
      orderType?: string
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<Team>>(
      "/api/teams",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: teamFilterToServerFormat(params.filter),
        },
      },
    );
  };

  const getTeam = async (params: {id: TeamId}, config?: AxiosRequestConfig) => {
    return axios.get<Team>(`/api/teams/${params.id}`, config);
  };

  const createTeam = async (params: {fields: TeamChangeableFields}, config?: AxiosRequestConfig) => {
    return axios.post<Team>("/api/teams", params.fields, config);
  };

  const updateTeam = async (params: {id: TeamId; newFields: TeamChangeableFields}, config?: AxiosRequestConfig) => {
    return axios.put<Team>(`/api/teams/${params.id}`, params.newFields, config);
  };

  const deleteTeam = async (params: {id: TeamId}, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/teams/${params.id}`, config);
  };

  return {
    getTeams,
    getTeam,
    createTeam,
    updateTeam,
    deleteTeam,
  };
};
