import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import s from "styles/_variables.module.scss";

class Icon1 extends PureComponent {
  // @ts-ignore
  public static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.icon == prevState.icon) return null;

    return {
      ...prevState,
      LoadedIcon: null,
      icon: nextProps.icon,
    };
  }

  constructor({ }) {
    // @ts-ignore
    super();
    this.state = {
      LoadedIcon: null,
    };
  }
  // @ts-ignore
  public componentDidUpdate(prevProps, prevState) {
    // @ts-ignore
    if (prevState.LoadedIcon === null || prevProps.icon != this.props.icon) {
      this.loadIcon();
    }
  }
  public componentDidMount() {
    this.loadIcon();
  }

  public loadIcon() {
    import(
      /* webpackChunkName: "SvgIcons" */
      /* webpackPreload: true */
      "components/common/elements/Icon/chunkMaker"
    )
      // @ts-ignore
      .then( (getIcon) => getIcon.default(this.props.icon))
      .then( (res) => this.setState({ LoadedIcon: res.default }));
  }

  // @ts-ignore
  public render() {
    // return 'Icon'
    const {
      // @ts-ignore
      size = 25,
      // @ts-ignore
      color = s.primaryColor,
      ...other
    } = this.props;
    // @ts-ignore
    const { LoadedIcon } = this.state;

    if (!LoadedIcon) return null;

    return (
      <LoadedIcon
        width={size}
        height={size}
        fill={color}
        color={color}
        {...other}
      />
    ) as any;
  }
}

// @ts-ignore
Icon1.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

const Icon: React.FC<{
  icon: string
  size?: number
  color?: string
  fill?: string
  style?: any
  className?: string
  onClick?: () => void
}> = Icon1 as any;

export {
  Icon,
  Icon as default,
};
