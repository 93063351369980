import { SERVER_ACTIONS } from "./consts";
import type { ServerId, Server } from "./types";
import { entitySetter } from "../utils";

type State = Record<ServerId, Server>;
type Action = {type: string} & Record<string, any>

const ACTION_HANDLERS: Record<SERVER_ACTIONS, (state: State, action: Action) => State | undefined> = {
  [SERVER_ACTIONS.SERVERS_SET]: (state, { servers }: Action) => entitySetter(state, servers, true) as State,
};

const initialState: State = {};

export default function reducer<T extends {type: string}>(state: State = initialState, action: Action ) {
  // @ts-ignore
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
