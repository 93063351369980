import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

export { default as path } from "./path";

const SubscriptionsPage = Loadable({
  loader: async () => {
    try {
      const { BillingPage } = await import(/* webpackChunkName:'SubscriptionsPage' */ "./BillingPage");
      return BillingPage;
    } catch (error) {
      console.log("Page loading err", error);
      return Error404;
    }
  },
  loading: Loading,
});

export {
  SubscriptionsPage,
};
