import type { MassAction } from "@gs/core/domain/MassAction/MassAction";
import styles from "./MassActionItem.module.scss";
import dayjs from "dayjs";
import { Avatar } from "@gs/uiKit/dataDisplay/Avatar";
import Button from "components/common/buttons/Button";
import { DarkThemeTooltip } from "components/common/Tooltip/Tooltip";
import { fileExportApi } from "domain/fileExport/fileExportApi";
import { downloadFileFromResponse } from "services/utils";
import { getNameComponentByType } from "./MassActionNames/getNameComponentByType";
import { MassActionMetrics } from "./MassActionMetrics";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import { massActionStatusLabels } from "@gs/core/domain/MassAction/massActionStatusLabels";
import { modalService } from "feature/modal/ModalService";
import { useFindOrFetchUser } from "domain/user/hook/useFindOrFetchUser";
import { Button as NButton } from "@gs/uiKit/general/Button";
import { List } from "@gs/uiKit/dataDisplay/List";
import { Typography } from "@gs/uiKit/general/Typography";
import { ReactComponent as TrashIcon } from "bootstrap-icons/icons/trash.svg";
import { Flex } from "@gs/uiKit/layout/Flex";
import { massActionApi } from "domain/massAction/massActionApi";



export const MassActionItem = ({ massAction }: { massAction: MassAction }) => {
  const [user] = useFindOrFetchUser(massAction.user_id);
  const handleDownloadCsv = async (fileName: string) => {
    const response = await fileExportApi.downloadCsvExport({ fileName });
    downloadFileFromResponse({ file: response.data, filename: fileName });
  };

  const deleteMassAction = async () => {
    await massActionApi.deleteMassAction({ id: massAction.uuid });
  };

  const isCsvTask = massAction.type === "account_export_csv" || massAction.type === "contact_export_csv";
  const isFinished = ["done", "failed"].includes(massAction.status);

  return (
    <div className={styles.MassAction}>
      <Flex
        justify="space-between"
        align="center"
        gap="middle"
      >
        <div className={styles.MassActionTitle}>
          {getNameComponentByType(massAction.type, massAction.payload)}
        </div>

        {isFinished && (
          <NButton
            onClick={deleteMassAction}
            type="text"
            icon={<TrashIcon style={{ position: "relative", top: 3 }} />}
          />
        )}
      </Flex>
      <div className={styles.MassActionCreated}>
        <div>
          <strong>Created </strong>
          {dayjs(massAction.created_at).format("DD MMM YYYY [at] HH:mm")}
          {" "}
          by
          {" "}
          {user && (
            <>
              <Avatar
                src={user.image_url}
                size={15}
                icon={<UserOutlined size={12} />}
              />
              {" "}
              <strong>{`${user.first_name} ${user.last_name}`}</strong>
            </>
          )}
          {!isCsvTask && (
            <div className={styles.Metrics}>
              <MassActionMetrics
                id={massAction.uuid}
                status={massAction.status}
              />
              <div
                onClick={() => {
                  modalService.openModal("massActionDetailsModalId", {
                    massActionId: massAction.uuid,
                  });
                }}
                className={styles.Details}
              >
                Click to view details
              </div>
            </div>
          )}

          {isCsvTask && (
            <>
              { massAction.status === "failed" && (
                <div className={styles.ExportError}>
                  <div>
                    {massActionStatusLabels[massAction.status]}
                  </div>
                  <p>{massAction.result?.error?.message || "Unknown Error"}</p>
                </div>
              )}


              { massAction.status !== "failed" && (
                <div className={styles.Download}>
                  <div style={{ color: "rgb(153, 172, 194)", marginBottom: 6 }}>
                    {massActionStatusLabels[massAction.status]}
                  </div>
                  {massAction.status !== "done" ? (
                    "Collecting data. Please wait! Your files will be available for download shortly."
                  ) : (
                    massAction.result?.files && (
                      <List
                        dataSource={massAction.result.files}
                        renderItem={(fileName, index) => (
                          <List.Item
                            actions={[
                              (
                                <NButton
                                  onClick={() => handleDownloadCsv(fileName)}
                                  icon={<DownloadOutlined />}
                                >
                                  Download
                                </NButton>
                              ),
                            ]}
                          >
                            <Typography.Text strong>
                              { `File ${(massAction.result!.files!.length > 1) ? (index + 1) : ""}` }
                            </Typography.Text>
                          </List.Item>
                        )}
                        size="small"
                      />
                    )
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
