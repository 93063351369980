import type { Team } from "@gs/core/domain/Team/Team";
import { useMemo } from "react";

function adjustBrightness(hex: string, percent: number): string {
  let num = parseInt(hex.slice(1), 16);

  let r = (num >> 16) + percent;
  let g = ((num >> 8) & 0x00FF) + percent;
  let b = (num & 0x0000FF) + percent;

  r = Math.min(Math.max(0, r), 255);
  g = Math.min(Math.max(0, g), 255);
  b = Math.min(Math.max(0, b), 255);

  return "#" + (r << 16 | g << 8 | b).toString(16).padStart(6, "0");
}

function getShadeColors(hex: string): {darkColor: string; lightColor: string} {
  if (!/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error("Invalid HEX color");
  }

  let lightColor = adjustBrightness(hex, 180);
  let darkColor = adjustBrightness(hex, -80);

  return { lightColor, darkColor };
}

export const useTeamColors = (hex: Team["color"]): {darkColor: string; lightColor: string} => {
  return useMemo(() => {
    if (!hex) return {
      darkColor: "var(--color-primary-text)",
      lightColor: "var(--color-primary-border)",
    };
    return getShadeColors(hex);
  }, [hex]);
};
