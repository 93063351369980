export class OuterPromise<T = unknown> {
  // @ts-ignore
   public readonly resolve: (value: (T | PromiseLike<T>)) => void
   // @ts-ignore
   public readonly reject: (reason?: unknown) => void
   constructor(executor?: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) {
     let outerResolve: (value: (T | PromiseLike<T>)) => void;
     let outerReject: (reason?: unknown) => void;

     const promise = new Promise<T>((resolve, reject) => {
       outerResolve = resolve;
       outerReject = reject;
       return executor?.(resolve, reject);
     });

     // @ts-ignore
     promise.resolve = outerResolve!;
     // @ts-ignore
     promise.reject = outerReject!;

     // @ts-ignore
     return promise;
   }
}
