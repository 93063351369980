import * as actions from "./consts";

const ACTION_HANDLERS = {
  [actions.TASKS_LOAD_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  // [actions.TASKS_LOAD_SUCCESS]: (state, { taskIds, total, aggs }) => ({
  //   ...state,
  //   isLoading: false,
  //   taskIds,
  //   total,
  //   aggs,
  // }),
  [actions.TASKS_LOAD_SUCCESS]: (state, { taskIds, total, hasMore }) => {
    const newIds = Array.from(new Set([...state.taskIds, ...taskIds]));
    const s = {
      ...state,
      isLoading: false,
      taskIds: newIds,
      total,
      hasMore,
    };
    return s;
  },
  [actions.TASKS_FOR_LEAD_LOAD_REQUEST]: (state, { id }) => {
    return {
      ...state,
      isLeadTasksLoading: true,
      activeleadID: id,
    };
  },
  [actions.TASKS_FOR_LEAD_LOAD_SUCCESS]: (state, { id }) => {
    return {
      ...state,
      isLeadTasksLoading: false,
      activeleadID: id,
    };
  },
  [actions.TASKS_FOR_LEAD_LOAD_FAILURE]: (state) => ({
    ...state,
    isLeadTasksLoading: false,
    activeleadID: null,
  }),
  [actions.TASKS_LOAD_FAILURE]: (state, { total = 0 }) => ({
    ...state,
    total,
    isLoading: false,
  }),
  [actions.TASKS_SELECTION_TOGGLE_PLURAL]: (state, { ids, all }) => ({
    ...state,
    selection: {
      ids,
      all,
    },
  }),
  [actions.TASKS_SELECTION_RESET]: (state) => ({
    ...state,
    selection: {
      ids: {},
      all: false,
    },
  }),
  [actions.TASKS_LEAVE]: () => initialState,
  [actions.TASKS_DROP_SEARCH]: (state) => ({
    ...state,
    taskIds: [],
    total: null,
    // aggs: null,
    selection: {
      all: false,
      ids: {},
    },
  }),
  // [actions.TASKS_SET_TOP_FILTER]: (state, { topFilters }) => ({
  //   ...state,
  //   topFilters: {
  //     ...topFilters,
  //   },
  // }),
  // [actions.TASKS_LIVE_UPDATE]: (state, { lead }) => {
  //   if (state.taskIds.indexOf(lead.id) !== -1) return state;

  //   return {
  //     ...state,
  //     taskIds: [lead.id, ...state.taskIds],
  //     total: state.total + 1,
  //     aggs: state.aggs && {
  //       ...state.aggs,
  //     },
  //   };
  // },
  // [actions.TASKS_SET_URL_FILTERS]: (state, { topFilters }) => ({
  //   ...state,
  //   topFilters,
  // }),
  [actions.TASKS_SET_EXECUTE]: (state, { task }) => {

    return {
      ...state,
      tasksIsActive: true,
      currentTask: task,
      total: state.total - 1 <= 0 ? 0 : state.total - 1,
    };
  },
  // [actions.TASKS_SET_CURRENT_TASK]:(state, { task }) => ({
  //   ...state,
  //   currentTask: task,
  // }),
  [actions.TASKS_P_APPLY_STATIC_FILTERS]: (state, { staticFilters }) => ({
    ...state,
    staticFilters,
    taskIds: [],
  }),
  // [actions.TASKS_SET_IS_ACTIVE]:(state) => ({
  //   ...state,
  //   tasksIsActive: true,
  // }),
  [actions.TASKS_SET_IS_ERROR]: (state, { isError }) => ({
    ...state,
    isError,
  }),
  [actions.TASKS_RESET_STATE]: (state) => ({
    ...state,
    taskIds: [],
  }),
  [actions.TASKS_SET_TASK_LINE]: (state, { data }) => ({
    ...state,
    tasksLine: {
      ...state.tasksLine,
      isStarted: true,
      batch: data.batch_number,
      isLoading: false,
      currentTask: data.task.uuid,
      currentLead: data.task.lead_uuid,
      totalCount: data.count,
      currentCount: data.current_number,
    },
  }),
  [actions.TASKS_CLOSE_TASK_LINE]: (state) => ({
    ...state,
    tasksLine: {
      ...state.tasksLine,
      isStarted: false,
      isLoading: false,
      batch: null,
      currentTask: null,
      currentLead: null,
      totalCount: null,
      currentCount: null,
    },
  }),
  [actions.TASKS_LINE_SET_IS_LOADING]: (state, { isLoadingState }) => ({
    ...state,
    tasksLine: {
      ...state.tasksLine,
      isLoading: isLoadingState,
    },
  }),
  [actions.TASKS_FINISH]: (state) => {

    return {
      ...state,
      tasksIsActive: false,
      currentTask: null,
    };
  },
  [actions.TASKS_SET_TOTAL_IN_PROGRESS]: (state, { count }) => {
    return {
      ...state,
      totalInProgress: count,
    };
  },

};


// const initialTopFilters = {
//   taskType: actions.TASKS_TYPES.Manual,
//   action: 0,
// }
const initialFilters = {
  taskType: actions.TASKS_TYPES.Manual,
  action: 0,
};
export const defaultTasksStaticFilters = {
  automation: "manual",
  lead_uuid: [],
  sender_profile_uuid: [],
  user_id: [],
  type: [],
  assigned_to: [],
  status: [],
  flow_uuid: [],
  schedule_at: { ">=": null, "<=": null },
};
const initialState = {
  isLoading: false,
  taskIds: [],
  total: null,
  totalInProgress: null,
  hasMore: false,
  tasksLine: {
    batch: null,
    currentTask: null,
    currentLead: null,
    isLoading: false,
    isStarted: false,
    totalCount: 0,
    currentCount: 0,
  },
  selection: {
    all: false,
    ids: {},
  },
  filters: initialFilters,
  staticFilters: defaultTasksStaticFilters,
  isError: false,
};

const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

const key = "tasksPage";

export {
  reducer as default,
  key,
  // initialTopFilters,
  initialFilters,
};
