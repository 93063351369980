import ReactModal from "react-modal";
import { ModalContainer } from "./Container/ModalContainer";
import { ModalHeader } from "./Header/ModalHeader";
import { ModalFooter } from "./Footer/ModalFooter";
import { ModalContent } from "./Content/ModalContent";
import { ModalErrorAlert } from "./ErrorAlert/ModalErrorAlert";

export const Modal = Object.assign(ReactModal, {
  Container: ModalContainer,
  Header: ModalHeader,
  Content: ModalContent,
  Footer: ModalFooter,
  ErrorAlert: ModalErrorAlert,
});
