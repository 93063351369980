import { getBaseAxios } from "services/axios";
import { createPipelineStageApi } from "@gs/core/domain/PipelineStage/createPipelineStageApi";
import { pipelineStageStore } from "./pipelineStageStore";
import type { PipelineStage, PipelineStageId } from "@gs/core/domain/PipelineStage/PipelineStage";
import { dataBus } from "../../services/dataBus";


export const pipelineStageApi = (() => {
  const leadsAxios = getBaseAxios("contactV2");
  const corePipelineStageApi = createPipelineStageApi(leadsAxios);

  const createPipelineStage: typeof corePipelineStageApi.createPipelineStage = async (...args) => {
    const result = await corePipelineStageApi.createPipelineStage(...args);

    pipelineStageStore.setState({
      [result.data.uuid]: result.data,
    });
    dataBus.emit("pipelineStage:create", { pipelineStageId: result.data.uuid });

    return result;
  };

  const getPipelineStage: typeof corePipelineStageApi.getPipelineStage = async (...args) => {
    const result = await corePipelineStageApi.getPipelineStage(...args);

    pipelineStageStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const getPipelineStages: typeof corePipelineStageApi.getPipelineStages = async (...args) => {
    const result = await corePipelineStageApi.getPipelineStages(...args);

    pipelineStageStore.setState(
      result.data.data.reduce<Record<PipelineStageId, PipelineStage>>((acc, pipelineStage) => {
        acc[pipelineStage.uuid] = pipelineStage;
        return acc;
      }, {}),
    );

    return result;
  };

  const updatePipelineStage: typeof corePipelineStageApi.updatePipelineStage = async (...args) => {
    const result = await corePipelineStageApi.updatePipelineStage(...args);

    pipelineStageStore.setState({
      [result.data.uuid]: result.data,
    });
    dataBus.emit("pipelineStage:update", { pipelineStageId: result.data.uuid });

    return result;
  };

  const deletePipelineStage: typeof corePipelineStageApi.deletePipelineStage = async (...args) => {
    const result = await corePipelineStageApi.deletePipelineStage(...args);

    pipelineStageStore.setState((prev) => {
      const newState = { ...prev };

      delete newState[args[1]];

      return newState;
    }, true);

    dataBus.emit("pipelineStage:delete", { pipelineStageId: args[0], replacePipelineStageId: args[1] });

    return result;
  };

  return {
    createPipelineStage,
    getPipelineStage,
    getPipelineStages,
    updatePipelineStage,
    deletePipelineStage,
    getPipelineStagesMetrics: corePipelineStageApi.getPipelineStagesMetrics,
  };
})();
