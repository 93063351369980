import { stepTypes as st } from "store/entities/flows/model/nodeTypes";

export const stepTypes = st;

export const nodeTypes = {
  START: "NODE_TYPE_START",
  END: "NODE_TYPE_END",
  ONE: "NODE_TYPE_ONE",
  TWO: "NODE_TYPE_TWO",
  MULTI: "NODE_TYPE_MULTI",
  PLUS: "NODE_TYPE_PLUS",
};

export const typeMaps = {
  [stepTypes.END]: nodeTypes.END,
  [stepTypes.ACTION]: nodeTypes.ONE,
  [stepTypes.UTIL]: nodeTypes.ONE,
  [stepTypes.RULE]: nodeTypes.MULTI,
  [stepTypes.TRIGGER]: nodeTypes.TWO,
};

export const checkPeriodTypes = {
  FLOW: "flow",
  FLOW_NODE: "flow_node",
  TIME: "time",
  ALL_TIME: "all_time",
};


export const getDefaultBranchesCount = (type, payload) => {
  switch (type) {
    case stepTypes.TRIGGER: return 2;
    case stepTypes.END: return 0;
    case stepTypes.RULE: return payload.branches.length;
    default: return 1;
  }
};
