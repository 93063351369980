export const FLOW_SOURCES_GET_SUCCESS = "FLOW_SOURCES_GET_SUCCESS";
export const FLOW_SOURCES_CREATE_SUCCESS = "FLOW_SOURCES_CREATE_SUCCESS";
export const FLOW_SOURCES_DELETE_SUCCESS = "FLOW_SOURCES_DELETE_SUCCESS";

export const flowSourceTypes = {
  people: "sales_navigator_leads",
  company: "sales_navigator_accounts",
};
export const dataSourceTypeLabels = {
  [flowSourceTypes.people]: "Sales Navigator People Search",
  [flowSourceTypes.company]: "Sales Navigator Account Search",
};

export const flowSourceStatuses = {
  completed: "completed",
  inProgress: "in_progress",
  onHold: "on_hold",
  notStarted: "not_started",
  failed: "failed",
};

export const seniorityLevelOptions = [
  { label: "Owner", value: 10 },
  { label: "Partner", value: 9 },
  { label: "CXO", value: 8 },
  { label: "VP", value: 7 },
  { label: "Director", value: 6 },
  { label: "Manager", value: 5 },
  { label: "Senior", value: 4 },
  { label: "Entry", value: 3 },
  { label: "Training", value: 2 },
  { label: "Unpavalue", value: 1 },
];

export const functionOptions = [
  { label: "Accounting", value: 1 },
  { label: "Administrative", value: 2 },
  { label: "Arts and Design", value: 3 },
  { label: "Business Development", value: 4 },
  { label: "Community and Social Services", value: 5 },
  { label: "Consulting", value: 6 },
  { label: "Education", value: 7 },
  { label: "Engineering", value: 8 },
  { label: "Entrepreneurship", value: 9 },
  { label: "Finance", value: 10 },
  { label: "Healthcare Services", value: 11 },
  { label: "Human Resources", value: 12 },
  { label: "Information Technology", value: 13 },
  { label: "Legal", value: 14 },
  { label: "Marketing", value: 15 },
  { label: "Media and Communication", value: 16 },
  { label: "Military and Protective Services", value: 17 },
  { label: "Operations", value: 18 },
  { label: "Product Management", value: 19 },
  { label: "Program and Project Management", value: 20 },
  { label: "Purchasing", value: 21 },
  { label: "Quality Assurance", value: 22 },
  { label: "Real Estate", value: 23 },
  { label: "Research", value: 24 },
  { label: "Sales", value: 25 },
  { label: "Support", value: 26 },
];
