import { useState, useMemo, useLayoutEffect } from "react";
import Icon from "../Icon";
import type { IAvaNameProps, IfallbackParams } from "./interfaces";
import cn from "classnames";

import styles from "./AvaName.module.scss";

export const AvaName = ({ size, src, name, style, badge, fallback, className, isRightBadge = false, badgeSize = 26, badgeClassName }: IAvaNameProps) => {
  const [isError, setIsError] = useState(false);
  const defaultFallback = useMemo(() => name?.split(/\s+/).map((str) => str[0]).join("").slice(0, 2).toLocaleUpperCase(),
    [name],
  );

  const theFallBack = fallback?.({ size }) || defaultFallback;

  // useEffect could run after onError of initial value
  useLayoutEffect(() => {
    setIsError(false);
  }, [src]);

  const sizeStyles = {
    width: size,
    height: size,
    lineHeight: `${size}px`,
    flex: `0 0 ${size}px`,
    borderRadius: size,
    fontSize: size / 2,
  };

  return (
    <div
      className={cn(styles.wrapper, className, { [styles.indigoBg!]: !fallback })}
      style={{ ...sizeStyles, ...style }}
    >
      {!isError && (
        <img
          className={styles.img}
          src={src}
          onError={() => setIsError(true)}
        />
      )}
      {isError && theFallBack}
      {badge && (
        <Icon
          className={cn(isRightBadge ? styles.badgeRight : styles.badge, { [styles.badgeSmall!]: size <= 20 }, badgeClassName )}
          icon={badge}
          size={!isRightBadge ? badgeSize : badgeSize - 2}
          color={"#425B76"}
        />
      )}
    </div>
  );
};


AvaName.fallbacks = {
  person: ({ size }: IfallbackParams) =>
    <Icon
      icon="no-profile"
      color="#CBD6E2"
      size={size}
    />,
};
