import * as actions from "./consts";
import { entitySetter } from "../utils";


const ACTION_HANDLERS = {
  [actions.SEED_WORKSPACES]: (state, { workspaces }) => entitySetter(state, workspaces),
  [actions.CREATE_WORKSPACE]: (state, { workspace }) => {
    return {
      ...state,
      [workspace.uuid]: workspace,
    };
  },
  [actions.UPDATE_WORKSPACE]: (state, { workspace }) => {
    return {
      ...state,
      [workspace.uuid]: workspace,
    };
  },
  [actions.DELETE_WORKSPACE]: (state, { workspaceUuid }) => {
    if (!state[workspaceUuid]) return state;

    const s = { ...state };
    delete s[workspaceUuid];
    return s;
  },
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
