import styles from "feature/modal/component/Content/ModalContent.module.scss";
import React from "react";
import cn from "classnames";

export const ModalContent = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cn(props.className, styles.content)}
    />
  );
};

