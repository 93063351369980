import { useFindOrFetchPipelineStage } from "domain/pipelineStage/hook/useFindOrFetchPipelineStage";
import type { PipelineStageId } from "@gs/core/domain/PipelineStage/PipelineStage";

export const PipelineMassActionName = ({
  payload,
}: {
  payload: { pipeline_stage_uuid: PipelineStageId | PipelineStageId[] }
}) => {
  const pipelineStageId = Array.isArray(payload.pipeline_stage_uuid) ? payload.pipeline_stage_uuid[0]! : payload.pipeline_stage_uuid;
  const [pipelineStage] = useFindOrFetchPipelineStage(pipelineStageId);
  return (
    <>
      Change to
      {" "}
      {pipelineStage?.name}
      {" "}
      pipeline stage
    </>
  );
};
