enum taskAutomations {
  AUTO = "auto",
  MANUAL = "manual",
}

enum TaskType {
  LINKEDIN_SEND_CONNECTION_REQUEST = "linkedin_send_connection_request",
  LINKEDIN_SEND_MESSAGE = "linkedin_send_message",
  LINKEDIN_VISIT_PROFILE = "linkedin_visit_profile",
  LINKEDIN_COMMENT_LATEST_POST = "linkedin_comment_latest_post",
  LINKEDIN_ENDORSE_SKILLS = "linkedin_endorse_skills",
  LINKEDIN_FIND_CONTACT = "linkedin_find_contact",
  LINKEDIN_WITHDRAW_CONNECTION_REQUEST = "linkedin_withdraw_connection_request",
  LINKEDIN_ENRICH_PROFILE = "linkedin_enrich_profile",
  LINKEDIN_LIKE_LATEST_POST = "linkedin_like_latest_post",
  EMAIL_SEND_MESSAGE = "email_send_message",
  PHONE_CALL_PHONE = "phone_call_phone",
  INTEGRATION_EXPORT_TO_CRM = "integration_export_to_crm",
  GS_ADD_TAG = "gs_add_tag",
  GS_REMOVE_TAG = "gs_remove_tag",
  GS_CHANGE_SENDER_PROFILE = "gs_change_sender_profile",
  GS_CHANGE_LIST = "gs_change_list",
  GS_CHANGE_PIPELINE_STAGE = "gs_change_pipeline_stage",
  GS_SEND_TO_FLOW = "gs_send_to_flow",
  TRIGGER_LINKEDIN_CONNECTION_REQUEST_ACCEPTED = "trigger_linkedin_connection_request_accepted",
  TRIGGER_MESSAGE_REPLIED = "trigger_message_replied",
  TRIGGER_EMAIL_OPENED = "trigger_email_opened",
  TRIGGER_LINK_CLICKED = "trigger_link_clicked",
  TRIGGER_WEBSITE_PAGE_VISITED = "trigger_website_page_visited",
  RULE_AB_TEST = "rule_ab_test",
  RULE_FILTER = "rule_filter",
  RULE_MANUAL_FILTER = "rule_manual_router"
}

enum TaskStatus {
  NEWBIE = "new", // In Progress (по умолчанию при загрузке)
  IN_PROGRESS = "in_progress", // In Progress (по умолчанию при загрузке)
  DELEGATED = "delegated", // In Progress (по умолчанию при загрузке)
  FINISHED = "finished", // Completed 
  FOR_CLOSE = "for_close", // Completed
  CLOSED = "closed", // Completed
  PAUSED = "paused", // Paused
  CANCELED = "canceled", // Canceled
  SKIPPED = "skipped", // Skipped
  FAILED = "failed", // Failed
  BOUNCED = "bounced", // Bounced
}

export type Attachment = {
  name: string
  uuid: string
}

enum StopOnReply {
  NEVER = "never",
  ALWAYS = "always"
}

export type TaskTypePayload = {
  type: TaskType.LINKEDIN_SEND_CONNECTION_REQUEST
  payload: { template: string; template_uuid: string; note?: string }
} | {
  type: TaskType.LINKEDIN_SEND_MESSAGE
  payload: {template: string; template_uuid?: string; note?: string; attachments?: Attachment[]; stop_on_reply_level: StopOnReply }
} | {
  type: TaskType.LINKEDIN_VISIT_PROFILE
  payload?: {note?: string}
} | {
  type: TaskType.LINKEDIN_COMMENT_LATEST_POST
  payload: {template: string; note?: string}
} | {
  type: TaskType.LINKEDIN_ENDORSE_SKILLS
  payload: {number: number; note?: string}
} | {
  type: TaskType.LINKEDIN_FIND_CONTACT
  payload?: {linkedin_filters?: any[]; note?: string}
} | {
  type: TaskType.LINKEDIN_WITHDRAW_CONNECTION_REQUEST
  payload?: {withdraw_key?: string; note?: string}
} | {
  type: TaskType.EMAIL_SEND_MESSAGE
  payload?: {email_field: string; subject: string; template: string; template_uuid: string; note?: string; attachments?: Attachment[]; add_history: boolean; stop_on_reply_level: StopOnReply}
} | {
  type: TaskType.PHONE_CALL_PHONE
  payload: {phone: string; note?: string}
} | {
  type: TaskType.INTEGRATION_EXPORT_TO_CRM
  payload: {integration_uuid: string}
} | {
  type: TaskType.GS_ADD_TAG
  payload: {tag_uuid: string}
} | {
  type: TaskType.GS_REMOVE_TAG
  payload: {tag_uuid: string}
} | {
  type: TaskType.GS_CHANGE_SENDER_PROFILE
  payload: {sender_profile_uuid: string}
} | {
  type: TaskType.GS_CHANGE_LIST
  payload: {list_uuid: string}
} | {
  type: TaskType.GS_CHANGE_PIPELINE_STAGE
  payload: {pipeline_stage_uuid: string}
} | {
  type: TaskType.GS_SEND_TO_FLOW
  payload: {flow_uuid: string}
} | {
  type: TaskType.TRIGGER_LINKEDIN_CONNECTION_REQUEST_ACCEPTED
  payload: {subtasks: any[]}
} | {
  type: TaskType.TRIGGER_MESSAGE_REPLIED
  payload: {check_period_type: number; check_period_seconds?: number; check_period_flow_node_id?: number; subtasks: any[]}
} | {
  type: TaskType.TRIGGER_LINK_CLICKED
  payload: {check_period_type: number; check_period_seconds?: number; check_period_flow_node_id?: number}
} | {
  type: TaskType.TRIGGER_WEBSITE_PAGE_VISITED
  payload: {check_period_type: number; check_period_seconds?: number; check_period_flow_node_id?: number; wildcard?: string}
} | {
  type: TaskType.RULE_AB_TEST
  payload: {winner_mode: number; branches: any[]}
} | {
  type: TaskType.RULE_FILTER
  payload: {branches: any[]}
} | {
  type: TaskType.RULE_MANUAL_FILTER
  payload: {branches: any[]; note?: string}
}

export {
  taskAutomations,
  TaskType,
  TaskStatus,
};
