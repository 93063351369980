import type { FC, ReactNode } from "react";

export const ContentHeaderTitle: FC<{children: ReactNode}> = (props) => {
  // h1 doesn't work well with dark theme
  return (
    // <h1
    <div
      style={{
        fontSize: 17,
        fontWeight: 600,
        position: "relative",
        top: 2,
      }}
    >
      { props.children }
    </div>
  );
};
