import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse, Mixed, OrderType } from "../../commonTypes";
import type { List, ListId, ListMetrics } from "./List";
import type { UserId } from "../User/User";

type ListEditableFields = Pick<List, "name">

export const createListApi = (axios: AxiosInstance) => {
  const getLists = (
    params: {
      filter?: {
        query?: string
        id?: Mixed<ListId>
        name?: Mixed<string>
        userId?: Mixed<UserId>
        withDeleted?: boolean
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<List>>(
      "/api/lists",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: {
            q: params.filter?.query,
            uuid: params.filter?.id,
            name: params.filter?.name,
            user_id: params.filter?.userId,
            deleted: params.filter?.withDeleted,
          },
        },
      },
    );
  };

  const getList = (params: {id: ListId}, config?: AxiosRequestConfig) => {
    return axios.get<List>(`/api/lists/${params.id}`, config);
  };

  const createList = async (params: {fields: ListEditableFields}, config?: AxiosRequestConfig) => {
    return axios.post<List>("/api/lists", params.fields, config);
  };

  const updateList = async (params: {id: ListId; newFields: ListEditableFields}, config?: AxiosRequestConfig) => {
    return axios.put<List>(`/api/lists/${params.id}`, params.newFields, config);
  };

  const deleteList = async (params: {id: ListId}, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/lists/${params.id}`, config);
  };

  const getListMetrics = async (
    params: {
      ids: ListId[]
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Record<ListId, ListMetrics>>(
      "/api/lists/metrics",
      {
        uuids: params.ids,
      },
      config,
    );
  };

  return {
    getLists,
    getList,
    createList,
    updateList,
    deleteList,
    getListMetrics,
  };
};
