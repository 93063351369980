import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

export { default as path } from "./path";

export default Loadable({
  loader: () => {
    return import(/* webpackChunkName:'LinkedinFiltersPage' */ "./LinkedinFiltersPage")
      .then((module) => module.LinkedinFiltersPage)
      .catch(() => Error404);
  },
  loading: Loading as any,
});
