import { createSelector } from "reselect";
import { handleDate } from "services/utils";
import { ALL_ACTIVITIES } from "./consts";

const defaultArray = [];

const dayFormat = "YYYY-MM-DD";

const sliceSelector = (state) => state.entities.contacts;

const getContactById = (state, { id }) => sliceSelector(state)[id];
const getLeadActivityById = (state, { id }) => sliceSelector(state)[id].activity;
const getSortedLeadActivityById = createSelector(
  [getLeadActivityById],
  (activities) => [...activities].sort((a, b) => (+handleDate(a.created_at) - handleDate(b.created_at))),
);

const getWebsiteSessions = (state, { id }) => {
  const contact = getContactById(state, { id });

  if (!contact) {
    return defaultArray;
  }

  return contact.website_sessions;
};

const getWebsiteSessionsLength = (state, { id }) => {
  const websiteSessions = getWebsiteSessions(state, { id });

  if (!websiteSessions) {
    return 0;
  }

  return websiteSessions.length;
};

const activitySelector = (state, { id }) => {
  const contact = getContactById(state, { id });

  if (!contact || !contact.activity) {
    return defaultArray;
  }

  return contact.activity;
};

const allActivityCountSelector = (state, { id }) => {
  const contact = getContactById(state, { id });

  if (!contact || !contact.activity) {
    return defaultArray;
  }

  return contact.activity;
};

const activityFilteredSelector = createSelector(
  [activitySelector], (activity) => {
    const allActivitiesTypes = Object.values(ALL_ACTIVITIES);

    if (!Array.isArray(activity)) {
      return defaultArray;
    }

    return activity.filter((activ) => allActivitiesTypes.includes(activ.type));
  });

const allActivitySelector = (state, { id }) => {
  const res = activityFilteredSelector(state, { id });

  const websiteSessions = getWebsiteSessions(state, { id });

  return res.concat(websiteSessions);
};

const allActivitySortedSelector = createSelector(
  [
    activitySelector,
    getWebsiteSessions,
  ], (activity, websiteSessions) => {
    const allActivitiesTypes = Object.values(ALL_ACTIVITIES);
    let day = handleDate(activity.created_at).format(dayFormat);

    const res = {};

    if (!Array.isArray(activity)) {
      return res;
    }

    const allActivity = activity.filter((activ) => allActivitiesTypes.includes(activ.type));

    allActivity.forEach((activity) => {
      day = handleDate(activity.created_at).format(dayFormat);

      if (!res[day]) {
        res[day] = [];
      }

      res[day].push(activity);
    });

    websiteSessions.forEach((sessions) => {
      day = handleDate(sessions.first_visited_at).format(dayFormat);

      if (!res[day]) {
        res[day] = [];
      }

      res[day].push(sessions);
    });

    for (const day of Object.keys(res)) {
      res[day].sort((a, b) => (a.order_ts || a.created_at) > (b.order_ts || b.created_at) ? 1 : -1);
    }

    return res;
  },
);

const defaultTypes = [
  ALL_ACTIVITIES.SOCIAL_DM_SENT,
  ALL_ACTIVITIES.SOCIAL_DM_REPLIED,
  ALL_ACTIVITIES.EMAIL_SENT,
  ALL_ACTIVITIES.EMAIL_REPLIED,
  ALL_ACTIVITIES.EMAIL_OPENED,
  ALL_ACTIVITIES.SOCIAL_CONNECT_SENT,
  ALL_ACTIVITIES.SOCIAL_CONNECTED,
];
const getLastActivity = (state, { neededTypes = defaultTypes, leadId }) => {
  const lead = getContactById(state, { id: leadId });
  if (!lead) return null;

  for (const act of lead.activity) {
    if (neededTypes.indexOf(act.type) !== -1) {
      return act;
    }
  }

  return null;
};


export {
  sliceSelector,
  getContactById,
  getLeadActivityById,
  getSortedLeadActivityById,
  getWebsiteSessions,
  getWebsiteSessionsLength,
  activitySelector,
  allActivityCountSelector,
  allActivitySortedSelector,
  allActivitySelector,
  activityFilteredSelector,
  getLastActivity,
};
