import { ReactComponent as GsFullLogo } from "assets/black_logo.svg";
// import vantageFullLogo from "../../../assets/whiteLabels/vantage/fullLogo.png";
// import grinfiFullLogo from "../../../assets/whiteLabels/grinfi/fullLogo.png";
// import { whiteLabels } from "services/whiteLabels";
import { useSelector } from "react-redux";
import { fullWhiteLabelLogo } from "../../../store/whiteLabel/selectors";

export const FullLogo = (props: { className?: string; style: any}) => {
  const whiteLabelLogoUrl = useSelector(fullWhiteLabelLogo);

  if (whiteLabelLogoUrl) {
    return <img
      src={whiteLabelLogoUrl}
      {...props}
    />;
  }

  // if (window.location.host === whiteLabels.ADVANTAGE.domain) {
  //   return <img src={vantageFullLogo} {...props} />;
  // }
  // if (window.location.host === whiteLabels.GRINFI.domain) {
  //   return <img src={grinfiFullLogo} {...props} />;
  // }

  return <GsFullLogo {...props} />;
};
