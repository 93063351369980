import * as consts from "./consts";

const ACTION_HANDLERS = {
  [consts.LOAD_INIT_REQUEST]: (state) => {
    return {
      ...state,
      isInitLoading: true,
    };
  },
  [consts.DASHBOARD_STATS_P_LOAD_REQUEST]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [consts.DASHBOARD_STATS_P_LOAD_SUCCESS]: (state, { stats }) => {
    return {
      ...state,
      isLoading: false,
      stats: {
        ...stats,
      },
    };
  },
  [consts.DASHBOARD_STATS_P_LOAD_FAILURE]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [consts.INIT_COMPLITED]: (state) => {
    return {
      ...state,
      isInitLoading: false,
    };
  },
  [consts.SET_DASHBOARD_FILTERS]: (state, { filters }) => {
    const newFilters = {
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    };
    return newFilters;
  },
};

const initialState = {
  isLoading: false,
  isInitLoading: false,
  stats: {},
  filters: {
    period: [],
    flows: [],
    senderProfiles: [],
  },
};

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

const key = "dashboardPage";

export {
  key,
};
