import { Switch, Route } from "react-router-dom";
import Avatar from "components/common/elements/Avatar";
import type { IAboutTemplateContentProps } from "../../interfaces";
import { getLinkTo, sortedTemplates } from "../../const";

import styles from "../Modal.module.scss";
import { useSelector } from "react-redux";
import { getIsWhiteLabel } from "../../../../../../../store/whiteLabel/selectors";

export const AboutTemplate = () => {
  return (
    <Switch>
      {sortedTemplates.map(({ uuid, label, aboutTemplate }) => {
        return (
          <Route
            key={label}
            exact
            path={`${getLinkTo(uuid || "")}`}
          >
            <AboutTemplateContent content={aboutTemplate} />
          </Route>
        );
      })}
    </Switch>
  );
};


const AboutTemplateContent = ({ content }: IAboutTemplateContentProps) => {
  const isWhiteLabel = useSelector(getIsWhiteLabel);

  return (
    <div className={styles.AboutTemplate}>
      <div className={styles.AboutTemplateLeft}>
        <h3>About this template</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: content.description,
          }}
        >
        </div>
      </div>
      {!isWhiteLabel && (
        <div className={styles.AboutTemplateRight}>
          <h3>About author</h3>
          <div className={styles.Author}>
            <div className={styles.AuthorLeft}>
              <Avatar
                size={45}
                imageUrl={content.author.avatar}
              />
            </div>
            <div className={styles.AuthorRight}>
              <span>{content.author.name}</span>
              <strong>{content.author.position}</strong>
            </div>
          </div>
          <p>{content.author.description}</p>
        </div>
      )}
    </div>
  );
};
