import type { AvatarProps } from "@gs/uiKit/dataDisplay/Avatar";
import { Avatar } from "@gs/uiKit/dataDisplay/Avatar";
import type { TeamId } from "@gs/core/domain/Team/Team";
import { useTeamColors } from "domain/team/hook/useTeamColors";
import { useFindOrFetchTeam } from "domain/team/hook/useFindOrFetchTeam";

export const TeamAvatar = (props: {teamId: TeamId } & AvatarProps) => {
  const {
    teamId,
    ...propsToPropagate
  } = props;
  const [team] = useFindOrFetchTeam(teamId);
  const { darkColor, lightColor } = useTeamColors(team?.color || null);

  if (!team) return null;

  return (
    <Avatar
      shape="square"
      style={{
        color: darkColor,
        backgroundColor: lightColor,
      }}
      {...propsToPropagate}
    >
      { String.fromCodePoint(team.name.codePointAt(0)!) }
    </Avatar>
  );
};
