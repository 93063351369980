export const senderProfilesPath = "/sender-profiles";
export const senderProfileDetailsPath = `${senderProfilesPath}/:SPUuid`;

export const detailPaths = {
  SPOverview: `${senderProfileDetailsPath}/details`,
  SPLinkedin: `${senderProfileDetailsPath}/linkedin`,
  SPEmail: `${senderProfileDetailsPath}/email`,
  SPAutoTasks: `${senderProfileDetailsPath}/auto-tasks`,
};
export const paths = {
  SPRoot: senderProfilesPath,
  SPDetails: senderProfileDetailsPath,
  ...detailPaths,
};

export enum SenderProfileStatuses {
  ENABLED = "enabled",
  DISABLED = "disabled"
}

export default senderProfilesPath;
