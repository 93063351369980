const TEAM_P_LOAD_USERS_REQUEST = "TEAM_P_LOAD_USERS_REQUEST";
const TEAM_P_LOAD_USERS_SUCCESS = "TEAM_P_LOAD_USERS_SUCCESS";
const TEAM_P_LOAD_USERS_FAILURE = "TEAM_P_LOAD_USERS_FAILURE";
const TEAM_P_SET_FILTERS = "TEAM_P_SET_FILTERS";
const TEAM_P_SET_Q = "TEAM_P_SET_Q";
const TEAM_P_CLEAR_IDS = "TEAM_P_CLEAR_IDS";
const TEAM_P_SET_PAGINATION = "TEAM_P_SET_PAGINATION";

const TEAM_P_LOAD_GET_USERS = "TEAM_P_LOAD_GET_USERS";

const TEAM_P_LOAD_INVITES_REQUEST = "TEAM_P_LOAD_INVITES_REQUEST";
const TEAM_P_LOAD_INVITES_SUCCESS = "TEAM_P_LOAD_INVITES_SUCCESS";
const TEAM_P_LOAD_INVITES_FAILURE = "TEAM_P_LOAD_INVITES_FAILURE";

export {
  TEAM_P_LOAD_USERS_REQUEST,
  TEAM_P_LOAD_USERS_SUCCESS,
  TEAM_P_LOAD_USERS_FAILURE,
  TEAM_P_SET_FILTERS,
  TEAM_P_SET_Q,
  TEAM_P_CLEAR_IDS,
  TEAM_P_SET_PAGINATION,
  TEAM_P_LOAD_GET_USERS,
  TEAM_P_LOAD_INVITES_REQUEST,
  TEAM_P_LOAD_INVITES_SUCCESS,
  TEAM_P_LOAD_INVITES_FAILURE,
};
