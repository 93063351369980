import amplitude from "amplitude-js";
import config from "config";

const hardCodedApiKey = "503f866d46e94e3f0e2ff244257ac3bd";

const amplitudeKey = config.amplitudeKey || hardCodedApiKey;

const instance = amplitude.getInstance();

instance.init(amplitudeKey, null, {
  logLevel: "DISABLE",
  includeReferrer: true,
});

export const identifyUser = (userId, userProperties) => {
  instance.setUserId(userId);
  instance.setUserProperties(userProperties);

};

export const unsetUserId = () => {
  instance.setUserId(null);
  instance.regenerateDeviceId();
};

export const logEvent = (event, data, setFrequency) => {
  instance.logEvent(event, data);

  if (setFrequency) {
    const date = (new Date).toISOString();
    const identify = new amplitude.Identify();
    identify.setOnce(`${event} - First`, date);
    identify.set(`${event} - Last`, date);
    identify.add(`${event} - Amount`, 1);

    instance.identify(identify);
  }
};
