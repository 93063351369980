import * as consts from "./consts";
import { sendMessageToPlugin } from "components/routes/PluginGroup/pluginEvents";
import { messagesFromFrontAppToClientPlugin } from "clientPluginCommunication";


export const setProxyStatus = (status) => ({
  type: consts.PROXY_SET_STATUS,
  status,
});
export const setProxyServerId = (id) => ({
  type: consts.PROXY_SELECT,
  id,
});

export const selectProxyServer = (server) => {
  sendMessageToPlugin(messagesFromFrontAppToClientPlugin.SET_PROXY, { server });

  return {
    type: consts.PROXY_SELECT,
    id: server.id,
  };
};

export const clearProxyServer = () => {
  sendMessageToPlugin(messagesFromFrontAppToClientPlugin.CLEAR_PROXY, {});

  return { type: consts.PROXY_CLEAR };
};
