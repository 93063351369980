export const crmPath = "/leads";

export const paths = {
  contacts: `${crmPath}/people`,
  companies: `${crmPath}/companies`,
  lists: `${crmPath}/lists`,
  imports: `${crmPath}/imports`,
};

export const stageCategories = {
  cold: "cold",
  engaging: "engaging",
  positive: "positive",
  negative: "negative",
};

export const stageColors = {
  [stageCategories.cold]: "#7C98B6",
  [stageCategories.engaging]: "#FF7A59",
  [stageCategories.positive]: "#00BDA5",
  [stageCategories.negative]: "#F2545B",
};

export const stageGradientColors = {
  [stageCategories.cold]: "linear-gradient(180deg, #425B76 0%, rgba(66, 91, 118, 0.5) 100%)",
  [stageCategories.engaging]: "linear-gradient(180deg, #FF7C5C 0%, rgba(255, 124, 92, 0.5) 100%)",
  [stageCategories.positive]: "linear-gradient(180deg, #1AC3AE 0%, rgba(36, 197, 177, 0.5) 100%)",
  [stageCategories.negative]: "linear-gradient(180deg, #6A78D1 0%, rgba(106, 120, 209, 0.5) 100%)",
};

export const pageFilters = {
  pipelineStage: "pipeline_stage_uuid",
  aside: "aside",
};

export default crmPath;
