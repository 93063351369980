import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { __DEV__ } from "services/env";
import Error404 from "components/routes/NotFound";

export { playgroundPath } from "./path";

const FlowDetailsPage = Loadable({
  loader: () => {
    const componentPromise = __DEV__
      ? import(/* webpackChunkName: 'Playground' */ "./Playground")
      : Promise.resolve(Error404);

    return componentPromise
      .catch( (e) => console.log("Page loading err", e) || Error404 );
  },
  loading: Loading,
});

export default FlowDetailsPage;
