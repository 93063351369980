import * as actions from "./consts";
import { entitySetter } from "../utils";


const ACTION_HANDLERS = {
  [actions.SEED_CATEGORIES]: (state, { categories }) => entitySetter(state, categories),
  [actions.CREATE_TEMPLATE_CATEGORY]: (state, { category }) => {
    return {
      ...state,
      [category.uuid]: category,
    };
  },
  [actions.UPDATE_TEMPLATE_CATEGORY]: (state, { category }) => {
    return {
      ...state,
      [category.uuid]: category,
    };
  },
  [actions.DELETE_TEMPLATE_CATEGORY]: (state, { categoryId }) => {
    if (!state[categoryId]) return state;

    const s = { ...state };
    delete s[categoryId];
    return s;
  },
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
