import axios from "axios";
import history from "services/browserHistory";
import appConfig from "config";
import authPath from "components/routes/authGroup/Signup/path";
import teamsManagementPath from "components/routes/UserSettingsGroup/TeamsManagement/path";
import { sessionStore } from "feature/session/sessionStore";
import { dataBus } from "services/dataBus";


const endPoints = appConfig.endPoints;

const interceptorSuccessCb = (response) => {
  return response.data;
};


let refreshingPromise = null;
let refreshSubscribers = [];

function onRrefreshed(newToken) {
  refreshSubscribers.forEach((cb) => cb(newToken));
  refreshSubscribers = [];
}

const refreshToken = () => axios.post(appConfig.endPoints.idV2 + "api/users/refresh-jwt-token", {}, { withCredentials: true });

const getInterceptorFailureCb = (onlyPayload) => (err) => {
  if (!err.response) {
    return Promise.reject(err);
  }

  const originalRequest = err.config;

  switch (err.response.status) {
    case 400: {
      if (err.response.data?.message === "Team-ID header is required") {
        history.push(teamsManagementPath);
      }
      break;
    }
    case 401:
      console.log("401", err.response.data);
      return new Promise((resolve, reject) => {
        refreshSubscribers.push((token) => {
        // using cookies now
        // originalRequest.headers['Authorization'] = `Bearer ${token}`;
          resolve(axios(originalRequest).then( (r) => onlyPayload ? r.data : r ));
        });

        if (!refreshingPromise) {
          refreshingPromise = refreshToken()
            .then(onRrefreshed)
            .finally(() => {
              refreshingPromise = null;
            });
        }

        refreshingPromise.catch( () => {
          if (!history.location.pathname.includes("restore")) {
            if (![authPath.login, authPath.signup, authPath.verification].includes(history.location.pathname)) {
              history.push(authPath.login);
            }
          }

          dataBus.emit("currentUser:logout");
          reject(err.response.data);
        });
      });
      break;
    case 402:
    // need payment
      return Promise.reject(err.response);
      break;
    case 403:
      console.log("403", err.response.data);
      return new Promise((resolve, reject) => {
        refreshSubscribers.push((token) => {
          // using cookies now
          // originalRequest.headers['Authorization'] = `Bearer ${token}`;
          resolve(axios(originalRequest).then( (r) => {
            return onlyPayload ? r.data : r;
          } ).catch((err) => {
            throw err.response;
          }));
        });
        if (!refreshingPromise) {
          refreshingPromise = refreshToken()
            .then(onRrefreshed)
            .finally(() => {
              refreshingPromise = null;
            });
        }
      });
      // probably user is out od team
      break;
  }

  err.response.data = {
    responseCode: err.response.status,
    ...err.response.data,
  };
  return Promise.reject(err.response.data);
};

const createAxiosInstance = (type, onlyPayload) => {
  const axiosInstance = axios.create({
    baseURL: endPoints[type],
    withCredentials: true,
    crossDomain: true,
    headers: {
      accept: "application/json",
    },
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      // using cookies now
      // config.headers.Authorization = `Bearer ${jwtService.getToken()}`
      const { teamId, clusterId } = sessionStore.getState();
      if (/\/c\/$/.test(config.baseURL)) {
        config.baseURL = config.baseURL.replace(/c\/$/, `c${clusterId}/`);
      }
      if (teamId) {
        config.headers["Team-Id"] = config.headers["Team-Id"] ?? teamId;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  const onFulfilled = onlyPayload ? interceptorSuccessCb : undefined;
  axiosInstance.interceptors.response.use(onFulfilled, getInterceptorFailureCb(onlyPayload));

  return axiosInstance;
};

export const createInstanceGetter = () => {
  const instances = {};

  return (type) => {
    if (!instances[type]) {
      instances[type] = createAxiosInstance(type, true);
    }

    return instances[type];
  };
};
export const createBaseInstanceGetter = () => {
  const instances = {};

  return (type) => {
    if (!instances[type]) {
      instances[type] = createAxiosInstance(type, false);
    }

    return instances[type];
  };
};

export const getBaseAxios = createBaseInstanceGetter();

export default createInstanceGetter();

export const createSource = () => axios.CancelToken.source();
