import { createSelector } from "reselect";
import {
  getSelectionCountersGetters,
  getTripleSelectionState,
} from "services/helpers/selectionHelper";
import { key } from "./reducer";
import { sliceSelector as tasksEntities } from "store/entities/tasks/selectors";
import { sliceSelector as leadEntities } from "store/entities/contacts/selectors";
import { tasksNames } from "../../../../store/entities/tasks/consts";

const sliceSelector = (state) => state[key] ? state[key] : {};
const staticFilters = (state) => state[key] ? state[key].staticFilters : {};
const tasksLine = (state) => state[key] ? state[key].tasksLine : {};
const selection = (state) => sliceSelector(state).selection;
const total = (state) => sliceSelector(state).total;
const hasMore = (state) => sliceSelector(state).hasMore;
const isLoading = (state) => sliceSelector(state).isLoading;
const isError = (state) => sliceSelector(state).isError;
const taskIds = (state) => sliceSelector(state).taskIds;
const totalInProgress = (state) => sliceSelector(state).totalInProgress;
const copiedMessageValue = (state) => sliceSelector(state).copiedMessageValue;
const loadedCount = (state) => sliceSelector(state).taskIds.length;
const aggsSelector = (state) => sliceSelector(state).aggs || {};
const aggsAutomationsSelector = (state) => aggsSelector(state).automations || {};
const autoCountSelector = (state) => aggsAutomationsSelector(state).auto || 0;
const manualCountSelector = (state) => aggsAutomationsSelector(state).manual || 0;
const topFilterSelector = (state) => sliceSelector(state).topFilters || {};
const topFilterTasksType = (state) => topFilterSelector(state).taskType;
const topFilterActionSelector = (state) => topFilterSelector(state).action;
const tasksIsActiveSelector = (state) => sliceSelector(state).tasksIsActive;
const currentTaskSelector = (state) => sliceSelector(state).currentTask;

const getTasksArr = createSelector(
  [tasksEntities, taskIds, leadEntities],
  (tasksEntitiesRes, taskIdsRes, leadEntitiesRes) => {
    if (!taskIdsRes) {
      return [];
    }

    return taskIdsRes.map((id) => {

      const task = tasksEntitiesRes[id];
      const lead = leadEntitiesRes[task.lead_id];

      return {
        ...task,
        lead,
      };
    });
  },
);

const getFirstTask = (state) => getTasksArr(state)[0];

const selectionData = getTripleSelectionState({
  selection,
  loadedIds: taskIds,
  total,
});

const selectionCountersGetters = getSelectionCountersGetters({ selection, total, loadedCount });

const getSelectedCount = selectionCountersGetters.getSelectedCount;
const getShowSelectAll = selectionCountersGetters.getShowSelectAll;

const selectionIds = (state) => state[key].selection.ids;
const selectionAll = (state) => state[key].selection.all;

const selectedIdsArr = createSelector(
  [selectionIds],
  (selectionIds) => Object.keys(selectionIds),
);

export const getFullSelection = createSelector(
  [selectionAll, selectedIdsArr],
  (all, ids) => ({
    all,
    ids,
  }),
);

const taskTypesSelector = createSelector(
  [aggsSelector],
  (aggs) => {
    const {
      automations,
      actions,
    } = aggs;

    const ungroupedCount = automations && (automations.manual || 0) + (automations.auto || 0) || 0;

    const res = [
      {
        value: 0,
        name: `All tasks (${ungroupedCount})`,
      },
    ];

    if (!actions) {
      return res;
    }

    const tps = Object.entries(actions).map(([actionName, count]) => ({
      value: actionName,
      name: `${tasksNames[actionName]} (${count})`,
    }));

    return [...res, ...tps];
  },
);

export {
  selection,
  total,
  isLoading,
  isError,
  taskIds,
  copiedMessageValue,
  loadedCount,
  aggsSelector,
  topFilterTasksType,
  getTasksArr,
  getFirstTask,
  selectionData,
  getSelectedCount,
  staticFilters,
  getShowSelectAll,
  tasksIsActiveSelector,
  currentTaskSelector,
  taskTypesSelector,
  topFilterActionSelector,
  autoCountSelector,
  manualCountSelector,
  tasksLine,
  selectionAll,
  hasMore,
  totalInProgress,
  selectedIdsArr,
};
