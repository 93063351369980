import { SquareLogo } from "components/common/Logo/SquareLogo";
import { FullLogo } from "components/common/Logo/FullLogo";
import { mainLayoutService } from "feature/mainLayout/MainLayoutService";
import { Flex } from "@gs/uiKit/layout/Flex";
// import { MenuFoldOutlined } from "@ant-design/icons";
import { ReactComponent as SidebarCloseIcon } from "assets/svg/sidebar-close.svg";
import { Button } from "@gs/uiKit/general/Button";

export const SidebarHeader = () => {
  const isSideMenuOpen = mainLayoutService.useStore((s) => s.isSideMenuOpen);

  return (
    <Flex
      gap="middle"
      justify="space-between"
      align="center"
      style={{
        width: isSideMenuOpen ? "100%" : undefined,
        padding: isSideMenuOpen ? "0 var(--padding-sm)" : undefined,
      }}
    >
      {isSideMenuOpen ? (
        <FullLogo style={{ height: 40, width: "fit-content" }} />
      ) : (
        <SquareLogo
          style={{ width: 40, height: 40 }}
        />
      )}

      {isSideMenuOpen && (
        <Button
          onClick={mainLayoutService.closeSideMenu}
          icon={<SidebarCloseIcon style={{ width: 16, position: "relative", top: -1 }} />}
          type="text"
        />
      )}
    </Flex>
  );
};
