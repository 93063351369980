import { createSelector } from "reselect";

const categoriesSelector = (state) => state.entities.templateCategories || {};

const categoriesArraySelector = createSelector(
  [categoriesSelector], (categories) => Object.values(categories),
);


export {
  categoriesSelector,
  categoriesArraySelector,
};
