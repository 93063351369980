import * as actions from "./consts";
import getAxios from "services/axios";
import { FLOW_SENDER_PROFILES_METRICS_SET } from "./consts";

const axios = getAxios("flowV2");

const loadFlowMetrics = (ids) => (dispatch, _getState) => {
  return axios.post("/api/flows/metrics", { uuids: ids })
    .then((flows) => {
      dispatch({
        type: actions.FLOW_METRICS_SET,
        flowMetrics: flows,
      });

      return flows;
    });
};
const loadFlowsSenderProfiles = (ids) => (dispatch, _getState) => {
  return axios.post("/api/flows/all-sender-profiles", { uuids: ids })
    .then((senders) => {
      dispatch({
        type: actions.FLOW_SENDER_PROFILES_SET,
        senders,
      });

      return senders;
    });
};
const loadFlowsSenderProfilesMetrics = ({ sender_profiles_uuids, flow_uuid, statuses = ["in_progress"], metrics = ["tasks_count"] }) => (dispatch, _getState) => {
  return axios.post("/api/tasks/metrics", { sender_profiles_uuids, flow_uuid, statuses, metrics })
    .then((senderMetrics) => {
      dispatch({
        type: actions.FLOW_SENDER_PROFILES_METRICS_SET,
        senderMetrics,
      });

      return senderMetrics;
    });
};
export {
  loadFlowMetrics,
  loadFlowsSenderProfiles,
  loadFlowsSenderProfilesMetrics,
};
