import styles from "components/common/Modal/Footer/ModalFooter.module.scss";
import React from "react";
import cn from "classnames";


const ModalFooter = ({ children, className = "" }) => (
  <div className={cn(styles.container, className)}>
    { children }
  </div>
);

export default ModalFooter;
