import { useLocation } from "react-router-dom";
import Preloader from "components/common/elements/Preloader";
import { ReactComponent as Bg } from "assets/login-bg.svg";
import { companiesList } from "./const";
import { FullLogo } from "components/common/Logo/FullLogo";

import style from "./UnauthLayout.module.scss";
import authPath from "../../routes/authGroup/Signup/path";
import { useCurrentUser } from "feature/session/useCurrentUser";
import browserHistory from "services/browserHistory";

const UnauthLayout = ({ children }) => {
  const { currentUserStatus } = useCurrentUser();
  const { pathname } = useLocation();
  const isAuth = currentUserStatus === "authorized";
  // const isVerifyPage = pathname === authPath.verification;

  if (isAuth) {
    browserHistory.push("/");
    return <Preloader />;
  }

  return (
    <div className={style.componentContainer}>
      <Bg className={style.Bg} />
      {pathname !== authPath.verification ? (<>
        <div className={style.componentBox}>
          <div className={style.LogoWrapper}>
            <FullLogo className={style.Logo} />
          </div>
          {children}
        </div>
        {pathname === authPath.signup ? (
          <div
            style={{ display: "none" }}
            className={style.companies}
          >
            <h3>Learn why 500+ companies trust their sales automation to GetSales.io</h3>
            <div className={style.companiesList}>
              {companiesList.map((company, index) => {
                return (
                  <img
                    key={index}
                    src={company.logoPath}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div
            style={{ display: "none" }}
            className={style.productUpdates}
          >
            <h2 className={style.productUpdatesHeader}>Every day we grow and improve the product to make it even better!</h2>
            <div className={style.productUpdatesItem}>
              <span className={style.productUpdatesItemDate}>May 25, 2022 - Whats app channel release</span>
              <div className={style.productUpdatesItemHeader}>
                <span className={style.productUpdatesItemFeature}>New Feature</span>
                <span>Whats app automation</span>
              </div>
              <p>New Whats app send message node added, it will allow you to send whats app automation messages some more text, sorry but something happened with my imagination today!</p>

              <div className={style.productUpdatesItemHeader}>
                <span className={style.productUpdatesItemImprovment}>Improvment</span>
                <span>New filter “Location Region” added</span>
              </div>
              <p>New Whats app send message node added, it will allow you to send whats app automation messages some more text, sorry but something happened with my imagination today!</p>

              <div className={style.productUpdatesItemHeader}>
                <span className={style.productUpdatesItemBugfix}>Bugfix</span>
                <span>Custom domains support added</span>
              </div>
              <p>New Whats app send message node added, it will allow you to send whats app automation messages some more text, sorry but something happened with my imagination today!</p>
            </div>
            <div className={style.productUpdatesItem}>
              <span className={style.productUpdatesItemDate}>May 1, 2022 - Major tasks improvments</span>
              <div className={style.productUpdatesItemHeader}>
                <span className={style.productUpdatesItemFeature}>New Feature</span>
                <span>Whats app automation</span>
              </div>
              <p>New Whats app send message node added, it will allow you to send whats app automation messages some more text, sorry but something happened with my imagination today!</p>
            </div>
          </div>
        )}
      </>) : children}

    </div>
  );
};

export default UnauthLayout;
