import createNode from "./createNode";
import nodeTypes from "../nodeTypes";

export default (model, filter, filter_tree_format, senderProfiles, name, active_limit, coworkers_limit) => {
  const source = {
    id: model.nextSourceId++,
    mass_actions_filter: filter,
    filter_tree_format,
    after_id: model.flow.version.first_common_node_id,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    sender_profiles: senderProfiles || [],
    name, active_limit, coworkers_limit,
  };
  model.flow.version.contact_sources.push(source);

  const sourceUiNode = createNode(model, {
    type: nodeTypes.SOURCE,
    payload: source,
    sourceId: source.id,
    union: null,
    before: [],
    after: [],
    name, active_limit, coworkers_limit,
  });

  const startUiNode = model.nodes.find((node) => node.type === nodeTypes.START);
  const firstCommonUiNode = model.nodes.find((node) => node.serverNodeId === model.flow.version.first_common_node_id);
  const plusBeforeFirstCommonNode = model.nodes.find((node) => node.id === firstCommonUiNode.before[0]);

  const beforePlusNode = createNode(model, {
    type: nodeTypes.PLUS,
    hidden: true,
    before: [startUiNode.id],
    after: [sourceUiNode.id],
  });

  startUiNode.after.splice(-1, 0, beforePlusNode.id);
  sourceUiNode.before = [beforePlusNode.id];

  if (plusBeforeFirstCommonNode.root) {
    const afterPlusNode = createNode(model, {
      type: nodeTypes.PLUS,
      before: [sourceUiNode.id],
      after: [plusBeforeFirstCommonNode.id],
    });

    sourceUiNode.after = [afterPlusNode.id];
    plusBeforeFirstCommonNode.before.splice(-1, 0, afterPlusNode.id);
  } else {
    const afterPlusNode = createNode(model, {
      type: nodeTypes.PLUS,
      before: [sourceUiNode.id],
      after: [],
    });
    sourceUiNode.after = [afterPlusNode.id];
    const unionPlusNode = createNode(model, {
      type: nodeTypes.PLUS,
      before: [afterPlusNode.id, plusBeforeFirstCommonNode.id],
      after: [firstCommonUiNode.id],
      root: startUiNode.id,
    });
    startUiNode.union = unionPlusNode.id;
    afterPlusNode.after = [unionPlusNode.id];
    plusBeforeFirstCommonNode.after = [unionPlusNode.id];
  }
};
