import store from "store/store";
import { injectReducer } from "store/reducers";
import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const FlowsListPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'FlowListPage' */ "./FlowList");

    return import(/* webpackChunkName:'FlowListPage' */ "./reducer/reducer")
      .then( (reducer) => injectReducer(store, { key: "flowList", reducer: reducer.default }) )
      .then(() => component)
      .catch( (e) => console.log("Page loading err", e) || Error404 );
  },
  loading: Loading,
});

export default FlowsListPage;
