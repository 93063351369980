import * as actions from "./consts";
import _ from "lodash";

const ACTION_HANDLERS = {
  [actions.TAGS_GET_SUCCESS]: (state, { tags }) => _.reduce(tags, (res, tag) => {
    res[tag.uuid] = tag;
    return res;
  }, { ...state }),
  [actions.TAG_DELETE_SUCCESS]: (state, { uuid }) => {
    const s = { ...state };

    delete s[uuid];

    return s;
  },
  [actions.TAG_CREATE_SUCCESS]: (state, { tag }) => ({
    ...state,
    [tag.uuid]: tag,
  }),
};

const initialState = {};

export default function tagsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
