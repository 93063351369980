import * as actions from "./consts";
import getAxios from "services/axios";
import { createCachedFunction } from "../utils";

const axios = getAxios("idV2");

export const cacher = createCachedFunction((params) => (dispatch, getState) => {
  return axios.get("/api/subscriptions", { params })
    .then( (res) => {
      dispatch({
        type: actions.SUBSCRIPTIONS_GET_SUCCESS,
        subscriptions: res.data,
      });

      return res;
    });
}, { recursively: true });

export const loadSubscriptions = cacher.cachedFunction;

export const loadSubscriptionById = (id) => (dispatch) => {
  return axios.get(`/api/subscriptions/${id}`)
    .then( (sub) => {
      dispatch({
        type: actions.SUBSCRIPTIONS_GET_SUCCESS,
        subscriptions: [sub],
      });

      return sub;
    });
};

export const resumeSubscription = (subscriptionId) => (dispatch) => {
  return axios.put(`/api/billing/getsales/getsales/subscriptions/${subscriptionId}/resume`)
    .then( (subscription) => {
      console.log("resume subscription", subscription);
      dispatch({
        type: actions.SUBSCRIPTIONS_RESUME_SUCCESS,
        subscription,
      });
    });
};

export const cancelSubscription = (subscriptionId) => (dispatch) => {
  return axios.put(`/api/subscriptions/${subscriptionId}/cancel`)
    .then( (subscription) => {
      dispatch({
        type: actions.SUBSCRIPTIONS_CANCEL_SUCCESS,
        subscriptionId,
        subscription,
      });
    });
};

export const updateSubscription = ({ id, params }) => (dispatch) => {
  return axios.put(`api/subscriptions/${id}/update-plan`, params).then((subscription) => {
    dispatch({
      type: actions.SUBSCRIPTIONS_UPDATE,
      subscription,
    });
    return subscription;
  });
};

// export const getTeamSubscriptions =(teamId) => {
//   return axios.get(`/api/subscriptions/${teamId}`)
//     .then( (subscription) => {
//       return subscription;
//     });
// };
