export const messagesFromClientPluginToFrontApp = {
  RESTART: "RESTART",
  RECORDS: "RECORDS",
  DYNAMIC_DATA: "DYNAMIC_DATA",
  CURRENT_TAB_URL: "CURRENT_TAB_URL",
  SET_ROUTE: "SET_ROUTE",
  SET_TEAM_ID: "SET_TEAM_ID",

  // proxy
  WRONG_PROFILE_PROXY: "WRONG_PROFILE_PROXY",
  RIGHT_PROFILE_PROXY: "RIGHT_PROFILE_PROXY",
  NEUTRAL_PROFILE_PROXY: "NEUTRAL_PROFILE_PROXY",
};
