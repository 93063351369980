import * as actions from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  [actions.SET_INVALID_NODES]: (state, { invalidNodes, versionUuid, flowUuid }) => ({
    ...state,
    invalidNodes,
    versionUuid,
    flowUuid,
  }),
};

const initialState = {
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
