import { applyMiddleware, compose, createStore as createReduxStore } from "redux";
import thunk from "redux-thunk";
// import { routerMiddleware } from 'connected-react-router'
import history from "services/browserHistory";
import { __DEV__ } from "services/env";
import makeRootReducer from "./reducers";
import { sessionService } from "feature/session/SessionService";

const createStore = (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk /*routerMiddleware(history)*/];

  // ======================================================
  // Store Enhancers
  // ======================================================

  const enhancers = [];
  let composeEnhancers = compose;

  if (__DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function") {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(undefined, history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  );
  store.asyncReducers = {};

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  // store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     const reducers = require('./reducers').default
  //     store.replaceReducer(reducers(store.asyncReducers))
  //   })
  // }

  sessionService.store.subscribe((state) => state.status, (status) => {
    if (status === "unauthorized") {
      store.dispatch({ type: "LOGOUT_SUCCESS" });
    }
  });

  return store;
};

export default createStore;
