import { Redirect, Route, Switch } from "react-router-dom";
import Navigation from "./Navigation";
import CustomFields, { path as customFieldsPath } from "./CustomFields";
import Team, { path as teamPath } from "./Team";
import PipelineStages from "./PipelineStages";
import { pipelineLeadPath, pipelineCompanyPath } from "./PipelineStages/path";
import { settingsPaths } from "./path";
import WebhooksPage from "./Webhooks";
import NotFoundRoute from "../NotFound";
import rootPath from "./path";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import type { ReactNode } from "react";
import { WebhooksNavigation } from "components/routes/SettingsGroup/Webhooks/WebhooksNavigation/WebhooksNavigation";
import linkedinFiltersPath from "components/routes/SettingsGroup/LinkedinFilters/path";
import LinkedinFiltersAsyncPage from "components/routes/SettingsGroup/LinkedinFilters";
import { ContentBody } from "feature/mainLayout/component/ContentBody/ContentBody";


export { default as path } from "./path";

export default () => {
  const subRoutes: Array<{
    path: string
    exact: boolean
    body: ReactNode
    header: ReactNode
  }> = [
    {
      path: pipelineLeadPath,
      exact: true,
      body: <PipelineStages object="lead" />,
      header: <ContentHeader.Title> Contact Pipeline Stages </ContentHeader.Title>,
    },
    {
      path: pipelineCompanyPath,
      exact: true,
      body: <PipelineStages object="company" />,
      header: <ContentHeader.Title> Account Pipeline Stages </ContentHeader.Title>,
    },
    {
      path: `${customFieldsPath}/:object`,
      exact: true,
      body: <CustomFields />,
      header: <ContentHeader.Title> Custom Fields </ContentHeader.Title>,
    },
    {
      path: teamPath,
      exact: false,
      body: <Team />,
      header: <ContentHeader.Title> Team Members </ContentHeader.Title>,
    },
    {
      path: settingsPaths.webhooks,
      exact: false,
      body: <WebhooksPage />,
      header: <WebhooksNavigation />,
    },
    {
      path: linkedinFiltersPath,
      exact: true,
      body: <LinkedinFiltersAsyncPage />,
      header: <ContentHeader.Title> Linkedin Filters </ContentHeader.Title>,
    },
  ];

  return (
    <>
      <ContentHeader>
        <Switch>
          { subRoutes.map((subRoute) => (
            <Route
              key={subRoute.path}
              path={subRoute.path}
              exact={subRoute.exact}
            >
              { subRoute.header }
            </Route>
          ))}
        </Switch>
      </ContentHeader>
      <ContentBody style={{ display: "flex", gap: "var(--padding)" }}>
        <Navigation />

        <div style={{ width: 0, flexGrow: 1 }}>
          <Switch>
            <Route
              exact
              path={rootPath}
            >
              <Redirect to={pipelineLeadPath} />
            </Route>
            { subRoutes.map((subRoute) => (
              <Route
                key={subRoute.path}
                path={subRoute.path}
                exact={subRoute.exact}
              >
                { subRoute.body }
              </Route>
            ))}
            {/* <Route path={emailAccountPagePath} component={EmailAccountPage} /> */}
            <Route component={NotFoundRoute} />
            {" "}
            {/* 404 */}
          </Switch>
        </div>
      </ContentBody>
    </>
  );
};
