import { useEffect } from "react";
import cn from "classnames";
import Button from "components/common/buttons/Button";
import { NewOrEditModal } from "../NewOrEditModal";
import { CategoryItem } from "./Item";
import { useDispatch, useSelector } from "react-redux";
import { categoriesArraySelector } from "store/entities/templateCategories/selectors";
import { getCategories } from "store/entities/templateCategories/actions";
import type { ICategoriesProps } from "./interfaces";
import { Icon } from "../../../common/elements/Icon";

import styles from "./Categories.module.scss";

export const Categories = ({ onChange, activeCategory }: ICategoriesProps) => {
  const dispatch = useDispatch();
  const categoriesData = useSelector(categoriesArraySelector);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleClick = (uuid: string | null ) => {
    onChange({
      template_category_uuid: uuid ? uuid : null,
    });
  };

  return (
    <div className={styles.CategoriesWrap}>
      <div className={styles.Header}>
        <div className={styles.HeaderTitle}>
          <Icon
            size={13}
            icon={"folder-icon"}
          />
          Folders
        </div>
        <NewOrEditModal>
          <Button
            transparent
            className={styles.CategoriesAddNew}
          >
            + Add
          </Button>
        </NewOrEditModal>
      </div>
      <div
        onClick={() => handleClick(null)}
        className={cn(styles.CategoriesItem, { [styles.active!]: !activeCategory })}
      >
        All Folders
      </div>
      {categoriesData.map((categoryData) => {
        return (
          <CategoryItem
            key={categoryData.uuid}
            onClick={handleClick}
            categoryData={categoryData}
            isActive={activeCategory === categoryData.uuid}

          />
        );
      })}

    </div>
  );
};
