import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Popover } from "antd";

const defaultFunction = () => { };


const createPopoverTrigger = (PopoverContent) => {
  return (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
      opened,
      children,
      placement = "bottomLeft",
      onClick,
      onOpen = defaultFunction,
      onClose = defaultFunction,
      onOpenChange,
      className = "",
      trigger = "hover",
    } = props;

    const handlePopover = (show) => {
      setIsOpen(show);
      if (show) {
        onOpen();
      } else {
        onClose();
      }
    };

    const handleToggle = (e) => {
      if (!isOpen) {
        handlePopover(true);
        //@ts-ignore
        onClick?.(e);
      } else {
        handlePopover(false);
      }
    };

    useEffect(() => {
      setIsOpen(opened);
    }, [opened]);
    useLayoutEffect(() => {
      if (isOpen) {
        onOpen();
      }
    }, [isOpen]);

    return (
      <Popover
        arrow={false}
        open={isOpen}
        trigger={trigger}
        placement={placement}
        overlayClassName={className}
        content={<PopoverContent
          onRequestClose={() => handleToggle(false)}
          {...props}
                 />}
        onOpenChange={onOpenChange ? () => onOpenChange() : (open) => {
          handleToggle(!open);
        }}
        destroyTooltipOnHide={true}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (trigger !== "hover") {
              handleToggle(!isOpen);
            }
          }}
        >
          {children}
        </div>
      </Popover>
    );
  };
};

export const PopoverTrigger = createPopoverTrigger;
