import { createSelector } from "reselect";
import store from "../../store";
import { getSenderProfile } from "./actions";
import history from "services/browserHistory";
import senderProfilesPath from "components/routes/SenderProfileGroup/consts";

let redirectCount = 0;

const senderProfileSelector = (state) => state.entities.senderProfiles || {};
const getSenderProfileByUuid = (state, { uuid }) => {
  if (!uuid) return;
  const senderProfile = senderProfileSelector(state)?.[uuid];
  if (!senderProfile) {
    store.dispatch(getSenderProfile(uuid)).catch((err) => {
      if (err.responseCode === 403 || err.status === 403) {
        if (redirectCount === 0) {
          // pizdec
          history.push(senderProfilesPath);
          redirectCount++;
          setTimeout(() => {
            redirectCount = 0;
          }, 1000);
        }
      } else {
        // throw err.response;
      }
    });
  }

  return senderProfile;
};

const selectSenderProfile = (state, { senderProfileId }) => {
  return senderProfileSelector(state)[senderProfileId];
};

const senderProfileArraySelector = createSelector(
  [senderProfileSelector], (profiles) => Object.values(profiles),
);
const selectSenderProfileIds = createSelector(
  [senderProfileSelector], (profiles) => Object.keys(profiles),
);


export {
  senderProfileSelector,
  getSenderProfileByUuid,
  senderProfileArraySelector,
  selectSenderProfileIds,
  selectSenderProfile,
};
