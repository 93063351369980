const SEED_CONTACTS = "SEED_CONTACTS";
const CONTACTS_DELETE_SUCCESS = "CONTACTS_DELETE_SUCCESS";
const CONTACTS_SET_LIST_SUCCESS = "CONTACTS_SET_LIST_SUCCESS";
const CONTACTS_SET_TAGS_SUCCESS = "CONTACTS_SET_TAGS_SUCCESS";
const CONTACTS_DELETE_TAGS_SUCCESS = "CONTACTS_DELETE_TAGS_SUCCESS";
const CONTACTS_SET_STAGE_SUCCESS = "CONTACTS_SET_STAGE_SUCCESS";
const CONTACTS_SET_STAGE_PLURAL_SUCCESS = "CONTACTS_SET_STAGE_PLURAL_SUCCESS";
const CONTACTS_SET_ASSIGN_PLURAL_SUCCESS = "CONTACTS_SET_ASSIGN_PLURAL_SUCCESS";
const CONTACTS_ASSIGN_SUCCESS = "CONTACTS_ASSIGN_SUCCESS";
const CONTACTS_SET_NETWORK_TYPE_SUCCESS = "CONTACTS_SET_NETWORK_TYPE_SUCCESS";
const CONTACTS_MESSAGES_READ = "CONTACTS_MESSAGES_READ";
const CONTACTS_ADD_ACTIVITY = "CONTACTS_ADD_ACTIVITY";
const CONTACTS_CANCEL_ACTIVITY = "CONTACTS_CANCEL_ACTIVITY";
const CONTACTS_MARK_UNREAD = "CONTACTS_MARK_UNREAD";

const EMAIL_ACTIVITIES = {
  EMAIL_OPENED: "email_opened",
  EMAIL_REPLIED: "email_replied",
  EMAIL_SENT: "email_sent",
  EMAIL_BOUNCED: "email_bounced",
  EMAIL_LINK_CLICKED: "email_link_clicked",
};

const SOCIAL_ACTIVITIES = {
  LINKEDIN_CONNECTION_SENT: "linkedin_connection_request_sent",
  LINKEDIN_CONNECTION_ACCEPTED: "linkedin_connection_request_accepted",
  LINKEDIN_CONNECTION_WITHDRAWN: "linkedin_connection_request_withdrawn",
  LINKEDIN_MESSAGE_SENT: "linkedin_message_sent",
  LINKEDIN_MESSAGE_REPLIED: "linkedin_message_replied",
  LINKEDIN_LINK_CLICKED: "linkedin_link_clicked",
  LINKEDIN_POST_LIKED: "linkedin_post_liked",
  LINKEDIN_ENDORSE_SKILLS: " linkedin_skills_endorsed",
  LINKEDIN_PROFILE_VISITED: "linkedin_profile_visited",
  LINKEDIN_POST_COMMENTED: "linkedin_post_commented",
};

const FLOW_ACTIVITIES = {
  FLOW_FINISHED: "flow_finished",
  FLOW_FAILED: "flow_failed",
  FLOW_CANCELLED: "flow_cancelled",
  ADDED_TO_FLOW: "added_to_flow",
};

const TAGS_ACTIVITIES = {
  TAGS_ADDED: "tags_added",
  TAGS_REMOVED: "tags_removed",
};

const OTHER_ACTIVITIES = {
  LEAD_CREATED: "lead_created",
  EXPORTED_TO_CSV: "exported_to_csv",
  SYNCED_CRM: "synced_crm",
  ASSIGNED_TO: "assigned_to_sdr",
  PIPELINE_STAGE_CHANGED: "pipeline_stage_changed",
  LIST_CHANGED: "list_changed",
  LEAD_ENRICHED: "enriched",
};

const ALL_ACTIVITIES = {
  ...EMAIL_ACTIVITIES,
  ...SOCIAL_ACTIVITIES,
  ...FLOW_ACTIVITIES,
  ...TAGS_ACTIVITIES,
  ...OTHER_ACTIVITIES,
};

const ActivitesNames = {
  [ALL_ACTIVITIES.TAGS_ADDED]: "Tags Added to Lead",
  [ALL_ACTIVITIES.TAGS_REMOVED]: "Tags Removed from Lead Removed",

  [ALL_ACTIVITIES.FLOW_FINISHED]: "Automation Finished",
  [ALL_ACTIVITIES.FLOW_FAILED]: "Automation Failed",
  [ALL_ACTIVITIES.FLOW_CANCELLED]: "Automation Сancelled",
  [ALL_ACTIVITIES.ADDED_TO_FLOW]: "Added to Automation",

  [ALL_ACTIVITIES.LINKEDIN_CONNECTION_SENT]: "Connect",
  [ALL_ACTIVITIES.LINKEDIN_CONNECTION_ACCEPTED]: "LinkedIn Connected",
  [ALL_ACTIVITIES.LINKEDIN_CONNECTION_WITHDRAWN]: "Withdraw Connect",
  [ALL_ACTIVITIES.LINKEDIN_MESSAGE_SENT]: "Direct Message sent",
  [ALL_ACTIVITIES.LINKEDIN_MESSAGE_REPLIED]: "Direct Message replied",
  [ALL_ACTIVITIES.LINKEDIN_LINK_CLICKED]: "Direct Message click",
  [ALL_ACTIVITIES.LINKEDIN_POST_LIKED]: "Post Like",
  [ALL_ACTIVITIES.LINKEDIN_ENDORSE_SKILLS]: "Skills Endorsement",
  [ALL_ACTIVITIES.LINKEDIN_PROFILE_VISITED]: "Profile Visit",
  [ALL_ACTIVITIES.LINKEDIN_POST_COMMENTED]: "Post Comment",

  [ALL_ACTIVITIES.EMAIL_OPENED]: "Email opened",
  [ALL_ACTIVITIES.EMAIL_REPLIED]: "Email replied",
  [ALL_ACTIVITIES.EMAIL_SENT]: "Email sent",
  [ALL_ACTIVITIES.EMAIL_BOUNCED]: "Email bounced",
  [ALL_ACTIVITIES.EMAIL_LINK_CLICKED]: "Email link click",

  [ALL_ACTIVITIES.LEAD_CREATED]: "Lead Created",
  [ALL_ACTIVITIES.LEAD_ENRICHED]: "Lead Enriched",
  [ALL_ACTIVITIES.EXPORTED_TO_CSV]: "Exported to CSV",
  [ALL_ACTIVITIES.SYNCED_CRM]: "Synced to CRM",
  [ALL_ACTIVITIES.ASSIGNED_TO]: "Assigned to SDR",
  [ALL_ACTIVITIES.PIPELINE_STAGE_CHANGED]: "Pipeline stage changed",
  [ALL_ACTIVITIES.LIST_CHANGED]: "List changed",
};

export {
  SEED_CONTACTS,
  CONTACTS_DELETE_SUCCESS,
  CONTACTS_SET_LIST_SUCCESS,
  CONTACTS_SET_TAGS_SUCCESS,
  CONTACTS_DELETE_TAGS_SUCCESS,
  CONTACTS_SET_STAGE_SUCCESS,
  CONTACTS_SET_STAGE_PLURAL_SUCCESS,
  CONTACTS_SET_ASSIGN_PLURAL_SUCCESS,
  CONTACTS_ASSIGN_SUCCESS,
  CONTACTS_SET_NETWORK_TYPE_SUCCESS,
  CONTACTS_MESSAGES_READ,
  CONTACTS_ADD_ACTIVITY,
  CONTACTS_CANCEL_ACTIVITY,
  CONTACTS_MARK_UNREAD,
  ALL_ACTIVITIES,
  ActivitesNames,
};
