import React from "react";
import { wait } from "./wait";

const RETRIES_COUNT = 3;

export const reactLazyWithRetry: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      for (let i = 0; i < RETRIES_COUNT; i++) {
        await wait(2 ** i * 1000);
        try {
          return await importer();
        } catch (e) {
          console.log("retrying import", e);
        }
      }
      throw error;
    }
  };

  return React.lazy(retryImport);
};
