import React from "react";
import { __DEV__ } from "services/env";

import styles from "./Styles.module.scss";

let Whoops = () => <h1 className={styles.Label}> Whoops... </h1>;

if (__DEV__) Whoops = () => (
  <div className="gs-text _exlg"> __DEV__ whoops </div>
);

export default Whoops;
