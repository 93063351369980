import store from "store/store";
import { injectReducer } from "store/reducers";
import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

export { default as path } from "./path";

const CustomFieldsPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'CustomFieldsPage' */ "./CustomFields");

    return import(/* webpackChunkName:'CustomFieldsPage' */ "./reducer/reducer")
      .then( (reducer) => injectReducer(store, { key: reducer.key, reducer: reducer.default }) )
      .then( () => component )
      .catch( (e) => console.log("Page loading err", e) || Error404 );
  },
  loading: Loading,
});

export default CustomFieldsPage;

