import { createSelector } from "reselect";
import store from "store/store";
import { loadFlow } from "./actions";

const sliceSelector = (state) => state.entities.flows;

const getFlowById = (state, { flowId }) => {
  const flow = sliceSelector(state)[flowId];

  if (!flow) {
    store.dispatch(loadFlow(flowId));
  }

  return flow;
};

const flowsArraySelector = createSelector(
  [sliceSelector], (flows) => Object.values(flows),
);

const activeFlowsArraySelector = createSelector(
  [flowsArraySelector], (flowsArr) => flowsArr.filter((flow) => {
    return !!flow.status;
  }),
);

export {
  sliceSelector,
  getFlowById,
  flowsArraySelector,
  activeFlowsArraySelector,
};
