import { createBoundedUseStore, createStore } from "store/zustand/createStore";
import type { UserId } from "@gs/core/domain/User/User";
import type { TeamId, ClusterId } from "@gs/core/domain/Team/Team";


type SessionState = {
  userId: UserId | null
  teamId: TeamId | null
  clusterId: ClusterId | null
  status: "loading" | "authorized" | "unauthorized" | "unknown"
}

export const sessionStore = createStore<SessionState>(
  "@feature/session",
  (set, get) => ({
    userId: null,
    teamId: null,
    clusterId: null,
    status: "unknown",
  }),
);

export const useSessionStore = createBoundedUseStore(sessionStore);
