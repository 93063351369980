import * as actions from "./consts";

const entitySetter = (state, entities) => {
  const s = { ...state };

  if (!Array.isArray(entities)) {
    entities = [entities];
  }

  entities.forEach((entity) => {
    s[entity.uuid] = {
      ...s[entity.uuid],
      ...entity,
    };
  });
  return s;
};


const ACTION_HANDLERS = {
  [actions.SEED_COMPANIES]: (state, { companies }) => entitySetter(state, companies),
  [actions.COMPANIES_SET_TAGS_SUCCESS]: (state, { companies }) => {
    const s = { ...state };

    companies.forEach((company) => {
      s[company.company.uuid] = { ...company.company, markers: company.markers, activity: company.activity };
    });

    return s;
  },
  [actions.COMPANIES_SET_PIPELINE_SUCCESS]: (state, { companies }) => {
    const s = { ...state };

    companies.forEach((company) => {
      s[company.company.uuid] = { ...company.company, markers: company.markers, activity: company.activity };
    });

    return s;
  },
  [actions.COMPANIES_SET_LIST_SUCCESS]: (state, { companies }) => {
    const s = { ...state };

    companies.forEach((company) => {
      s[company.company.uuid] = { ...company.company, markers: company.markers, activity: company.activity };
    });

    return s;
  },
  [actions.COMPANIES_DELETE_SUCCESS]: (state, { loadedSelection }) => {
    const s = { ...state };

    for (const id of loadedSelection) {
      delete s[id];
    }

    return s;
  },
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
