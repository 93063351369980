import find from "lodash/find";
import { ONE_SIDE_WIDTH } from "../../nodeTypes";
import getAllNodesIdsOnLine from "./getAllNodesIdsOnLine";

const setNodesWidth = (model) => {
  const firstUiNode = model.nodes.filter((node) => !node.before.length)[0];

  setWithRecursively(model, firstUiNode.id);

  return model;
};

function setWithRecursively(model, uiId) {
  const uiNode = find(model.nodes, { id: uiId });
  if (uiNode.innerWidth != null) {
    return uiNode;
  }

  if (uiNode.before.length > 1) {
    uiNode.innerWidth = null;
    uiNode.outterLeftWidth = null;
    uiNode.outterRightWidth = null;
  } else {
    switch (uiNode.after.length) {
      case 0:
      case 1: {
        uiNode.innerWidth = 0;
        uiNode.outterLeftWidth = uiNode.outterRightWidth = ONE_SIDE_WIDTH;

        if (uiNode.after[0]) {
          setWithRecursively(model, uiNode.after[0]);
        }
        break;
      }
      default: {
        uiNode.innerWidth = 0;
        const mostLeftBranch = getAllNodesIdsOnLine(model, uiNode.after[0]).map((id) => setWithRecursively(model, id));
        const mostRightBranch = getAllNodesIdsOnLine(model, uiNode.after[uiNode.after.length - 1]).map((id) => setWithRecursively(model, id));
        const innerBranches = uiNode.after.slice(1, -1).map((id) => getAllNodesIdsOnLine(model, id).map((id) => setWithRecursively(model, id)));

        uiNode.innerWidth += Math.max(...mostLeftBranch.map((node) => node.outterRightWidth));
        uiNode.innerWidth += Math.max(...mostRightBranch.map((node) => node.outterLeftWidth));
        for (const innerBranch of innerBranches) {
          uiNode.innerWidth += Math.max(...innerBranch.map((node) => node.outterLeftWidth));
          uiNode.innerWidth += Math.max(...innerBranch.map((node) => node.outterRightWidth));
        }

        uiNode.outterLeftWidth = uiNode.innerWidth / 2 + Math.max(...mostLeftBranch.map((node) => node.outterLeftWidth));
        uiNode.outterRightWidth = uiNode.innerWidth / 2 + Math.max(...mostRightBranch.map((node) => node.outterRightWidth));
        break;
      }
    }
  }

  for (const id of uiNode.after) {
    setWithRecursively(model, id);
  }

  return uiNode;
}

export default setNodesWidth;
