import * as actions from "./consts";
import { createCachedFunction } from "../utils";
import getAxios from "services/axios";

const axios = getAxios("flowV2");

export const cacher = createCachedFunction((params) => (dispatch) => {
  return axios.get("/api/ai-templates", {
    params,
  })
    .then((response) => {
      const { data } = response;
      const uuids = data.map(({ uuid }) => uuid);

      if (!uuids.length) return response;

      return getTemplatesMetrics({ uuids })
        .then((metrics) => {
          const templates = data.map((template) => {
            return {
              ...template,
              metrics: metrics[template.uuid],
            };
          });

          dispatch({
            type: actions.SEED_TEMPLATES,
            templates,
          });

          return response;
        });
    });
}, { recursively: true });


export const getTemplates = cacher.cachedFunction;

export const loadTemplateByUuid = (uuid) => (dispatch) => {
  return axios.get(`/api/ai-templates/${uuid}`)
    .then( (template) => {
      dispatch({
        type: actions.SEED_TEMPLATES,
        templates: [template],
      });

      return template;
    });
};

export const createTemplate = ({ ...data }) => (dispatch) => {
  return axios.post("api/ai-templates", { ...data })
    .then( (template) => {
      cacher.clearCache();
      dispatch({
        type: actions.SEED_TEMPLATES,
        templates: [template],
      });
      return template;
    });
};

export const editTemplate = ({ uuid, ...data }) => (dispatch) => {
  return axios.put(`api/ai-templates/${uuid}`, { ...data })
    .then( (template) => {
      cacher.clearCache();
      dispatch({
        type: actions.SEED_TEMPLATES,
        templates: [template],
      });
    });
};

export const generateTemplatePreview = ({ text, lead_uuid, sender_profile_uuid }) => {
  return axios.post("api/ai-templates/render", { text, lead_uuid, sender_profile_uuid });
};

export const getTemplatesMetrics = ({ uuids }) => {
  return axios.post("/api/ai-templates/metrics", {
    uuids,
  });
};
