import { getFlowById } from "store/entities/flows/selectors";

export const sliceSelector = (state) => state.flowDetails;

export const modalTypeSelector = (state) => sliceSelector(state).activeModalType;

export const flowDetailsModeSelector = (state) => sliceSelector(state).mode;

export const workTableSelector = (state) => sliceSelector(state)?.workTable;

export const flowUuidSelector = (state) => workTableSelector(state)?.flow?.uuid;

export const sourceSelector = (state) => workTableSelector(state).flow?.version?.contact_sources;
export const serverNodesSelector = (state) => workTableSelector(state)?.flow?.nodes;
export const uiNodesSelector = (state) => workTableSelector(state)?.nodes;

export const flowSelector = (state) => getFlowById(state, { flowId: workTableSelector(state).flow.uuid });

const focusedNodeIdSelector = (state) => workTableSelector(state)?.focusedNodeId;
const selectFocusedUiNode = (state) => workTableSelector(state).nodes?.find((node) => node.id === focusedNodeIdSelector(state));
const selectFocusedServerNode = (state) => workTableSelector(state).flow.nodes.find((node) => node.id === selectFocusedUiNode(state)?.serverNodeId);

export const getIsModified = (state) => sliceSelector(state).isModified;

export {
  sliceSelector as flowDetailsSelector,
  focusedNodeIdSelector,
  selectFocusedUiNode,
  selectFocusedServerNode,
};
