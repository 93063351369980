import * as actions from "./consts";
import reduce from "lodash/reduce";
import { immuteSet, immuteDelete } from "services/immuteActions";

const ACTION_HANDLERS = {
  [actions.FLOW_SOURCES_GET_SUCCESS]: (state, { flowSources }) => reduce(flowSources, (acc, flowSource) => {
    acc[flowSource.uuid] = flowSource;
    return acc;
  }, { ...state }),
  [actions.FLOW_SOURCES_DELETE_SUCCESS]: (state, { id }) => immuteDelete(state, `${id}`),
  [actions.FLOW_SOURCES_CREATE_SUCCESS]: (state, { flowSource }) => immuteSet(state, `${flowSource.uuid}`, flowSource),
};

const initialState = {};

export default function flowSourcesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
