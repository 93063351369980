import * as actions from "./consts";
import getAxios from "services/axios";
import store from "store/store";
import { createCachedFunction } from "../utils";
import { getTeams } from "../teams/actions";
import { getTeamUsers } from "components/routes/SettingsGroup/Team/reducer/actions";

const axios = getAxios("idV2");

export const loadUsers = (params) => (dispatch) => {
  return axios.get("api/users", { params } )
    .then( (res) => {
      dispatch(setUsers(res.data));
      return res;
    });
};

// Тольуко для текущего юзера
export const updateUser = (params) => (dispath) => {
  return axios.put("api/users", params)
    .then( (user) => {
      dispath(setUsers(user));
      return user;
    });
};

export const setUserServer = ({ userId, server }) => ({
  type: actions.SET_USER_SERVER,
  userId,
  server,
});

export const createUserForTeam = (params) => (dispatch) => {
  return axios.post("/api/billing/plans/add-user", params)
    .then((user) => {
      cacher.clearCache();
      console.log("createUserForTeam user", user);
      dispatch(setUsers([user]));
      return user;
    });
};

export const serUserMailbox = (userId, mailbox) => ({
  type: actions.SET_USER_MAILBOX,
  userId,
  mailbox,
});

export const changePassword = (oldPass, pass, passConfirm) => (dispatch) =>
  axios.put("api/users/change-password", {
    old_password: oldPass,
    password: pass,
    password_confirmation: passConfirm,
  });

export const changeEmail = (email) => (dispatch) =>
  axios.put("api/users/change-email", {
    email,
  });

export const setUsers = (users = []) => ({
  type: actions.SET_USERS,
  users,
});

const cacher = createCachedFunction(() => (dispatch) => {
  return axios.get("/account/team/members")
    .then( (users) => {
      dispatch(setUsers(Object.values(users)));

      return users;
    });
}, { recursively: true });

export const getTeam = cacher.cachedFunction;

const cacherUsers = createCachedFunction((ids) => (dispatch) => {
  if (!ids.length) return Promise.resolve();
  return axios.post("/api/users/list-by-ids", { ids })
    .then(( users ) => {
      const newUsers = {};
      for (const key in users) {
        const newUser = users[key];
        if (!newUser.first_name) newUser.first_name = "";
        if (!newUser.last_name) newUser.last_name = "";
        newUsers[key] = newUser;
      }
      dispatch(setUsers(Object.values(newUsers)));
      return newUsers;
    });
}, { recursively: true });

export const getUsersByUuids = cacherUsers.cachedFunction;


export const getTeamOptions = () => {

  return store.dispatch(getTeams())
    .then((teams) => {
      const options = teams.map((team) => {
        return {
          value: team.id,
          label: `${team.name}`,
          color: team.color || "#425B76",
        };
      });
      return options;
    });
  // .then(members => members
  //   .filter(({id}) => !withoutCurrent || id !== state.auth.id)
  //   .filter(({subscription}) => !onlyActives || subscription && actions.USER_VISIBLE_STATUSES.includes(subscription.paddle_status))
  //   .map(member => ({
  //     value: member.id,
  //     label: `${member.first_name} ${member.last_name}`,
  //     user: member,
  //   }))
  // )
};
export const getTeamUsersOptions = (teamId, withAll = false) => {
  return store.dispatch(getTeamUsers(teamId))
    .then((users) => {
      const options = users.map((user) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name || ""}`,
          avatarUrl: user.image_url || "empty",
        };
      });
      if (withAll) {
        options.unshift({
          value: [],
          label: "All Users",
        });
      }
      return options;
    });
};

export const getUsersAcceptedInvite = (id, params) => {
  return axios.get(`api/teams/users/${id}/get-accepted-invitation`, params).then((invite) => {
    return invite;
  });
};
