import * as actions from "./consts";
import { createCachedFunction } from "../utils";
import { cacher as templatesCacher } from "../templates/actions";
import getAxios from "services/axios";
import store from "store/store";

const axios = getAxios("flowV2");

const categoriesCacher = createCachedFunction((params) => (dispatch) => {
  return axios.get("/api/template-categories", {
    params,
  })
    .then((response) => {
      const { data } = response;
      const uuids = data.map(({ uuid }) => uuid);
      if (!uuids.length) return response;

      return getCategoriesMetrics({ uuids })
        .then((metrics) => {
          const categories = data.map((category) => {
            return {
              ...category,
              metrics: metrics[category.uuid],
            };
          });

          dispatch({
            type: actions.SEED_CATEGORIES,
            categories,
          });

          return response;
        });
    });
}, { recursively: true });


export const getCategories = categoriesCacher.cachedFunction;

export const updateCategories = () => (dispatch) => {
  categoriesCacher.clearCache();
  dispatch(getCategories());
};

export const createCategory = ({ name, uuid }) => (dispatch) => {
  return axios.post("api/template-categories", { name })
    .then( (category) => {
      categoriesCacher.clearCache();
      dispatch({
        type: actions.CREATE_TEMPLATE_CATEGORY,
        category,
      });
      return category;
    });
};

export const editCategory = ({ uuid, name }) => (dispatch) => {
  return axios.put(`api/template-categories/${uuid}`, { name })
    .then( (category) => {
      categoriesCacher.clearCache();
      dispatch({
        type: actions.UPDATE_TEMPLATE_CATEGORY,
        category,
      });
    });
};

export const deleteCategory = (categoryId) => (dispatch) => {
  templatesCacher.clearCache();

  return axios.delete(`api/template-categories/${categoryId}`)
    .then(() => {
      categoriesCacher.clearCache();
      dispatch({
        type: actions.DELETE_TEMPLATE_CATEGORY,
        categoryId,
      });
    });
};

export const getCategoriesMetrics = ({ uuids }) => {
  return axios.post("/api/template-categories/metrics", {
    uuids,
  });
};

export const getCategoriesOptions = (query) => store.dispatch(getCategories())
  .then(({ data }) => data.reduce((res, category) => {
    if (RegExp(query, "i").test(category.name) || typeof query !== "string") {
      res.push({ value: category.uuid, label: category.name });
    }
    return res;
  }, []));

export const getCategoriesOptionsWithAll = (query) => {
  const result = [];
  store.dispatch(getCategories())
    .then(({ data }) => data.reduce((res, category) => {
      if (RegExp(query, "i").test(category.name) || typeof query !== "string") {
        result.push({ value: category.uuid, label: category.name });
      }
    }, []));
  result.push({ value: null, label: "All Folders" });
  return result;
};

