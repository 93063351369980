import { createBoundedUseStore, createStore } from "store/zustand/createStore";
import { featureFlag } from "services/featureFlag";

const THEMES = ["dark", "light"] as const;

type ThemeState = {
  theme: typeof THEMES[number]
  isSystem: boolean
  colorPrimary: string // TODO: remove
}

export class ThemeService {
  public readonly store;
  public readonly useStore;
  private readonly storageKey = "gs-theme";
  private cleanUpCbs: Array<() => void> = [];

  constructor() {
    this.store = createStore<ThemeState>(
      "@feature/theme",
      (set, get) => this.getInitialState(),
    );

    this.useStore = createBoundedUseStore(this.store);
    this.cleanUpCbs.push(this.listenSystemTheme());
  }

  public cleanUp() {
    this.cleanUpCbs.forEach((cb) => cb());
  }

  public setTheme(theme: ThemeState["theme"]) {
    this.store.setState({ theme });
  }

  private listenSystemTheme(): () => void {
    const mediaQueryList = this.getMediaQueryList();
    const cb = (event: MediaQueryListEvent) => {
      if (!this.store.getState().isSystem) return;

      this.store.setState({ theme: event.matches ? "dark" : "light" });
    };

    mediaQueryList.addEventListener("change", cb);
    return () => mediaQueryList.removeEventListener("change", cb);
  };

  private getSystemTheme(): ThemeState["theme"] {
    if (this.getMediaQueryList().matches) {
      return "dark";
    } else {
      return "light";
    }
  }

  private getMediaQueryList() {
    return window.matchMedia("(prefers-color-scheme: dark)");
  }

  private getStateForSystemTheme(): ThemeState {
    return {
      theme: this.getSystemTheme(),
      isSystem: true,
      colorPrimary: this.getPrimaryColor(),
    };
  }

  private isThemeState(theme: string): theme is ThemeState["theme"] {
    return THEMES.includes(theme as any);
  }

  private getPrimaryColor() {
    return "#4B64FF";
  }

  private getInitialState(): ThemeState {
    if (!featureFlag.darkTheme) {
      return {
        theme: "light",
        isSystem: false,
        colorPrimary: this.getPrimaryColor(),
      };
    }
    const theme = localStorage.getItem(this.storageKey);
    if (!theme) return this.getStateForSystemTheme();
    if (this.isThemeState(theme)) {
      return {
        theme,
        isSystem: false,
        colorPrimary: this.getPrimaryColor(),
      };
    }

    return this.getStateForSystemTheme();
  }
}


export const themeService = new ThemeService();
