const SEED_PROFILES = "SEED_PROFILES";
const DELETE_SENDER_PROFILE = "DELETE_SENDER_PROFILE";
const SEED_PROFILE_COUNTERS = "SEED_PROFILE_COUNTERS";
const SET_PROFILE_COUNTERS_LOADING = "SET_PROFILE_COUNTERS_LOADING";
const SEED_PROFILES_SERVERS = "SEED_PROFILE_SERVERS";

export {
  SEED_PROFILES,
  DELETE_SENDER_PROFILE,
  SEED_PROFILE_COUNTERS,
  SET_PROFILE_COUNTERS_LOADING,
  SEED_PROFILES_SERVERS,
};
