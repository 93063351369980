import type { AnchorHTMLAttributes } from "react";

export const ExternalLink = (props: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {to: string}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      referrerPolicy="no-referrer"
      href={props.to}
      {...props}
    />
  );
};
