import * as actions from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  [actions.SET_USERS]: (state, { users }) => {
    return entitySetter(state, users);
  },

  [actions.SET_USER_MAILBOX]: (state, { userId, mailbox }) => {
    const s = { ...state };
    s[userId] = {
      ...s[userId],
      mailbox,
    };

    return s;
  },
  [actions.SET_USER_SERVER]: (state, { userId, server }) => {
    const s = { ...state };
    s[userId] = {
      ...s[userId],
      server,
    };

    return s;
  },
};

const initialState = {};

export default function usersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
