import { getBaseAxios } from "services/axios";
import { userStore } from "./userStore";
import { createUserApi } from "@gs/core/domain/User/createUserApi";
import type { UserId, User } from "@gs/core/domain/User/User";

export const userApi = (() => {
  const leadsAxios = getBaseAxios("idV2");

  const coreUserApi = createUserApi(leadsAxios);

  const getUsers: typeof coreUserApi.getUsers = async (...args) => {
    const result = await coreUserApi.getUsers(...args);

    userStore.setState(
      result.data.data.reduce<Record<UserId, User>>((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {}),
    );

    return result;
  };

  return {
    getUsers,
  };
})();
