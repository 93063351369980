import { combineReducers } from "redux";
import proxy from "./proxy";
import entities from "./entities";
import whiteLabelReducer from "./whiteLabel/reducer";
import globalReducer from "./global";
import history from "services/browserHistory";

export const makeRootReducer = (asyncReducers, history) => {
  const appReducer = combineReducers({
    // router: connectRouter(history),
    global: globalReducer,
    proxy,
    entities,
    whiteLabel: whiteLabelReducer,
    ...asyncReducers,
  });

  return (state, action) => {
    if (action.type === "LOGOUT_SUCCESS") {
      // state = undefined;

      // dont clear whiteLabel on logout
      state = {
        whiteLabel: state.whiteLabel,
      };
    }

    return appReducer(state, action);
  };
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers, history));
};

export default makeRootReducer;
