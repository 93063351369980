import * as actions from "./consts";

import getAxios from "services/axios";

const axios = getAxios("integrations");

export const exportToCrm = (filter, slug) => (dispatch) => {
  return axios.post(`/api/integrations/${slug}/exports`, { filter });
};
export const getMapping = (slug) => (dispatch) => {
  return axios.get(`/api/integrations/${slug}/mappings`);
};

export const setIntegrations = (integrations) => {
  if (!(integrations instanceof Array)) {
    integrations = [integrations];
  }
  return {
    type: actions.INTEGRATIONS_SET,
    integrations,
  };
};

export const loadIntegrationsConfig = () => (dispatch) => {
  return axios.get("/config");
};

export const getIntegrationErrors = (integrationId) => {
  return axios.get(`api/integrations/get-errors/${integrationId}`);
};

export const purgeIntegrationErrors = (integrationId) => {
  return axios.put(`api/integrations/purge-errors/${integrationId}`);
};

export const getAllErrors = (integrations) => async (dispatch) => {
  const errors = await Promise.all(integrations
    .map(async ({ id }) => await getIntegrationErrors(id)));

  const updatedIntegrations = integrations.map((integration, index) => ({
    ...integration,
    errors: errors[index],
  }));

  dispatch(setIntegrations(updatedIntegrations));
};

export const loadIntegrations = (params, cancelToken) => (dispatch, getState) => {
  return axios.get("/api/integrations", {
    params,
    cancelToken,
  })
    .then((res) => {
      dispatch(getAllErrors(res.data));
      return res;
    });
};

export const getAuthUrl = (slug) => {
  return axios.get(`/api/${slug}/auth/auth-url`);
};

export const getProperties = (slug) => (dispatch) => {
  return axios.get(`/api/integrations/${slug}/properties`);
};

export const deleteIntegration = (slug) => (dispatch, getState) => {
  return axios.get(`/api/${slug}/auth/revoke`)
    .then( (res) => {
      dispatch({
        type: actions.INTEGRATIONS_DELETE_SUCCESS,
        slug,
      });

      return res;
    });
};

export const toggleIntegration = (slug) => (dispatch, getState) => {
  dispatch({
    type: actions.INTEGRATION_TOGGLE,
    slug,
  });
  return axios.put(`/api/integrations/${slug}/toggle-active`)
    .catch((err) => {
      dispatch({
        type: actions.INTEGRATION_TOGGLE,
        slug,
      });

      throw err;
    });
};

export const updateIntegrationMapping = (slug, columns) => async (dispatch) => {
  const mapping = await axios.put(`/api/integrations/${slug}/mappings`, { data: { columns }, name: "default" });

  dispatch({
    type: actions.INTEGRATION_SET_MAPPING,
    slug,
    mapping,
  });

  return mapping;
};
