export default "/settings";

export const settingsPaths = {
  leadPipelineStages: "/settings/pipeline-stages",
  companyPipelineStages: "/settings/pipeline-stages-company",
  leadCustomFields: "/settings/custom-fields/lead",
  companyCustomFields: "/settings/custom-fields/company",
  senderProfilesCustomFields: "/settings/custom-fields/sender_profile",
  teamMembers: "/settings/team",
  webhooks: "/settings/webhooks",
};
