import { Switch, Route } from "react-router-dom";
import DetailsRoute from "./Flow/Details";
import ListRoute from "./Flow/List";
import NotFoundRoute from "components/routes/NotFound";
import PublicFlow from "./Flow/PublicFlow";
import { flowsPath, paths, statusFilters } from "./const";

export default () => {
  return (
    <Switch>
      <Route
        exact
        path={paths.automation}
      >
        <ListRoute topFilter={statusFilters.automation} />
      </Route>
      <Route
        exact
        path={paths.archive}
      >
        <ListRoute topFilter={statusFilters.archive} />
      </Route>
      <Route
        exact
        path={paths.templates}
        component={ListRoute}
      />
      <Route
        exact
        path={paths.publicFlow}
        component={PublicFlow}
      />
      <Route
        exact
        path={paths.flowDetails}
        component={DetailsRoute}
      />
      <Route component={NotFoundRoute} />
    </Switch>
  );
};


export { flowsPath as path } from "./const";
