import * as consts from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  // [consts.CONVERSATIONS_SET]: (state, { conversations }) => {
  //   let s = {...state};
  //
  //   s = entitySetter(s, conversations);
  //
  //   return s;
  // },
};

const initialState = {
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
