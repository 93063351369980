export const FDP_PARSE = "FDP_PARSE";
export const FDP_TO_END = "FDP_TO_END";
export const FDP_SWITCH_TYPE = "FDP_SWITCH_TYPE";
export const FDP_FOCUS_NODE = "FDP_FOCUS_NODE";
export const FDP_SET_TEMP_TYPE = "FDP_SET_TEMP_TYPE";
export const FDP_FORCE_UPDATE = "FDP_FORCE_UPDATE";
export const FDP_SET_MODEL = "FDP_SET_MODEL";
export const FDP_SET_IS_MODIFIED = "FDP_SET_IS_MODIFIED";
export const FDP_VERSION_SAVE_SUCCESS = "FDP_VERSION_SAVE_SUCCESS";
export const FDP_SET_TEMP_NODES_DATA = "FDP_SET_TEMP_NODES_DATA";

export const FDP_SET_ACTIVE_MODAL = "FDP_SET_ACTIVE_MODAL";

export const FDP_SET_STEP = "FDP_SET_STEP";

export const FDP_LEAVE = "FDP_LEAVE";

export const FDP_CHANGE_MODE = "FDP_CHANGE_MODE";

export const FDP_UPDATE_ALL_STEPS = "FDP_UPDATE_ALL_STEPS";
export const FDP_NODE_ERRORS = "FDP_NODE_ERRORS";
export const FDP_UPDATE_FOCUSED_ID = "FDP_UPDATE_FOCUSED_ID";

export const modalTypes = {
  SOURCE_EDIT: "SOURCE_EDIT",
  SOURCE_FILTER: "SOURCE_FILTER",
  SOURCE_SENDER_PROFILES: "SOURCE_SENDER_PROFILES",
  NODE_FILTER: "NODE_FILTER",
};

export const FDP_MODE_TYPES = {
  VIEW: "View",
  EDIT: "Edit",
};
