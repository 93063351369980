import cn from "classnames";
import type { ICheckBoxProps } from "./interfaces";
import styles from "../Styles.module.scss";
import Icon from "../../elements/Icon";

const CheckBox = (props: ICheckBoxProps) => {
  const {
    inputClassName = styles.checkboxInput,
    big = false,
    triple = false,
    any = false,
    all = false,
    className = "",
    ...other
  } = props;

  return (
    <label
      className={`${styles.checkboxLabel} ${className}`}
      role='button'
    >
      { !triple && (
        // @ts-ignore
        <input
          type="checkbox"
          className={`${inputClassName}`}
          {...other}
        />
      )}
      {/* @ts-ignore */}
      <i
        className={cn(styles.checkboxButton, {
          [styles.checkboxButtonBig!]: big,
          [styles.checkboxButtonTriple!]: triple,
          [styles.checkboxButtonTripleAny!]: any,
          [styles.checkboxButtonTripleAll!]: all,
        })}
        {...other}
      >
        <Icon
          icon="checked"
          size={11}
        />
      </i>
    </label>
  );
};

CheckBox.propTypes = {
};

export {
  CheckBox as default,
  CheckBox,
};
