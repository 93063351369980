import * as actions from "./consts";
import { FLOW_DELETE_SUCCESS, FLOW_CREATE_SUCCESS } from "store/entities/flows/consts";
import pull from "lodash/pull";
import { ACTIVE_FLOWS_URL_FILTER } from "store/entities/flows/types";

const ACTION_HANDLERS = {
  [actions.FLP_LOAD_REQUEST]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [actions.FLP_LOAD_SUCCESS]: (state, { ids, total, hasMore }) => {
    const newIds = Array.from(new Set([...state.ids, ...ids]));

    const s = {
      ...state,
      isLoading: false,
      ids: newIds,
      total,
      hasMore,
    };

    return s;
  },
  [actions.FLP_P_CLEAR_IDS]: (state) => {
    const s = {
      ...state,
      ids: [],
    };

    return s;
  },
  [actions.FLP_LOAD_FAILED]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [actions.FLP_P_SET_FILTERS]: (state, { filters }) => {
    const newFilters = {
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    };
    // if (newFilters.filters.flow_workspace_uuid === null) {
    //   delete newFilters.filters.flow_workspace_uuid
    // }
    return newFilters;
  },
  [actions.FLP_P_DROP_SEARCH]: (state) => ({
    ...state,
    ids: [],
    hasMore: true,
  }),
  [actions.FLP_LEAVE]: (state) => {
    return initialState;
  },
  [actions.FLP_ADD_FLOW]: (state, { id }) => ({
    ...state,
    ids: [id, ...state.ids],
  }),
  [FLOW_DELETE_SUCCESS]: (state, { flowId }) => ({
    ...state,
    ids: pull([...state.ids], flowId),
  }),
  [FLOW_CREATE_SUCCESS]: (state, { flow }) => ({
    ...state,
    ids: [flow.uuid, ...state.ids],
  }),
  [actions.FLP_DELETE_FROM_LIST]: (state, { uuid }) => ({
    ...state,
    ids: state.ids.filter((flowUuid) => flowUuid !== uuid),
  }),

};

const initialState = {
  ids: [],
  hasMore: true,
  isLoading: false,
  filters: ACTIVE_FLOWS_URL_FILTER,
};

export default function flowReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export const key = "flowList";
