import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const WebhooksPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'webhooksPage' */ "./WebhooksPage")
      .catch( (e) => console.log("Page loading err", e) || Error404 );
  },
  loading: Loading,
});

export default WebhooksPage;
