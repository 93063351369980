import find from "lodash/find";
import getAllNodesIdsOnLine from "./getAllNodesIdsOnLine";
import setNodesWidth from "./setNodesWidth";

export const clearX = (model) => {
  for (const uiNode of model.nodes) {
    delete uiNode.x;
    delete uiNode.minX;
    delete uiNode.maxX;
    delete uiNode.innerWidth;
    delete uiNode.outterLeftWidth;
    delete uiNode.outterRightWidth;
  }
};

const setNodesX = (model) => {
  setNodesWidth(model);
  const firstUiNode = model.nodes.filter((node) => !node.before.length)[0];
  firstUiNode.x = 0;
  model.minX = 0;
  model.maxX = 0;


  setAftersXRecursively(model, firstUiNode);

  return model;
};

const setX = (model, node, x) => {
  node.x = x;
  model.minX = Math.min(model.minX, x);
  model.maxX = Math.max(model.maxX, x);
};

function setAftersXRecursively(model, uiNode) {
  const afterUiNodes = uiNode.after.map((id) => find(model.nodes, { id }));

  let isFirstBranch = true;
  let currentOffset = uiNode.x - uiNode.innerWidth / 2;

  for (const afterUiNode of afterUiNodes) {
    if (afterUiNode.root) {
      const rootUiNode = find(model.nodes, { id: afterUiNode.root });
      setX(model, afterUiNode, rootUiNode.x);
    } else {
      if (!isFirstBranch) {
        currentOffset += Math.max(
          ...getAllNodesIdsOnLine(model, afterUiNode.id)
            .map((id) => find(model.nodes, { id }))
            .map((uiNode) => uiNode.outterLeftWidth),
        );
      }
      isFirstBranch = false;

      setX(model, afterUiNode, currentOffset);

      currentOffset += Math.max(
        ...getAllNodesIdsOnLine(model, afterUiNode.id)
          .map((id) => find(model.nodes, { id }))
          .map((uiNode) => uiNode.outterRightWidth),
      );
    }

    setAftersXRecursively(model, afterUiNode);
  }
}


export default setNodesX;
