import getAxios from "services/axios";
import { sessionService } from "feature/session/SessionService";
import { wait } from "@gs/core/utils/wait";
import type { IsoDate, TimeoutId } from "@gs/core/commonTypes";
import type { Lead } from "@gs/core/domain/Lead/Lead";
import type { LeadMarkers } from "@gs/core/domain/LeadMarkers/LeadMarkers";
import type { LinkedinMessage } from "@gs/core/domain/LinkedinMessage/LinkedinMessage";
import type { Activity } from "@gs/core/domain/Activity/Activity";
import { linkedinMessageStore } from "domain/linkedinMessage/linkedinMessageStore";

const axios = getAxios("flowV2");

const waitUser = async () => {
  let { teamId } = sessionService.store.getState();

  while (!teamId) {
    await wait(2000);
    teamId = sessionService.store.getState().teamId;
  }
};

export type DmServerEvent = {
  lead_aggregation: {
    lead: Lead
    markers: LeadMarkers
  }
  message: LinkedinMessage
  type: "dm_event"
}

export type ActivityServerEvent = {
  lead_aggregation: {
    lead: Lead
    markers: LeadMarkers
  }
  activity: Activity
  type: "activity_event"
}

type SEvent =
  | DmServerEvent
  | ActivityServerEvent

export const isActivitySEvent = (event: SEvent): event is ActivityServerEvent => {
  return event.type === "activity_event";
};

export class SSE {
  public static eventTypes = {
    MESSAGE: "dm_event",
    ACTIVITY: "activity_event",
  } as const;
  private isActive: boolean;
  private errorsCount: number;
  private delayLoopTimeoutId?: TimeoutId;
  private cbs: Array<(events: SEvent[]) => void>;

  constructor() {
    this.isActive = false;
    this.loop = this.loop.bind(this);
    this.request = this.request.bind(this);
    this.cbs = [];
    this.errorsCount = 0;

    this.loop();
  }

  private delayLoop(last_event_at: IsoDate | undefined, delay = 5_000) {
    this.isActive = true;

    this.delayLoopTimeoutId = setTimeout(() => {
      this.loop(last_event_at);
    }, delay);
  }
  private request(last_event_at: IsoDate | undefined) {
    return waitUser().then(() => {
      return axios.get("/api/events/stream", { params: { last_event_at } })
        .then(({ events, last_event_at }: {events: SEvent[]; last_event_at: IsoDate}) => {
          if (events && events.length) {
            this.handleEvents(events);
          }
          return last_event_at;
        });
    });
  }
  private loop(last_event_at?: IsoDate): Promise<void> {
    this.isActive = true;

    return this.request(last_event_at)
      .then((last_event_at) => {
        this.errorsCount = 0;
        return this.loop(last_event_at);
      })
      .catch((err) => {
        console.log("stream error", err);
        if (err === "Unauthorized.") return this.stop();
        if (err && [401, 402].includes(err.status)) return this.stop();
        if (this.errorsCount > 2) {
          return this.stop();
        }
        this.errorsCount++;
        this.delayLoop(last_event_at);
      });
  }

  public listen(cb: (events: SEvent[]) => void) {
    this.cbs.push(cb);
    return () => {
      const index = this.cbs.indexOf(cb);

      this.cbs.splice(index, 1);
    };
  }
  private handleEvents(events: SEvent[]) {
    for (const event of events) {
      if (event.type === "dm_event") {
        linkedinMessageStore.setState({
          [event.message.uuid]: event.message,
        });
      }
    }
    for (const cb of this.cbs) {
      try {
        cb(events);
      } catch (error) {
        console.log("Error in SSE handler", error);
      }
    }
  }

  private stop() {
    clearTimeout(this.delayLoopTimeoutId);
    this.isActive = false;
  }
}

// SSE.eventTypes = {
//   MESSAGE: "dm_event",
//   ACTIVITY: "activity_event",
// };

const sse = new SSE();


export default sse;
