import { paths } from "../../const";
import type { ISortedTemplateItem } from "./interfaces";
import avatarAuthor from "assets/author.jpeg";
import GrowYourNetwork from "./JsonTemplates/GrowYourNetwork.json";
import AttractYourLeads from "./JsonTemplates/AttractYourLeads.json";
import GetLeadsInterviews from "./JsonTemplates/GetLeadsInterviews.json";
import GetLeadsRewardInterviews from "./JsonTemplates/GetLeadsRewardInterviews.json";
import ResearchSaasProductMarket from "./JsonTemplates/ResearchSaasProductMarket.json";
import PromoteNewProductOrService from "./JsonTemplates/PromoteNewProductOrService.json";
import GetSalesSaaS from "./JsonTemplates/GetSalesSaaS.json";
import GetSalesServices1 from "./JsonTemplates/GetSalesServices1.json";
import GetSalesServices2 from "./JsonTemplates/GetSalesServices2.json";
import GetSalesOutsourceOutstaff from "./JsonTemplates/GetSalesOutsourceOutstaff.json";
import EstablishPotential from "./JsonTemplates/EstablishPotentialInvestorsRelationships.json";
import ReEngageInactiveLeads from "./JsonTemplates/ReEngageInactiveLeads.json";

export const flowTemplatesPath = `${paths.automation}/templates`;


const importAll = (r: __WebpackModuleApi.RequireContext) => {
  const images: { [key: string]: any } = {};
  r.keys().forEach((fileName: string) => {
    images[fileName.replace("./", "")] = r(fileName);
  });

  return images;
};


const flow_previews: { [key: string]: any } = importAll(require.context("../../../../../assets/flow_templates", false));

export const getLinkTo = (uuid: string) => {
  return `${flowTemplatesPath}/${uuid}/public`;
};

const authorDescription = "Digital Marketing and Lead Generation expert whose strategy development and team leadership have created countless successful International B2B campaigns. elevating brand awareness and adoption.";

export const templateCategories = [
  {
    name: "BRAND AWARENESS",
    templatesList: [
      {
        label: "Grow your network",
        preview: flow_previews["templates-4341272_120578.svg"],
        uuid: "7ab42cb9-7280-4264-adf5-a6f431b836cf",
        borderColor: "#FF7A59",
        jsonTemplate: GrowYourNetwork,
        aboutTemplate: {
          description: `
          <p>
            Discover the power of Automation and take your LinkedIn networking game to the next level! If you haven't used our Automation to expand your network of business contacts before, this is the perfect opportunity to get started.
          </p>
          <p>
            With Automation, you can effortlessly send connection requests to leads and even create additional engagement (such as page visits, comments, endorsements and post likes) while you wait for your applications to be approved. This boosts your chances of getting approved, resulting in a higher conversion rate.
          </p>
          <p>
            Plus, with Automation, you don't have to worry about keeping track of unapproved requests - any unapproved requests will be automatically withdrawn after 14 days. Don't miss out on this game-changing tool - give Automation a try and see your LinkedIn network grow!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Attract your leads",
        preview: flow_previews["templates-4341271_120582.svg"],
        uuid: "4883e1b9-4952-4351-86ae-2b66fdf0d1cb",
        borderColor: "#FF7A59",
        jsonTemplate: AttractYourLeads,
        aboutTemplate: {
          description: `
          <p>
          Want to attract more leads to your LinkedIn page? Look no further than the power of Automation!
          </p>
          <p>
          By automating social activity on your lead pages, you can draw attention to your profile and the valuable content within it.
          </p>
          <p>
          With our cutting-edge Automation, you can effortlessly create three types of automatic activities for every lead you send our way - including page visits, likes on latest posts, endorsements and even comments on most recent content.
          </p>
          <p>
          And with our smart, human-like delays between each action, your profile will look like it's being manually managed with care and attention.
          </p>
          <p>
          Say goodbye to the headache of manually managing your social activity - try our Automation today and watch as the leads come pouring in!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
    ],
  },
  {
    name: "CUSTOMER INTERVIEW",
    templatesList: [
      {
        label: "Get lead's interviews",
        preview: flow_previews["templates-4341274_120573.svg"],
        uuid: "659ab38b-068b-44d2-89b6-bed5c5e41357",
        borderColor: "#FF7A59",
        jsonTemplate: GetLeadsInterviews,
        aboutTemplate: {
          description: `
          <p>
          When it comes to launching a new product or service, it's crucial to test your hypotheses about who your ideal customer is and what problem they're willing to pay to solve. And with our cutting-edge automation Automation, testing those hypotheses has never been easier!
          </p>
          <p>
          Our platform automates your invitations for interviews by sending connection requests to leads and following up with personalized interview invitations. No more worrying about manually managing these important outreach efforts - our Automation does it all for you.
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Get lead's reward interviews",
        preview: flow_previews["templates-4341275_120581.svg"],
        uuid: "37229836-a255-4929-bb77-9515ddf15abc",
        borderColor: "#FF7A59",
        jsonTemplate: GetLeadsRewardInterviews,
        aboutTemplate: {
          description: `
          <p>
          Launching a new product or service is exciting, but it's crucial to test your hypotheses about your target customer and their pain points before you hit the market. And with our powerful Automation, you can do just that - and even incentivize respondents to participate in your interviews!
          </p>
          <p>
          Our platform automates your lead's invitations to paid interviews, sending connection requests to leads and following up with personalized messages inviting them to participate in paid interviews.
          </p>
          <p>
          By offering to pay respondents for their time, you'll speed up the interview receipt process and get the insights you need to fine-tune your product or service. With our Automation, you can easily manage all of your outreach efforts and scale up your interview requests to reach more leads than ever before.
          </p>
          <p>
          Don't leave the success of your product or service to chance - try our Automation today and start getting the insights you need to succeed!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Research SaaS Product Market",
        preview: flow_previews["templates-4341277_120577.svg"],
        uuid: "d8a05e35-da54-4079-a0bc-f267561060df",
        jsonTemplate: ResearchSaasProductMarket,
        borderColor: "#FF7A59",
        aboutTemplate: {
          description: `
          <p>
          Expanding your business to new markets can be a daunting task, especially when you're unsure about the market specifics and how valuable your offer will be. But with our powerful Automation, you can easily reach out to market experts and get the insights you need to succeed!
          </p>
          <p>
          Our platform automates your outreach efforts by sending connection requests to leads and following up with personalized messages asking for expert opinions about your product or service.
          </p>
          <p>
          With our Automation, you can easily manage your outreach efforts and scale up your requests to reach more experts than ever before.
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
    ],
  },
  {
    name: "SALES",
    templatesList: [
      {
        label: "Promote a new product or service",
        preview: flow_previews["templates-4341270_120574.svg"],
        uuid: "38f159d3-58fa-4a68-95dc-4468c3cb3f8e",
        borderColor: "#FF7A59",
        jsonTemplate: PromoteNewProductOrService,
        aboutTemplate: {
          description: `
          <p>
          Generating buzz around a new product launch can be a challenge, but with our Automation, it's never been easier!
          </p>
          <p>
          Our Platform automates your outreach efforts by sending connection requests and personalized messages to your target audience on LinkedIn. You can use these messages to highlight the benefits and features of your new product or even promote an existing one.
          </p>
          <p>
          And don't worry - our Automation is smart enough to switch any respondent to manual mode, so you can focus on building genuine relationships with those who show interest in your product.
          </p>
          <p>
          By automating your outreach efforts, you can scale up your marketing efforts and reach more potential customers than ever before.
          </p>
          <p>
          Don't miss out on the opportunity to generate buzz around your product - try our Automation today and watch your launch take off!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Get Sales SaaS",
        preview: flow_previews["templates-4341278_120575.svg"],
        uuid: "eeb4d8ac-1c54-4994-905b-b706c1a9ffe6",
        borderColor: "#FF7A59",
        jsonTemplate: GetSalesSaaS,
        aboutTemplate: {
          description: `
          <p>
          Looking to promote your SaaS solution on LinkedIn and beyond? Look no further than this Automation!
          </p>
          <p>
          With our easy-to-use message template, you can describe the benefits and use cases of your solution to potential clients and let our Automation do the rest.
          </p>
          <p>
          Our platform automates your outreach efforts by sending connection requests and personalized messages with your value proposition to leads. And with the ability to switch any respondent to manual mode, you can focus on building real relationships with those who show interest in your solution.
          </p>
          <p>
          By automating your outreach efforts, you can save time and energy, all the while reaching more potential clients than ever before. So why wait? Try our Automation today and start promoting your SaaS solution with confidence!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Get Sales Services 1",
        preview: flow_previews["templates-4341275_120581.svg"],
        uuid: "6d65c6d4-91d9-4912-8a5c-c4b251fd5867",
        borderColor: "#FF7A59",
        jsonTemplate: GetSalesServices1,
        aboutTemplate: {
          description: `
          <p>
          Looking to make your LinkedIn newsletters and marketing efforts more efficient? Say hello to the ultimate solution - our multifunctional Automation!
          </p>
          <p>
          With this tool, you can automate the process and sit back as it sends out connection requests and personalized messages highlighting the services you offer. But that's not all - you'll also be able to showcase the benefits your clients can expect from working with you! And the best part? You'll have full control over the conversations, as anyone who responds will be switched to manual mode.
          </p>
          <p>
          So why wait? Give your LinkedIn marketing the boost it deserves with our Automation!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Get Sales Services 2",
        preview: flow_previews["templates-4341275_120581.svg"],
        uuid: "b3221ab9-d156-4d19-8aba-6fa2c98041d5",
        borderColor: "#FF7A59",
        jsonTemplate: GetSalesServices2,
        aboutTemplate: {
          description: `
          <p>
          Maximize the efficiency of your LinkedIn newsletters and marketing campaigns with our powerful Automation tool.
          </p>
          <p>
          To get started, simply fill out our message templates with details about your services and the benefits that come with collaborating with you.Use variables for maximum customization. From there, our Automation will take over and send out personalized connection requests and messages highlighting your unique value proposition.
          </p>
          <p>
          And don't worry about losing control - anyone who responds to your messages will be switched to manual mode, giving you the power to steer the conversation in the right direction. So why not give our Automation a try and streamline your LinkedIn marketing efforts today?
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
      {
        label: "Get Sales Outsource / Outstaff",
        preview: flow_previews["templates-4341277_120577.svg"],
        uuid: "9f2ccec5-9c76-41d5-8bdc-ece6685de07b",
        borderColor: "#FF7A59",
        jsonTemplate: GetSalesOutsourceOutstaff,
        aboutTemplate: {
          description: `
          <p>
          Automate your LinkedIn newsletters and service promotions on Outsource or Outstaff with ease!
          </p>
          <p>
          Describe your services and their benefits in our message templates, and let our Automation handle the rest. It will send connection requests and messages with your value proposition, and anyone who responds will be switched to manual mode so that you can take charge.
          </p>
          <p>
          Get started today and see how our automation can supercharge your LinkedIn marketing efforts!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
    ],
  },
  {
    name: "INVESTMENT",
    templatesList: [
      {
        label: "Establish potential investors relationships",
        preview: flow_previews["templates-4341275_120581.svg"],
        uuid: "a4350e2e-b6b3-4201-9a2d-af8487020a56",
        borderColor: "#FF7A59",
        jsonTemplate: EstablishPotential,
        aboutTemplate: {
          description: `
          <p>
          Attract potential investors for your solutions with ease using our automated tool!
          </p>
          <p>
          Our solution sends connection requests and messages showcasing your offer, while allowing you to retain control over the conversation.
          </p>
          <p>
          Try it out today and take your business to new heights!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
    ],
  },
  {
    name: "RE-ENGAGE",
    templatesList: [
      {
        label: "Re-engage inactive leads",
        preview: flow_previews["templates-4341269_120580.svg"],
        uuid: "bc70c057-8c98-4931-97b4-dc8a38e7b532",
        borderColor: "#FF7A59",
        jsonTemplate: ReEngageInactiveLeads,
        aboutTemplate: {
          description: `
          <p>
          Re-engage with inactive leads using our powerful Re-engagement Automation!
          </p>
          <p>
          Send a regular newsletter with product or service updates, deals, or even your brand story to create an emotional connection - after all,we all like things we relate to.
          </p>
          <p>
          With our Automation, you can turn these leads into loyal customers once again!
          </p>
          `,
          author: {
            name: "Victor Dol",
            position: "B2B Lead Generation & Conversion Expert",
            avatar: avatarAuthor,
            description: authorDescription,
          },
        },
      },
    ],
  },
];

export const sortedTemplates = templateCategories.reduce((acc: ISortedTemplateItem[], item) => {
  acc.push(...item.templatesList);
  return acc;
}, []);

export const defaultTemplateItems = [...sortedTemplates.slice(0, 6), {
  label: "More Templates",
  icon: "ellipsis",
  uuid: sortedTemplates[0]!.uuid,
}];
