import { LN_ACCOUNT_ACTIONS } from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  [LN_ACCOUNT_ACTIONS.LN_ACCOUNT_SET]: (state, { linkedinAccounts }) => entitySetter(state, linkedinAccounts),
  // [LN_ACCOUNT_ACTIONS.LN_ACCOUNT_DELETE]: (state, { id }) => {
  //   const s = { ...state };
  //   delete s[id];
  //   return s;
  // },
};

const initialState = {};

export default function reducer(state = initialState, action ) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
