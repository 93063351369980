import * as consts from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  [consts.TASKS_SET]: (state, { payload: tasks }) => {
    if (!Array.isArray(tasks)) {
      tasks = Object.values(tasks);
    }

    let s = { ...state };

    s = entitySetter(s, tasks);

    return s;
  },
  [consts.TASKS_SET_EXECUTE]: (state, { payload }) => {
    const currentTask = state[payload];

    if (!currentTask) {
      return state;
    }

    currentTask.status = "canceled";

    delete state[payload];

    return {
      ...state,
      // [payload]: currentTask
    };
  },
  [consts.TASKS_SET_UPDATE]: (state, { payload }) => {
    const s = { ...state };
    if (Array.isArray(payload)) {
      payload.forEach((task) => {
        s[task.uuid] = task;
      });
      return s;
    } else {
      return {
        ...state,
        [payload.uuid]: payload,
      };
    }
  },
};

const initialState = {};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
