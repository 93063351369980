import { SERVER_ACTIONS, serverStatuses } from "./consts";
import getAxios from "services/axios";
import { getSenderProfile } from "store/entities/senderProfiles/actions";

const axios = getAxios("flowV2");

export const getServerIsoTime = () => axios.get("/api/linkedin-servers/time-now");

export const takeServer = ({ senderProfileId, login, password, navigator }) => async (dispatch, getState) => {
  const server = await axios.put("/api/linkedin-servers/take", {
    login,
    password,
    session: { navigator },
    sender_profile_uuid: senderProfileId,
  });

  dispatch({
    type: SERVER_ACTIONS.SERVERS_SET,
    servers: [server],
  });

  await dispatch(getSenderProfile(senderProfileId));

  return server;
};

export const getServersByUuid = (uuids) => async (dispatch) => {
  const { data } = await axios.get("/api/linkedin-servers", {
    params: {
      filter: { linkedin_account_uuid: uuids },
      limit: uuids.length,
    },

  });
  dispatch({
    type: SERVER_ACTIONS.SERVERS_SET,
    servers: data,
  });
  return data;
};

export const getServer = ({ id }) => async (dispatch, getState) => {
  const { data } = await axios.get("/api/linkedin-servers", {
    params: {
      filter: { id: [id] },
      limit: 1,
    },
  });
  dispatch({
    type: SERVER_ACTIONS.SERVERS_SET,
    servers: data,
  });
  // const [server] = await dispatch(getServersByUuid([id]));
  // const server = await axios.get(`/api/linkedin-servers/${id}`);
  //
  // dispatch({
  //   type: SERVER_ACTIONS.SERVERS_SET,
  //   servers: [server],
  // });

  return data[0];
};

export const updateServer = (serverId, params) => async (dispatch, getState) => {
  const server = await axios.put(`/api/linkedin-servers/${serverId}`, params);

  // dispatch({
  //   type: SERVER_ACTIONS.SERVERS_SET,
  //   servers: [server],
  // });

  return dispatch(getServer({ id: serverId }));
};

export const submitCredentials = (serverId, { login, password }) => updateServer(serverId, {
  login,
  password,
  status: serverStatuses.LOGINING,
});

export const submitCode = (serverId, code) => updateServer(serverId, {
  ln_code: code,
  status: serverStatuses.NEED_CODE,
});

export const resendCode = (serverId) => updateServer(serverId, {
  ln_code: "",
  status: serverStatuses.PASS_CODE_RESENT,
});
