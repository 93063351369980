import { createSelector } from "reselect";
import { getTaskById } from "./actions";
import store from "store/store";

const sliceSelector = (state) => state.entities.tasks;
const tasksIds = (_state, { tasksIds }) => tasksIds;
const leadIdSelector = (_state, { leadId }) => leadId;
const takeTaskById = (state, { id }) => {
  if (!id) return;

  const task = sliceSelector(state)[id];
  if (!task) {
    store.dispatch(getTaskById(id));
  }

  return task;
};

const tasksByIds = createSelector(
  [sliceSelector, tasksIds],
  (tasks, tasksIdsRes) => tasksIdsRes.map((id) => tasks[id]),
);

const tasksArray = createSelector(
  [sliceSelector],
  (tasks) => Object.values(tasks),
);

const tasksCount = createSelector(
  [tasksArray],
  (tasks) => tasks.length,
);

const tasksByLeadIdArray = createSelector(
  [tasksArray, leadIdSelector],
  (tasks, leadId) => tasks.filter((task) => task.lead_uuid === leadId),
);

export {
  sliceSelector,
  tasksIds,
  tasksByIds,
  tasksArray,
  tasksCount,
  tasksByLeadIdArray,
  takeTaskById,
};
