import { useEffect, useState } from "react";
import { getError } from "services/utils";
import type { createDomainStore } from "./createDomainStore";
import { createDomainBatchFetcher } from "./createDomainBatchFetcher";


type CreateFindOrFetchForDomainArgs<Domain, DomainId extends PropertyKey> = {
  useStore: ReturnType<typeof createDomainStore<Domain, DomainId>>[1]
  fetch: (id: DomainId[]) => unknown
}

// TODO: id shouldn't be optional??
type FindOrFetchForDomainHook<DomainId extends PropertyKey, Domain> = (id?: DomainId | null, options?: {forceFetch?: boolean}) => [Domain | undefined, boolean, string]

export const createFindOrFetchForDomainHook = <Domain, DomainId extends PropertyKey>(args: CreateFindOrFetchForDomainArgs<Domain, DomainId>): FindOrFetchForDomainHook<DomainId, Domain> => {
  const batchFetcher = createDomainBatchFetcher(args.fetch);

  return (id, { forceFetch } = {}) => {
    const entity = args.useStore((s) => id ? s[id] : undefined);
    const [isLoading, setIsLoading] = useState(id ? !entity : false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
      if (entity && !forceFetch) return;
      if (!id) {
        setError("");
        setIsLoading(false);
        return;
      }

      const fetchEntity = async () => {
        setIsLoading(true);
        setError("");
        try {
          await batchFetcher(id);
        } catch (err: unknown) {
          setError(getError(err) || "Unknown Error");
        } finally {
          setIsLoading(false);
        }
      };

      fetchEntity();
    }, [id]);

    return [
      entity,
      isLoading,
      error,
    ];
  };
};

export const createFindOrFetchForDomainHookSingleEntityRequest = <Domain, DomainId extends PropertyKey>(args: {
  useStore: ReturnType<typeof createDomainStore<Domain, DomainId>>[1]
  fetch: (id: DomainId) => unknown
}): FindOrFetchForDomainHook<DomainId, Domain> => {
  return (id) => {
    const entity = args.useStore((s) => id ? s[id] : undefined);
    const [isLoading, setIsLoading] = useState(id ? !entity : false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
      if (entity) return;
      if (!id) {
        setError("");
        setIsLoading(false);
        return;
      }

      const fetchEntity = async () => {
        setIsLoading(true);
        setError("");
        try {
          await args.fetch(id);
        } catch (err: unknown) {
          setError(getError(err) || "Unknown Error");
        } finally {
          setIsLoading(false);
        }
      };

      fetchEntity();
    }, [id]);

    return [
      entity,
      isLoading,
      error,
    ];
  };
};
