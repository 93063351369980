import find from "lodash/find";
import createNode from "../handlers/createNode";
import nodeTypes from "../nodeTypes";

export default (model, targetMultiId, index) => {
  const targetMultiNode = find(model.nodes, { id: targetMultiId });
  if (index == null) {
    index = targetMultiNode.after.length;
  }

  if (targetMultiNode.after.length < 2) {
    throw "Can't add branch to node with single before";
  }

  if (targetMultiNode.union) {
    const createdPlus = createNode(model, {
      type: nodeTypes.PLUS,
      before: [targetMultiNode.id],
      after: [targetMultiNode.union],
    });

    const unionNode = find(model.nodes, { id: targetMultiNode.union });
    unionNode.before.push(createdPlus.id);
    targetMultiNode.after.splice(index, 0, createdPlus.id);
  } else {
    const createdPlus = createNode(model, {
      type: nodeTypes.PLUS,
      before: [targetMultiNode.id],
    });

    const createdEnd = createNode(model, {
      type: nodeTypes.END,
      before: [createdPlus.id],
      after: [],
    });
    createdPlus.after = [createdEnd.id];

    targetMultiNode.after.splice(index, 0, createdPlus.id);
  }
  return model;
};
