import type { ReactNode } from "react";
import React from "react";
import { Alert } from "@gs/uiKit/feedback/Alert";

export const ModalErrorAlert = (props: {
  message: ReactNode
  onClose: () => void
  className?: string
}) => {
  const { onClose, message, className } = props;

  return (
    <Alert
      className={className}
      style={{ marginBottom: "var(--margin)" }}
      showIcon
      message={message}
      type="error"
      closable
      onClose={onClose}
    />
  );
};

