import * as actions from "./consts";
import { entitySetter } from "../utils";


const ACTION_HANDLERS = {
  [actions.SEED_PROMPT_TEMPLATES]: (state, { templates }) => entitySetter(state, templates),
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
