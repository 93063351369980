import React, { PureComponent } from "react";
import cn from "classnames";
import SelectBox from "components/common/inputs/SelectBox";

import styles from "../Styles.module.scss";
import { DarkThemeTooltip } from "components/common/Tooltip/Tooltip";

class SelectRow extends PureComponent {
  componentDidMount() {
    const { value, options, disableAutoFill, onChange, simpleValue } = this.props;

    if (value == null && !disableAutoFill && options && options[0]) {
      let val = this.props.options[0];

      // simpleValue dont work in react-select 2
      // if (simpleValue) val = val.value;

      onChange(val);
    }
  }

  render() {
    const {
      rowClassName,
      label,
      extra,
      error,
      required,
      withoutErrorBLock,
      isDisabled = false,
      disabledTooltipText,
      isSubmitted,
      ...other
    } = this.props;

    const isError = isSubmitted && error;

    return (
      <div className={cn(styles.gsFormRow, { [rowClassName]: rowClassName })}>
        <div className={cn({ [styles.labelRow]: !!label })}>
          { label && (
            <label className={cn({ [styles.required]: required })}>
              { label }
            </label>
          )}
          { extra }
        </div>
        {isDisabled && disabledTooltipText ? (
          <DarkThemeTooltip
            tooltip={<span style={{ maxWidth: "431px", display: "inline-flex", fontSize: "12px" }}>{disabledTooltipText}</span>}
            placement="top"
          >
            <SelectBox
              isDisabled={isDisabled}
              isError={isError}
              {...other}
            />
          </DarkThemeTooltip>
        ) : (
          <SelectBox
            isDisabled={isDisabled}
            {...other}
            isError={isError}
          />
        )}

        { error && (
          <label className="gs-text _error _c-danger gs-input--label__error">
            { error }
          </label>
        )}
      </div>
    );
  }
}

SelectRow.propTypes = {
};

export {
  SelectRow as default,
  SelectRow,
};
