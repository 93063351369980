import * as actions from "./consts";
import { entitySetter } from "../utils";
import { immuteDelete } from "services/immuteActions";

const ACTION_HANDLERS = {
  [actions.CARD_DELETE_SUCCESS]: (state, { id }) => immuteDelete(state, `${id}`),
  [actions.CARDS_GET_SUCCESS]: (state, { cards }) => entitySetter(state, cards),
  [actions.CARD_CREATE_SUCCESS]: (state, { card }) => entitySetter(state, card),
};

const initialState = {};

export default function cardsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
