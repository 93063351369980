import React from "react";
import cn from "classnames";

import Spinner from "components/common/elements/Spinner";

import styles from "../Styles.module.scss";

const Button = (props) => {
  const {
    children,
    reverse,
    mini,
    rounded,
    transparent,
    green,
    white,
    blue,
    micro,
    text,
    active,
    disabled,
    action,
    cancelType,
    darkType,
    indigoType,
    deleteType,
    orange,
    className,
    borderType,
    ...other
  } = props;

  return (
    <button
      className={cn(styles.Button, {
        [className]: className,
        [styles.Reverse]: reverse,
        [styles.Mini]: mini,
        [styles.Green]: green,
        [styles.Blue]: blue,
        [styles.Rounded]: rounded,
        [styles.Micro]: micro,
        [styles.Transparent]: transparent,
        [styles.CancelType]: cancelType,
        [styles.DarkType]: darkType,
        [styles.IndigoType]: indigoType,
        [styles.Orange]: orange,
        [styles.DeleteType]: deleteType,
        [styles.BorderType]: borderType,
        [styles.White]: white,
      })}
      disabled={disabled || active}
      role='button'
      {...other}
    >
      { children || text }
      { active && (
        <Spinner
          color='#00BDA5'
          style={{ borderRadius: "inherit" }}
          size={mini ? 20 : 20}
        />
      )}
    </button>
  );
};

export {
  Button as default,
  Button,
};
