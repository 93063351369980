import * as actions from "./consts";
import { key as templatesPageState } from "./reducer";
import debounce from "lodash/debounce";
import { deleteCategory } from "store/entities/templateCategories/actions";
import { createSource } from "services/axios";
import { getTemplates } from "store/entities/templates/actions";

let loadMoreSource;

const cancelReasons = {
  DROP_SEARCH: "DROP_SEARCH",
  RESET_PAGE: "RESET_PAGE",
};


export const loadMoreTemplates = () => (dispatch, getState) => {
  const templatesState = getState()[templatesPageState];

  if (templatesState.isLoading) {
    return Promise.resolve();
  }

  dispatch({
    type: actions.TEMPLATES_P_LOAD_REQUEST,
  });

  loadMoreSource = createSource();

  const params = {
    filter: templatesState.filters,
    offset: templatesState.templateIds.length,
    limit: 10,
  };

  return dispatch(getTemplates(params, loadMoreSource.token))
    .then((res) => {
      const { data, total, has_more } = res;
      const templateIds = data.map((el) => el.uuid);

      dispatch({
        type: actions.TEMPLATES_P_LOAD_SUCCESS,
        hasMore: has_more,
        templateIds,
        total,
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.TEMPLATES_P_LOAD_FAILURE,
      });
      console.log("err", err);

      throw err;
    });

};

export const deleteTemplateCategory = (uuid) => (dispatch, getState) => {
  const templatesEntities = getState()[templatesPageState];

  return dispatch(deleteCategory(uuid))
    .then(() => {
      const filteredIds = templatesEntities?.templateIds
        .filter((templateId) => templateId !== uuid);

      dispatch({
        type: actions.TEMPLATES_P_DELETE_CATEGORY,
        templateIds: filteredIds,
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.TEMPLATES_P_LOAD_FAILURE,
      });
      console.log("err", err);

      throw err;
    });
};

export const setFilters = (filters, needDebounce) => (dispatch) => {
  dispatch({
    type: actions.TEMPLATES_P_SET_FILTERS,
    filters,
  });

  if (needDebounce) {
    debouncedDropSearch(dispatch);
  } else {
    dispatch(dropSearch());
  }
};

export const leavePage = () => {
  if (loadMoreSource) {
    loadMoreSource.cancel();
  }

  return {
    type: actions.TEMPLATES_P_LEAVE,
  };
};

export const dropSearch = () => (dispatch) => {
  if (loadMoreSource) {
    loadMoreSource.cancel(cancelReasons.DROP_SEARCH);
  }

  dispatch({ type: actions.TEMPLATES_P_DROP_SEARCH });
};

const debouncedDropSearch = debounce((dispatch) => dispatch(dropSearch()), 1000);

