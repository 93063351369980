import * as actions from "./consts";
import { entitySetter } from "../utils";

const deleteTeam = (state, id) => {
  const newState = { ...state };
  delete newState[id];
  return newState;
};

const ACTION_HANDLERS = {
  [actions.SET_TEAMS]: (state, { teams }) => {
    return entitySetter(state, teams);
  },
  [actions.EDIT_TEAM]: (state, { updatedTeam }) => {
    const s = { ...state };
    s[updatedTeam.id] = updatedTeam;
    return s;
  },
  [actions.DELETE_TEAM]: (state, { id }) => {
    return deleteTeam(state, id);
  },
};

const initialState = {};

export default function usersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
