import nodeTypes from "./nodeTypes";

export const restoreServerFlow = (model) => {
  const startUiNode = model.nodes.find((node) => node.type === nodeTypes.START);
  const serverNodes = model.flow.nodes;
  model.flow.nodes = [];

  for (const uiNodeId of startUiNode.after) {
    handleUiNode({
      uiNodeId,
      fromServerNodeId: null,
      model,
      serverNodes,
    });
  }
};

const handleUiNode = ({ uiNodeId, model, fromServerNodeId, serverNodes }) => {
  const uiNode = model.nodes.find(({ id }) => id === uiNodeId);

  if (!uiNode.serverNodeId) {
    handleUiNode({
      uiNodeId: uiNode.after[0],
      fromServerNodeId,
      model,
      serverNodes,
    });

    return;
  }

  let serverNode = model.flow.nodes.find(({ id }) => id === uiNode.serverNodeId);
  const isFirstHandle = !serverNode;
  if (!serverNode) {
    serverNode = serverNodes.find(({ id }) => id === uiNode.serverNodeId);
    serverNode.after = [];
    serverNode.before = [];
    model.flow.nodes.push(serverNode);
  }

  if (fromServerNodeId) {
    const beforeServerNode = model.flow.nodes.find(({ id }) => id === fromServerNodeId);
    const branch_id = beforeServerNode.after.length + 1;
    serverNode.before.push({
      branch_id,
      node_id: beforeServerNode.id,
    });
    beforeServerNode.after.push({
      branch_id,
      node_id: serverNode.id,
    });
  }

  if (!isFirstHandle) return;

  for (const afterUiId of uiNode.after) {
    handleUiNode({
      uiNodeId: afterUiId,
      fromServerNodeId: uiNode.serverNodeId,
      model,
      serverNodes,
    });
  }
};

export default restoreServerFlow;
