import type { Store } from "redux";
import type { WhiteLabelState } from "../store/whiteLabel/reducer";
import gsFavIcon from "../assets/favicon-32x32.png";

type WhiteLabels = {
  domain: string
}

export const whiteLabels = {
  ADVANTAGE: {
    domain: "app.getadvantage.io",
  },
  GRINFI: {
    domain: "leadgen.grinfi.io",
  },
} as const;

// TO TEST ?_white_label_test_domain_=app.getadvantage.io
//         ?_white_label_test_domain_=leadgen.grinfi.io

export const whiteLabelService = {
  subscribeStore: (store: Store<{whiteLabel: WhiteLabelState}>) => {
    const setFavIcon = (href: string) => {
      const linkElem = document.createElement("link");
      linkElem.rel = "icon";
      linkElem.type = "image/png";
      linkElem.href = href;

      document.head.prepend(linkElem);
    };

    const unsubscribe = store.subscribe(() => {
      const whiteLabelState = store.getState().whiteLabel;
      switch (whiteLabelState.status) {
        case "ready": {
          setFavIcon(whiteLabelState.squareLogoUrl);
          unsubscribe();
          break;
        }
        case "none": {
          setFavIcon(gsFavIcon);
          unsubscribe();
          break;
        }
      }
    });
  },
};
