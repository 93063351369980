import * as actions from "./consts";

const entitySetter = (state, entities, { dontOverride } = {}) => {
  const s = { ...state };

  if (!Array.isArray(entities)) {
    entities = [entities];
  }

  entities.forEach((entity) => {
    const generatedActivity = (s[entity.uuid] && s[entity.uuid].activity || [])
      .filter((a) => a._generated);

    if (dontOverride) {
      s[entity.uuid] = {
        ...entity,
        ...s[entity.uuid],
      };
    } else {
      s[entity.uuid] = {
        ...s[entity.uuid],
        ...entity,
      };
    }

    s[entity.uuid].activity = s[entity.uuid].activity ? [...s[entity.uuid].activity, ...generatedActivity] : [];
  });

  return s;
};


const ACTION_HANDLERS = {
  [actions.SEED_LEADS]: (state, { leads, dontOverride }) => {
    return entitySetter(state, leads, { dontOverride });
  },
  [actions.SEED_LEAD]: (state, { lead }) => ({
    ...state,
    [lead.uuid]: {
      activity: [],
      flows: [],
      ...state[lead.uuid],
      ...lead,
    },
  }),
  [actions.SEED_LEAD_COUNTERS]: (state, { lead }) => {
    const s = { ...state };
    const oldLeadActivities = state[lead.lead.uuid]?.activity || lead.activity;

    s[lead.lead.uuid] = { ...lead.lead, markers: lead.markers, flow: lead.flow, activity: oldLeadActivities || [] };
    return s;
  },
  [actions.LEADS_ADD_ACTIVITY]: (state, { leadUuid, activity }) => {
    if (!state[leadUuid]) return state;
    if (state[leadUuid].activity.find((theActivity) => {
      return (
        theActivity.type === activity.type
        && Object.keys(activity.payload).every((key) => theActivity.payload[key] == activity.payload[key])
      );
    })) {
      return state;
    }

    const s = { ...state };

    s[leadUuid] = { ...s[leadUuid] };
    s[leadUuid].activity = [...s[leadUuid].activity, activity];

    return s;
  },
  [actions.LEADS_MESSAGES_READ]: (state, { leadUuid }) => ({
    ...state,
    [leadUuid]: {
      ...state[leadUuid],
      markers: {
        ...state[leadUuid].markers,
        dm_user_unread_count: 0,
      },
    },
  }),
  [actions.LEADS_MARK_UNREAD]: (state, { leadUuid }) => ({
    ...state,
    [leadUuid]: {
      ...state[leadUuid],
      markers: {
        ...state[leadUuid].markers,
        dm_user_unread_count: 1,
      },
    },
  }),
  [actions.LEADS_DELETE_SUCCESS]: (state, { loadedSelection }) => {
    const s = { ...state };

    for (const id of loadedSelection) {
      delete s[id];
    }

    return s;
  },
  [actions.LEADS_SET_TAGS_SUCCESS]: (state, { leads }) => {
    const s = { ...state };

    leads.forEach((lead) => {
      s[lead.lead.uuid] = { ...lead.lead, markers: lead.markers, flows: lead.flows, activity: lead.activity };
    });
    return s;
  },
  [actions.LEADS_SET_UNREAD_COUNTS_SUCCESS]: (state, { leads }) => {
    const s = { ...state };

    leads.forEach((lead) => {
      s[lead.lead.uuid] = { ...lead.lead, markers: lead.markers, flows: lead.flows, activity: lead.activity };
    });
    return s;
  },
  [actions.LEADS_SET_LIST_SUCCESS]: (state, { leads }) => {
    const s = { ...state };

    leads.forEach((lead) => {
      s[lead.lead.uuid] = { ...lead.lead, markers: lead.markers, flows: lead.flows, activity: lead.activity };
    });

    return s;
  },
  [actions.LEADS_ADD_TO_FLOW_SUCCESS]: (state, { leads }) => {
    const s = { ...state };
    leads.forEach((lead) => {
      s[lead.lead.uuid] = { ...lead.lead, markers: lead.markers, flows: lead.flows, activity: lead.activity };
    });

    return s;
  },
  [actions.LEADS_SET_PIPELINE_SUCCESS]: (state, { leads }) => {
    const s = { ...state };

    leads.forEach((lead) => {
      s[lead.lead.uuid] = { ...lead.lead, markers: lead.markers, flows: lead.flows, activity: lead.activity };
    });
    return s;
  },
  [actions.LEADS_SET_SENDER_PROFILE]: (state, { leads }) => {
    const s = { ...state };

    leads.forEach((lead) => {
      s[lead.uuid] = { ...s[lead.uuid], ...lead };
    });
    return s;
  },
  [actions.LEADS_DELETE_SUCCESS]: (state, { loadedSelection }) => {
    const s = { ...state };

    for (const id of loadedSelection) {
      delete s[id];
    }

    return s;
  },
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
