import styles from "./ModalFooterButton.module.scss";
import type { ReactNode } from "react";
import React from "react";
import cn from "classnames";
import type { ButtonProps } from "@gs/uiKit/general/Button";
import { Button } from "@gs/uiKit/general/Button";

export type ModalFooterProps = {
  children: ReactNode
  className?: string
  shiftRight?: boolean
}

export const ModalFooterButton = (props: ButtonProps) => {
  const { className, ...restProps } = props;

  return (
    <Button
      className={cn(className, styles.button)}
      {...restProps}
    />
  );
};

