import flowPlaceholder from "assets/flows_placeholder.jpg";

import style from "./FakeLayout.module.scss";


export default ({ children }: any) => {
  return (
    <div
      className={style.Container}
      style={{ backgroundImage: `url(${flowPlaceholder})` }}
    >
      {children}
    </div>
  );
};
