export const messagesFromFrontAppToClientPlugin = {
  APPLY_QUERY_PARAMS: "APPLY_QUERY_PARAMS",
  GET_URL: "GET_URL",
  SET_URL: "SET_URL",
  UI_LOGIN_SUCCESS: "UI_LOGIN_SUCCESS",
  UI_LOGOUT_SUCCESS: "UI_LOGOUT_SUCCESS",
  START_TASKS: "START_TASKS",
  SET_PROXY: "SET_PROXY",
  CLEAR_PROXY: "CLEAR_PROXY",
  OPEN_PLUGIN_BY_UI: "OPEN_PLUGIN_BY_UI",
  SET_TEAM_ID_VALUE: "SET_TEAM_ID_VALUE",
  START_PARSING: "START_PARSING",
  REMOVE_AUGMENT: "REMOVE_AUGMENT",
};
