import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const AsyncLeadsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'LeadsPage' */ "./LeadsPage")
      .catch( (e) => {
        console.log("Leads Page loading err", e);
        return Error404;
      });
  },
  // @ts-ignore
  loading: Loading,
});

export default AsyncLeadsPage;

