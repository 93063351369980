import { useCallback, useRef } from "react";
import cn from "classnames";
import Select from "react-select";
import Async from "react-select/async";
import AsyncCreatable from "react-select/async-creatable";
import Creatable from "react-select/creatable";
import * as Animated from "react-select/animated";

import colorStyles from "styles/_variables.module.scss";

import styles from "../Styles.module.scss";
import Icon from "components/common/elements/Icon";
import { AvaName } from "components/common/elements/AvaName";

const CREATE_KEY = Symbol("CREATE_KEY");
function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const CustomDropdownIndicator = ({
  innerProps,
  // ...other
}) => {
  return (
    <div
      {...innerProps}
      className="react-select-arrow"
      style={{ padding: "1px 5px 0 0" }}
    >
      <svg
        style={{ display: "block" }}
        width="9"
        height="5"
        viewBox="0 0 9 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="transparent"
          d="M1 1L4.5 4L8 1"
          stroke="#425B76"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

const getValueColor = (isGhost, isDisabled, isSelected, isFocused) => {
  if (isDisabled) return colorStyles.disabledColor;
  if (isSelected) return colorStyles.actionColor;
  if (isFocused) return "white";
  if (isGhost) return "#516F90";

  return "#253342";
};

const SelectBox = (props) => {
  const {
    additionalInfo,
    components,
    className = "",
    menuPlacement = "auto",
    isError,
    creatable,
    loadOptions,
    isShortInput,
    createOnBlur,
    onBlur = () => { },
    onChange,
    placeholder,
    disabled = false,
    onSearch,
    selectStyles,
    defaultMenu = false,
    icon,
    ...other
  } = props;
  let { filterOption } = props;
  const selectRef = useRef();

  const colourStyles = {
    control: (styles, { isFocused, isDisabled, menuIsOpen }) => ({
      ...styles,
      minHeight: 31,
      overflow: "hidden",
      borderRadius: 6,
      backgroundColor: isDisabled ? colorStyles.disabledColor : "white",
      borderColor: isFocused ? "#00A4BD" : isError ? "#FF0000" : "#CBD6E2",
      boxShadow: isFocused ? `0 0 1px ${colorStyles.actionColor}` : "none",
      ":hover": {
        borderColor: "#00A4BD",
      },
      ":hover .react-select-arrow path": {
        stroke: "#00A4BD",
      },
      ".react-select-arrow": {

        svg: {
          transition: "all 100ms",
          transform: menuIsOpen && "rotate(180deg)",
        },
        path: {
          transition: "all 400ms",
          stroke: menuIsOpen && "#00A4BD",
        },
      },

      ...selectStyles,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled
          ? null
          : isSelected ? "#CBD6E2" : isFocused ? "#00A4BD" : data.status === "archived" ? "#99ACC2" : data.status === "off" ? "black" : data.status === "on" ? "#6A78D1" : null,
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: "transparent",
        overflowWrap: "break-word",
        ":hover": {
          borderColor: "#00A4BD",
        },
        maxWidth: "100%",
      };
    },
    menuList: (styles) => ({
      ...styles,
      maxWidth: "100%",
      zIndex: 999999,
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #F0F3F6",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#99ACC2",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#8495a8",
      },
    }),
    menu: (styles) => ({ ...styles, width: "100%", minWidth: "100%", zIndex: 999999 }),
    menuPortal: (styles) => ({ ...styles, zIndex: 1002 }),
    valueContainer: (styles) => ({ ...styles, overflow: "hidden", padding: icon ? "0 0 0 35px" : "0 0 0 7px" }),
    input: (styles) => ({ ...styles, overflow: "hidden" }),
    loadingIndicator: (styles) => ({ ...styles, position: "absolute", right: "10px" }),
    placeholder: (styles) => ({ ...styles, color: "#99ACC2", overflow: "hidden" }),
    singleValue: (styles, { data }) => ({ ...styles, color: data?.ghost ? "#516F90" : "#253342", overflow: "hidden" }),
    multiValue: (styles) => ({ ...styles, backgroundColor: colorStyles.actionColor }),
    multiValueLabel: (styles) => ({ ...styles, color: "#253342", fontWeight: 400 }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: colorStyles.disabledColor,
      ":hover": {
        color: "#253342",
      },
    }),
  };

  let SelectComponent;
  if (loadOptions) {
    SelectComponent = creatable ? AsyncCreatable : Async;
    filterOption = filterOption || ((v, str) => v.label.match(new RegExp(escapeRegExp(str), "i")));
  } else {
    SelectComponent = creatable ? Creatable : Select;
  }

  const wrappedLoadOptions = useCallback((...args) => {
    return Promise.resolve(loadOptions(...args))
      .then((options) => options);
  }, [loadOptions]);
  const wrappedOnChange = useCallback((selectedValue) => {
    if (selectedValue[CREATE_KEY]) {
      onChange({
        value: null,
        label: selectedValue.label,
        __isNew__: false,
      });
      setTimeout(() => selectRef.current.focus());
      return;
    }
    if (Array.isArray(selectedValue) && selectedValue.some((v) => v[CREATE_KEY])) {
      onChange(selectedValue.filter((v) => !v[CREATE_KEY]));
      setTimeout(() => selectRef.current.focus());
      return;
    }

    onChange(selectedValue);
  }, [onChange]);

  const handleBlur = useCallback((e) => {
    if (!creatable) return;
    if (!createOnBlur) return;
    const inputValue = e.target.value;

    if (inputValue) {
      onChange({
        value: inputValue,
        label: inputValue,
        __isNew__: true,
      });
    }

    onBlur(e);
  }, [onBlur, createOnBlur, creatable]);
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      {data.label && <span style={{ color: data.label === "running" ? "#6A78D1" : data.label === "archived" ? "#99ACC2" : "black" }}>{data.label}</span>}
      {data.label && <span style={groupBadgeStyles}>{data.options.length}</span>}
    </div>
  );

  const formatOptionLabel = ({ value, label, color, avatarUrl }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      {(color || avatarUrl) &&
      <>
        {color && (
          <div
            style={{ background: color || "black" }}
            className={styles.TeamBadge}
          >
          </div>
        )}
        { avatarUrl && (
          <AvaName
            size={16}
            fallback={AvaName.fallbacks.person}
            src={avatarUrl || ""}
            name={label}
          />
        ) }
      </>
      }
      <div className={styles.SelectLabel}>{label}</div>
    </div>
  );

  return (
    <div
      className={cn(styles.inputWrapper, {
        [styles.AdditionalInfo]: additionalInfo,
        [styles.Error]: isError,
        [styles.ShortInput]: isShortInput,
        [className]: true,
      })}
      data-additional-info={additionalInfo}
    >
      {icon && <Icon
        icon={icon}
        className={styles.InputIcon}
        size={18}
      />}
      <SelectComponent
        ref={selectRef}
        menuPlacement={menuPlacement}
        className={styles.Select}
        openOnFocus
        isDisabled={disabled}
        styles={colourStyles}
        components={{
          Animated,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: () => null,
          ...components,
        }}
        loadOptions={wrappedLoadOptions}
        defaultOptions
        formatOptionLabel={formatOptionLabel}
        formatGroupLabel={formatGroupLabel}
        filterOption={filterOption}
        noOptionsMessage={() => creatable ? "Type to create" : undefined}
        onBlur={handleBlur}
        onChange={wrappedOnChange}
        onInputChange={(value) => onSearch?.(value)}
        placeholder={placeholder || (creatable ? "Type to create..." : "Select...")}
        {...other}
      />
    </div>
  );
};

export default SelectBox;
