import React from "react";
import cn from "classnames";
import TooltipTrigger from "react-popper-tooltip";

import "react-popper-tooltip/dist/styles.css";
import styles from "./Tooltip.module.scss";

class Tooltip extends React.PureComponent {
  constructor({ defaultTooltipShown, tooltipShown }) {
    super();

    this.state = {
      tooltipShown: tooltipShown === undefined ? !!defaultTooltipShown : tooltipShown,
    };

    this.setShown = this.setShown.bind(this);
    this.tooltip = this.tooltip.bind(this);
  }

  setShown(tooltipShown) {
    this.setState({ tooltipShown });

    this.props.onVisibilityChange && this.props.onVisibilityChange(tooltipShown);
  }

  tooltip({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) {
    const { tooltip, hideArrow, tooltipClassName, arrowClassName, left, hideContainerClass } = this.props;
    const tooltipProps = {
      ...getTooltipProps({
        ref: tooltipRef,
        className: cn({
          [styles.Tooltip]: !hideContainerClass },
        { "tooltip-container": !hideContainerClass },
        { [styles.TooltipSide]: !hideContainerClass && (placement === "right" || placement === "left") },
        { [tooltipClassName]: !!tooltipClassName },
        ) },
      ),
    };
    let elem;

    if (typeof tooltip === "function") {
      elem = React.cloneElement(tooltip, {
        hidePopper: () => this.setShown(false),
      });
    } else {
      elem = tooltip;
    }

    if (left) {
      tooltipProps.style.left = `${left}px`;
    }
    return (
      <div
        {...tooltipProps}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: cn("tooltip-arrow", { [arrowClassName]: !!arrowClassName }),
              "data-placement": placement,
            })}
          />
        )}
        {elem}
      </div>
    );
  }

  render() {
    const { children, hideArrow, className, ...props } = this.props;
    const { tooltipShown } = this.state;

    return (
      <TooltipTrigger
        {...props}
        tooltip={this.tooltip}
        tooltipShown={tooltipShown}
        onVisibilityChange={this.setShown}
      >
        {({ getTriggerProps, triggerRef }) => (
          <span
            {...getTriggerProps({
              ref: triggerRef,
              className: cn("trigger", { [className]: className }),
            })}
          >
            {children}
          </span>
        )}
      </TooltipTrigger>
    );
  }
}

const DarkThemeTooltip = ({ tooltip, children, placement = "bottom", className = "" }) => (
  tooltip ? <Tooltip
    tooltip={(
      <div className={styles.activityTooltipInner}>
        {tooltip}
      </div>
    )}
    trigger="hover"
    delayHide={100}
    delayShow={100}
    className={cn(styles.CompanyActivityTooltipPopper, className)}
    tooltipClassName={styles.activityTooltip}
    arrowClassName={styles.activityTooltipArrow}
    placement={placement}
  >
    {children}
  </Tooltip> : children
);

export {
  Tooltip as default,
  Tooltip,
  DarkThemeTooltip,
};

