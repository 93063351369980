import type { WhiteLabelState } from "./reducer";


const sliceSelector = (state: any) => state.whiteLabel as WhiteLabelState;

export const squareWhiteLabelLogo = (state: any) => {
  const wlState = sliceSelector(state);
  if (wlState.status === "ready") return wlState.squareLogoUrl;
  return "";
};
export const fullWhiteLabelLogo = (state: any) => {
  const wlState = sliceSelector(state);
  if (wlState.status === "ready") return wlState.fullLogoUrl;
  return "";
};
export const getWhiteLabelName = (state: any) => {
  const wlState = sliceSelector(state);
  if (wlState.status === "ready") return wlState.companyName;
  return "";
};
export const getWhiteLabelTerms = (state: any) => {
  const wlState = sliceSelector(state);
  if (wlState.status === "ready") return wlState.terms;
  return "";
};
export const getWhiteLabelStatus = (state: any) => sliceSelector(state).status;
export const getIsWhiteLabel = (state: any) => getWhiteLabelStatus(state) !== "none";
