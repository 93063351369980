import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SpinnerGif from "./spinnerGif.gif";

import styles from "./Styles.module.scss";

const Spinner = ({ size = 50, gif = false, color = "#2d9fee", style = {}, ...other }) => (
  <div
    className={styles.spinnerContainer}
    style={style}
  >
    <div className={styles.spinnerIcon}>
      { !gif &&
      <svg
        x="0"
        y="0"
        viewBox="25 25 50 50"
        {...other}
        style={{
			    width: size + "px",
			    height: size + "px",
			  }}
      >
        <circle
          cx="50"
          cy="50"
          r="17"
          fill={color}
          stroke={color}
          strokeWidth="5"
          strokeMiterlimit="10"
        >
        </circle>
      </svg>
      }
      { gif && <img
        src={SpinnerGif}
        alt="spinner"
        {...other}
               /> }
    </div>
  </div>
);

Spinner.propTypes = {
};

export default Spinner;
