import { getBaseAxios } from "services/axios";
import { createSenderProfileApi } from "@gs/core/domain/SenderProfile/createSenderProfileApi";
import { senderProfileStore } from "./senderProfileStore";
import type { SenderProfile, SenderProfileId } from "@gs/core/domain/SenderProfile/SenderProfile";

export const senderProfileApi = (() => {
  const flowAxios = getBaseAxios("flowV2");

  const coreSenderProfileApi = createSenderProfileApi(flowAxios);

  const getSenderProfiles: typeof coreSenderProfileApi.getSenderProfiles = async (...args) => {
    const result = await coreSenderProfileApi.getSenderProfiles(...args);

    senderProfileStore.setState(
      result.data.data.reduce<Record<SenderProfileId, SenderProfile>>((acc, senderProfile) => {
        acc[senderProfile.uuid] = senderProfile;
        return acc;
      }, {}),
    );

    return result;
  };

  const getSenderProfile: typeof coreSenderProfileApi.getSenderProfile = async (...args) => {
    const result = await coreSenderProfileApi.getSenderProfile(...args);

    senderProfileStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const updateSenderProfile: typeof coreSenderProfileApi.updateSenderProfile = async (...args) => {
    const result = await coreSenderProfileApi.updateSenderProfile(...args);

    senderProfileStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  return {
    updateSenderProfile,
    getSenderProfile,
    getSenderProfiles,
    getSenderProfilesMetrics: coreSenderProfileApi.getSenderProfilesMetrics,
  };
})();
