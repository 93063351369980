import styles from "feature/mainLayout/component/ContentHeader/ContentHeaderTabs.module.scss";
import type { TabsProps } from "@gs/uiKit/dataDisplay/Tabs";
import { Tabs } from "@gs/uiKit/dataDisplay/Tabs";
import cn from "classnames";
import { ContentHeaderTabsNavigation } from "feature/mainLayout/component/ContentHeader/ContentHeaderTabsNavigation";

export const ContentHeaderTabs = (props: TabsProps) => {
  return (
    <Tabs
      {...props}
      className={cn(styles.wrapper, props.className)}
    />
  );
};

ContentHeaderTabs.Navigation = ContentHeaderTabsNavigation;
