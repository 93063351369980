import Popover from "react-popover";
import React, { PureComponent } from "react";
import cn from "classnames";

const defaultFunction = () => {};

export default (PopoverContent) => {
  return class extends PureComponent {
    constructor() {
      super();
      this.state = {
        isOpen: false,
      };
    }

    handleToggle(show) {
      const { disabled, onOpen = defaultFunction, onClose = defaultFunction } = this.props;
      if (show && disabled) return;

      this.setState({ isOpen: show });

      if (show) {
        onOpen();
      } else {
        onClose();
      }
    }

    render() {
      const {
        children,
        place,
        preferPlace = "below", // ["start","end","above","right","below","left","column","row"].
        className,
        popoverClassName,
        tipSize = 7,
        style,
        persistent,
        ...props
      } = this.props;

      return (
        <Popover
          isOpen={this.state.isOpen}
          tipSize={tipSize}
          place={place}
          preferPlace={preferPlace}
          className={popoverClassName}
          onOuterAction={() => !persistent && this.handleToggle( false)}
          body={<PopoverContent
            onRequestClose={() => this.handleToggle(false)}
            {...props}
                />}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleToggle(!this.state.isOpen);
            }}
            className={cn(className, "popover-btn-container")}
            style={style}
          >
            { children }
          </div>
        </Popover>
      );
    }
  };
};
