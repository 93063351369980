import styles from "./ProfileMenuContent.module.scss";
import { ReactComponent as GearIcon } from "bootstrap-icons/icons/gear.svg";
import { ReactComponent as PeopleIcon } from "bootstrap-icons/icons/people.svg";
import { ReactComponent as ArrowRightIcon } from "bootstrap-icons/icons/arrow-right.svg";
import { ReactComponent as FileEarmarkCheckIcon } from "bootstrap-icons/icons/file-earmark-check.svg";
import { ReactComponent as QuestionSquareIcon } from "bootstrap-icons/icons/question-square.svg";
import { ReactComponent as BoxArrowInRightIcon } from "bootstrap-icons/icons/box-arrow-up-right.svg";
import { ChromeOutlined } from "@ant-design/icons";
import { Flex } from "@gs/uiKit/layout/Flex";
import { Divider } from "@gs/uiKit/layout/Divider";
import { Button } from "@gs/uiKit/general/Button";
import { ExternalLink } from "components/common/routing/ExternalLink";
import { InnerLink } from "components/common/routing/InnerLink";
import subscriptionsPath from "components/routes/UserSettingsGroup/Subscriptions/path";
import teamsManagementPath from "components/routes/UserSettingsGroup/TeamsManagement/path";
import { default as userPath } from "components/routes/UserSettingsGroup/User/path";
import { UserAvatar } from "domain/user/component/UserAvatar";
import { useMemo } from "react";
import authPath from "components/routes/authGroup/Signup/path";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "feature/session/useCurrentUser";
import { sessionService } from "feature/session/SessionService";
import { useSelector } from "react-redux";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";

export const ProfileMenuContent = () => {
  const { currentUser } = useCurrentUser();
  const browserHistory = useHistory();
  const isWhiteLabel = useSelector(getIsWhiteLabel);

  const userName = useMemo(() => {
    if (!currentUser) return "";
    return [currentUser.first_name, currentUser.last_name]
      .filter(Boolean)
      .join(" ");
  }, [currentUser?.first_name, currentUser?.last_name]);

  return (
    <div
      style={{
        padding: "var(--padding-xs)",
        width: 244,
      }}
    >
      <Flex
        gap="small"
        align="center"
      >
        <UserAvatar
          userId={currentUser!.id}
          style={{ flexShrink: 0 }}
          shape="square"
        />
        <div>
          <p>
            { userName }
          </p>
          <p style={{ fontSize: "var(--font-size-sm)", color: "var(--color-text-label)" }}>
            { currentUser?.email }
          </p>
        </div>
      </Flex>

      <Divider style={{ margin: "var(--margin-xs) 0" }} />

      <Flex vertical>
        <InnerLink to={userPath}>
          <Button
            type="text"
            icon={<GearIcon />}
            className={styles.button}
          >
            Settings
          </Button>
        </InnerLink>
        <InnerLink to={teamsManagementPath}>
          <Button
            type="text"
            icon={<PeopleIcon />}
            className={styles.button}
          >
            Teams
          </Button>
        </InnerLink>
        <InnerLink to={subscriptionsPath}>
          <Button
            type="text"
            icon={<FileEarmarkCheckIcon />}
            className={styles.button}
          >
            Subscriptions
          </Button>
        </InnerLink>

        {!isWhiteLabel && (
          <>
            <Divider style={{ margin: "var(--margin-xs) 0" }} />

            <ExternalLink to="https://help.getsales.io">
              <Button
                type="text"
                icon={<QuestionSquareIcon />}
                className={styles.button}
              >
                Help Center
                <div style={{ flexGrow: 1 }} />
                <BoxArrowInRightIcon />
              </Button>
            </ExternalLink>
            <ExternalLink to="https://chromewebstore.google.com/detail/getsalesio-collect-leads/idkdpobomaapfgbeeplpanlklbfgjifj">
              <Button
                type="text"
                icon={<ChromeOutlined />}
                className={styles.button}
              >
                Chrome Extension
                <div style={{ flexGrow: 1 }} />
                <BoxArrowInRightIcon />
              </Button>
            </ExternalLink>
          </>
        )}
      </Flex>

      <Divider style={{ margin: "var(--margin-xs) 0" }} />

      <Button
        type="text"
        icon={<ArrowRightIcon />}
        className={styles.button}
        style={{ width: "100%" }}
        onClick={() => {
          sessionService.signOut();
          browserHistory.push(authPath.login);
        }}
      >
        Log out
      </Button>
    </div>
  );
};
