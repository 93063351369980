import { Switch, Route } from "react-router-dom";
import Messages from "./index";
import NotFoundRoute from "components/routes/NotFound";
import pathname from "./path";

export default () => (
  <Switch>
    <Route
      path={`${pathname}/:leadUuid?`}
      // @ts-ignore
      component={Messages}
    />
    {/* @ts-ignore */}
    <Route component={NotFoundRoute} />
    {" "}
    {/* 404 */}
  </Switch>
);

export { default as path } from "./path";
