import { createSelector } from "reselect";
import store from "store/store";
import { loadListById } from "./actions";

const listsSelector = (state) => state.entities.lists || {};

const listsArraySelector = createSelector(
  [listsSelector], (lists) => Object.values(lists),
);

const listsWithLeadsArraySelector = createSelector(
  [listsArraySelector], (listsArr) => listsArr.filter((list) => !!list.total_leads),
);

const listById = (state, { listId }) => {
  if (!listId) return;
  const list = listsSelector(state)[listId];

  if (!list) {
    store.dispatch(loadListById(listId));
  }

  return list;
};

const listsById = (state, { listIds }) => {
  return listIds.map((listId) => listById(state, { listId }));
};

export {
  listById,
  listsById,
  listsSelector,
  listsArraySelector,
  listsWithLeadsArraySelector,
};
