import styles from "./TeamMenuContent.module.scss";
import { useAllTeams } from "domain/team/hook/useAllTeams";
import { Flex } from "@gs/uiKit/layout/Flex";
import { TeamAvatar } from "domain/team/component/TeamAvatar";
import { sessionService } from "feature/session/SessionService";
import Preloader from "components/common/elements/Preloader";
import { Typography } from "@gs/uiKit/general/Typography";
import { CheckOutlined } from "@ant-design/icons";
import cn from "classnames";

export const TeamMenuContent = () => {
  const [teams, isLoading] = useAllTeams();
  const currentTeamId = sessionService.useStore((s) => s.teamId);

  return (
    <Flex
      vertical
      gap="small"
      style={{
        padding: "var(--padding-sm)",
        width: 220,
        maxHeight: 400,
        overflow: "auto",
      }}
    >
      {teams.map((team) => (
        <Flex
          key={team.id}
          gap="small"
          align="center"
          className={cn({ [styles.teamButton as string]: team.id !== currentTeamId })}
          onClick={() => team.id !== currentTeamId && sessionService.changeTeam(team.id)}
        >
          <TeamAvatar teamId={team.id} />
          <Typography.Text
            style={{ flex: "1 1 0" }}
            ellipsis={{ tooltip: team.name }}
          >
            { team.name }
          </Typography.Text>
          { team.id === currentTeamId && (
            <CheckOutlined style={{ color: "var(--color-primary)" }} />
          )}
        </Flex>
      ))}
      { isLoading && <Preloader />}
    </Flex>
  );
};
