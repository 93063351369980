import React from "react";
import type { BadgeProps as AntdBadgeProps } from "antd";
import { Badge as AntdBadge } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type BadgeProps = AntdBadgeProps & {
  isLoading?: boolean
}

const BadgeFC = (props: BadgeProps) => {
  const { isLoading, ...badgeProps } = props;


  const count = !isLoading
    ? badgeProps.count
    : (
      <div
        style={{
          backgroundColor: "var(--color-bg-base )",
          padding: 1,
          width: 10,
          height: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-primary)",
            padding: 2,
            width: 8,
            height: 8,
            borderRadius: 8,
          }}
        >
          <LoadingOutlined style={{ color: "white", fontSize: 4, display: "block" }} />
        </div>
      </div>
    );
  return (
    <AntdBadge
      {...badgeProps}
      count={count}
    />
  );
};
BadgeFC.Ribbon = AntdBadge.Ribbon;

export const Badge = BadgeFC;
