
import { createSelector } from "reselect";
import { loadTeamById } from "./actions";
import store from "../../store";


const sliceSelector = (state) => state.entities?.teams || {};
const teamsIds = (_state, { teamsIds }) => teamsIds;

const getTeamById = (state, { id }) => {
  if (!id) return;
  const team = sliceSelector(state)?.[id];
  if (!team) {
    store.dispatch(loadTeamById(id));
  }

  return team;
};

const teamsArraySelector = createSelector(
  [sliceSelector], (profiles) => Object.values(profiles),
);

const teamsByIds = createSelector(
  [sliceSelector, teamsIds],
  (teams, teamsIdsRes) => teamsIdsRes.map((id) => teams[id]),
);

export {
  sliceSelector as teamsSelector,
  teamsArraySelector,
  getTeamById,
  teamsByIds,
};

