import * as actions from "./consts";
import { entitySetter } from "../utils";


const ACTION_HANDLERS = {
  [actions.SEED_PROMPTS]: (state, { prompts }) => entitySetter(state, prompts),
  [actions.UPDATE_PROMPT]: (state, { prompt }) => {
    const s = { ...state };
    s[prompt.uuid] = prompt;
    return s;
  },
};


const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
