import * as actions from "./consts";
import reduce from "lodash/reduce";
import { immuteSet, immuteDelete } from "services/immuteActions";

const ACTION_HANDLERS = {
  [actions.SEGMENTS_GET_SUCCESS]: (state, { segments }) => reduce(segments, (acc, segment) => {
    acc[segment.id] = segment;
    return acc;
  }, { ...state }),
  [actions.SEGMENT_DELETE_SUCCESS]: (state, { id }) => immuteDelete(state, `${id}`),
  [actions.SEGMENT_CREATE_SUCCESS]: (state, { segment }) => immuteSet(state, `${segment.id}`, segment),
};

const initialState = {};

export default function segmentsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
