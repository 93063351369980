import * as actions from "./consts";
import cloneDeep from "lodash/cloneDeep";

const entitySetter = (state, entities, category) => {
  const categoryData = {};

  if (!Array.isArray(entities)) {
    entities = [entities];
  }

  entities.forEach((entity) => {
    categoryData[entity.uuid] = entity;
  });
  return {
    ...state,
    [category]: categoryData,
  };
};

const deletePipeline = (state, stageUuid, category) => {
  const newState = cloneDeep(state);
  const deletedPipeline = newState[category][stageUuid];
  const newOrders = {};
  Object.values(newState[category]).forEach((item) => {
    newOrders[item.uuid] = item.order > deletedPipeline.order ? { ...item, order: item.order - 1 } : item;
  });
  delete newOrders[stageUuid];
  return {
    ...newState,
    [category]: newOrders,
  };
};

const ACTION_HANDLERS = {
  [actions.SEED_PIPELINE_STAGES]: (state, { stages, category }) => entitySetter(state, stages, category),
  [actions.EDIT_PIPELINE_STAGE]: (state, { stages, category }) => {
    return ({ ...state, [category]: stages });
  },
  [actions.DELETE_PIPELINE_STAGE]: (state, { stageUuid, category }) => deletePipeline(state, stageUuid, category),
  [actions.ADD_PIPELINE_STAGE]: (state, { stage, category }) => ({
    ...state,
    [category]: { ...state[category], [stage.uuid]: stage },
  }),
};

const initialState = {};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
