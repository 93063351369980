import type { GsModalId } from "feature/modal/ModalService";
import type { GsModalConfig } from "feature/modal/ModalService";
import { reactLazyWithRetry } from "@gs/core/utils/reactLazyWithRetry";

export const modalsRegistry = {
  flowNodeTasksModalId: {
    modalId: "flowNodeTasksModalId",
    Content: reactLazyWithRetry(() => import("components/routes/flowsGroup/Flow/Details/FlowVisualizer/NodeVisualizer/NodePreviewFacade/RealNode/FlowNodeTasksModal/FlowNodeTasksModal")),
    query: true,
  },
  massActionDetailsModalId: {
    modalId: "massActionDetailsModalId",
    Content: reactLazyWithRetry(() => import("components/common/UserMassActions/MassActionDetailsModal/MassActionDetailsModalContent")),
  },
  updateEntitiesFromCsvModalId: {
    modalId: "updateEntitiesFromCsvModalId",
    Content: reactLazyWithRetry(() => import("domain/dataSource/modal/UpdateEntitiesFromCsvModal/UpdateEntitiesFromCsvModal")),
    query: true,
  },
  linkedinFilterModalId: {
    modalId: "linkedinFilterModalId",
    Content: reactLazyWithRetry(() => import("domain/linkedinFilter/modal/LinkedinFilterModal/LinkedinFilterModal")),
    query: true,
  },
  findContactModalId: {
    modalId: "findContactModalId",
    Content: reactLazyWithRetry(() => import("domain/company/modal/FindContactModal/FindContactModal")),
  },
  webhookTestModalId: {
    modalId: "webhookTestModalId",
    Content: reactLazyWithRetry(() => import("domain/webhook/modal/WebhookTestModal")),
    query: true,
  },
  webhookModalId: {
    modalId: "webhookModalId",
    Content: reactLazyWithRetry(() => import("domain/webhook/modal/WebhookModal")),
    query: true,
  },
  linkedinDataSourceModalId: {
    modalId: "linkedinDataSourceModalId",
    Content: reactLazyWithRetry(() => import("domain/dataSource/modal/LinkedinDataSourceModal/LinkedinDataSourceModal")),
    query: true,
  },
  editDataSourceModalId: {
    modalId: "editDataSourceModalId",
    Content: reactLazyWithRetry(() => import("domain/dataSource/modal/EditDataSourceModal/EditDataSourceModal")),
    query: true,
  },
  createDataSourceFromCsvModalId: {
    modalId: "createDataSourceFromCsvModalId",
    Content: reactLazyWithRetry(() => import("domain/dataSource/modal/CreateDataSourceFromCsvModal/CreateDataSourceFromCsvModal")),
    query: true,
  },
  dataSourceItemsModalId: {
    modalId: "dataSourceItemsModalId",
    Content: reactLazyWithRetry(() => import("domain/dataSourceItem/modal/DataSourceItemsModal/DataSourceItemsModal")),
    query: true,
  },
  leadMassActionForFlowModalId: {
    modalId: "leadMassActionForFlowModalId",
    Content: reactLazyWithRetry(() => import("domain/massAction/modal/LeadMassActionForFlowModal/LeadMassActionForFlowModal")),
  },
  leadMassActionForTagModalId: {
    modalId: "leadMassActionForTagModalId",
    Content: reactLazyWithRetry(() => import("domain/massAction/modal/LeadMassActionForTagModal/LeadMassActionForTagModal")),
  },
  leadMassActionForPipelineStageModalId: {
    modalId: "leadMassActionForPipelineStageModalId",
    Content: reactLazyWithRetry(() => import("domain/massAction/modal/LeadMassActionForPipelineStageModal/LeadMassActionForPipelineStageModal")),
  },
  leadMassActionForListModalId: {
    modalId: "leadMassActionForListModalId",
    Content: reactLazyWithRetry(() => import("domain/massAction/modal/LeadMassActionForListModal/LeadMassActionForListModal")),
  },
  leadMassActionDeleteModalId: {
    modalId: "leadMassActionDeleteModalId",
    Content: reactLazyWithRetry(() => import("domain/massAction/modal/LeadMassActionDeleteModal/LeadMassActionDeleteModal")),
  },
  leadCsvExportModalId: {
    modalId: "leadCsvExportModalId",
    Content: reactLazyWithRetry(() => import("domain/lead/modal/LeadCsvExportModal/LeadCsvExportModal")),
  },
  leadWebhookExportModalId: {
    modalId: "leadWebhookExportModalId",
    Content: reactLazyWithRetry(() => import("domain/lead/modal/LeadWebhookExportModal/LeadWebhookExportModal")),
  },
  createTasksModalId: {
    modalId: "createTasksModalId",
    Content: reactLazyWithRetry(() => import("domain/taskPipeline/modal/CreateTasksModal/CreateTasksModal")),
  },
  selectAttachmentModalId: {
    modalId: "selectAttachmentModalId",
    Content: reactLazyWithRetry(() => import("domain/attachment/modal/SelectAttachmentModal/SelectAttachmentModal")),
  },
  newSubscriptionModalId: {
    modalId: "newSubscriptionModalId",
    Content: reactLazyWithRetry(() => import("feature/billing/modal/NewSubscriptionModal")),
    query: true,
  },
  addLicensesModalId: {
    modalId: "addLicensesModalId",
    Content: reactLazyWithRetry(() => import("feature/billing/modal/AddLicensesModal")),
    query: true,
  },
  removeLicensesModalId: {
    modalId: "removeLicensesModalId",
    Content: reactLazyWithRetry(() => import("feature/billing/modal/RemoveLicensesModal")),
    query: true,
  },
  changePlanModalId: {
    modalId: "changePlanModalId",
    Content: reactLazyWithRetry(() => import("feature/billing/modal/ChangePlanModal")),
    query: true,
  },
  cancelSubscriptionModalId: {
    modalId: "cancelSubscriptionModalId",
    Content: reactLazyWithRetry(() => import("feature/billing/modal/CancelSubscriptionModal")),
  },
} as const satisfies {
  [K in GsModalId]: GsModalConfig<K>
};
