import { key } from "./reducer";
import { createSelector } from "reselect";
import { sliceSelector as flowEntities } from "store/entities/templates/selectors";

export const loadedIds = (state) => state[key].ids;
export const total = (state) => state[key].total;
export const filters = (state) => state[key]?.filters || {};
export const hasMore = (state) => state[key].hasMore;
export const isLoading = (state) => state[key].isLoading;
export const loadedCount = (state) => state[key].ids.length;


export const getAllFlowsArr = createSelector(
  [flowEntities, loadedIds],
  (flowEntities, loadedIds) => loadedIds.map((uuid) => flowEntities[uuid]),
);
