import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import type { ReactElement, ReactNode } from "react";
import { forwardRef } from "react";
import { useToWithTeamId } from "./useToWithTeamId";

export const InnerLink = forwardRef<HTMLAnchorElement, Omit<LinkProps, "children"> & {children?: ReactElement | ReactNode}>((props, ref) => {
  const {
    to,
    target,
    children,
    ...rest
  } = props;
  const newTo = useToWithTeamId(to, target);

  return (
    <Link
      ref={ref}
      to={newTo}
      target={target}
      children={children as any}
      {...rest}
    />
  );
});
