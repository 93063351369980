const SEED_WORKSPACES = "SEED_CATEGORIES_WORKSPACES";
const DELETE_WORKSPACE = "DELETE_WORKSPACES";
const CREATE_WORKSPACE = "CREATE_WORKSPACES";
const UPDATE_WORKSPACE = "UPDATE_WORKSPACES";

export {
  SEED_WORKSPACES,
  DELETE_WORKSPACE,
  CREATE_WORKSPACE,
  UPDATE_WORKSPACE,
};
