import type { MassActionType } from "@gs/core/domain/MassAction/massActionType";

const getNameByType = (type: MassActionType) => {
  switch (type) {
    case "contact_delete":
      return "Delete contacts";
    case "account_delete":
      return "Delete accounts";
    case "contact_mark_read":
      return "Mark Contacts as Read";
    case "contact_mark_unread":
      return "Mark Contacts as Unread";
    case "contact_export_csv":
      return "Export contacts to CSV";
    case "account_export_csv":
      return "Export accounts to CSV";
  }
};
export const DefaultMassActionName = ({
  type,
}: {
  type: MassActionType
}) => {
  return (
    <>
      {getNameByType(type)}
    </>
  );
};
