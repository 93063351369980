import bellsoftPath from "assets/companies/bellsoft.png";
import buzzGuruPath from "assets/companies/BuzzGuru.png";
import cofixPath from "assets/companies/cofix.png";
import crocPath from "assets/companies/croc.svg";
import displayforcePath from "assets/companies/displayforce.png";
import ivideonPath from "assets/companies/ivideon.svg";
import kasperskyPath from "assets/companies/kaspersky.svg";
import yvaPath from "assets/companies/Yva.png";
import pwcPath from "assets/companies/pwc.svg";
import vkPath from "assets/companies/vk-group-logo.svg";
import megafonPath from "assets/companies/megafon-logo.svg";
import prosperixPath from "assets/companies/prosperix.svg";


export const companiesList = [
  {
    logoPath: pwcPath,
  },
  {
    logoPath: yvaPath,
  },
  {
    logoPath: vkPath,
  },
  {
    logoPath: crocPath,
  },
  {
    logoPath: kasperskyPath,
  },
  {
    logoPath: ivideonPath,
  },
  {
    logoPath: cofixPath,
  },
  {
    logoPath: displayforcePath,
  },
  {
    logoPath: bellsoftPath,
  },
  {
    logoPath: megafonPath,
  },
  {
    logoPath: buzzGuruPath,
  },
  {
    logoPath: prosperixPath,
  },
];
