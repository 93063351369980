import { createMassActionApi } from "@gs/core/domain/MassAction/createMassActionApi";
import { getBaseAxios } from "services/axios";
import { massActionStore } from "./massActionStore";
import { massActionMetricsStore } from "./massActionMetricsStore";
import type { MassAction, MassActionId, MassActionMetrics } from "@gs/core/domain/MassAction/MassAction";
import { dataBus } from "services/dataBus";

export const massActionApi = (() => {
  const contactAxios = getBaseAxios("contactV2");
  const coreMassActionApi = createMassActionApi(contactAxios);

  const getMassActions: typeof coreMassActionApi.getMassActions = async (...args) => {
    const result = await coreMassActionApi.getMassActions(...args);
    massActionStore.setState(
      result.data.data.reduce<Record<MassActionId, MassAction>>((acc, massAction) => {
        acc[massAction.uuid] = massAction;
        return acc;
      }, {}),
    );
    return result;
  };

  const getMassAction: typeof coreMassActionApi.getMassAction = async (...args) => {
    const result = await coreMassActionApi.getMassAction(...args);

    massActionStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const deleteMassAction: typeof coreMassActionApi.deleteMassAction = async (...args) => {
    const result = await coreMassActionApi.deleteMassAction(...args);

    dataBus.emit("massAction:delete", { massActionIds: [args[0].id] });

    return result;
  };

  const getMassActionsMetrics: typeof coreMassActionApi.getMassActionsMetrics = async (...args) => {
    const result = await coreMassActionApi.getMassActionsMetrics(...args);
    const keys = Object.entries(result.data) as Array<[MassActionId, MassActionMetrics]>;

    massActionMetricsStore.setState((prev) => ({
      ...prev,
      ...keys.reduce<Record<MassActionId, MassActionMetrics>>((acc, dataItem) => {
        acc[dataItem[0]] = dataItem[1];
        return acc;
      }, {}),
    }));

    return result;
  };

  return {
    getMassActions,
    getMassAction,
    getMassActionsMetrics,
    deleteMassAction,
  };
})();
