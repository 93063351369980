import { DataBus } from "@gs/core/utils/DataBus";
import type { TaskPipelineId } from "@gs/core/domain/TaskPipeline/TaskPipeline";
import type { LeadId } from "@gs/core/domain/Lead/Lead";
import type { PipelineStageId } from "@gs/core/domain/PipelineStage/PipelineStage";
import type { WebhookId } from "@gs/core/domain/Webhook/Webhook";
import type { MassActionId } from "@gs/core/domain/MassAction/MassAction";
import type { CompanyId } from "@gs/core/domain/Company/Company";
import type { DataSourceId } from "@gs/core/domain/DataSource/DataSource";


// should be extended by declare
// keep it sorted please
export interface EventTypesToData {
  "company:delete": {all: boolean; ids: CompanyId[]}
  "currentUser:logout": {}
  "messages_read": {leadIds: LeadId[]}
  "messages_unread": {leadIds: LeadId[]}
  "pipelineStage:create": {pipelineStageId: PipelineStageId}
  "pipelineStage:delete": {pipelineStageId: PipelineStageId; replacePipelineStageId: PipelineStageId}
  "pipelineStage:update": {pipelineStageId: PipelineStageId}
  "webhook:create": {webhookIds: WebhookId[]}
  "webhook:delete": {webhookIds: WebhookId[]}
  "webhook:update": {webhookIds: WebhookId[]}
}
/*
Example how to extend:
declare module "services/dataBus" {
  interface EventTypesToData {
    "company:delete": {all: boolean; ids: CompanyId[]}
  }
}
 */

// @ts-ignore
export const dataBus = new DataBus<EventTypesToData>();
