import find from "lodash/find";
import pull from "lodash/pull";
import deleteNodeRecursively from "./deleteNodeRecursively";
import recalculate from "../recalculate";

export default (model, targetNodeId, mainAfterId) => {
  const targetNode = find(model.nodes, { id: targetNodeId });

  if (!targetNode.after.length) {
    throw "Can't delete End";
  }
  if (!targetNode.serverNodeId) {
    throw "Can't delete not real node";
  }

  mainAfterId = mainAfterId || targetNode.after[targetNode.after.length - 1];

  targetNode.after
    .filter((id) => id !== mainAfterId)
    .forEach((id) => deleteNodeRecursively(model, id, targetNodeId));

  const beforeNode = find(model.nodes, { id: targetNode.before[0] });
  const mainAfterNode = find(model.nodes, { id: mainAfterId }); // it is Plus node
  const nextNextNode = find(model.nodes, { id: mainAfterNode.after[0] });
  beforeNode.after = mainAfterNode.after;
  const index = nextNextNode.before.indexOf(mainAfterNode.id);
  nextNextNode.before[index] = beforeNode.id;

  pull(model.nodes, targetNode);
  pull(model.nodes, mainAfterNode);

  recalculate(model);

  return model;
};
