const SEED_PIPELINE_STAGES = "SEED_PIPELINE_STAGES";
const SET_PIPELINE = "SET_PIPELINE";
const EDIT_PIPELINE_STAGE = "EDIT_PIPELINE_STAGE";
const DELETE_PIPELINE_STAGE = "DELETE_PIPELINE_STAGE";
const ADD_PIPELINE_STAGE = "ADD_PIPELINE_STAGE";

export {
  SEED_PIPELINE_STAGES,
  SET_PIPELINE,
  EDIT_PIPELINE_STAGE,
  DELETE_PIPELINE_STAGE,
  ADD_PIPELINE_STAGE,
};
