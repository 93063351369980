import * as actions from "./consts";
import getAxios from "services/axios";
import { createCachedFunction } from "../utils";
import store from "store/store";

const axios = getAxios("contactV2");

const listCacher = createCachedFunction((params) => (dispatch) => {
  return axios.get("/api/lists", {
    params,
  })
    .then((response) => {
      const { data } = response;
      const listsIds = data.map((el) => el.uuid);

      if (!listsIds.length) return response;

      return getListMetrics({ uuids: listsIds })
        .then((metrics) => {
          const lists = data.map((list) => {
            return {
              ...list,
              metrics: metrics[list.uuid],
            };
          });

          dispatch({
            type: actions.LISTS_GET_SUCCESS,
            lists,
          });

          return response;
        });
    });
}, { recursively: true });


export const loadLists = listCacher.cachedFunction;


const byIdCacher = createCachedFunction((listId) => (dispatch) => {
  return axios.get(`/api/lists/${listId}`)
    .then((response) => {
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists: [response],
      });

      return response;
    });
}, { recursively: true });


export const loadListById = byIdCacher.cachedFunction;

export const getListMetrics = ({ uuids }) => {
  return axios.put("/api/lists/metrics", {
    uuids,
  });
};


export const loadListsWithPagination = ({ limit, offset }) => (dispatch) => {
  return axios.get("/api/lists/search", { params: { limit, offset } })
    .then((res) => {
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists: res.data,
      });

      return res;
    });
};

export const deleteList = (uuid) => (dispatch) => {
  return axios.delete(`/api/lists/${uuid}`)
    .then(() => {
      listCacher.clearCache(); // надо ли??
      byIdCacher.clearCache();

      dispatch({
        type: actions.LIST_DELETE_SUCCESS,
        ids: [uuid],
      });
    })
    .catch((e) => console.log(e));
};

export const updateList = ({ uuid, ...params }) => (dispatch, getState) => {
  return axios.put(`/api/lists/${uuid}`, params)
    .then((list) => {
      listCacher.clearCache();
      byIdCacher.clearCache();
      dispatch({
        type: actions.LISTS_UPDATE_SUCCESS,
        list,
      });

      return list;
    });
};

export const createList = ({ name, flow_id, user_id }) => (dispatch) => {
  return axios.post("/api/lists", { name, flow_id, user_id })
    .then((list) => {
      listCacher.clearCache();
      byIdCacher.clearCache();
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists: [list],
      });
      return list;
    });
};

export const getListOptions = (query = "", params = {}) => {
  return Promise.resolve(store.dispatch(loadLists({ ...params, filter: { q: query, ...params.filter } })))
    .then(({ data }) => data.map((list) => {
      return { value: list.uuid, label: list.name };
    }));
};

export const getListOptionsWithPagination = (params = {}) => {
  listCacher.clearCache();
  const { query, ...otherParams } = params;
  return Promise.resolve(store.dispatch(loadLists({ ...otherParams, filter: { q: query || "", ...params.filter } })))
    .then(({ data, has_more }) => {
      const options = data.map((list) => {
        return { value: list.uuid, label: list.name };
      });
      return { options, hasMore: has_more };
    });
};
