import { memo } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Navigation.module.scss";
import { path as customFieldsPagePath } from "../CustomFields";
import { pipelineCompanyPath, pipelineLeadPath } from "../PipelineStages/path";
import { path as teamPath } from "../Team";
import Icon from "components/common/elements/Icon";
import { Link } from "react-router-dom";
import { path as userPath } from "components/routes/UserSettingsGroup/User";
import Button from "components/common/buttons/Button";
import { settingsPaths } from "../path";
import { useCurrentTeam } from "feature/session/useCurrentTeam";
import linkedinFiltersPath from "components/routes/SettingsGroup/LinkedinFilters/path";
import { featureFlag } from "services/featureFlag";



const Navigation = () => {
  const team = useCurrentTeam();

  return (
    <div className={styles.container}>
      <div className={styles.Test}>
        <div className={styles.title}>
          <div className={styles.titleIcon}>
            <Icon
              icon={"settings"}
              size={14}
            />
          </div>
          <div className={styles.titleCaption}>
            <div className={styles.titleCaptionTitle}>Team settings</div>
            <div className={styles.titleCaptionTeam}>
              <div
                className={styles.titleCaptionTeamBadge}
                style={{ background: team?.color || "rgb(66, 91, 118)" }}
              >
              </div>
              <span>{`${team?.id} ${team?.name}`}</span>
            </div>
          </div>
        </div>
        <div className={styles.links}>
          <NavLink
            to={pipelineLeadPath}
            activeClassName="--active"
            className={styles.link}
          >
            Contact Pipeline Stages
          </NavLink>
          <NavLink
            to={pipelineCompanyPath}
            activeClassName="--active"
            className={styles.link}
          >
            Account Pipeline Stages
          </NavLink>
          <NavLink
            to={`${customFieldsPagePath}/lead`}
            activeClassName="--active"
            className={styles.link}
          >
            Contact Custom Fields
          </NavLink>
          <NavLink
            to={`${customFieldsPagePath}/company`}
            activeClassName="--active"
            className={styles.link}
          >
            Account Custom Fields
          </NavLink>
          <NavLink
            to={`${customFieldsPagePath}/sender_profile`}
            activeClassName="--active"
            className={styles.link}
          >
            Sender Profile Custom Fields
          </NavLink>
          <NavLink
            to={`${teamPath}`}
            activeClassName="--active"
            className={styles.link}
          >
            Team Members
          </NavLink>
          <NavLink
            to={settingsPaths.webhooks}
            activeClassName="--active"
            className={styles.link}
          >
            Webhooks
          </NavLink>
          {featureFlag.abm &&
            <NavLink
              to={linkedinFiltersPath}
              activeClassName="--active"
              className={styles.link}
            >
              Linkedin Filters
            </NavLink>
          }
          {/* <NavLink
          to={schedulesPath}
          activeClassName="--active"
          className={styles.link}
        >
          Default Schedule
        </NavLink>
        <NavLink
          to={integrationsPath}
          activeClassName="--active"
          className={styles.link}
        >
          Integrations
        </NavLink> */}
        </div>

      </div>
      <div className={styles.UserSettings}>
        <Link to={userPath}>
          <Button cancelType>
            Open user settings
            <Icon
              size={15}
              icon={"external-link"}
            >
            </Icon>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default memo(Navigation);
