import * as actions from "./consts";
import { entitySetter } from "../utils";

const ACTION_HANDLERS = {
  [actions.FLOW_METRICS_SET]: (state, { flowMetrics }) => {
    const s = { ...state };
    const keys = Object.keys(flowMetrics);
    keys.forEach((id) => {
      s.metrics[id] = flowMetrics[id];
    });
    return s;
  },
  [actions.FLOW_SENDER_PROFILES_SET]: (state, { senders }) => {
    const s = { ...state };
    const keys = Object.keys(senders);
    keys.forEach((id) => {
      s.senderProfiles[id] = senders[id];
    });
    return s;
  },
  [actions.FLOW_SENDER_PROFILES_METRICS_SET]: (state, { senderMetrics }) => {
    return { ...state, senderMetrics };
  },
};

const initialState = {
  metrics: {},
  senderProfiles: {},
};

const flowMetricsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export {
  flowMetricsReducer,
};
