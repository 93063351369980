import type { MassActionType } from "@gs/core/domain/MassAction/massActionType";
import { useFindOrFetchTag } from "domain/tag/hook/useFindOrFetchTag";
import type { MassActionTypeMap } from "@gs/core/domain/MassAction/MassAction";
import type { TagId } from "@gs/core/domain/Tag/Tag";



export const TagMassActionName = ({
  payload,
  type,
}: {
  payload: { tags?: TagId | TagId[]; removed_tags?: TagId | TagId[]; added_tags?: TagId | TagId[] }
  type: MassActionType
}) => {
  const getTagPayload = (type: MassActionType) => {
    if (type === "contact_remove_tags" || type === "account_remove_tags") {
      return { action: "Remove", tag: payload.removed_tags![0] as TagId };
    } else if (type === "contact_add_tags" || type === "account_add_tags") {
      return { action: "Add", tag: payload.added_tags![0] as TagId };
    } else if (type === "contact_replace_tags" || type === "account_replace_tags") {
      return { action: "Add", tag: payload.tags![0]! as TagId };
    }
  };

  const [tag] = useFindOrFetchTag(getTagPayload(type)!.tag);

  return (
    <>
      {getTagPayload(type)?.action}
      {" "}
      {tag?.name}
      {" "}
      tag
    </>
  );
};
