import styles from "./MainLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "components/common/elements/Preloader";
import history from "services/browserHistory";
import { path as teamsPath } from "components/routes/UserSettingsGroup/TeamsManagement";
import { path as userSettingsGroupPath } from "components/routes/UserSettingsGroup";
import { NavigationMenu } from "./NavigationMenu/NavigationMenu";
import useIsColumnPlugin from "feature/linkedinPlugin/hook/useIsColumnPlugin";
import authPath from "../../routes/authGroup/Signup/path";
import { teamsSelector } from "../../../store/entities/teams/selectors";
import useDocumentTitle from "components/common/hooks/useDocumentTitle";
import { useEffect } from "react";
import { getTeams } from "store/entities/teams/actions";
import { getSenderProfiles } from "store/entities/senderProfiles/actions";
import { loadSubscriptions } from "store/entities/subscriptions/actions";
import { Layout } from "@gs/uiKit/layout/Layout";
import { themeService } from "feature/theme/ThemeService";
import { ProfileMenu } from "components/layouts/MainLayout/ProfileMenu/ProfileMenu";
import { TeamMenu } from "components/layouts/MainLayout/TeamMenu/TeamMenu";
import { Divider } from "@gs/uiKit/layout/Divider";
import { useCurrentUser } from "feature/session/useCurrentUser";
import { mainLayoutService } from "feature/mainLayout/MainLayoutService";
import { SidebarHeader } from "components/layouts/MainLayout/SidebarHeader/SidebarHeader";
import { sessionService } from "feature/session/SessionService";

const MainLayout = ({ children }) => {
  const theme = themeService.useStore((s) => s.theme);
  const isSideMenuOpen = mainLayoutService.useStore((s) => s.isSideMenuOpen);

  const dispatch = useDispatch();
  const teamsObj = useSelector(teamsSelector);
  const { currentUser: user, currentUserStatus } = useCurrentUser();
  const teamId = sessionService.useStore((s) => s.teamId);
  useDocumentTitle(teamId, teamsObj);

  const isPlugin = useIsColumnPlugin();

  useEffect(() => {
    if (currentUserStatus !== "authorized") return;
    if (teamId) return;
    if (location.pathname.includes(userSettingsGroupPath)) return;
    history.push(teamsPath);
  }, [teamId, user]);

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getSenderProfiles({ limit: 200 }));
    dispatch(loadSubscriptions());
  }, []);
  if (currentUserStatus === "loading") {
    return (
      <Preloader />
    );
  }

  if (currentUserStatus == "unauthorized") {
    console.log("redirect to logon from layout: unauthorized");
    history.push(authPath.login + window.location.search);

    return (
      <Preloader />
    );
  }

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      {!isPlugin && (
        <Layout.Sider
          // breakpoint="lg"
          // collapsedWidth="0"
          // onBreakpoint={(broken) => {
          //   console.log("onBreakpoint", broken);
          // }}
          // onCollapse={(collapsed, type) => {
          //   console.log("onCollapse", collapsed, type);
          // }}
          collapsed={!isSideMenuOpen}
          theme={theme}
          className={styles.aside}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "var(--margin-lg)",
              marginTop: "var(--margin-sm)",
            }}
          >
            <SidebarHeader />

            <TeamMenu />
          </div>
          <Divider style={{ margin: "var(--margin-sm) 0" }} />
          <NavigationMenu className={styles.navigation} />

          <ProfileMenu
            style={{
              margin: "10px auto 20px",
            }}
          />
        </Layout.Sider>
      )}
      <Layout.Content style={{ display: "flex", flexDirection: "column" }}>
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default MainLayout;
