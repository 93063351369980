import type { ComponentType } from "react";
import React, { memo, useEffect, useState, Suspense } from "react";
import cn from "classnames";
import ErrorBoundary from "components/ErrorBoundary";
import type { GsModal, GsModalId, GsModalProps } from "../ModalService";
import { modalService } from "../ModalService";
import { ModalLoadingContent } from "./ModalLoadingContent";
import { ModalLoadingError } from "./ModalLoadingError";
import { Modal } from "./Modal";

type ModalControllerProps = {}

export const ModalController: React.FC<ModalControllerProps> = memo((props: ModalControllerProps) => {
  const [modals, setModals] = useState(modalService.modals);

  useEffect(() => {
    setModals({ ...modalService.modals });
    return modalService.subscribe((modals) => setModals({ ...modals }));
  }, []);

  return (
    <>
      { Object.entries(modals).map((entry) => {
        const [modalId, modalParams] = entry as [GsModalId, GsModal[GsModalId]];

        const config = modalService.registeredModals[modalId];
        if (!config) {
          console.warn("try to render unregistered modal", modalId);
          return null;
        }
        const Content = config.Content as ComponentType<GsModalProps<{}>>;

        return (
          <Modal
            key={modalId}
            isOpen
            onRequestClose={() => modalService.closeModal(modalId) }
            onAfterOpen={config.onAfterOpen}
            shouldCloseOnOverlayClick={config.shouldCloseOnOverlayClick}
            // shouldCloseOnEsc={false}
            className={cn("gs-modal _open", config.className)}
            overlayClassName={cn("gs-modal__overlay _open", config.overlayClassName)}
          >
            <ErrorBoundary FallbackComponent={() => <ModalLoadingError closeModal={() => modalService.closeModal(modalId)} />}>
              <Suspense fallback={<ModalLoadingContent />}>
                <Content
                  {...modalParams}
                  onRequestClose={() => modalService.closeModal(modalId)}
                />
              </Suspense>
            </ErrorBoundary>
          </Modal>
        );
      })}
    </>
  );
});
