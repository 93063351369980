import type { ComponentType, ReactNode } from "react";
import React, { memo, Component } from "react";
import Whoops from "components/common/Whoops";
import { getBugsnagErrorBoundary } from "services/bugsnag";
import { featureFlag } from "../services/featureFlag";

type FallbackComponentType = React.ComponentType<{
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}>
export const ErrorBoundary = memo<{children: ReactNode; FallbackComponent?: FallbackComponentType}>(({ children, FallbackComponent }) => {
  const ErrorBoundaryComponent = featureFlag.bugsnag ? getBugsnagErrorBoundary() : DefaultErrorBoundary;

  return (
    <ErrorBoundaryComponent
      FallbackComponent={FallbackComponent || Whoops}
    >
      { children }
    </ErrorBoundaryComponent>
  );
});
class DefaultErrorBoundary extends Component<
  {children: React.ReactNode; FallbackComponent: FallbackComponentType},
  {error: Error | null; info: React.ErrorInfo | null}
> {
  constructor(props: {children: React.ReactNode; FallbackComponent: FallbackComponentType}) {
    super(props);

    this.state = {
      error: null,
      info: null,
    };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error, info });

    console.log("componentDidCatch", error);
  }

  public render() {
    const { FallbackComponent, children } = this.props;
    if (this.state.error) {
      return (
        <FallbackComponent
          error={this.state.error}
          info={this.state.info!}
          clearError={() => this.setState({ error: null, info: null })}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
