import { getBaseAxios } from "services/axios";
import { createTagApi } from "@gs/core/domain/Tag/createTagApi";
import type { Tag, TagId } from "@gs/core/domain/Tag/Tag";
import { tagStore } from "./tagStore";

export const tagApi = (() => {
  const leadsAxios = getBaseAxios("contactV2");

  const coreTagApi = createTagApi(leadsAxios);

  const createTag: typeof coreTagApi.createTag = async (...args) => {
    const result = await coreTagApi.createTag(...args);

    tagStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const getTags: typeof coreTagApi.getTags = async (...args) => {
    const result = await coreTagApi.getTags(...args);

    tagStore.setState(
      result.data.data.reduce<Record<TagId, Tag>>((acc, tag) => {
        acc[tag.uuid] = tag;
        return acc;
      }, {}),
    );

    return result;
  };

  return {
    createTag,
    getTags,
  };
})();
