import { getBaseAxios } from "services/axios";
import { flowStore } from "./flowStore";
import { createFlowApi } from "@gs/core/domain/Flow/createFlowApi";
import type { Flow, FlowId } from "@gs/core/domain/Flow/Flow";
import withMemo from "@eabgaryan/with-memo";
import stableStringify from "fast-json-stable-stringify";

export const flowApi = (() => {
  const leadsAxios = getBaseAxios("flowV2");

  const coreFlowApi = createFlowApi(leadsAxios);

  const getFlows: typeof coreFlowApi.getFlows = async (...args) => {
    const result = await coreFlowApi.getFlows(...args);

    flowStore.setState(
      result.data.data.reduce<Record<FlowId, Flow>>((acc, flow) => {
        acc[flow.uuid] = flow;
        return acc;
      }, {}),
    );

    return result;
  };

  const getFlowsMemoized = withMemo(
    getFlows,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  const getFlow: typeof coreFlowApi.getFlow = async (...args) => {
    const result = await coreFlowApi.getFlow(...args);
    flowStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const cancelLeadFromFlows: typeof coreFlowApi.cancelLeadFromFlows = async (...args) => {
    const result = await coreFlowApi.cancelLeadFromFlows(...args);
    return result;
  };

  return {
    getFlows,
    getFlowsMemoized,
    getFlow,
    cancelLeadFromFlows,
  };
})();
