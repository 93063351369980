import { ReactComponent as ChatIcon } from "bootstrap-icons/icons/chat.svg";
import type { FC, HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Badge } from "@gs/uiKit/dataDisplay/Badge/Badge";
import type { TSenderProfileData } from "store/entities/senderProfiles/types";
import { senderProfileArraySelector } from "store/entities/senderProfiles/selectors";
import store from "store/store";
import { getSenderProfiles } from "store/entities/senderProfiles/actions";

// TODO: remove redux, add listeners
export const MessagesMenuItemIcon: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const [isHaveUnreadMessages, setIsHaveUnreadMessages] = useState(false);
  const senderProfilesData: TSenderProfileData[] = useSelector(senderProfileArraySelector);

  useEffect(() => {
    store.dispatch(getSenderProfiles());
  }, []);

  useEffect(() => {
    let count = 0;
    senderProfilesData.forEach((profile: any) => {
      count += profile.counters?.unread_count_linkedin + profile.counters?.unread_count_email;
    });
    if (count > 0) {
      setIsHaveUnreadMessages(true);
    } else {
      setIsHaveUnreadMessages(false);
    }
  }, [senderProfilesData]);

  return (
    <Badge
      dot={isHaveUnreadMessages}
      {...props}
    >
      <ChatIcon />
    </Badge>
  );
};
