import { __DEV__ } from "./env";

type Feature =
  | "editorAiBlock"
  | "webhookFilterField"
  | "bugsnag"
  | "flowTasks"
  | "darkTheme"
  | "abm"

const prodOnly = !__DEV__;
const exceptProd = __DEV__;
const localOnly = __DEV__ && window.location.host !== "new.getsales.io";


export const featureFlag: Record<Feature, boolean> = {
  get editorAiBlock() {
    return exceptProd;
  },
  get webhookFilterField() {
    return exceptProd;
  },
  flowTasks: exceptProd,
  bugsnag: prodOnly,
  darkTheme: localOnly,
  abm: exceptProd,
};
