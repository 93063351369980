import styles from "./ContentBody.module.scss";
import type { HTMLAttributes } from "react";
import cn from "classnames";

type ContentBodyProps = HTMLAttributes<HTMLSpanElement>
export const ContentBody = (props: ContentBodyProps) => (
  <div
    {...props}
    className={cn(styles.ContentBody, props.className)}
  />
);
