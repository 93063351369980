import { ConfigProvider, theme as antdTheme } from "antd";
import type { FC, ReactNode } from "react";
import { useEffect, useMemo } from "react";
import { NeverError } from "@gs/core/errors/NeverError";
import { themeService } from "../feature/theme/ThemeService";

export const Theme: FC<{children: ReactNode}> = (props) => {
  const { theme, colorPrimary } = themeService.useStore();

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  const algorithm = useMemo(() => {
    switch (theme) {
      case "light": return antdTheme.defaultAlgorithm;
      case "dark": return antdTheme.darkAlgorithm;
      default: throw new NeverError(theme);
    }
  }, [theme]);

  // перенести все css переменные в корень
  // useEffect(() => {
  //   const antNodes = document.querySelectorAll(".ant, [class^=\"ant-\"]"); // Уточните селектор по необходимости
  //
  //   antNodes.forEach((node) => {
  //     const computedStyle = getComputedStyle(node);
  //     // Перебираем все свойства стиля найденного узла
  //     for (const property of computedStyle) {
  //       if (property.startsWith("--")) { // Если свойство является CSS переменной
  //         const value = computedStyle.getPropertyValue(property).trim();
  //         // Копируем переменную и её значение в :root
  //         document.documentElement.style.setProperty(property, value);
  //       }
  //     }
  //   });
  // }, []);

  return (
    <ConfigProvider
      theme={{
        cssVar: { prefix: "" },
        hashed: false,
        token: {
          colorPrimary,
          fontFamily: "Onest",
          colorError: "#DE3531",
          colorBgLayout: theme === "dark" ? "#282A32" : "#F8F9FC", // origin for darkMode is #000
        },
        algorithm,
      }}
    >
      {props.children}
    </ConfigProvider>
  );
};
