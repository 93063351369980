export const flowStatuses = {
  ON: "on",
  OFF: "off",
  ARCHIVED: "archived",
} as const;

export const ACTIVE_FLOWS_URL_FILTER = { "status": ["on", "off"], "flow_workspace_uuid": "is_null" };

export type FlowStatus = typeof flowStatuses[keyof typeof flowStatuses]

export type TFlowData = {
  uuid: string
  team_id: number
  name: string
  flow_workspace_uuid: string | null
  flow_version_uuid: string
  first_common_node_id: number
  timezone?: string
  schedule: {
    timezone: "string"
    timeblocks: Ttimeblocks[]
  }
  description: string
  capacity?: string
  company_active_leads_count?: number
  status: FlowStatus
  is_public: boolean
  user_id: number
  created_at: string
  updated_at: string
}

export type Ttimeblocks = {
  dow: number
  min: number
  max: number
}
