import * as actions from "./consts";
import { deleteByIds, entitySetter } from "../utils";
import { FLOW_SOURCES_DELETE_SUCCESS, FLOW_SOURCES_CREATE_SUCCESS } from "../flowSources/consts";

const ACTION_HANDLERS = {
  [actions.LISTS_GET_SUCCESS]: (state, { lists }) => entitySetter(state, lists),
  [actions.LIST_DELETE_SUCCESS]: (state, { ids }) => deleteByIds(state, ids),
  [actions.LISTS_UPDATE_SUCCESS]: (state, { list }) => ({
    ...state,
    [list.uuid]: {
      ...state[list.uuid],
      ...list,
    },
  }),
  [FLOW_SOURCES_DELETE_SUCCESS]: (state, { listId }) => {
    if (!state[listId]) return state;

    return {
      ...state,
      [listId]: {
        ...state[listId],
        active_data_sources: state[listId].active_data_sources - 1,
      },
    };
  },
  [FLOW_SOURCES_CREATE_SUCCESS]: (state, { flowSource }) => {
    const listId = flowSource.list_id;
    if (!state[listId]) return state;

    return {
      ...state,
      [listId]: {
        ...state[listId],
        active_data_sources: state[listId].active_data_sources + 1,
      },
    };
  },
};

const initialState = {};

export default function listsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
