import { useEffect, useState } from "react";
import Icon from "../../../common/elements/Icon";
import Modal from "../../../common/Modal/Modal";
import { ActionsPopup } from "components/common/ActionsPopup";
import { useDispatch } from "react-redux";
import { ConfirmationContent } from "components/common/modals/Confirmation";
import { ModalContent as EditModalContent } from "../NewOrEditModal/Content";
import { deleteTemplateCategory } from "../Templates/reducer/actions";
import cn from "classnames";
import type { ICategoryItemProps } from "./interfaces";

import styles from "./Categories.module.scss";

export const CategoryItem = ({ onClick, categoryData, isActive }: ICategoryItemProps) => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);

  const { name, metrics, uuid } = categoryData;

  const handleRemove = () => {
    dispatch(deleteTemplateCategory(uuid))
      //@ts-ignore
      .then(() => {
        if (isActive) {
          onClick("");
        }
      });
  };

  useEffect(() => {
    return () => {
      setEditModal(false);
      setDeleteModal(false);
    };
  }, []);

  return (
    <div
      className={cn(styles.CategoriesItem, { [styles.active!]: isActive })}
      onClick={() => onClick(uuid)}
    >
      {/* <p onClick={() => onClick(uuid)}>{`${name} (${metrics?.count || 0})`}</p> */}
      <p>{`${name}`}</p>
      <ActionsPopup
        items={[
          {
            label: "Edit",
            action: () => setEditModal(true),
            closeAfterAction: true,
          },
          {
            label: "Delete",
            action: () => setDeleteModal(true),
            closeAfterAction: true,
          },
        ]}
        className={styles.Actions}
      >
        <Icon
          icon="more"
          size={20}
          color={"#99ACC2"}
          style={{ cursor: "pointer" }}
        />
      </ActionsPopup>
      <Modal
        isOpen={editModal}
        closeModal={() => setEditModal(false)}
      >
        <EditModalContent
          category={categoryData}
          onRequestClose={() => setEditModal(false)}
        />
      </Modal>
      <Modal
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
      >
        <ConfirmationContent
          action={handleRemove}
          confirmText="Delete"
          headerText="Delete folder"
          mainText="Are you sure you want to delete the folder?"
        />
      </Modal>
    </div>
  );
};
