import * as actions from "./consts";
import getAxios from "services/axios";

const axios = getAxios("idV2");

export const createInvite = (emails) => (dispatch) => {
  return axios.post("api/teams/send-team-invitation", { emails })
    .then((invite) => {
      dispatch({
        type: actions.SET_INVITES,
        invites: [invite],
      });

      return invite;
    });
};

export const getInvites = (params) => (dispatch) => {
  return axios.get("api/teams/pending-invitations", { params })
    .then((res) => {
      dispatch({
        type: actions.SET_INVITES,
        invites: res.data,
      });

      return res;
    });
};

export const revokeInvite = (id) => (dispatch) => {
  return axios.post(`api/teams/revoke-invitation/${id}`)
    .then((res) => {
      dispatch({
        type: actions.DELETE_INVITES,
        ids: [id],
      });

      return res;
    });
};

export const rejectInvite = (id) => (dispatch) => {
  return axios.post(`api/teams/reject-invitation/${id}`)
    .then((res) => {
      dispatch({
        type: actions.DELETE_INVITES,
        ids: [id],
      });

      return res;
    });
};

export const acceptInvite = (id) => (dispatch) => {
  return axios.post(`api/teams/accept-invitation/${id}`)
    .then((invite) => {
      dispatch({
        type: actions.SET_INVITES,
        invites: [invite],
      });

      return invite;
    });
};
