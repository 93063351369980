import throttle from "lodash/throttle";
import { OuterPromise } from "@gs/core/utils/OuterPromise";

export const createDomainBatchFetcher = <DomainId extends PropertyKey, Res>(massFetch: (id: DomainId[]) => Res) => {
  const asyncFinishMap = new Map<DomainId, OuterPromise<Awaited<Res>>>();

  const throttledBatchFetch = throttle(
    async () => {
      const currentBatchMap = new Map(asyncFinishMap);
      asyncFinishMap.clear();
      const idsArray = Array.from(currentBatchMap.keys());

      try {
        const res = await massFetch(idsArray);

        for (const id of idsArray) {
          currentBatchMap.get(id)?.resolve(res);
        }
      } catch (error) {
        console.log("throttledBatchFetch error", error);
        for (const id of idsArray) {
          currentBatchMap.get(id)?.reject();
        }
      }
    },
    50,
    { leading: false },
  );

  return (id: DomainId) => {
    let finishPromise = asyncFinishMap.get(id);
    if (!finishPromise) {
      finishPromise = new OuterPromise();
      asyncFinishMap.set(id, finishPromise);
      throttledBatchFetch();
    }
    return finishPromise as any as Promise<Awaited<Res>>;
  };
};
