import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { PipelineStage, PipelineStageId, PipelineStageMetrics } from "./PipelineStage";
import type { ApiListResponse } from "../../commonTypes";
import type { Mixed } from "../../commonTypes";
import type { UserId } from "../User/User";

export const createPipelineStageApi = (axios: AxiosInstance) => {
  const getPipelineStages = async (params: {
    limit: number
    offset: number
    filter?: {
      query?: string
      object?: Mixed<PipelineStage["object"]>
      id?: Mixed<PipelineStageId>
      name?: Mixed<string>
      type?: Mixed<PipelineStage["type"]>
      category?: Mixed<PipelineStage["category"]>
      userId?: Mixed<UserId>
      withDeleted?: boolean
    }
  }) => {
    return axios.get<ApiListResponse<PipelineStage>>("/api/pipeline-stages", {
      params: {
        filter: {
          q: params.filter?.query,
          object: params.filter?.object,
          uuid: params.filter?.id,
          name: params.filter?.name,
          type: params.filter?.type,
          category: params.filter?.category,
          user_id: params.filter?.userId,
          deleted: params.filter?.withDeleted,
        },
        limit: params.limit,
        offset: params.offset,
      },
    });
  };

  const getPipelineStage = async (id: PipelineStageId) => {
    return axios.get<PipelineStage>(`/api/pipeline-stages/${id}`);
  };

  const createPipelineStage = async (
    params: {
      fields: Pick<PipelineStage, "name" | "object" | "category"> & Partial<Pick<PipelineStage, "type" | "order">>
    },
  ) => {
    return axios.post<PipelineStage>("/api/pipeline-stages", params.fields);
  };

  const updatePipelineStage = async (params: Pick<PipelineStage, "name" | "type" | "order" | "category">) => {
    return axios.put<PipelineStage>("/api/pipeline-stages", params);
  };

  const deletePipelineStage = async (id: PipelineStageId, replaceId: PipelineStageId) => {
    return axios.delete<unknown>(`/api/pipeline-stages/${id}`, {
      params: {
        replace_pipeline_stage_uuid: replaceId,
      },
    });
  };

  const getPipelineStagesMetrics = async (
    params: {
      object: PipelineStage["object"]
      objectElasticQuery?: unknown
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Record<PipelineStageId, PipelineStageMetrics>>(
      "/api/pipeline-stages/metrics",
      {
        object: params.object,
        filter: {
          elasticQuery: params.objectElasticQuery,
        },
      },
      config,
    );
  };

  return {
    getPipelineStages,
    getPipelineStage,
    createPipelineStage,
    updatePipelineStage,
    deletePipelineStage,
    getPipelineStagesMetrics,
  };
};
