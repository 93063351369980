import { createSelector } from "reselect";

const workspacesSelector = (state) => state.entities.flowWorkspaces || {};

const workspacesArraySelector = createSelector(
  [workspacesSelector], (workspaces) => Object.values(workspaces),
);


export {
  workspacesSelector,
  workspacesArraySelector,
};
