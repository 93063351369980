import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { CompanyMassActionType } from "../MassAction/massActionType";
import type { Company, CompanyId } from "./Company";
import type { MassActionResponse } from "../MassAction/createMassActionApi";
import type { MassActionTypeMap } from "../MassAction/MassAction";
import type { Activity } from "../Activity/Activity";
import type { CompanyMarkers } from "../CompanyMarkers/CompanyMarkers";
import type { Mixed, ApiListResponse, OrderType } from "../../commonTypes";
import type { UserId } from "../User/User";
import type { LeadPack } from "../Lead/createLeadApi";
import type { DataSourceId } from "../DataSource/DataSource";
import type { PipelineStageId } from "../PipelineStage/PipelineStage";
import type { TagId } from "../Tag/Tag";
import type { ListId } from "../List/List";

export type CompanyPack = {
  activity: Activity[]
  company: Company
  markers: CompanyMarkers
}

export type CompanyMassActionFilter = {
  all: boolean
  ids: CompanyId[]
  elasticQuery?: unknown
  filter?: CompanyFilter
}

export const companyFilterToServerFormat = (companyFilter?: CompanyFilter) => companyFilter && ({
  q: companyFilter.query,
  _id: companyFilter.id,
  user_id: companyFilter.userId,
  data_source_uuid: companyFilter.dataSourceId,
  pipeline_stage_uuid: companyFilter.pipelineStageId,
  name: companyFilter.name,
  domain: companyFilter.domain,
  ln_id: companyFilter.lnId,
  linkedin: companyFilter.linkedin,
  lists: companyFilter.lists,
  tags: companyFilter.tags,
  duplicate_data_sources: companyFilter.duplicateDataSources,
  status: companyFilter.status,
  linkedin_status: companyFilter.linkedinStatus,
});

export const companyMassFilterToServerFormat = (massFilter?: CompanyMassActionFilter) => massFilter && ({
  all: massFilter.all,
  ids: massFilter.ids,
  elasticQuery: massFilter.elasticQuery,
  companyFilter: companyFilterToServerFormat(massFilter.filter),
});

export type CompanyFilter = {
  query?: string
  id?: Mixed<CompanyId>
  userId?: Mixed<UserId>
  dataSourceId?: Mixed<DataSourceId>
  pipelineStageId?: Mixed<PipelineStageId>
  name?: Mixed<Company["name"]>
  domain?: Mixed<Company["domain"]>
  lnId?: Mixed<Company["ln_id"]>
  linkedin?: Mixed<Company["linkedin"]>
  lists?: Mixed<ListId>
  tags?: Mixed<TagId>
  duplicateDataSources?: Mixed<DataSourceId>
  status?: Mixed<Company["status"]>
  linkedinStatus?: Mixed<Company["linkedin_status"]>
  // public mixed $deleted = false, // doesn't work with elastic
}

export const createCompanyApi = (axios: AxiosInstance) => {
  const getCompanies = (
    params: {
      filter?: CompanyFilter & {
        companyElasticQuery?: unknown
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<CompanyPack>>(
      "/api/companies",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: {
            elasticQuery: params.filter?.companyElasticQuery,
            companyFilter: companyFilterToServerFormat(params.filter),
          },
        },
      },
    );
  };

  const getCompany = (id: CompanyId) => {
    return axios.get<CompanyPack>(`/api/companies/${id}`);
  };

  const updateCompany = (params: { id: CompanyId; newFields: Omit<Company, "id"> }) => {
    return axios.put<CompanyPack>(`/api/companies/${params.id}`, params.newFields);
  };

  const lookupCompany = (params: {lnId?: Company["ln_id"]; linkedin?: Company["linkedin"]; website?: Company["website"]; name?: Company["name"]}) => {
    return axios.post<Company>("/api/companies/lookup", { ln_id: params.lnId, linkedin: params.linkedin, name: params.name, website: params.website });
  };

  const getCompaniesLeads = (params: {ids: CompanyId[]}, config?: AxiosRequestConfig) => {
    return axios.post<Record<CompanyId, LeadPack[]>>("/api/companies/leads", { uuids: params.ids }, config);
  };

  const callCompanyMassAction = async<TheActionType extends CompanyMassActionType> (
    params: {
      type: CompanyMassActionType
      payload?: MassActionTypeMap[TheActionType]["payload"]
      filter: CompanyMassActionFilter
    }) => {
    return axios.put<MassActionResponse<CompanyPack>>("/api/companies/mass-action", {
      type: params.type,
      payload: params.payload,
      filter: companyMassFilterToServerFormat(params.filter),
    });
  };

  const getFilterSuggestions = (params: {
    elasticQuery?: unknown
    fieldName: string
    query: string
  }) => {
    return axios.post<Array<{
      count: number
      key: string
      label: string
    }>>(
      "/api/companies/search-suggestions",
      {
        filter: {
          elasticQuery: params.elasticQuery,
        },
        suggest_name: params.fieldName,
        term: params.query,
      },
    );
  };

  return {
    getCompanies,
    getCompany,
    callCompanyMassAction,
    getFilterSuggestions,
    getCompaniesLeads,
    updateCompany,
    lookupCompany,
  };
};
